import { AuthenticationState } from "react-aad-msal";

export default {
  cases: [],
  countries: [],
  caseCategories: [],
  documents: [],
  employmentTypes: [],
  investigationTypes: [],
  roles: [],
  genders: [],
  yesNoAnswers: [],
  nationalities: [],
  remediationPartners: [],
  sites: [],
  siteStatuses: [],
  remediationStatuses: [],
  youngWorkerDecisionSources: [],
  youngWorkerConditionOfWorks: [],
  youngWorkerNatureOfWorks: [],
  investigationStatuses: [],
  caseDocumentTypes: [],
  suppliers: [],
  tiers: [],
  reports: [],
  problemDrops: [],
  licenseTypes: [],
  currencies: [],
  paymentTypes: [],
  user: {
    isAdmin: false,
    hasReportLicense: false,
    hasAppLicense: true,
    isAuthorized: true,
  },
  users: [],
  auth: {
    initializing: false,
    initialized: false,
    idToken: null,
    accessToken: null,
    state: AuthenticationState.Unauthenticated,
  },
};
