import React, { useState, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { toastr } from "../components/Common/toastr";
import useProtectApi from "../hooks/useProtectApi";

const supplierReducer = (state, { type, payload }) => {

	switch (type) {
		case "LOAD_R_SUPPLIER":
			return payload;

		case "SUPPLIER_DETAILS_CHANGED":
			return {
				...state,
				name: payload.name,
				number: payload.number,
			};

		case "SUPPLIER_ADD_NEW_NOTE":
			return {
				...state,
				notes: [...state.notes, payload],
			};

		case "SUPPLIER_REMOVE_NOTE":
			return {
				...state,
				notes: state.notes.filter((n) => n.noteId !== payload.noteId),
			};

		case "SUPPLIER_CHANGE_NOTE":
			return {
				...state,
				notes: (state.notes ? state.notes : []).map((sn) =>
					sn.noteId !== payload.noteId
						? sn
						: {
								...sn,
								noteText: payload.noteText,
						  }
				),
			};

		default:
			return state;
	}
};

const useFetchSupplier = () => {
	let { supplierId } = useParams();

	const supplierApi = useProtectApi("supplier/" + supplierId);

	const [fetchedSupplier, dispatchSupplier] = useReducer(supplierReducer, {});
	const [fetched, setFetched] = useState(false);

	useEffect(() => {
		const fetchSupplier = () => {
			supplierApi.api().then((w) =>
				w
					.get()
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						throw new Error(errDetail);
					})
					.json((supplier) => {
						dispatchSupplier({
							type: "LOAD_R_SUPPLIER",
							payload: supplier,
						});
						setFetched(true);
					})
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to retrieve Supplier details.");
					})
			);
		};

		fetchSupplier();
	}, [supplierId]);

	return {
		supplier: fetchedSupplier,
		supplierFetched: fetched,
		supplierDispatcher: dispatchSupplier,
	};
};

export default useFetchSupplier;
