import React, { useState } from "react";
import useStyles from "../../components/Common/styles";
import { useHistory, useParams } from "react-router-dom";
import { toastr } from "../../components/Common/toastr";
import { Backdrop } from "@material-ui/core";
import useProtectApi from "../../hooks/useProtectApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import Spinner from "../../components/Common/Spinner";
import wretch from "wretch";

const ReportView = () => {
  const classes = useStyles();
  const history = useHistory();
  const { reportId } = useParams();

  const reportApi = useProtectApi("report/");
  const ticketApi = useProtectApi("qlik/ticket");
  const settingsApi = useProtectApi("qlik/settings");

  const [report, setReport] = React.useState(null);
  const [qlikTicket, setQlikTicket] = React.useState(null);
  const [qlikSettings, setQlikSettings] = React.useState(null);

  const [reportUrl, setReportUrl] = React.useState(null);

  const [cookiePresent, setCookiePresent] = React.useState(false);
  const [ticketRequired, setTicketRequired] = React.useState(false);

  React.useEffect(() => {
    const fetchReport = () => {
      reportApi.api().then((w) =>
        w
          .url(reportId)
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .forbidden((e) => {
            console.info("Access to Reports forbidden");
            toastr.error("You do not have the license required to access Reports");
            history.push("/home");
            return;
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((report) => {
            setReport(report);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Report details.");
          })
      );
    };

    !report && fetchReport();
  }, [report]);

  React.useEffect(() => {
    const fetchQlikSettings = () => {
      settingsApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .forbidden((e) => {
            console.info("Access to Reports forbidden");
            toastr.error("You do not have the license required to access Reports");
            history.push("/home");
            return;
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((json) => {
            setQlikSettings(json);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve report settings");
          })
      );
    };

    report && fetchQlikSettings();
  }, [report]);

  React.useEffect(() => {
    const downloadQlikAssetWithoutTicket = () => {
      wretch()
        .accept("*/*")
        .url(qlikSettings.cookieAssetUri)
        .options({ credentials: "include" })
        .get()
        .res(() => {
          console.info("html page received");
          setCookiePresent(true);
        })
        .catch((error) => {
          console.log(error);
          setCookiePresent(false);
          setTicketRequired(true);
        });
    };

    report && qlikSettings && downloadQlikAssetWithoutTicket();
  }, [report, qlikSettings]);

  React.useEffect(() => {
    const fetchQlikTicket = () => {
      console.info("fetching Qlik ticket");

      ticketApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .forbidden((e) => {
            console.info("Access to Reports forbidden");
            toastr.error("You do not have the license required to access Reports");
            history.push("/home");
            return;
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((qt) => {
            setQlikTicket(qt.ticket);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve report access ticket.");
          })
      );
    };

    report && ticketRequired && fetchQlikTicket();
  }, [report, ticketRequired]);

  React.useEffect(() => {
    const downloadQlikAssetWithTicket = () => {
      console.info("downloading Qlik Asset");

      wretch()
        .accept("*/*")
        .url(qlikSettings.cookieAssetUri)
        .query({ QlikTicket: qlikTicket })
        .options({ credentials: "include" })
        .get()
        .res(() => {
          console.info("html page received");
          setCookiePresent(true);
        })
        .catch((error) => {
          console.log(error);
          toastr.error("Unable to display Report.");
        });
    };

    report && qlikTicket && ticketRequired && qlikSettings && downloadQlikAssetWithTicket();
  }, [report, qlikTicket, qlikSettings, ticketRequired]);

  React.useEffect(() => {
    const constructReportUrl = () => {
      // assemble url with qlikSettings
      // var url = qlikSettings.sheetUri;
      var url = qlikSettings.proxyUri;
      // url += "?appid=" + report.qlikAppId;
      url += `/${report.qlikObjectUriSegment}`;

      console.info(url);
      setTimeout(function () {
        setReportUrl(url);
      }, 1000);
    };

    cookiePresent && !reportUrl && constructReportUrl();
  }, [cookiePresent, reportUrl]);

  return (
    <>
      {!!reportUrl && <iframe src={reportUrl} frameBorder="0" className={classes.iFrame}></iframe>}
      <Spinner show={!reportUrl} />
    </>
  );
};

export default ReportView;
