import React, { useState, useEffect } from "react";
import { Button, TextField, Grid, Typography, Tab, Tabs, TabPanel } from "@material-ui/core";
import useStyles from "../../components/Common/styles";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Blade, BladeValidationDisplay, BladeTabPanel } from "../../components/Common/Blade";
import { toastr } from "../../components/Common/toastr";
import useProtectApi from "../../hooks/useProtectApi";
import { CaseCtx } from "./case.manage";
import { useDispatch } from "react-redux";
import { useFetchCaseDocumentTypes, useFetchDocumentUploadSettings } from "../../hooks/useFetchEntities";
import { Autocomplete } from "@material-ui/lab";
import FormatDate from "../../helpers/date.display.format";
import MUIDataTable from "mui-datatables";

const CaseAddDocument = () => {
  const [tabValue, setTabValue] = React.useState(0);

  const [caseDocumentTypes, caseDocumentTypesFetched] = useFetchCaseDocumentTypes();
  const [defaultCaseDocumentType, setDefaultCaseDocumentType] = React.useState(null);

  let { caseId, caseDocumentType } = useParams();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  useEffect(() => {
    var caseDocumentTypeFromParam = (caseDocumentTypes.length === 0 ? [] : caseDocumentTypes).find(
      (cdt) => cdt.id === parseInt(caseDocumentType)
    );

    caseDocumentTypeFromParam && setDefaultCaseDocumentType(caseDocumentTypeFromParam.name);
  }, [caseDocumentType, caseDocumentTypesFetched, caseDocumentTypes, defaultCaseDocumentType]);

  return !defaultCaseDocumentType ? (
    <div></div>
  ) : (
    <Blade size="Largest">
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="user tabs"
        variant="fullWidth"
        indicatorColor="primary"
      >
        <Tab label="Attach Existing" {...a11yProps(0)} />
        <Tab label="Add New Document" {...a11yProps(1)} />
      </Tabs>
      <BladeTabPanel value={tabValue} index={0}>
        <AddExistingDocument caseId={caseId} caseDocumentType={defaultCaseDocumentType} />
      </BladeTabPanel>
      <BladeTabPanel value={tabValue} index={1}>
        <AddNewDocument caseId={caseId} caseDocumentType={defaultCaseDocumentType} />
      </BladeTabPanel>
    </Blade>
  );
};

const AddExistingDocument = ({ caseId, caseDocumentType }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { spCase, caseDispatcher, caseFetched } = React.useContext(CaseCtx);
  const [caseDocumentTypes, caseDocumentTypesFetched] = useFetchCaseDocumentTypes();

  const { register, handleSubmit, errors } = useForm();

  const getAvailableDocumentsApi = useProtectApi("document/country/");
  const attachDocumentsApi = useProtectApi("casedocument/attach");

  const [createErrors, setCreateErrors] = React.useState();
  const [isBusy, setIsBusy] = React.useState(false);

  const [availableDocuments, setAvailableDocuments] = React.useState([]);
  const [availableDocumentsFetched, setAvailableDocumentsFetched] = React.useState(false);
  const [selectedDocumentIds, setSelectedDocumentIds] = React.useState([]);
  const [rowsSelected, setRowsSelected] = React.useState([]);

  useEffect(() => {
    const fetchAvailableDocuments = () => {
      getAvailableDocumentsApi.api().then((w) =>
        w
          .url(spCase.audit.country)
          .get()
          .json((json) => {
            setAvailableDocuments(json);
            return json;
          })
          .then(() => setAvailableDocumentsFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Documents to attach to Case"))
      );
    };

    if (!availableDocumentsFetched) {
      availableDocuments.length === 0 && caseFetched && fetchAvailableDocuments();
    }
  }, [caseFetched && availableDocuments, availableDocumentsFetched]);

  useEffect(() => {
    var ids = [];
    rowsSelected.forEach((rowIndex) => {
      var item = availableDocuments[rowIndex];
      ids.push(item.id);
    });

    console.info(ids);

    setSelectedDocumentIds(ids);
  }, [rowsSelected]);

  const FindDocument = (documentId) => {
    return availableDocuments.find((d) => d.id === documentId);
  };

  const onSubmit = async (data) => {
    var request = {
      caseId: caseId,
      documentIds: selectedDocumentIds,
      caseDocumentType: data.caseDocumentType,
    };

    const attachDocs = () =>
      attachDocumentsApi.api().then((w) =>
        w
          .post(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setCreateErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setCreateErrors(errDetail);
            throw new Error(errDetail);
          })
          .json((json) => {
            json.forEach((element) => {
              var doc = FindDocument(element.documentId);

              var caseDocument = {
                caseDocumentId: element.caseDocumentId,
                documentId: element.documentId,
                caseDocumentType: data.caseDocumentType,
                caseDocumentTypeId: element.caseDocumentTypeId,
                fileName: doc.fileName,
                description: doc.description,
              };

              caseDispatcher({
                type: "DOCUMENT_ATTACHED_TO_CASE",
                payload: caseDocument,
              });

              dispatch({
                type: "DOCUMENT_ATTACHED_TO_CASE",
                payload: { id: element.documentId },
              });
            });

            return json;
          })
          .then(() => {
            setSelectedDocumentIds([]);
            setAvailableDocuments([]);
            setAvailableDocumentsFetched(false);
          })
          .then(() => toastr.success("Document attached to Case " + spCase.number))
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to attach Document to Case " + spCase.number);
          })
          .finally(() => {
            setIsBusy(false);
          })
      );
    setIsBusy(true);
    attachDocs();
  };

  const gridOptions = {
    filterType: "multiselect",
    selectableRows: "multiple",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 30],
    print: false,
    download: false,
    filter: false,
    searchOpen: true,
    viewColumns: false,
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    disableToolbarSelect: true,
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
        if (colIndex === 3) {
          return (new Date(a.data[colIndex]) < new Date(b.data[colIndex]) ? -1 : 1) * (order === "desc" ? 1 : -1);
        } else {
          return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === "desc" ? 1 : -1);
        }
      });
    },
    textLabels: {
      body: {
        noMatch: "Sorry, no Documents found",
      },
    },
    setTableProps: () => {
      return {
        padding: "checkbox",
        size: "small",
      };
    },
    rowsSelected: rowsSelected,
    onRowsSelect: (rowsSelected, allRows) => {
      setRowsSelected(allRows.map((row) => row.dataIndex));
    },
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "fileName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "createdDate",
      label: "Upload Date",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return FormatDate(value);
        },
      },
    },
    {
      name: "createdBy",
      label: "Uploaded By",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <section name="Case Document Type">
        <Autocomplete
          id="caseDocumentTypeAutocomplete"
          options={caseDocumentTypes && caseDocumentTypes.map((r) => r.name)}
          getOptionLabel={(option) => option}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                name="caseDocumentType"
                label={"Document Type *"}
                fullWidth
                inputRef={register({
                  required: {
                    value: true,
                    message: "Document Type is required",
                  },
                })}
                error={!!(errors && errors.caseDocumentType)}
                helperText={errors && errors.caseDocumentType && errors.caseDocumentType.message}
                style={{ marginBottom: 16 }}
              />
            );
          }}
          defaultValue={caseDocumentType}
        />
      </section>

      <section name="Document selection">
        <div style={{ marginBottom: 16 }}>
          <MUIDataTable title={""} data={availableDocuments} columns={gridColumns} options={gridOptions} />
        </div>
      </section>

      {!!createErrors && <BladeValidationDisplay validationErrors={createErrors} />}

      <div className={classes.drawerBladeToolbar}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          type="submit"
          className={classes.button}
          disabled={isBusy}
          endIcon={<SaveIcon />}
        >
          ADD
        </Button>
      </div>
    </form>
  );
};

const AddNewDocument = ({ caseId, caseDocumentType }) => {
  const classes = useStyles();
  const history = useHistory();
  const { spCase, caseDispatcher } = React.useContext(CaseCtx);
  const createdAndAttachDocApi = useProtectApi("casedocument/create");
  const [caseDocumentTypes, caseDocumentTypesFetched] = useFetchCaseDocumentTypes();
  const { documentSettings, documentSettingsFetched } = useFetchDocumentUploadSettings();

  const { register, handleSubmit, errors, clearError } = useForm();
  const [createErrors, setCreateErrors] = React.useState();
  const [isBusy, setIsBusy] = React.useState(false);

  const [blockSubmit, setBlockSubmit] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState();
  const [selectedFileName, setSelectedFileName] = useState();
  const [selectFilePrompt, setSelectFilePrompt] = useState("Select File...");

  const closeDrawer = () => {
    history.push("/case/" + spCase.id);
  };

  const onSubmit = async (data) => {
    if (blockSubmit) {
      return;
    }

    var request = {
      fileName: selectedFileName,
      document: selectedDocument,
      caseId: caseId,
      caseDocumentType: data.caseDocumentType,
      description: data.description,
    };
    const createdAndAttachDoc = () =>
      createdAndAttachDocApi.api().then((w) =>
        w
          .formData(request)
          .post()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setCreateErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setCreateErrors(errDetail);
            throw new Error(errDetail);
          })
          .json((json) => {
            var caseDocument = {
              caseDocumentId: json.caseDocumentId,
              documentId: json.documentId,
              caseDocumentType: data.caseDocumentType,
              caseDocumentTypeId: json.caseDocumentTypeId,
              fileName: json.fileName,
              description: request.description,
              createdDate: json.createdDate,
              createdBy: json.createdBy,
              attachedDate: json.attachedDate,
              attachedBy: json.attachedBy,
              countryName: json.countryName,
            };

            caseDispatcher({
              type: "DOCUMENT_ATTACHED_TO_CASE",
              payload: caseDocument,
            });

            return json;
          })
          .then(() => toastr.success("Document attached to Case " + spCase.number))
          .then(() => closeDrawer())
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to attach Document to Case " + spCase.number);
          })
          .finally(() => {
            setIsBusy(false);
          })
      );
    setIsBusy(true);
    createdAndAttachDoc();
  };

  const ValidateFileSize = (file) => {
    if (file.size > documentSettings.maxFileSize) {
      setBlockSubmit(true);
    } else {
      setBlockSubmit(false);
    }
  };

  const setFile = (e) => {
    if (e.target.files.length > 0) {
      setSelectFilePrompt("Change File...");
      setSelectedFileName(e.target.files[0].name);
      setSelectedDocument(e.target.files[0]);
      clearError("document");

      ValidateFileSize(e.target.files[0]);
    } else {
      setSelectFilePrompt("Select File...");
      setSelectedFileName("");
      setSelectedDocument(null);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <section name="Case Document Type">
        <Autocomplete
          id="caseDocumentTypeAutocomplete"
          options={caseDocumentTypes && caseDocumentTypes.map((r) => r.name)}
          getOptionLabel={(option) => option}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                name="caseDocumentType"
                label={"Document Type *"}
                fullWidth
                inputRef={register({
                  required: {
                    value: true,
                    message: "Document Type is required",
                  },
                })}
                error={!!(errors && errors.caseDocumentType)}
                helperText={errors && errors.caseDocumentType && errors.caseDocumentType.message}
                style={{ marginBottom: 16 }}
              />
            );
          }}
          defaultValue={caseDocumentType}
        />
      </section>

      <Grid container spacing={2} style={{ marginBottom: 16 }}>
        <Grid item xs={5}>
          <React.Fragment>
            <input
              color="secondary"
              type="file"
              onChange={(e) => setFile(e)}
              id="icon-button-file"
              name="document"
              style={{ display: "none" }}
              accept={documentSettingsFetched && documentSettings.allowedExtensions.toString()}
              ref={register({ required: true })}
            />
            <label htmlFor="icon-button-file">
              <Button variant="contained" component="span" className={classes.button} color="secondary">
                {selectFilePrompt}
              </Button>
            </label>
          </React.Fragment>
        </Grid>
        <Grid item xs={7}>
          <div style={{ overflowWrap: "break-word" }}>
            <Typography>{selectedFileName}</Typography>
          </div>
        </Grid>
      </Grid>

      {errors.document && <BladeValidationDisplay validationErrors="You must select a File" marginBottom="8" />}

      {blockSubmit && (
        <BladeValidationDisplay validationErrors="The file selected is to large to upload" marginBottom="8" />
      )}

      <section name="Document Description">
        <TextField
          label="Description"
          fullWidth
          name="description"
          multiline
          autoComplete="off"
          rows="6"
          error={!!(errors && errors.description)}
          style={{ marginBottom: 16 }}
          inputRef={register()}
          inputProps={{
            "aria-label": "Document description",
          }}
          helperText={errors && errors.description && errors.description.message}
        />
      </section>

      {!!createErrors && <BladeValidationDisplay validationErrors={createErrors} />}

      <div className={classes.drawerBladeToolbar}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          type="submit"
          className={classes.button}
          disabled={isBusy}
          endIcon={<SaveIcon />}
        >
          SAVE
        </Button>
      </div>
    </form>
  );
};

export default CaseAddDocument;
