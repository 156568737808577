import initialState from "../initialState";
import { sortByName } from "../sortEntities";

export const countries = (state = initialState.countries, action) => {
	switch (action.type) {
		case "LOAD_COUNTRIES":
			return [...action.payload];

		case "LOAD_COUNTRY":
			return state.map((c) =>
				c.id !== action.payload.id
					? c
					: {
							...c,
					  }
			);

		case "COUNTRY_MODIFIED":
			return state
				.map((c) =>
					c.id !== action.payload.id
						? c
						: {
								...c,
								name: action.payload.name,
								code: action.payload.code,
						  }
				)
				.sort(sortByName);

		case "CREATE_COUNTRY":
			return [...state, action.payload].sort(sortByName);

		case "REMOVE_COUNTRY":
			return state.filter((country) => country.id !== action.payload.id);

		default:
			return state;
	}
};

export default countries;
