import React, { useState } from "react";
import { Button, TextField, Typography, Switch, FormControlLabel } from "@material-ui/core";
import useStyles from "../../components/Common/styles";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Blade, BladeValidationDisplay } from "../../components/Common/Blade";
import { toastr } from "../../components/Common/toastr";
import useProtectApi from "../../hooks/useProtectApi";

const SupplierCreate = () => {
	const classes = useStyles();
	const { register, handleSubmit, errors } = useForm();
	const history = useHistory();
	const dispatch = useDispatch();
	const supplierCreateApi = useProtectApi("supplier/create");

	const [createErrors, setCreateErrors] = useState();
	const [isBusy, setIsBusy] = useState(false);

	const onSubmit = async (data) => {
		var supplierRequest = {
			name: data.name,
			number: data.number,
		};

		const createSupplier = () =>
			supplierCreateApi.api().then((w) =>
				w
					.post(supplierRequest)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setCreateErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setCreateErrors(errDetail);
						throw new Error(errDetail);
					})
					.json((json) => {
						supplierRequest.id = json.id;
						dispatch({
							type: "CREATE_SUPPLIER",
							payload: supplierRequest,
						});
					})
					.then(() => toastr.success("Supplier created"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to create Supplier.");
					})
					.finally(() => setIsBusy(false))
			);
		setIsBusy(true);
		createSupplier();
	};

	const closeDrawer = () => {
		history.push("/suppliers");
	};

	return (
		<Blade title="Create Supplier">
			<div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<TextField
						label="Name *"
						fullWidth
						name="name"
						error={!!(errors && errors.name)}
						autoComplete="off"
						style={{ marginBottom: 16 }}
						inputRef={register({
							required: {
								value: true,
								message: "Name is required",
							},
							maxLength: {
								value: 255,
								message: "Name is too long",
							},
						})}
						inputProps={{
							"aria-label": "Description",
						}}
						helperText={errors && errors.name && errors.name.message}
					/>

					<TextField
						label="Code *"
						fullWidth
						name="number"
						error={!!(errors && errors.number)}
						autoComplete="off"
						inputRef={register({
							required: {
								value: true,
								message: "Code is required",
							},
							maxLength: {
								value: 255,
								message: "Code is too long",
							},
						})}
						inputProps={{
							"aria-label": "Description",
						}}
						helperText={errors && errors.number && errors.number.message}
						style={{ marginBottom: 16 }}
					/>

					{!!createErrors && <BladeValidationDisplay validationErrors={createErrors} />}

					<div className={classes.drawerBladeToolbar}>
						<Button
							variant="contained"
							color="primary"
							size="medium"
							type="submit"
							className={classes.button}
							endIcon={<SaveIcon />}
							disabled={isBusy}>
							SAVE
						</Button>
					</div>
				</form>
			</div>
		</Blade>
	);
};

export default SupplierCreate;
