import { makeStyles, alpha } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  search: {
    position: "relative",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
  },
  inputRoot: {
    color: "white",
    // width: 300,
  },
  inputInput: {
    width: "100%",
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: 300,
    },
  },
  menuItem: {
    width: 300,
  },
}));
