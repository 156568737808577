import React, { useState } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import useStyles from "../../components/Common/styles";
import SaveIcon from "@material-ui/icons/Save";
import { useHistory, useParams } from "react-router-dom";
import { toastr } from "../../components/Common/toastr";
import { Blade, BladeValidationDisplay } from "../../components/Common/Blade";
import useProtectApi from "../../hooks/useProtectApi";
import { useForm } from "react-hook-form";
import { DeleteButton } from "../../components/Common/Buttons";
import { SiteCtx } from "./site.manage";

const SiteManageNote = () => {
	const classes = useStyles();
	const history = useHistory();
	const { register, handleSubmit, errors } = useForm();
	const removeSiteNoteApi = useProtectApi("site/removeNote");
	const changeSiteNoteApi = useProtectApi("site/changeNote");
	const { site, siteDispatcher } = React.useContext(SiteCtx);

	let { siteId, noteId } = useParams();

	const note = site.notes.find((a) => a.noteId === noteId);
	!note && history.push("/site/manage/" + siteId);

	const [modifyErrors, setModifyErrors] = useState();
	const [isBusy, setIsBusy] = useState(false);

	const closeDrawer = () => {
		history.push("/site/manage/" + siteId);
	};

	const removeNoteClick = async () => {
		// delete note
		var request = {
			siteId: siteId,
			noteId: noteId,
		};

		const removeNote = () =>
			removeSiteNoteApi.api().then((w) =>
				w
					.put(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setModifyErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setModifyErrors(errDetail);
						throw new Error(errDetail);
					})
					.res((response) => {
						siteDispatcher({
							type: "SITE_REMOVE_NOTE",
							payload: { noteId: noteId },
						});
					})
					.then(() => toastr.success("Site note removed"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to remove note.");
					})
					.finally(() => {
						setIsBusy(false);
					})
			);

		setIsBusy(true);
		removeNote();
	};

	const formSubmit = async (data) => {
		var request = {
			siteId: siteId,
			noteText: data.noteText,
			noteId: noteId,
		};

		const changeNoteText = () =>
			changeSiteNoteApi.api().then((w) =>
				w
					.put(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setModifyErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setModifyErrors(errDetail);
						throw new Error(errDetail);
					})
					.res((response) => {
						siteDispatcher({
							type: "SITE_CHANGE_NOTE",
							payload: { ...note, noteText: data.noteText },
						});
					})
					.then(() => toastr.success("Note change"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to change Note.");
					})
					.finally(() => {
						setIsBusy(false);
					})
			);

		setIsBusy(true);
		changeNoteText();
	};

	return (
		<Blade title="Change Note">
			<div>
				<form onSubmit={handleSubmit(formSubmit)}>
					<section>
						<TextField
							label="Note *"
							fullWidth
							autoFocus
							name="noteText"
							multiline
							autoComplete="off"
							rows="6"
							error={!!(errors && errors.noteText)}
							style={{ marginBottom: 16 }}
							inputRef={register({
								required: {
									value: true,
									message: "Note Text is required",
								},
							})}
							inputProps={{
								"aria-label": "Note Text",
							}}
							helperText={errors && errors.noteText && errors.noteText.message}
							defaultValue={note && note.noteText}
						/>
					</section>

					{!!modifyErrors && <BladeValidationDisplay validationErrors={modifyErrors} />}
					<div className={classes.drawerBladeToolbar}>
						<DeleteButton
							variant="contained"
							size="medium"
							onClick={removeNoteClick}
							className={classes.deleteButton}
							text={"REMOVE"}
						/>

						<Button
							variant="contained"
							color="primary"
							size="medium"
							type="submit"
							className={classes.button}
							style={{ marginLeft: 16 }}
							disabled={isBusy}
							endIcon={<SaveIcon />}>
							SAVE
						</Button>
					</div>
				</form>
			</div>
		</Blade>
	);
};

export default SiteManageNote;
