const sortByName = (a, b) => {
	return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
};

const sortByIsOther = (a, b) => {
	if (!a.isOther) {
		return sortByName(a, b);
	}
	if (a.isOther) return 1;
};

export { sortByName, sortByIsOther };
