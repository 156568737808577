import { combineReducers } from "redux";
import cases from "./cases";
import caseCategories from "./caseCategories";
import countries from "./countries";
import documents from "./documents";
import employmentTypes from "./employmentTypes";
import investigationTypes from "./investigationTypes";
import {
  genders,
  yesNoAnswers,
  youngWorkerConditionOfWorks,
  roles,
  youngWorkerNatureOfWorks,
  youngWorkerDecisionSources,
  caseDocumentTypes,
  licenseTypes,
} from "./lookups";
import nationalities from "./nationalities";
import remediationPartners from "./remediationPartners";
import sites from "./sites";
import siteStatuses from "./siteStatuses";
import remediationStatuses from "./remediationStatuses";
import investigationStatuses from "./investigationStatuses";
import suppliers from "./suppliers";
import tiers from "./tiers";
import user from "./user";
import users from "./users";
import auth from "./auth";
import reports from "./reports";
import problemDrops from "./problemDrops";
import currencies from "./currencies";
import paymentTypes from "./paymentTypes";

export default combineReducers({
  user,
  countries,
  auth,
  cases,
  caseCategories,
  documents,
  employmentTypes,
  investigationTypes,
  roles,
  genders,
  yesNoAnswers,
  youngWorkerConditionOfWorks,
  youngWorkerNatureOfWorks,
  youngWorkerDecisionSources,
  nationalities,
  remediationPartners,
  sites,
  siteStatuses,
  investigationStatuses,
  remediationStatuses,
  suppliers,
  tiers,
  users,
  caseDocumentTypes,
  reports,
  licenseTypes,
  problemDrops,
  currencies,
  paymentTypes,
});
