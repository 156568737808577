import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Redux from "./redux";
import Main from "./components/Common/Main";
import { Routes } from "./pages/routes";
import Theme from "./theme/";
import { authProvider, AuthProviderCtx } from "./auth/authProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { AzureAD } from "react-aad-msal";
import { store } from "./redux/store";

function App() {
  let provider = authProvider();

  console.info(window.location.href);

  if (window.location.href.indexOf("AADB2C90118") > -1) {
    window.localStorage.setItem("resetPassword", true);
    provider.createSignInProvider();

    console.info("resetPassword-true");

    window.location.reload();
  } else if (window.localStorage.getItem("resetPassword")) {
    window.localStorage.removeItem("resetPassword");
    provider.createResetPasswordProvider();

    console.info("resetPassword-false");

    window.localStorage.setItem("resettingPassword", true);

    console.info("resettingPassword-true");
  } else if (window.localStorage.getItem("resettingPassword")) {
    window.localStorage.removeItem("resettingPassword");

    console.info("resettingPassword-false");

    provider.createSignInProvider();

    console.info("logging out");

    provider.provider().logout();

    console.info("refreshing");

    window.location.reload();
  } else {
    console.info("back to normal");

    provider.createSignInProvider();
  }

  var appAuthProvider = provider.provider();
  return (
    <AzureAD provider={appAuthProvider} reduxStore={store} forceLogin={true}>
      <AuthProviderCtx.Provider value={appAuthProvider}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router>
            <Redux>
              <Theme>
                <Main>
                  <Routes />
                </Main>
              </Theme>
            </Redux>
          </Router>
        </MuiPickersUtilsProvider>
      </AuthProviderCtx.Provider>
    </AzureAD>
  );
}
export default App;
