import initialState from "../initialState";
import { sortByName } from "../sortEntities";

export const tiers = (state = initialState.tiers, action) => {
	switch (action.type) {
		case "LOAD_TIERS":
			return [...action.payload];

		case "LOAD_TIER":
			return state.map((t) =>
				t.id !== action.payload.id
					? t
					: {
							...t,
					  }
			);

		case "CREATE_TIER":
			return [...state, action.payload].sort(sortByName);

		case "RENAME_TIER":
			return state
				.map((x) => (x.id !== action.payload.id ? x : { ...x, name: action.payload.name }))
				.sort(sortByName);

		case "REMOVE_TIER":
			return state.filter((tier) => tier.id !== action.payload.id);

		default:
			return state;
	}
};

export default tiers;
