import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./home/home";
import Cases from "./cases/cases";
import Suppliers from "./suppliers/suppliers";
import Sites from "./sites/sites";
import Documents from "./documents/documents";
import Reports from "./reports/reports";
import Users from "./users/users";
import Countries from "./countries/countries";
import Currencies from "./currencies/currencies";
import CaseCategories from "./caseCategories/casecategories";
import EmploymentTypes from "./employmentTypes/employmenttypes";
import InvestigationTypes from "./investigationTypes/investigationtypes";
import Nationalities from "./nationalities/nationalities";
import RemediationPartners from "./remediationPartners/remediationpartners";
import SiteStatuses from "./siteStatuses/sitestatuses";
import InvestigationStatuses from "./investigationStatuses/investigationstatuses";
import RemediationStatuses from "./remediationStatuses/remediationstatuses";
import Tiers from "./tiers/tiers";
import AdminRoute from "../auth/AdminRoute";
import SupplierManage from "./suppliers/supplier.manage";
import SiteManage from "./sites/site.manage";
import CaseManage from "./cases/case.manage";
import ReportView from "./reports/report.view";
import ProblemDocumentDrops from "./problemdrops/problemDocumentDrops";
import PaymentTypes from "./paymentTypes/paymenttypes";

export const Routes = () => {
  return (
    <Switch>
      <Route path="/home">
        <Home />
      </Route>
      <Route path="/cases">
        <Cases />
      </Route>
      <Route path="/case/:caseId">
        <CaseManage />
      </Route>
      <Route path="/suppliers">
        <Suppliers />
      </Route>
      <Route path="/supplier/manage/:supplierId">
        <SupplierManage />
      </Route>
      <Route path="/sites">
        <Sites />
      </Route>
      <Route path="/site/manage/:siteId">
        <SiteManage />
      </Route>
      <Route path="/documents">
        <Documents />
      </Route>
      <Route path="/problemdrops">
        <ProblemDocumentDrops />
      </Route>
      <Route path="/reports">
        <Reports />
      </Route>
      <Route path="/report/view/:reportId">
        <ReportView />
      </Route>
      <AdminRoute path="/users">
        <Users />
      </AdminRoute>
      <AdminRoute path="/countries">
        <Countries />
      </AdminRoute>
      <AdminRoute path="/currencies">
        <Currencies />
      </AdminRoute>
      <AdminRoute path="/casecategories">
        <CaseCategories />
      </AdminRoute>
      <AdminRoute path="/employmenttypes">
        <EmploymentTypes />
      </AdminRoute>
      <AdminRoute path="/investigationtypes">
        <InvestigationTypes />
      </AdminRoute>
      <AdminRoute path="/nationalities">
        <Nationalities />
      </AdminRoute>
      <AdminRoute path="/remediationpartners">
        <RemediationPartners />
      </AdminRoute>
      <AdminRoute path="/sitestatuses">
        <SiteStatuses />
      </AdminRoute>
      <AdminRoute path="/remediationstatuses">
        <RemediationStatuses />
      </AdminRoute>
      <AdminRoute path="/paymenttypes">
        <PaymentTypes />
      </AdminRoute>
      <AdminRoute path="/investigationstatuses">
        <InvestigationStatuses />
      </AdminRoute>
      <AdminRoute path="/tiers">
        <Tiers />
      </AdminRoute>
      <Route path="">
        <Redirect from="/" to="home" />
      </Route>
    </Switch>
  );
};
