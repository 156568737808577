import initialState from "../initialState";
import { sortByName } from "../sortEntities";

export const currencies = (state = initialState.currencies, action) => {
	switch (action.type) {
		case "LOAD_CURRENCIES":
			return [...action.payload];

		case "LOAD_CURRENCY":
			return state.map((x) =>
				x.id !== action.payload.id
					? XMLDocument
					: {
							...x,
					  }
			);

		case "CREATE_CURRENCY":
			return [...state, action.payload].sort(sortByName);

		case "RENAME_CURRENCY":
			return state
				.map((x) => (x.id !== action.payload.id ? x : { ...x, name: action.payload.name }))
				.sort(sortByName);

		case "REMOVE_CURRENCY":
			return state.filter((x) => x.id !== action.payload.id);

		default:
			return state;
	}
};

export default currencies;
