import React, { useRef } from "react";
import { Grid, TextField, InputAdornment, MenuItem, Menu } from "@material-ui/core";
import useStyles from "./styles";
import { useFetchYesNoAnswers, useFetchCurrencies, useFetchPaymentTypes } from "../../hooks/useFetchEntities";
import useProtectApi from "../../hooks/useProtectApi";
import { Autocomplete } from "@material-ui/lab";
import { toastr } from "../../components/Common/toastr";
import { useForm } from "react-hook-form";
import { CaseCtx, CaseTabReadOnlyToolbar, CaseTabEditingToolbar, CaseDocumentsDisplay } from "./case.manage";
import moment from "moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useDispatch } from "react-redux";
import { CaseDocumentTypes } from "./case.document.types";
import CurrencyInput from "../../components/Common/CurrencyInput";
import DecimalInput from "../../components/Common/DecimalInput";
import FormatDate from "../../helpers/date.display.format";

const CaseRemediation = ({ tabIndex, beginEdit, finishEdit, manageDocument }) => {
  const [editing, setEditing] = React.useState(false);

  const enableEditing = () => {
    setEditing(true);
    beginEdit(tabIndex);
  };

  const finishEditing = () => {
    setEditing(false);
    finishEdit(tabIndex);
  };

  return editing ? (
    <CaseRemediationEditing finishEditing={finishEditing} />
  ) : (
    <CaseRemediationReadOnly enableEditing={enableEditing} manageDocument={manageDocument} />
  );
};

const CaseRemediationEditing = ({ finishEditing }) => {
  const { spCase, caseDispatcher } = React.useContext(CaseCtx);

  const dispatch = useDispatch();
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [yesNoAnswers] = useFetchYesNoAnswers();
  const [currencies, currenciesFetched] = useFetchCurrencies();
  const [paymentTypes] = useFetchPaymentTypes();
  const saveRemediationApi = useProtectApi("case/remediation");
  const remediationForm = useRef();

  const [workingRemediation, setWorkingRemediation] = React.useState(spCase.remediation);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isBusy, setIsBusy] = React.useState(false);
  const [apiErrors, setApiErrors] = React.useState();
  const [startDate, setStartDate] = React.useState(spCase.remediation.startDate);
  const [endDate, setEndDate] = React.useState(spCase.remediation.endDate);
  const [currencySymbol, setCurrencySymbol] = React.useState("");
  const [showConversionRate, setShowConversionRate] = React.useState(false);

  const findEntity = (name, entities) => {
    return entities.find((x) => x.name === name) || null;
  };

  const findEntityId = (name, entities) => {
    const _entity = findEntity(name, entities);
    return _entity ? _entity.id : null;
  };

  const onSubmitRemediation = async (data, e) => {
    e.preventDefault();

    var selectedCurrencyId = findEntityId(data.currency, currencies);

    var moment = require("moment-timezone");
    var zoneName = moment.tz.guess();

    var request = {
      caseId: spCase.id,
      remediation: {
        ...workingRemediation,
        remediationPaymentTypeId: workingRemediation.remediationPaymentTypeId || null,
      },
      currencyId: selectedCurrencyId,
      timezone: zoneName,
    };

    const saveRemediation = () =>
      saveRemediationApi.api().then((w) =>
        w
          .put(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .json((json) => {
            caseDispatcher({
              type: "CASE_MODIFY_REMEDIATION",
              payload: workingRemediation,
            });

            dispatch({
              type: "CASE_MODIFY_END_DATE",
              payload: {
                id: spCase.id,
                endDate: endDate,
              },
            });
          })
          .then(() => toastr.success("Remediation details changed"))
          .then(() => setIsDirty(false))
          .then(() => finishEditing())
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to save Remediation.");
          })
          .finally(() => setIsBusy(false))
      );

    setIsBusy(true);
    saveRemediation();
  };

  React.useEffect(() => {
    setIsDirty(JSON.stringify(spCase.remediation) !== JSON.stringify(workingRemediation));
  }, [spCase.remediation, workingRemediation]);

  React.useEffect(() => {
    var runningTotal = 0;

    runningTotal += Number(
      workingRemediation.livingAllowance ? workingRemediation.livingAllowance.replace(",", "") : 0
    );

    runningTotal += Number(
      workingRemediation.educationSupportCosts ? workingRemediation.educationSupportCosts.replace(",", "") : 0
    );

    runningTotal += Number(
      workingRemediation.partnerSupportCosts ? workingRemediation.partnerSupportCosts.replace(",", "") : 0
    );

    runningTotal += Number(workingRemediation.otherCosts ? workingRemediation.otherCosts.replace(",", "") : 0);

    // var displayTotal = new Intl.NumberFormat("en-GB", {
    // 	maximumFractionDigits: 2,
    // }).format(runningTotal);

    //console.info(runningTotal);
    //console.info(displayTotal);

    setWorkingRemediation((prevState) => ({
      ...prevState,
      ["totalBudget"]: runningTotal,
    }));
  }, [
    workingRemediation.educationSupportCosts,
    workingRemediation.partnerSupportCosts,
    workingRemediation.livingAllowance,
    workingRemediation.otherCosts,
  ]);

  React.useEffect(() => {
    setCurrencySymbol(workingRemediation.remediationCurrencySymbol);

    currencies.length > 0 &&
      workingRemediation.remediationCurrencyName &&
      setCurrencySymbol(findEntity(workingRemediation.remediationCurrencyName, currencies).symbol);
  }, [spCase.remediation, workingRemediation.remediationCurrencyName, currencies]);

  React.useEffect(() => {
    currencies.length > 0 &&
      workingRemediation.remediationCurrencyName &&
      setShowConversionRate(!findEntity(workingRemediation.remediationCurrencyName, currencies).isSys);
  }, [spCase.remediation, workingRemediation.remediationCurrencyName, currencies]);

  React.useEffect(() => {
    currencySymbol && updateValue("remediationCurrencySymbol", currencySymbol);
  }, [currencySymbol]);

  const updateValue = (name, value) => {
    setWorkingRemediation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleStartDateChange = (e, v) => {
    setStartDate(e);

    let _startDate = moment(e.format("YYYY/MM/DD") + " " + moment().format("HH:mm"));

    updateValue("startDate", _startDate);
  };

  const handleEndDateChange = (e, v) => {
    setEndDate(e);

    let _endDate = moment(e.format("YYYY/MM/DD") + " " + moment().format("HH:mm"));

    updateValue("endDate", _endDate);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <form onSubmit={handleSubmit(onSubmitRemediation)} ref={remediationForm} id="remediationForm">
          <Grid container spacing={2} alignItems="stretch">
            <Grid item md={6} sm={6} xs={12} container spacing={2} direction="column">
              <Grid item>
                <Autocomplete
                  id="remediationConsentAutocomplete"
                  options={yesNoAnswers && yesNoAnswers.map((r) => r.name)}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        name="consentObtained"
                        label={"Partner Confirms Consent Obtained"}
                        // fullWidth
                        inputRef={register({})}
                        error={!!(errors && errors.consentObtained)}
                        helperText={errors && errors.consentObtained && errors.consentObtained.message}
                        style={{ width: "100%" }}
                      />
                    );
                  }}
                  defaultValue={workingRemediation.consentObtained}
                  onChange={(e, v) => updateValue("consentObtained", v)}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  id="remediationRightsTrainingAutocomplete"
                  options={yesNoAnswers && yesNoAnswers.map((r) => r.name)}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        name="rightsTrainingReceived"
                        label={"Workers Rights Training Received"}
                        // fullWidth
                        inputRef={register({})}
                        error={!!(errors && errors.rightsTrainingReceived)}
                        helperText={errors && errors.rightsTrainingReceived && errors.rightsTrainingReceived.message}
                        style={{ width: "100%" }}
                      />
                    );
                  }}
                  defaultValue={workingRemediation.rightsTrainingReceived}
                  onChange={(e, v) => updateValue("rightsTrainingReceived", v)}
                />
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  autoOk={true}
                  name="startDate"
                  error={!!(errors && errors.startDate)}
                  animateYearScrolling={true}
                  format="DD/MM/YYYY"
                  label="Start Date"
                  onChange={handleStartDateChange}
                  value={startDate ? moment(startDate) : null}
                  inputRef={register({})}
                  helperText={errors && errors.startDate && errors.startDate.message}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  autoOk={true}
                  animateYearScrolling={true}
                  name="endDate"
                  error={!!(errors && errors.endDate)}
                  format="DD/MM/YYYY"
                  label="End Date"
                  onChange={handleEndDateChange}
                  value={endDate ? moment(endDate) : null}
                  inputRef={register({})}
                  helperText={errors && errors.endDate && errors.endDate.message}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item>
                <TextField
                  name="remediationPaymentTypeId"
                  label={"Payment Type"}
                  fullWidth
                  inputRef={register({})}
                  error={!!(errors && errors.remediationPaymentTypeId)}
                  helperText={errors && errors.remediationPaymentTypeId && errors.remediationPaymentTypeId.message}
                  select
                  value={workingRemediation.remediationPaymentTypeId}
                  onChange={(e) => updateValue("remediationPaymentTypeId", e.target.value)}
                >
                  <MenuItem value="">N/A</MenuItem>
                  {paymentTypes &&
                    paymentTypes.map((p) => (
                      <MenuItem key={p.id} value={p.id}>
                        {p.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid item md={6} sm={6} xs={12} container spacing={2} direction="column">
              <Grid item>
                <Autocomplete
                  id="currencyAutocomplete"
                  options={currencies ? currencies.map((r) => r.name) : []}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        name="currency"
                        label={"Currency"}
                        fullWidth
                        inputRef={register({})}
                        error={!!(errors && errors.currency)}
                        helperText={errors && errors.currency && errors.currency.message}
                      />
                    );
                  }}
                  defaultValue={workingRemediation.remediationCurrencyName}
                  onChange={(e, v) => updateValue("remediationCurrencyName", v)}
                />
              </Grid>
              {showConversionRate && (
                <Grid item>
                  <TextField
                    label="Conversion Rate"
                    value={workingRemediation.conversionRate}
                    name="conversionRate"
                    onChange={(e) => {
                      updateValue("conversionRate", e.target.value);
                    }}
                    inputRef={register()}
                    InputProps={{
                      inputComponent: DecimalInput,
                    }}
                    style={{ width: "100%" }}
                    autoComplete="off"
                    inputProps={{
                      "aria-label": "Conversion Rate",
                    }}
                    helperText={errors && errors.conversionRate && errors.conversionRate.message}
                  />
                </Grid>
              )}
              <Grid item>
                <TextField
                  label="Living Allowance"
                  value={workingRemediation.livingAllowance}
                  name="livingAllowance"
                  onChange={(e) => {
                    updateValue("livingAllowance", e.target.value);
                  }}
                  inputRef={register({})}
                  InputProps={{
                    inputComponent: CurrencyInput,
                    startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                  }}
                  style={{ width: "100%" }}
                  autoComplete="off"
                  inputProps={{
                    "aria-label": "Living Allowance",
                  }}
                  helperText={errors && errors.livingAllowance && errors.livingAllowance.message}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Education Support Costs"
                  value={workingRemediation.educationSupportCosts}
                  name="educationSupportCosts"
                  onChange={(e) => {
                    updateValue("educationSupportCosts", e.target.value);
                  }}
                  inputRef={register({})}
                  InputProps={{
                    inputComponent: CurrencyInput,
                    startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                  }}
                  style={{ width: "100%" }}
                  autoComplete="off"
                  inputProps={{
                    "aria-label": "Education Support Costs",
                  }}
                  helperText={errors && errors.educationSupportCosts && errors.educationSupportCosts.message}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Partner Support Costs"
                  value={workingRemediation.partnerSupportCosts}
                  name="partnerSupportCosts"
                  onChange={(e) => {
                    updateValue("partnerSupportCosts", e.target.value);
                  }}
                  inputRef={register({})}
                  InputProps={{
                    inputComponent: CurrencyInput,
                    startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                  }}
                  style={{ width: "100%" }}
                  autoComplete="off"
                  inputProps={{
                    "aria-label": "Partner Support Costs",
                  }}
                  helperText={errors && errors.partnerSupportCosts && errors.partnerSupportCosts.message}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Other Costs"
                  value={workingRemediation.otherCosts}
                  name="otherCosts"
                  onChange={(e) => {
                    updateValue("otherCosts", e.target.value);
                  }}
                  inputRef={register({})}
                  InputProps={{
                    inputComponent: CurrencyInput,
                    startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                  }}
                  style={{ width: "100%" }}
                  autoComplete="off"
                  inputProps={{
                    "aria-label": "Other Costs",
                  }}
                  helperText={errors && errors.otherCosts && errors.otherCosts.message}
                />
              </Grid>
              <Grid item>
                <div className={classes.row}>
                  <div className={classes.label}>Total Budget:</div>
                  <div className={classes.value}>
                    {currencySymbol && <span style={{ marginRight: 1 }}>{currencySymbol}</span>}
                    {workingRemediation.totalBudget &&
                      Intl.NumberFormat("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                        workingRemediation.totalBudget
                      )}
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <fieldset className={classes.fieldset}>
                <legend className={classes.legend}>Summary of Remediation Plan</legend>
                <TextField
                  fullWidth
                  name="remediationSummary"
                  multiline
                  autoComplete="off"
                  rows="6"
                  error={!!(errors && errors.remediationSummary)}
                  inputRef={register({})}
                  inputProps={{
                    "aria-label": "Closure Summary Text",
                  }}
                  helperText={errors && errors.remediationSummary && errors.remediationSummary.message}
                  defaultValue={workingRemediation && workingRemediation.remediationSummary}
                  onChange={(e) => updateValue(e.target.name, e.target.value)}
                />
              </fieldset>
            </Grid>
            {!!apiErrors && (
              <Grid item xs={12}>
                <div className={classes.row}>
                  <div className={classes.error}>{apiErrors}</div>
                </div>
              </Grid>
            )}

            <Grid item xs={12}>
              <CaseTabEditingToolbar
                saveDisabled={isBusy || !isDirty}
                onCloseClick={finishEditing}
                formRef={remediationForm && remediationForm.current}
              />
            </Grid>
          </Grid>
        </form>
      </MuiPickersUtilsProvider>
    </>
  );
};

const CaseRemediationReadOnly = ({ enableEditing, manageDocument }) => {
  const classes = useStyles();
  const { spCase } = React.useContext(CaseCtx);

  const [currencies, currenciesFetched] = useFetchCurrencies();
  const [payementTypes, payementTypesFetched] = useFetchPaymentTypes();
  const [displayConvertedCost, setDisplayConvertedCost] = React.useState(false);
  const [systemCurrencySymbol, setSystemCurrencySymbol] = React.useState("£");

  React.useEffect(() => {
    const findCurrency = (name) => {
      return (currencies ? currencies : []).find((c) => c.name === name) || null;
    };
    // we display converted cost if the currency is NOT the system one
    var currency = findCurrency(spCase.remediation.remediationCurrencyName);
    var rateInput = spCase.remediation.conversionRate && spCase.remediation.conversionRate > 0;
    setDisplayConvertedCost(currency ? !currency.isSys && rateInput : false);
  }, [spCase.remediation, currencies]);

  const paymentType = payementTypes.find((p) => p.id === spCase.remediation.remediationPaymentTypeId)?.name || "N/A";

  React.useEffect(() => {
    var currency = (currencies ? currencies : []).find((c) => c.isSys) || null;
    setSystemCurrencySymbol(currency ? currency.symbol : "£");
  }, [currencies]);

  return (
    <>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} sm={8} md={8} container spacing={1}>
          <Grid item lg={3} md={8} sm={6} xs={12}>
            <div className={classes.row}>
              <div className={classes.label} style={{ minWidth: 0 }}>
                Partner confirms consent obtained:
              </div>
              <div className={classes.value}>{spCase.remediation.consentObtained}</div>
            </div>
            <div className={classes.row}>
              <div className={classes.label} style={{ minWidth: 0 }}>
                Workers Rights Training Received:
              </div>
              <div className={classes.value}>{spCase.remediation.rightsTrainingReceived}</div>
            </div>
          </Grid>

          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div className={classes.row}>
              <div className={classes.label}>Start Date:</div>
              <div className={classes.value}>
                {spCase.remediation.startDate && FormatDate(spCase.remediation.startDate)}
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.label}>End Date:</div>
              <div className={classes.value}>
                {spCase.remediation.endDate && FormatDate(spCase.remediation.endDate)}
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.label}>Payment Type:</div>
              <div className={classes.value}>{paymentType}</div>
            </div>
          </Grid>
          <Grid item lg={5} md={12} sm={12} xs={12} container spacing={1} direction="column">
            <Grid item>
              <div className={classes.row}>
                <div className={classes.label} style={{ width: 150 }}>
                  Living Allowance:
                </div>
                <RemediationCostDisplay
                  cost={spCase.remediation.livingAllowance}
                  symbol={spCase.remediation.remediationCurrencySymbol}
                  rate={spCase.remediation.conversionRate}
                  displayConvertedCost={displayConvertedCost}
                  systemCurrencySymbol={systemCurrencySymbol}
                />
              </div>
              <div className={classes.row}>
                <div className={classes.label} style={{ width: 150 }}>
                  Education Support Costs:
                </div>
                <RemediationCostDisplay
                  cost={spCase.remediation.educationSupportCosts}
                  symbol={spCase.remediation.remediationCurrencySymbol}
                  rate={spCase.remediation.conversionRate}
                  displayConvertedCost={displayConvertedCost}
                  systemCurrencySymbol={systemCurrencySymbol}
                />
              </div>
              <div className={classes.row}>
                <div className={classes.label} style={{ width: 150 }}>
                  Partner Support Costs:
                </div>
                <RemediationCostDisplay
                  cost={spCase.remediation.partnerSupportCosts}
                  symbol={spCase.remediation.remediationCurrencySymbol}
                  rate={spCase.remediation.conversionRate}
                  displayConvertedCost={displayConvertedCost}
                  systemCurrencySymbol={systemCurrencySymbol}
                />
              </div>
              <div className={classes.row}>
                <div className={classes.label} style={{ width: 150 }}>
                  Other:
                </div>

                <RemediationCostDisplay
                  cost={spCase.remediation.otherCosts}
                  symbol={spCase.remediation.remediationCurrencySymbol}
                  rate={spCase.remediation.conversionRate}
                  displayConvertedCost={displayConvertedCost}
                  systemCurrencySymbol={systemCurrencySymbol}
                />
              </div>
              <div className={classes.totalrow}>
                <div className={classes.label} style={{ width: 150 }}>
                  Total Budget:
                </div>

                <RemediationCostDisplay
                  cost={spCase.remediation.totalBudget}
                  symbol={spCase.remediation.remediationCurrencySymbol}
                  rate={spCase.remediation.conversionRate}
                  displayConvertedCost={displayConvertedCost}
                  systemCurrencySymbol={systemCurrencySymbol}
                />
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <fieldset className={classes.fieldset}>
              <legend className={classes.legend}>Summary of Remediation Plan:</legend>
              <div className={classes.readOnlySummary}>
                {spCase.remediation.remediationSummary || <div className={classes.label}>No Summary</div>}
              </div>
            </fieldset>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CaseDocumentsDisplay
            title="Documents"
            caseDocuments={spCase.caseDocuments}
            manageDocument={manageDocument}
            caseDocumentType={CaseDocumentTypes.remediation}
            caseId={spCase.id}
          />
        </Grid>
        <Grid item xs={12}>
          <CaseTabReadOnlyToolbar
            onEditClick={enableEditing}
            documentType={CaseDocumentTypes.remediation}
            caseId={spCase.id}
          />
        </Grid>
      </Grid>
    </>
  );
};

const RemediationCostDisplay = ({ cost, symbol, rate, displayConvertedCost, systemCurrencySymbol }) => {
  const classes = useStyles();

  return !cost ? null : (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <div className={classes.value} style={{ textAlign: "right", maxWidth: 200 }}>
            <span style={{ marginRight: 1 }}>{symbol}</span>
            {Intl.NumberFormat("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(cost)}
          </div>
        </Grid>
        {displayConvertedCost && (
          <Grid item xs={6}>
            <div className={classes.value} style={{ textAlign: "right", maxWidth: 200 }}>
              <span style={{ marginRight: 1 }}>{systemCurrencySymbol}</span>
              {Intl.NumberFormat("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(cost * rate)}
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CaseRemediation;
