import React, { useState, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { toastr } from "../components/Common/toastr";
import useProtectApi from "../hooks/useProtectApi";

const siteReducer = (state, { type, payload }) => {
  switch (type) {
    case "LOAD_R_SITE":
      return payload;

    case "SITE_DETAILS_CHANGED":
      return {
        ...state,
        name: payload.name,
        number: payload.number,
        countryName: payload.countryName,
        tierName: payload.tierName,
      };

    case "SITE_ADD_NEW_NOTE":
      return {
        ...state,
        notes: [...state.notes, payload],
      };

    case "SITE_REMOVE_NOTE":
      return {
        ...state,
        notes: state.notes.filter((n) => n.noteId !== payload.noteId),
      };

    case "SITE_CHANGE_NOTE":
      return {
        ...state,
        notes: (state.notes ? state.notes : []).map((sn) =>
          sn.noteId !== payload.noteId
            ? sn
            : {
                ...sn,
                noteText: payload.noteText,
              }
        ),
      };

    default:
      return state;
  }
};

const useFetchSite = () => {
  let { siteId } = useParams();

  const siteApi = useProtectApi("site/" + siteId);

  const [fetchedSite, dispatchSite] = useReducer(siteReducer, {});
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchSite = () => {
      siteApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((site) => {
            dispatchSite({ type: "LOAD_R_SITE", payload: site });
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Site details.");
          })
      );
    };

    fetchSite();
  }, [siteId]);

  return {
    site: fetchedSite,
    siteFetched: fetched,
    siteDispatcher: dispatchSite,
  };
};

export default useFetchSite;
