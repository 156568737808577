import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import useStyles from "../../components/Common/styles";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Blade, BladeValidationDisplay } from "../../components/Common/Blade";
import { toastr } from "../../components/Common/toastr";
import useProtectApi from "../../hooks/useProtectApi";
import { useFetchCountries } from "../../hooks/useFetchEntities";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

const CaseCreate = () => {
	const classes = useStyles();
	const { register, handleSubmit, errors } = useForm();
	const history = useHistory();
	const dispatch = useDispatch();
	const caseCreateApi = useProtectApi("case/create");

	const [countryNames] = useFetchCountries();
	const [dateFound, setDateFound] = useState(moment());
	const [createErrors, setCreateErrors] = useState();
	const [isBusy, setIsBusy] = useState(false);

	const handleDateFoundChange = (e) => {
		setDateFound(e);
	};

	const onSubmit = async (data) => {
		var countryId = data.country ? countryNames.find((c) => c.name === data.country).id : null;

		var moment = require('moment-timezone');
		var zoneName = moment.tz.guess();

		var caseRequest = {
			subjectName: data.name,
			dateFound: dateFound.toDate(),
			countryId: countryId,
			timezone: zoneName
		};

		console.info(caseRequest);

		const dateFoundMoment = moment(data.dateFound, "YYYY-MM-DD");

		const createCase = () =>
			caseCreateApi.api().then((w) =>
				w
					.post(caseRequest)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setCreateErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setCreateErrors(errDetail);
						throw new Error(errDetail);
					})
					.json((json) => {
						var newCase = {
							...caseRequest,
							id: json.id,
							dateFound: dateFoundMoment,
							country: data.country,
							number: json.number,
						};

						dispatch({
							type: "CREATE_CASE",
							payload: newCase,
						});

						return newCase;
					})
					.then((newCase) => history.push("/case/" + newCase.id))
					.then(() => toastr.success("Case created"))
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to create Case.");
					})
					.finally(() => setIsBusy(false))
			);
		setIsBusy(true);
		createCase();
	};

	const closeDrawer = () => {
		history.push("/cases");
	};

	return (
		<Blade title="Create Case">
			<div>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<section>
							<Autocomplete
								id="countryAutocomplete"
								options={countryNames && countryNames.map((r) => r.name)}
								getOptionLabel={(option) => option}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											name="country"
											label={"Country *"}
											fullWidth
											inputRef={register({
												required: {
													value: true,
													message: "Country is required",
												},
											})}
											error={!!(errors && errors.country)}
											helperText={errors && errors.country && errors.country.message}
											style={{ marginBottom: 16 }}
										/>
									);
								}}
							/>
						</section>

						<section>
							<KeyboardDatePicker
								autoOk={true}
								animateYearScrolling={true}
								name="dateFound"
								error={!!(errors && errors.dateFound)}
								format="DD/MM/YYYY"
								label="Date Found"
								onChange={handleDateFoundChange}
								value={dateFound}
								inputRef={register({
									required: {
										value: true,
										message: "Date Found is required",
									},
								})}
								helperText={errors && errors.dateFound && errors.dateFound.message}
								style={{ marginBottom: 16 }}
							/>
						</section>

						<TextField
							label="Name"
							fullWidth
							name="name"
							error={!!(errors && errors.name)}
							autoComplete="off"
							style={{ marginBottom: 16 }}
							inputRef={register({
								maxLength: {
									value: 255,
									message: "Name is too long",
								},
							})}
							inputProps={{
								"aria-label": "Description",
							}}
							helperText={errors && errors.name && errors.name.message}
						/>

						{!!createErrors && <BladeValidationDisplay validationErrors={createErrors} />}

						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
							}}>
							<Button
								variant="contained"
								color="primary"
								size="medium"
								type="submit"
								className={classes.button}
								endIcon={<SaveIcon />}
								disabled={isBusy}>
								SAVE
							</Button>
						</div>
					</form>
				</MuiPickersUtilsProvider>
			</div>
		</Blade>
	);
};

export default CaseCreate;
