import React, { useState } from "react";
import useStyles from "../../components/Common/styles";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toastr } from "../../components/Common/toastr";
import { Blade, BladeValidationDisplay, BladeTabPanel } from "../../components/Common/Blade";
import { TextField, Tab, Tabs, Grid, Typography, Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import useProtectApi from "../../hooks/useProtectApi";
import { useFetchEmploymentType } from "../../hooks/useFetchEntity";
import { DeleteButton } from "../../components/Common/Buttons";

const EmploymentTypeManage = () => {
	const [value, setValue] = React.useState(0);
	const [localEmploymentType] = useFetchEmploymentType();

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	function a11yProps(index) {
		return {
			id: `tab-${index}`,
			"aria-controls": `tabpanel-${index}`,
		};
	}
	return !localEmploymentType ? (
		<div></div>
	) : (
		<Blade>
			<Tabs
				value={value}
				onChange={handleChange}
				aria-label="user tabs"
				variant="fullWidth"
				indicatorColor="primary">
				<Tab label="Details" {...a11yProps(0)} />
				<Tab label="Remove" {...a11yProps(1)} />
			</Tabs>
			<BladeTabPanel value={value} index={0}>
				<EmploymentTypeDetails employmentType={localEmploymentType} />
			</BladeTabPanel>
			<BladeTabPanel value={value} index={1}>
				<RemoveEmploymentType employmentType={localEmploymentType} />
			</BladeTabPanel>
		</Blade>
	);
};

const EmploymentTypeDetails = ({ employmentType }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const renameEmploymentTypeApi = useProtectApi("employmentType/rename");
	const { register, handleSubmit, errors } = useForm();
	const history = useHistory();

	const [isBusy, setIsBusy] = useState(false);
	const [apiErrors, setApiErrors] = useState();

	const onSubmit = async (data) => {
		var request = {
			id: employmentType.id,
			name: data.name,
		};

		const renameEmploymentType = () =>
			renameEmploymentTypeApi.api().then((w) =>
				w
					.put(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.json((json) => {
						var modifiedEmploymentType = {
							...request,
						};

						dispatch({
							type: "RENAME_EMPLOYMENT_TYPE",
							payload: modifiedEmploymentType,
						});

						return modifiedEmploymentType;
					})
					.then(() => toastr.success("Employment Type details changed"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to change Employment Type name.");
					})
					.finally(() => setIsBusy(false))
			);

		setIsBusy(true);
		renameEmploymentType();
	};

	const closeDrawer = () => {
		history.goBack();
	};

	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<section>
					<TextField
						label="Name *"
						fullWidth
						autoFocus
						name="name"
						error={!!(errors && errors.name)}
						style={{ marginBottom: 16 }}
						autoComplete="off"
						inputRef={register({
							required: {
								value: true,
								message: "Name is required",
							},
							maxLength: {
								value: 255,
								message: "Name is too long",
							},
						})}
						inputProps={{
							"aria-label": "Name",
						}}
						helperText={errors && errors.name && errors.name.message}
						defaultValue={employmentType.name}
					/>
				</section>

				{!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}

				<div className={classes.drawerBladeToolbar}>
					<Button
						variant="contained"
						color="primary"
						size="medium"
						type="submit"
						className={classes.button}
						endIcon={<SaveIcon />}
						disabled={isBusy}>
						SAVE
					</Button>
				</div>
			</form>
		</div>
	);
};

const RemoveEmploymentType = ({ employmentType }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const removeEmploymentTypeApi = useProtectApi("employmentType/delete");
	const [isBusy, setIsBusy] = useState(false);
	const [apiErrors, setApiErrors] = useState();

	const closeDrawer = () => {
		history.goBack();
	};

	const removeEmploymentTypeClick = () => {
		var request = {
			Id: employmentType.id,
		};

		const apiDelete = () =>
			removeEmploymentTypeApi.api().then((w) =>
				w
					.put(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.res(() => {
						dispatch({
							type: "REMOVE_EMPLOYMENT_TYPE",
							payload: employmentType,
						});
					})
					.then(() => toastr.success("Employment Type removed"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to remove Employment Type.");
					})
			);

		setIsBusy(true);
		apiDelete();
	};

	return (
		<div>
			<Typography>
				<p>Remove Employment Type '{employmentType.name}' from the application?</p>
				<p>If you do so this Employment Type will no longer be available.</p>
			</Typography>
			<div className={classes.drawerBladeToolbar}>
				<DeleteButton
					variant="outlined"
					color="primary"
					size="medium"
					onClick={() => removeEmploymentTypeClick()}
					className={classes.button}
					disabled={isBusy}
					text="Remove"
				/>
			</div>
			{!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}
		</div>
	);
};

export default EmploymentTypeManage;
