import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Container, Fab, Tooltip, Backdrop } from "@material-ui/core";
import { Link, useHistory, Route } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "../../components/Common/styles";
import { useFetchReports } from "../../hooks/useFetchEntities";
import ReportView from "./report.view";

function Reports() {
  const history = useHistory();
  const classes = useStyles();
  const [reports, fetched] = useFetchReports();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(!fetched);
  }, [fetched]);

  const onRowClick = (rowData, rowMeta) => {
    history.push("/report/view/" + rowData[0]);
  };

  const gridOptions = {
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    filterType: "multiselect",
    selectableRows: "none",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 30],
    print: false,
    download: false,
    filter: "dropdown",
    onRowClick: onRowClick,
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },

    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
  ];

  return (
    <div style={{ marginRight: 32 }}>
      {!loading && <MUIDataTable title={"Reports"} data={reports} columns={gridColumns} options={gridOptions} />}
    </div>
  );
}

export default Reports;
