import React, { useState, useEffect } from "react";
import { Button, TextField, Grid, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import useStyles from "../../components/Common/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Blade, BladeValidationDisplay } from "../../components/Common/Blade";
import { toastr } from "../../components/Common/toastr";
import useProtectApi from "../../hooks/useProtectApi";
import { useFetchCountries, useFetchDocumentUploadSettings } from "../../hooks/useFetchEntities";

const DocumentCreate = () => {
	const classes = useStyles();
	const { register, handleSubmit, errors, clearError, setError } = useForm();
	const history = useHistory();
	const dispatch = useDispatch();
	const { documentSettings, documentSettingsFetched } = useFetchDocumentUploadSettings();

	const documentCreateApi = useProtectApi("document/create");

	const [countryNames] = useFetchCountries();

	const [createErrors, setCreateErrors] = useState();
	const [isBusy, setIsBusy] = useState(false);

	const [blockSubmit, setBlockSubmit] = useState(false);
	const [selectedDocument, setSelectedDocument] = useState();
	const [selectedFileName, setSelectedFileName] = useState();
	const [selectFilePrompt, setSelectFilePrompt] = useState("Select File...");

	const onSubmit = async (data) => {
		if (blockSubmit) {
			return;
		}
		var countryId = data.country ? countryNames.find((c) => c.name === data.country).id : null;

		var documentRequest = {
			fileName: selectedFileName,
			countryId: countryId,
			document: selectedDocument,
			description: data.description,
		};

		const createDocument = () =>
			documentCreateApi.api().then((w) =>
				w
					.formData(documentRequest)
					.post()
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setCreateErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setCreateErrors(errDetail);
						throw new Error(errDetail);
					})
					.json((json) => {
						var newDocument = {
							id: json.id,
							country: data.country,
							createdBy: json.createdBy,
							fileName: selectedFileName,
							country: data.country,
							description: data.description,
						};

						dispatch({
							type: "CREATE_DOCUMENT",
							payload: newDocument,
						});
					})
					.then(() => toastr.success("Document created"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to create Document.");
					})
					.finally(() => setIsBusy(false))
			);
		setIsBusy(true);
		createDocument();
	};

	const closeDrawer = () => {
		history.push("/documents");
	};

	const ValidateFileSize = (file) => {
		if (file.size > documentSettings.maxFileSize) {
			setBlockSubmit(true);
		} else {
			setBlockSubmit(false);
		}
	};

	const setFile = (e) => {
		if (e.target.files.length > 0) {
			setSelectFilePrompt("Change File...");
			setSelectedFileName(e.target.files[0].name);
			setSelectedDocument(e.target.files[0]);
			clearError("document");

			ValidateFileSize(e.target.files[0]);
		} else {
			setSelectFilePrompt("Select File...");
			setSelectedFileName("");
			setSelectedDocument(null);
		}
	};

	return (
		<Blade title="Create Document">
			<div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={2} style={{ marginBottom: 16 }}>
						<Grid item xs={5}>
							<React.Fragment>
								<input
									color="secondary"
									type="file"
									onChange={(e) => setFile(e)}
									id="icon-button-file"
									name="document"
									style={{ display: "none" }}
									accept={documentSettingsFetched && documentSettings.allowedExtensions.toString()}
									ref={register({ required: true })}
								/>
								<label htmlFor="icon-button-file">
									<Button
										variant="contained"
										component="span"
										className={classes.button}
										color="secondary">
										{selectFilePrompt}
									</Button>
								</label>
							</React.Fragment>
						</Grid>
						<Grid item xs={7}>
							<div style={{ overflowWrap: "break-word" }}>
								<Typography>{selectedFileName}</Typography>
							</div>
						</Grid>
					</Grid>

					{errors.document && (
						<BladeValidationDisplay validationErrors="You must select a File" marginBottom="8" />
					)}

					{blockSubmit && (
						<BladeValidationDisplay
							validationErrors="The file selected is to large to upload"
							marginBottom="8"
						/>
					)}

					<section>
						<TextField
							label="CL Number &amp; Description"
							fullWidth
							name="description"
							multiline
							autoComplete="off"
							rows="8"
							error={!!(errors && errors.description)}
							style={{ marginBottom: 16 }}
							inputRef={register()}
							inputProps={{
								"aria-label": "CL Number and description",
							}}
							helperText={errors && errors.description && errors.description.message}
						/>
					</section>

					<section>
						<Autocomplete
							id="countryAutocomplete"
							options={countryNames && countryNames.map((r) => r.name)}
							getOptionLabel={(option) => option}
							renderInput={(params) => {
								return (
									<TextField
										{...params}
										name="country"
										label={"Country"}
										fullWidth
										inputRef={register({
											required: {
												value: false,
											},
										})}
										error={!!(errors && errors.country)}
										helperText={errors && errors.country && errors.country.message}
										style={{ marginBottom: 16 }}
									/>
								);
							}}
						/>
					</section>

					{!!createErrors && <BladeValidationDisplay validationErrors={createErrors} />}

					<div className={classes.drawerBladeToolbar}>
						<Button
							variant="contained"
							color="primary"
							size="medium"
							type="submit"
							className={classes.button}
							endIcon={<CloudUploadIcon />}
							disabled={isBusy}>
							Upload
						</Button>
					</div>
				</form>
			</div>
		</Blade>
	);
};

export default DocumentCreate;
