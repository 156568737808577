import initialState from "../initialState";
import { sortByIsOther } from "../sortEntities";

export const suppliers = (state = initialState.suppliers, action) => {
	switch (action.type) {
		case "LOAD_SUPPLIERS":
			return [...action.payload];

		case "LOAD_SUPPLIER":
			return [...state, action.payload];

		case "CREATE_SUPPLIER":
			return [...state, action.payload].sort(sortByIsOther);

		case "REMOVE_SUPPLIER":
			return state.filter((sup) => sup.id !== action.payload.id);

		case "SUPPLIER_DETAILS_CHANGED":
			return state
				.map((s) =>
					s.id !== action.payload.id
						? s
						: {
								...s,
								name: action.payload.name,
								number: action.payload.number,
						  }
				)
				.sort(sortByIsOther);

		default:
			return state;
	}
};

export default suppliers;
