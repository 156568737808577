import React from "react";
import { AuthProviderCtx } from "../auth/authProvider";

const useSignOutUser = () => {
	const tokenProvider = React.useContext(AuthProviderCtx);

	const signOut = () => {
		return tokenProvider.logout();
	};

	return { signOut };
};

export default useSignOutUser;
