import React, { useEffect } from "react";
import List from "@material-ui/core/List";
import useStyles from "./styles";
import NavLink from "./NavLink";
import SubNavLink from "../Common/SubNavLink";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Business from "@material-ui/icons/Business";
import Dashboard from "@material-ui/icons/Dashboard";
import People from "@material-ui/icons/People";
import Warning from "@material-ui/icons/Warning";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import LocationCity from "@material-ui/icons/LocationCity";
import InsertChart from "@material-ui/icons/InsertChart";
import Work from "@material-ui/icons/Work";
import Description from "@material-ui/icons/Description";
import Flag from "@material-ui/icons/Flag";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Edit from "@material-ui/icons/Edit";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { useSelector } from "react-redux";

export const NavLinks = ({ menuOpen }) => {
  const classes = useStyles();

  const [adminOpen, setAdminOpen] = React.useState(false);
  const [showReports, setShowReports] = React.useState(false);
  const [hasAppLicense, setHasAppLicense] = React.useState(false);
  const [isAuthorized, setIsAuthorized] = React.useState(false);

  const user = useSelector((state) => state.user);

  React.useEffect(() => {
    user && setHasAppLicense(user.hasAppLicense);
  }, [user]);

  React.useEffect(() => {
    user && setIsAuthorized(user.isAuthorized);
  }, [user]);

  React.useEffect(() => {
    user && setShowReports(user.hasReportLicense);
  }, [user]);

  const handleAdminClick = () => {
    setAdminOpen(!adminOpen);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", maxHeight: "100vh" }}>
      <div className={classes.toolbar} />
      <div style={{ overflowY: "auto", flexGrow: 1 }}>
        {!hasAppLicense || !isAuthorized ? (
          <List component="nav">
            <NavLink to="/home" NavIcon={Dashboard} text="Home" />
          </List>
        ) : (
          <List component="nav">
            <NavLink to="/home" NavIcon={Dashboard} text="Dashboard" />
            <NavLink to="/cases" NavIcon={AssignmentInd} text="Cases" />
            <NavLink to="/suppliers" NavIcon={LocationCity} text="Suppliers" />
            <NavLink to="/sites" NavIcon={Business} text="Sites" />
            <NavLink to="/documents" NavIcon={Description} text="Documents" />
            <NavLink to="/problemdrops" NavIcon={Warning} text="Problem Documents" />
            {showReports && <NavLink to="/reports" NavIcon={InsertChart} text="Reports" />}

            {user.isAdmin && (
              <>
                <ListItem button onClick={handleAdminClick}>
                  <ListItemIcon>
                    <Work />
                  </ListItemIcon>
                  <ListItemText primary="Admin" />
                  {adminOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={adminOpen} timeout="auto" unmountOnExit style={{ paddingLeft: menuOpen ? 24 : 16 }}>
                  <SubNavLink to="/users" NavIcon={People} text="Users" />
                  <SubNavLink to="/casecategories" NavIcon={Edit} text="Case Categories" />
                  <SubNavLink to="/countries" NavIcon={Flag} text="Countries" />
                  <SubNavLink to="/currencies" NavIcon={AttachMoney} text="Currencies" />
                  <SubNavLink to="/employmenttypes" NavIcon={Edit} text="Employment Types" />
                  <SubNavLink to="/investigationstatuses" NavIcon={Edit} text="Investigation Statuses" />
                  <SubNavLink to="/investigationtypes" NavIcon={Edit} text="Investigation Types" />
                  <SubNavLink to="/nationalities" NavIcon={Edit} text="Nationalities" />
                  <SubNavLink to="/remediationpartners" NavIcon={Edit} text="Remediation Partners" />
                  <SubNavLink to="/remediationstatuses" NavIcon={Edit} text="Remediation Statuses" />
                  <SubNavLink to="/paymenttypes" NavIcon={Edit} text="Payment Types" />
                  <SubNavLink to="/sitestatuses" NavIcon={Edit} text="Site Statuses" />
                </Collapse>
              </>
            )}
          </List>
        )}
      </div>
      <div style={{ minHeight: 40 }} />
    </div>
  );
};

export default NavLinks;
