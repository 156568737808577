import React, { useState, useEffect, useReducer, useContext } from "react";
import { useParams } from "react-router-dom";
import { toastr } from "../components/Common/toastr";
import useProtectApi from "../hooks/useProtectApi";
import moment from "moment";

const caseReducer = (state, { type, payload }) => {

	var now = moment();

	switch (type) {
		case "LOAD_R_CASE":
			return payload;

		case "CASE_MODIFY_CLOSURE":
			return {
				...state,
				lastUpdatedDate: now,
				closure: { ...state.closure, ...payload },
			};

		case "CASE_MODIFY_NEEDS_ASSESSMENT":
			return {
				...state,
				lastUpdatedDate: now,
				needsAssessment: { ...state.needsAssessment, ...payload },
			};

		case "CASE_MODIFY_REMEDIATION":
			return {
				...state,
				lastUpdatedDate: now,
				remediation: { ...state.remediation, ...payload },
			};

		case "CASE_MODIFY_SUPPLIER_AGREEMENT":
			return {
				...state,
				lastUpdatedDate: now,
				supplierAgreement: { ...state.supplierAgreement, ...payload },
			};

		case "CASE_MODIFY_AUDIT":
			return {
				...state,
				lastUpdatedDate: now,
				audit: { ...state.audit, ...payload },
			};

		case "CASE_MODIFY_DETAIL":
			return {
				...state,
				lastUpdatedDate: now,
				detail: { ...state.detail, ...payload },
			};

		case "CASE_MONITORING_ADDED":
			return {
				...state,
				lastUpdatedDate: now,
				caseMonitorings: [...state.caseMonitorings, payload].sort((a, b) =>
					a.monitoringDate < b.monitoringDate ? 1 : -1
				),
			};

		case "CASE_MONITORING_MODIFIED":
			return {
				...state,
				lastUpdatedDate: now,
				caseMonitorings: (state.caseMonitorings ? state.caseMonitorings : [])
					.map((cm) =>
						cm.monitoringId !== payload.monitoringId
							? cm
							: {
									...cm,
									details: payload.details,
									monitoringDate: payload.monitoringDate,
							  }
					)
					.sort((a, b) => (a.monitoringDate < b.monitoringDate ? 1 : -1)),
			};

		case "CASE_MONITORING_REMOVED":
			return {
				...state,
				lastUpdatedDate: now,
				caseMonitorings: state.caseMonitorings
					.filter((n) => n.monitoringId !== payload.monitoringId)
					.sort((a, b) => (a.monitoringDate < b.monitoringDate ? 1 : -1)),
			};

		case "DOCUMENT_ATTACHED_TO_CASE":
			return {
				...state,
				lastUpdatedDate: now,
				caseDocuments: [...state.caseDocuments, payload],
			};

		case "DOCUMENT_REMOVED_FROM_CASE":
			return {
				...state,
				lastUpdatedDate: now,
				caseDocuments: state.caseDocuments.filter((n) => n.caseDocumentId !== payload.caseDocumentId),
			};

		case "DOCUMENT_DETAILS_CHANGED":
			return {
				...state,
				lastUpdatedDate: now,
				caseDocuments: (state.caseDocuments ? state.caseDocuments : []).map((cd) =>
					cd.caseDocumentId !== payload.caseDocumentId
						? cd
						: {
								...cd,
								caseDocumentType: payload.caseDocumentType,
								caseDocumentTypeId: payload.caseDocumentTypeId,
								description: payload.description,
						  }
				),
			};

		case "DOCUMENT_REMOVED_FROM_CASE_MONITORING":
			return {
				...state,
				lastUpdatedDate: now,
				caseMonitorings: (state.caseMonitorings ? state.caseMonitorings : []).map((cm) =>
					cm.monitoringId !== payload.monitoringId
						? cm
						: {
								...cm,
								documents: cm.documents.filter(
									(d) => d.caseMonitoringDocumentId !== payload.caseMonitoringDocumentId
								),
						  }
				),
			};

		case "DOCUMENT_ATTACHED_TO_CASE_MONITORING":
			return {
				...state,
				lastUpdatedDate: now,
				caseMonitorings: (state.caseMonitorings ? state.caseMonitorings : []).map((cm) =>
					cm.monitoringId !== payload.monitoringId
						? cm
						: {
								...cm,
								documents: [...cm.documents, payload.document],
						  }
				),
			};

		case "MONITORING_DOCUMENT_DETAILS_CHANGED":
			return {
				...state,
				lastUpdatedDate: now,
				caseMonitorings: (state.caseMonitorings ? state.caseMonitorings : []).map((cm) =>
					cm.monitoringId !== payload.monitoringId
						? cm
						: {
								...cm,
								documents: cm.documents.map((d) =>
									d.caseMonitoringDocumentId !== payload.caseMonitoringDocumentId
										? d
										: {
												...d,
												description: payload.description,
										  }
								),
						  }
				),
			};
		default:
			return state;
	}
};

const useFetchCase = () => {
	let { caseId } = useParams();

	const caseApi = useProtectApi("case/" + caseId);

	const [fetchedCase, dispatchCase] = useReducer(caseReducer, {});
	const [fetched, setFetched] = useState(false);

	useEffect(() => {
		const fetchCase = () => {
			caseApi.api().then((w) =>
				w
					.get()
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						throw new Error(errDetail);
					})
					.json((spCase) => {
						dispatchCase({ type: "LOAD_R_CASE", payload: spCase });
						setFetched(true);
					})
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to retrieve Case details.");
					})
			);
		};

		fetchCase();
	}, [caseId]);

	return {
		spCase: fetchedCase,
		caseFetched: fetched,
		caseDispatcher: dispatchCase,
	};
};

export default useFetchCase;
