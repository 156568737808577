import React, { useState, useEffect } from "react";
import {
	Button,
	TextField,
	Switch,
	FormControlLabel,
	List,
	ListItem,
	ListItemIcon,
	Checkbox,
	ListItemText,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import useStyles from "../../components/Common/styles";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Blade, BladeValidationDisplay } from "../../components/Common/Blade";
import { toastr } from "../../components/Common/toastr";
import useProtectApi from "../../hooks/useProtectApi";
import { useFetchRoles, useFetchLicenseTypes, useFetchCountries } from "../../hooks/useFetchEntities";

const UserCreate = () => {
	const classes = useStyles();
	const { register, handleSubmit, errors } = useForm();
	const history = useHistory();
	const dispatch = useDispatch();

	const userRegisterApi = useProtectApi("user/register");

	const [showCountries, setShowCountries] = React.useState(false);

	const [roleNames] = useFetchRoles();
	const [roleSelectedValue, setRoleSelectedValue] = React.useState();

	const [countries] = useFetchCountries();
	const [countriesChecked, setCountriesChecked] = React.useState([]);

	const [licenseTypes] = useFetchLicenseTypes();
	const [licensesChecked, setLicensesChecked] = React.useState([]);

	const [createErrors, setCreateErrors] = useState();
	const [isBusy, setIsBusy] = useState(false);

	const handleCountryToggle = (value) => () => {
		const currentIndex = countriesChecked.indexOf(value);
		const newChecked = [...countriesChecked];

		if (currentIndex === -1) {
			//checked
			newChecked.push(value);
		} else {
			// unchecked
			newChecked.splice(currentIndex, 1);
		}

		setCountriesChecked(newChecked);
	};

	const handleLicenseToggle = (value) => () => {
		const currentIndex = licensesChecked.indexOf(value);
		const newChecked = [...licensesChecked];

		if (currentIndex === -1) {
			//checked
			newChecked.push(value);
		} else {
			// unchecked
			newChecked.splice(currentIndex, 1);
		}

		setLicensesChecked(newChecked);
	};

	useEffect(() => {
		roleSelectedValue && setShowCountries(roleSelectedValue !== "Admin");
	}, [roleSelectedValue]);

	const onSubmit = async (data) => {
		var roleId = data.role ? roleNames.find((c) => c.name === data.role).id : null;

		var request = {
			displayName: data.name,
			emailAddress: data.email,
			telephoneNumber: data.telephone,
			jobTitle: data.jobtitle,
			role: roleId,
			sendInvitation: data.inviteNow,
			licenseTypes: licensesChecked,
			countries: countriesChecked,
		};

		const registerUser = () =>
			userRegisterApi.api().then((w) =>
				w
					.post(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setCreateErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setCreateErrors(errDetail);
						throw new Error(errDetail);
					})
					.json((json) => {
						var newUser = {
							...request,
							id: json.id,
							role: data.role,
							hasApplicationLicense: json.hasApplicationLicense,
							hasReportLicense: json.hasReportLicense,
							canResendInvitation: json.canResendInvitation,
							isAdmin: json.isAdmin,
							licenseTypes: licensesChecked,
							countries: countriesChecked.map((cId) => {
								return { countryId: cId, name: countries.find((c) => c.id === cId).name };
							}),
						};

						dispatch({
							type: "CREATE_USER",
							payload: newUser,
						});

						return newUser;
					})
					.then(() => toastr.success("User registered"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to register new User.");
					})
					.finally(() => setIsBusy(false))
			);

		setIsBusy(true);
		registerUser();
	};

	const closeDrawer = () => {
		history.push("/users");
	};

	return (
		<Blade title="Create New User">
			<div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<section>
						<TextField
							label="Name *"
							fullWidth
							autoFocus
							name="name"
							error={!!(errors && errors.name)}
							style={{ marginBottom: 16 }}
							autoComplete="off"
							inputRef={register({
								required: {
									value: true,
									message: "Name is required",
								},
								maxLength: {
									value: 255,
									message: "Name is too long",
								},
							})}
							inputProps={{
								"aria-label": "Name",
							}}
							helperText={errors && errors.name && errors.name.message}
						/>
					</section>

					<section>
						<TextField
							label="Email *"
							fullWidth
							name="email"
							emailaddress="true"
							error={!!(errors && errors.email)}
							inputRef={register({
								required: {
									value: true,
									message: "Email is required",
								},
								maxLength: {
									value: 255,
									message: "Email must be 255 characters only",
								},
							})}
							inputProps={{
								"aria-label": "Email",
							}}
							helperText={errors && errors.email && errors.email.message}
							style={{ marginBottom: 16 }}
						/>
					</section>

					<section>
						<TextField
							label="Job Title"
							fullWidth
							name="jobtitle"
							autoComplete="off"
							error={!!(errors && errors.jobtitle)}
							inputRef={register({
								maxLength: {
									value: 255,
									message: "Job Title must be 255 characters only",
								},
							})}
							inputProps={{
								"aria-label": "Job Title",
							}}
							helperText={errors && errors.jobtitle && errors.jobtitle.message}
							style={{ marginBottom: 16 }}
						/>
					</section>

					<section>
						<TextField
							label="Telephone"
							fullWidth
							name="telephone"
							autoComplete="off"
							error={!!(errors && errors.telephone)}
							inputRef={register({
								maxLength: {
									value: 255,
									message: "Telephone must be 255 characters only",
								},
							})}
							inputProps={{
								"aria-label": "Telephone",
							}}
							helperText={errors && errors.telephone && errors.telephone.message}
							style={{ marginBottom: 16 }}
						/>
					</section>

					<section name="Role">
						<Autocomplete
							id="roleAutocomplete"
							options={roleNames && roleNames.map((r) => r.name)}
							getOptionLabel={(option) => option}
							onChange={(e, v) => setRoleSelectedValue(v)}
							renderInput={(params) => {
								return (
									<TextField
										{...params}
										name="role"
										label={"Role *"}
										fullWidth
										inputRef={register({
											required: {
												value: true,
												message: "Role is required",
											},
										})}
										error={!!(errors && errors.role)}
										helperText={errors && errors.role && errors.role.message}
										style={{ marginBottom: 16 }}
									/>
								);
							}}
						/>
					</section>

					<section>
						<FormControlLabel
							labelPlacement="start"
							control={<Switch color="primary" name="inviteNow" inputRef={register({})} />}
							label="Send Invitation Now"
						/>
					</section>

					<section name="Licenses">
						<List>
							{licenseTypes &&
								licenseTypes.map((c) => {
									const labelId = `checkbox-list-label-${c.name}`;

									return (
										<ListItem
											key={c.id}
											role={undefined}
											dense
											button
											onClick={handleLicenseToggle(c.id)}>
											<ListItemIcon>
												<Checkbox
													edge="start"
													checked={licensesChecked.indexOf(c.id) !== -1}
													tabIndex={-1}
													disableRipple
													inputProps={{
														"aria-labelledby": labelId,
													}}
												/>
											</ListItemIcon>
											<ListItemText id={labelId} primary={c.name} />
										</ListItem>
									);
								})}
						</List>
					</section>

					<section name="Countries">
						{showCountries && (
							<List>
								{countries &&
									countries.map((c) => {
										const labelId = `countries-list-label-${c.name}`;

										return (
											<ListItem
												key={c.id}
												role={undefined}
												dense
												button
												onClick={handleCountryToggle(c.id)}>
												<ListItemIcon>
													<Checkbox
														edge="start"
														checked={countriesChecked.indexOf(c.id) !== -1}
														tabIndex={-1}
														disableRipple
														inputProps={{
															"aria-labelledby": labelId,
														}}
													/>
												</ListItemIcon>
												<ListItemText id={labelId} primary={c.name} />
											</ListItem>
										);
									})}
							</List>
						)}
					</section>

					{!!createErrors && <BladeValidationDisplay validationErrors={createErrors} />}

					<div className={classes.drawerBladeToolbar}>
						<Button
							variant="contained"
							color="primary"
							size="medium"
							type="submit"
							className={classes.button}
							endIcon={<SaveIcon />}
							disabled={isBusy}>
							SAVE
						</Button>
					</div>
				</form>
			</div>
		</Blade>
	);
};
export default UserCreate;
