import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

function AdminRoute({ children, ...rest }) {
	const user = useSelector((state) => state.user);

	return <Route {...rest} render={({ location }) => (user.isAdmin ? children : null)} />;
}

export default AdminRoute;
