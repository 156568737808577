import React from "react";
import { Button } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { useIntellyncColors } from "./styles";
import DeleteIcon from "@material-ui/icons/Delete";

const CancelButton = ({ color, size, onClick, className, text }) => {
	return (
		<Button
			color={color}
			size={size}
			onClick={onClick}
			className={className}
			style={{ color: grey[400], marginRight: 8 }}>
			{text}
		</Button>
	);
};

const DeleteButton = ({ variant, size, onClick, className, text, disabled = false }) => {
	const colours = useIntellyncColors();
	return (
		<Button
			disabled={disabled}
			variant={variant}
			size={size}
			onClick={onClick}
			endIcon={<DeleteIcon />}
			className={className}
			style={{
				color: colours.red_75_contrast,
				backgroundColor: !disabled ? colours.red_75 : colours.red_50,
			}}>
			{text}
		</Button>
	);
};

export { CancelButton, DeleteButton };
