import React, { useState } from "react";
import useStyles from "./styles";
import useGlobalStyles from "../../components/Common/styles";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toastr } from "../../components/Common/toastr";
import { Blade, BladeValidationDisplay, BladeTabPanel } from "../../components/Common/Blade";
import { Tab, Tabs, Grid, Typography, Button } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import { useForm } from "react-hook-form";
import useProtectApi from "../../hooks/useProtectApi";
import { useFetchDocumentDrop } from "../../hooks/useFetchEntity";
import { DeleteButton } from "../../components/Common/Buttons";
import FormatDate from "../../helpers/date.display.format";

const DocumentDropManage = () => {
	const [tabValue, setTabValue] = React.useState(0);
	const [localDocumentDrop] = useFetchDocumentDrop();

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	function a11yProps(index) {
		return {
			id: `tab-${index}`,
			"aria-controls": `tabpanel-${index}`,
		};
	}
	return !localDocumentDrop ? (
		<div></div>
	) : (
		<Blade>
			<Tabs
				value={tabValue}
				onChange={handleTabChange}
				aria-label="document drop tabs"
				variant="fullWidth"
				indicatorColor="primary">
				<Tab label="Details" {...a11yProps(0)} />
				<Tab label="Remove" {...a11yProps(1)} />
			</Tabs>
			<BladeTabPanel value={tabValue} index={0}>
				<DocumentDropDetails documentDrop={localDocumentDrop} />
			</BladeTabPanel>
			<BladeTabPanel value={tabValue} index={1}>
				<RemoveDocumentDrop documentDrop={localDocumentDrop} />
			</BladeTabPanel>
		</Blade>
	);
};

const DocumentDropDetails = ({ documentDrop }) => {
	const classes = useStyles();
	const globalClasses = useGlobalStyles();

	const dispatch = useDispatch();
	const resubmitDocumentDropApi = useProtectApi("documentDrop/resubmit");
	const { register, handleSubmit, errors } = useForm();
	const history = useHistory();
	const [isBusy, setIsBusy] = useState(false);
	const [apiErrors, setApiErrors] = useState();

	const onSubmit = async (data) => {
		var request = {
			id: documentDrop.id,
		};

		const resubmitDocumentDrop = () =>
			resubmitDocumentDropApi.api().then((w) =>
				w
					.put(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.res((res) => {
						var modifiedDocumentDrop = {
							...request,
						};

						dispatch({
							type: "RESUBMIT_PROBLEM_DROP",
							payload: modifiedDocumentDrop,
						});

						return modifiedDocumentDrop;
					})
					.then(() => toastr.success("Document Drop details changed"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to resubmit Document Drop for processing.");
					})
					.finally(() => setIsBusy(false))
			);

		setIsBusy(true);
		resubmitDocumentDrop();
	};

	const closeDrawer = () => {
		history.goBack();
	};

	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div style={{ marginBottom: 16 }}>
					<section name="read only data">
						<Grid container>
							<Grid item xs={12}>
								<div className={classes.row}>
									<div className={classes.label}>Uploaded By</div>
									<div>{documentDrop.uploadedBy}</div>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.row}>
									<div className={classes.label}>Uploaded Date</div>
									<div>{FormatDate(documentDrop.uploadedDateTime)}</div>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.row}>
									<div className={classes.label}>Country</div>
									<div>{documentDrop.countryName}</div>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.row}>
									<div className={classes.label}>Status</div>
									<div>{documentDrop.status}</div>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.row}>
									<div className={classes.label}>Description</div>
									<div>{documentDrop.description}</div>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.row}>
									<div className={classes.label}>Problem</div>
									<div>{documentDrop.failureMessage}</div>
								</div>
							</Grid>
						</Grid>
					</section>
				</div>
				{!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}

				{documentDrop.status !== "Infected" && (
					<div className={globalClasses.drawerBladeToolbar}>
						<Button
							variant="contained"
							color="primary"
							size="medium"
							type="submit"
							className={classes.button}
							endIcon={<PublishIcon />}
							disabled={isBusy}>
							RESUBMIT
						</Button>
					</div>
				)}
			</form>
		</div>
	);
};

const RemoveDocumentDrop = ({ documentDrop }) => {
	const classes = useStyles();
	const globalClasses = useGlobalStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const removeDocumentDropApi = useProtectApi("documentDrop/delete");
	const [isBusy, setIsBusy] = useState(false);
	const [apiErrors, setApiErrors] = useState();

	const closeDrawer = () => {
		history.push("/problemdrops");
	};

	const removeDocumentDropClick = () => {
		var request = {
			id: documentDrop.id,
		};

		const apiDelete = () =>
			removeDocumentDropApi.api().then((w) =>
				w
					.put(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.res(() => {
						dispatch({
							type: "REMOVE_PROBLEM_DROP",
							payload: documentDrop,
						});
					})
					.then(() => toastr.success("Problem Document Drop removed"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to remove Document Drop record.");
					})
					.finally(() => setIsBusy(false))
			);

		setIsBusy(true);
		apiDelete();
	};

	return (
		<div>
			<Typography>
				<p>Remove Problem Document Drop '{documentDrop.fileName}'?</p>
				<p>
					If you do so the document will not be available and the source document will be removed from
					storage.
				</p>
			</Typography>
			<div className={globalClasses.drawerBladeToolbar}>
				<DeleteButton
					variant="outlined"
					color="primary"
					size="medium"
					onClick={() => removeDocumentDropClick()}
					className={classes.button}
					disabled={isBusy}
					text="Remove"
				/>
			</div>
			{!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}
		</div>
	);
};

export default DocumentDropManage;
