import React, { useState, useEffect } from "react";
import useStyles from "../../components/Common/styles";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toastr } from "../../components/Common/toastr";
import { Blade, BladeTabPanel, BladeValidationDisplay } from "../../components/Common/Blade";
import { Autocomplete } from "@material-ui/lab";
import {
	TextField,
	Tab,
	Tabs,
	Grid,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
	Button,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useForm } from "react-hook-form";
import useProtectApi from "../../hooks/useProtectApi";
import { useFetchCaseDocumentTypes } from "../../hooks/useFetchEntities";
import { DeleteButton } from "../../components/Common/Buttons";
import { CaseCtx } from "./case.manage";
import FormatDate from "../../helpers/date.display.format";
import useDownloadDocument from "../../hooks/useDownloadDocument";

const CaseDocumentManage = () => {
	const [tabValue, setTabValue] = React.useState(0);
	let { caseDocumentId } = useParams();

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	function a11yProps(index) {
		return {
			id: `tab-${index}`,
			"aria-controls": `tabpanel-${index}`,
		};
	}
	return (
		<Blade size="Larger">
			<Tabs
				value={tabValue}
				onChange={handleTabChange}
				aria-label="user tabs"
				variant="fullWidth"
				indicatorColor="primary">
				<Tab label="Details" {...a11yProps(0)} />
				<Tab label="Remove" {...a11yProps(1)} />
			</Tabs>
			<BladeTabPanel value={tabValue} index={0}>
				<CaseDocumentDetails id={caseDocumentId} />
			</BladeTabPanel>
			<BladeTabPanel value={tabValue} index={1}>
				<RemoveCaseDocument id={caseDocumentId} />
			</BladeTabPanel>
		</Blade>
	);
};

const CaseDocumentDetails = ({ id }) => {
	const { spCase, caseDispatcher, caseFetched } = React.useContext(CaseCtx);
	const [caseDocumentTypes, caseDocumentTypesFetched] = useFetchCaseDocumentTypes();
	const documentDownload = useDownloadDocument();
	const classes = useStyles();
	const dispatch = useDispatch();
	const modifyCaseDocumentApi = useProtectApi("casedocument/modify/");
	const { register, handleSubmit, errors } = useForm();
	const history = useHistory();

	const [isBusy, setIsBusy] = useState(false);
	const [apiErrors, setApiErrors] = useState();
	const [localEntry, setLocalEntry] = useState();

	useEffect(() => {
		!localEntry && caseFetched && setLocalEntry(spCase.caseDocuments.find((cd) => cd.caseDocumentId === id));
	}, [caseFetched, localEntry, id]);

	const findEntity = (name, entities) => {
		return entities.find((x) => x.name === name) ? entities.find((x) => x.name === name) : null;
	};

	const onSubmit = async (data) => {
		var caseDocumentType = findEntity(data.caseDocumentType, caseDocumentTypes);

		var request = {
			documentId: localEntry.documentId,
			caseId: spCase.id,
			caseDocumentType: caseDocumentType.name,
			description: data.description,
		};

		const modifyDocument = () =>
			modifyCaseDocumentApi.api().then((w) =>
				w
					.put(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.res(() => {
						caseDispatcher({
							type: "DOCUMENT_DETAILS_CHANGED",
							payload: {
								caseDocumentId: id,
								caseDocumentType: data.caseDocumentType,
								caseDocumentTypeId: caseDocumentType.id,
								description: data.description,
							},
						});
					})
					.then(() => toastr.success("Document details changed"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to change Document details.");
					})
					.finally(() => setIsBusy(false))
			);
		setIsBusy(true);
		modifyDocument();
	};

	const closeDrawer = () => {
		history.push("/case/" + spCase.id);
	};

	return !localEntry ? (
		<div></div>
	) : (
		<div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div style={{ marginBottom: 16 }}>
					<section name="read only data">
						<Typography>File Name: {localEntry.fileName}</Typography>

						<Typography>Created By: {localEntry.createdBy}</Typography>

						<Typography>Created Date: {FormatDate(localEntry.createdDate)}</Typography>

						<Typography>Added By: {localEntry.attachedBy}</Typography>

						<Typography>Added Date: {FormatDate(localEntry.attachedDate)}</Typography>
					</section>
				</div>
				<section name="Case Document Type">
					<Autocomplete
						id="caseDocumentTypeAutocomplete"
						options={caseDocumentTypes && caseDocumentTypes.map((r) => r.name)}
						getOptionLabel={(option) => option}
						renderInput={(params) => {
							return (
								<TextField
									{...params}
									name="caseDocumentType"
									label={"Document Type *"}
									fullWidth
									inputRef={register({
										required: {
											value: true,
											message: "Document Type is required",
										},
									})}
									error={!!(errors && errors.caseDocumentType)}
									helperText={errors && errors.caseDocumentType && errors.caseDocumentType.message}
									style={{ marginBottom: 16 }}
								/>
							);
						}}
						defaultValue={localEntry.caseDocumentType}
					/>
				</section>

				<section name="Document Description">
					<TextField
						label="Description"
						fullWidth
						name="description"
						multiline
						autoComplete="off"
						rows="6"
						error={!!(errors && errors.description)}
						style={{ marginBottom: 16 }}
						inputRef={register()}
						inputProps={{
							"aria-label": "Document description",
						}}
						helperText={errors && errors.description && errors.description.message}
						defaultValue={localEntry.description}
					/>
				</section>

				{!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}

				<div className={classes.drawerBladeToolbar}>
					<Button
						variant="contained"
						color="secondary"
						size="medium"
						className={classes.button}
						style={{ marginRight: 16 }}
						endIcon={<GetAppIcon />}
						onClick={() => documentDownload(localEntry.documentId, localEntry.fileName)}>
						Download
					</Button>

					<Button
						variant="contained"
						color="primary"
						size="medium"
						type="submit"
						className={classes.button}
						endIcon={<SaveIcon />}
						disabled={isBusy}>
						SAVE
					</Button>
				</div>
			</form>
		</div>
	);
};

const RemoveCaseDocument = ({ id }) => {
	const { spCase, caseDispatcher, caseFetched } = React.useContext(CaseCtx);

	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const removeCaseDocumentApi = useProtectApi("casedocument/remove");
	const [isBusy, setIsBusy] = useState(false);
	const [apiErrors, setApiErrors] = useState();
	const [localEntry, setLocalEntry] = useState();
	const [showRemoveConfirmDialog, setShowRemoveConfirmDialog] = useState(false);

	useEffect(() => {
		!localEntry && caseFetched && setLocalEntry(spCase.caseDocuments.find((cd) => cd.caseDocumentId === id));
	}, [caseFetched, localEntry, id]);

	const closeDrawer = () => {
		history.goBack();
	};

	const closeRemoveDialog = () => {
		setShowRemoveConfirmDialog(false);
	};

	const confirmRemoveCaseDocumentClick = () => {
		var request = {
			documentId: localEntry.documentId,
			caseId: spCase.id,
		};

		const apiDelete = () =>
			removeCaseDocumentApi.api().then((w) =>
				w
					.put(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.res(() => {
						caseDispatcher({
							type: "DOCUMENT_REMOVED_FROM_CASE",
							payload: { caseDocumentId: id },
						});

						var document = {
							id: localEntry.documentId,
							status: "Available",
							fileName: localEntry.fileName,
							description: localEntry.description,
							createdDate: localEntry.createdDate,
							createdBy: localEntry.createdBy,
							country: localEntry.country,
							countryId: localEntry.countryId,
						};
						dispatch({
							type: "DOCUMENT_REMOVED_FROM_CASE",
							payload: document,
						});
					})
					.then(() => toastr.success("Document detached from Case"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						setShowRemoveConfirmDialog(false);
						toastr.error("Unable to detach Document from Case.");
					})
			);

		setIsBusy(true);
		apiDelete();
	};

	return !localEntry ? (
		<div></div>
	) : (
		<div>
			<Typography>Remove document '{localEntry.fileName}' from this Case?</Typography>

			<div className={classes.drawerBladeToolbar}>
				<DeleteButton
					variant="outlined"
					color="primary"
					size="medium"
					onClick={() => setShowRemoveConfirmDialog(true)}
					className={classes.button}
					disabled={isBusy}
					text="Remove"
				/>
			</div>

			{!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}
			{showRemoveConfirmDialog && localEntry && (
				<div>
					<Dialog
						open={showRemoveConfirmDialog}
						onClose={() => showRemoveConfirmDialog}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description">
						<DialogTitle id="alert-dialog-title">Remove</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								Are you sure you want to remove '{localEntry.fileName}'?
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={closeRemoveDialog} color="primary">
								Cancel
							</Button>
							<Button onClick={() => confirmRemoveCaseDocumentClick()} color="primary" autoFocus>
								Confirm
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			)}
		</div>
	);
};

export default CaseDocumentManage;
