import initialState from "../initialState";
import { sortByName } from "../sortEntities";

export const paymentTypes = (state = initialState.paymentTypes, action) => {
  switch (action.type) {
    case "LOAD_PAYMENT_TYPES":
      return [...action.payload];

    case "LOAD_PAYMENT_TYPE":
      return state.map((c) =>
        c.id !== action.payload.id
          ? c
          : {
              ...c,
            }
      );

    case "RENAME_PAYMENT_TYPE":
      return state
        .map((c) =>
          c.id !== action.payload.id
            ? c
            : {
                ...c,
                name: action.payload.name,
              }
        )
        .sort(sortByName);

    case "CREATE_PAYMENT_TYPE":
      return [...state, action.payload].sort(sortByName);

    case "REMOVE_PAYMENT_TYPE":
      return state.filter((paymentType) => paymentType.id !== action.payload.id);

    default:
      return state;
  }
};

export default paymentTypes;
