import initialState from "../initialState";
import { sortByIsOther } from "../sortEntities";

export const sites = (state = initialState.sites, action) => {
	switch (action.type) {
		case "LOAD_SITES":
			return [...action.payload];

		case "LOAD_SITE":
			return [...state, action.payload];

		case "CREATE_SITE":
			return [...state, action.payload].sort(sortByIsOther);

		case "REMOVE_SITE":
			return state.filter((site) => site.id !== action.payload.id);

		case "SITE_DETAILS_CHANGED":
			return state
				.map((s) =>
					s.id !== action.payload.id
						? s
						: {
								...s,
								name: action.payload.name,
								number: action.payload.number,
						  }
				)
				.sort(sortByIsOther);

		default:
			return state;
	}
};

export default sites;
