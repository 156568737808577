import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "../components/Common/toastr";
import useProtectApi from "../hooks/useProtectApi";
import { useHistory } from "react-router-dom";

const useFetchCaseCategories = () => {
  const dispatch = useDispatch();
  const caseCategoryApi = useProtectApi("CaseCategory");
  const [fetched, setFetched] = useState(false);
  let caseCategories = useSelector((state) => state.caseCategories);

  useEffect(() => {
    const fetchCaseCategories = () => {
      caseCategoryApi.api().then((w) =>
        w
          .get()
          .json((json) => {
            dispatch({
              type: "LOAD_CASE_CATEGORIES",
              payload: json,
            });
            return json;
          })

          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Case Categories"))
      );
    };

    if (caseCategories.length === 0) {
      !fetched && fetchCaseCategories();
    } else {
      setFetched(true);
    }
  }, []);

  return [caseCategories, fetched];
};

const useFetchEmploymentTypes = () => {
  const dispatch = useDispatch();
  const employmentTypesApi = useProtectApi("EmploymentType");

  const [fetched, setFetched] = useState(false);

  let employmentTypes = useSelector((state) => state.employmentTypes);

  useEffect(() => {
    const fetchEmploymentTypes = () => {
      employmentTypesApi.api().then((w) =>
        w
          .get()
          .json((json) => {
            dispatch({
              type: "LOAD_EMPLOYMENT_TYPES",
              payload: json,
            });
          })
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Employment Types"))
      );
    };

    if (employmentTypes.length === 0) {
      !fetched && fetchEmploymentTypes();
    } else {
      setFetched(true);
    }
  }, []);

  return [employmentTypes, fetched];
};

const useFetchInvestigationTypes = () => {
  const dispatch = useDispatch();
  const investigationTypesApi = useProtectApi("InvestigationType");
  const [fetched, setFetched] = useState(false);

  let investigationTypes = useSelector((state) => state.investigationTypes);

  useEffect(() => {
    const fetchInvestigationTypes = () => {
      investigationTypesApi.api().then((w) =>
        w
          .get()
          .json((json) => {
            dispatch({
              type: "LOAD_INVESTIGATION_TYPES",
              payload: json,
            });
          })
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Investigation Types"))
      );
    };

    if (investigationTypes.length === 0) {
      !fetched && fetchInvestigationTypes();
    } else {
      setFetched(true);
    }
  }, []);

  return [investigationTypes, fetched];
};

const useFetchNationalities = () => {
  const dispatch = useDispatch();
  const nationalityApi = useProtectApi("Nationality");
  const [fetched, setFetched] = useState(false);

  let nationalities = useSelector((state) => state.nationalities);

  useEffect(() => {
    const fetchNationalities = () => {
      nationalityApi.api().then((w) =>
        w
          .get()
          .json((json) => {
            dispatch({
              type: "LOAD_NATIONALITIES",
              payload: json,
            });
            return json;
          })
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Nationalities"))
      );
    };

    if (nationalities.length === 0) {
      !fetched && fetchNationalities();
    } else {
      setFetched(true);
    }
  }, []);

  return [nationalities, fetched];
};

const useFetchRemediationPartners = () => {
  const dispatch = useDispatch();
  const remediationPartnerApi = useProtectApi("RemediationPartner");

  const [fetched, setFetched] = useState(false);

  let remediationPartners = useSelector((state) => state.remediationPartners);

  useEffect(() => {
    const fetchRemediationPartners = () => {
      remediationPartnerApi.api().then((w) =>
        w
          .get()
          .json((json) => {
            dispatch({
              type: "LOAD_REMEDIATION_PARTNERS",
              payload: json,
            });
            return json;
          })
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Remediation Partners"))
      );
    };

    if (remediationPartners.length === 0) {
      !fetched && fetchRemediationPartners();
    } else {
      setFetched(true);
    }
  }, []);

  return [remediationPartners, fetched];
};

const useFetchSiteStatuses = () => {
  const dispatch = useDispatch();
  const siteStatusApi = useProtectApi("SiteStatus");
  const [fetched, setFetched] = useState(false);

  const siteStatuses = useSelector((state) => state.siteStatuses);

  useEffect(() => {
    const fetchSiteStatuses = () => {
      siteStatusApi.api().then((w) =>
        w
          .get()
          .json((json) => {
            dispatch({
              type: "LOAD_SITE_STATUSES",
              payload: json,
            });
            return json;
          })
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Site Statuses"))
      );
    };

    if (siteStatuses.length === 0) {
      !fetched && fetchSiteStatuses();
    } else {
      setFetched(true);
    }
  }, []);

  return [siteStatuses, fetched];
};

const useFetchInvestigationStatuses = () => {
  const dispatch = useDispatch();
  const investigationStatusApi = useProtectApi("InvestigationStatus");
  const [fetched, setFetched] = useState(false);
  const investigationStatuses = useSelector((state) => state.investigationStatuses);

  useEffect(() => {
    const fetchInvestigationStatuses = () => {
      investigationStatusApi.api().then((w) =>
        w
          .get()
          .json((json) => {
            dispatch({
              type: "LOAD_INVESTIGATION_STATUSES",
              payload: json,
            });
            return json;
          })
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Investigation Statuses"))
      );
    };

    if (investigationStatuses.length === 0) {
      !fetched && fetchInvestigationStatuses();
    } else {
      setFetched(true);
    }
  }, []);

  return [investigationStatuses, fetched];
};

const useFetchSiteTiers = () => {
  const dispatch = useDispatch();
  const tierApi = useProtectApi("Tier");
  const [fetched, setFetched] = useState(false);
  const tiers = useSelector((state) => state.tiers);

  useEffect(() => {
    const fetchTiers = () => {
      tierApi.api().then((w) =>
        w
          .get()
          .json((json) => {
            dispatch({
              type: "LOAD_TIERS",
              payload: json,
            });
            return json;
          })
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Tiers"))
      );
    };

    if (tiers.length === 0) {
      !fetched && fetchTiers();
    } else {
      setFetched(true);
    }
  }, []);

  return [tiers, fetched];
};

const useFetchRoles = () => {
  const dispatch = useDispatch();
  const rolesApi = useProtectApi("lookup/roles");
  const [fetched, setFetched] = useState(false);

  let roles = useSelector((state) => state.roles);

  useEffect(() => {
    const fetchRoles = () => {
      rolesApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((roles) => {
            dispatch({
              type: "LOAD_ROLES",
              payload: roles,
            });
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Roles.");
          })
      );
    };

    if (roles.length === 0) {
      !fetched && fetchRoles();
    } else {
      setFetched(true);
    }
  }, []);

  return [roles, fetched];
};

const useFetchLicenseTypes = () => {
  const dispatch = useDispatch();
  const licenseTypesApi = useProtectApi("license/types");
  const [fetched, setFetched] = useState(false);

  let licenseTypes = useSelector((state) => state.licenseTypes);

  useEffect(() => {
    const fetchLicenseTypes = () => {
      licenseTypesApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((licenseTypes) => {
            dispatch({
              type: "LOAD_LICENSE_TYPES",
              payload: licenseTypes,
            });
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve LicenseTypes.");
          })
      );
    };

    if (licenseTypes.length === 0) {
      !fetched && fetchLicenseTypes();
    } else {
      setFetched(true);
    }
  }, []);

  return [licenseTypes, fetched];
};

const useFetchGenders = () => {
  const dispatch = useDispatch();
  const gendersApi = useProtectApi("lookup/Genders");

  const [fetched, setFetched] = useState(false);

  let genders = useSelector((state) => state.genders);

  useEffect(() => {
    const fetchGenders = () => {
      gendersApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((genders) => {
            dispatch({
              type: "LOAD_GENDERS",
              payload: genders,
            });
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Genders.");
          })
      );
    };
    if (genders.length === 0) {
      !fetched && fetchGenders();
    } else {
      setFetched(true);
    }
  }, []);

  return [genders, fetched];
};

const useFetchYesNoAnswers = () => {
  const dispatch = useDispatch();
  const ynaApi = useProtectApi("lookup/YesNoAnswers");

  const [fetchedYna, setFetchedYna] = useState();
  const [fetched, setFetched] = useState(false);

  let yesNoAnswers = useSelector((state) => state.yesNoAnswers);

  useEffect(() => {
    const fetchYesNoAnswers = () => {
      ynaApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((json) => {
            dispatch({
              type: "LOAD_YES_NO_ANSWERS",
              payload: json,
            });

            setFetchedYna(json);
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Yes/No answers.");
          })
      );
    };

    if (yesNoAnswers.length === 0) {
      !fetched && fetchYesNoAnswers();
    } else {
      setFetched(true);
    }
  }, []);

  return [yesNoAnswers, fetched];
};

const useFetchCountries = () => {
  const dispatch = useDispatch();
  const countriesApi = useProtectApi("Country");
  const [fetched, setFetched] = useState(false);

  let countries = useSelector((state) => state.countries);

  useEffect(() => {
    const fetchCountries = () => {
      countriesApi.api().then((w) =>
        w
          .get()
          .json((json) =>
            dispatch({
              type: "LOAD_COUNTRIES",
              payload: json,
            })
          )
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Countries"))
      );
    };

    if (countries.length === 0) {
      !fetched && fetchCountries();
    } else {
      setFetched(true);
    }
  }, []);

  return [countries, fetched];
};
const useFetchPaymentTypes = () => {
  const dispatch = useDispatch();
  const paymentTypesApi = useProtectApi("paymenttype");
  const [fetched, setFetched] = useState(false);

  let paymentTypes = useSelector((state) => state.paymentTypes);

  useEffect(() => {
    const fetchPaymentTypes = () => {
      paymentTypesApi.api().then((w) =>
        w
          .get()
          .json((json) =>
            dispatch({
              type: "LOAD_PAYMENT_TYPES",
              payload: json,
            })
          )
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Payment Types"))
      );
    };

    if (paymentTypes.length === 0) {
      !fetched && fetchPaymentTypes();
    } else {
      setFetched(true);
    }
  }, []);

  return [paymentTypes, fetched];
};

const useFetchSuppliers = () => {
  const dispatch = useDispatch();
  const suppliersApi = useProtectApi("supplier");
  const [fetched, setFetched] = useState(false);
  let suppliers = useSelector((state) => state.suppliers);

  useEffect(() => {
    const fetchSuppliers = () => {
      suppliersApi.api().then((w) =>
        w
          .get()
          .json((json) =>
            dispatch({
              type: "LOAD_SUPPLIERS",
              payload: json,
            })
          )
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Suppliers"))
      );
    };

    if (suppliers.length === 0) {
      !fetched && fetchSuppliers();
    } else {
      setFetched(true);
    }
  }, []);

  return [suppliers, fetched];
};

const useFetchSitesForCase = (spCase) => {
  const countrySitesApi = useProtectApi("site/case/" + spCase);
  const [fetchedSites, setFetchedSites] = useState([]);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchSites = () => {
      countrySitesApi.api().then((w) =>
        w
          .get()
          .json((json) => {
            setFetchedSites(json);
            setFetched(true);
          })
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Sites"))
      );
    };

    if (fetchedSites.length === 0) {
      !fetched && fetchSites();
    }
  }, []);

  return [fetchedSites, fetched];
};

const useFetchSites = () => {
  const dispatch = useDispatch();

  const sitesApi = useProtectApi("Site/");
  const [fetched, setFetched] = useState(false);

  let sites = useSelector((state) => state.sites);

  useEffect(() => {
    const fetchSites = () => {
      sitesApi.api().then((w) =>
        w
          .get()
          .json((json) =>
            dispatch({
              type: "LOAD_SITES",
              payload: json,
            })
          )
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve sites"))
      );
    };

    if (sites.length === 0) {
      !fetched && fetchSites();
    } else {
      setFetched(true);
    }
  }, []);

  return [sites, fetched];
};

const useFetchDocuments = () => {
  const dispatch = useDispatch();
  const documentsApi = useProtectApi("Document");

  const [fetched, setFetched] = useState(false);
  const documents = useSelector((state) => state.documents);
  // let documents = useSelector((state) => state.documents);

  useEffect(() => {
    const fetchDocuments = () => {
      documentsApi.api().then((w) =>
        w
          .get()
          .json((json) =>
            dispatch({
              type: "LOAD_DOCUMENTS",
              payload: json,
            })
          )
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Documents"))
      );
    };

    // if (documents.length === 0) {
    !fetched && fetchDocuments();
    // } else {
    // 	setFetched(true);
    // }
  }, []);

  return [documents, fetched];
};

const useFetchProblemDocumentDrops = () => {
  const dispatch = useDispatch();
  const documentDropApi = useProtectApi("DocumentDrop");
  const [fetched, setFetched] = useState(false);
  const documentDrops = useSelector((state) => state.problemDrops);

  useEffect(() => {
    const fetchDocumentDrops = () => {
      documentDropApi.api().then((w) =>
        w
          .get()
          .json((json) => {
            dispatch({
              type: "LOAD_PROBLEM_DROPS",
              payload: json,
            });
            return json;
          })
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Document Drop Problems"))
      );
    };

    //if (documentDrops.length === 0) {
    !fetched && fetchDocumentDrops();
    //} else {
    //setFetched(true);
    //}
  }, []);

  return [documentDrops, fetched];
};

const useFetchRemediationStatuses = () => {
  const dispatch = useDispatch();
  const remediationStatusApi = useProtectApi("RemediationStatus");
  const [fetched, setFetched] = useState(false);

  let remediationStatuses = useSelector((state) => state.remediationStatuses);

  useEffect(() => {
    const fetchRemediationStatuses = () => {
      remediationStatusApi.api().then((w) =>
        w
          .get()
          .json((json) => {
            dispatch({
              type: "LOAD_REMEDIATION_STATUSES",
              payload: json,
            });
            return json;
          })
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Remediation Statuses"))
      );
    };

    if (remediationStatuses.length === 0) {
      !fetched && fetchRemediationStatuses();
    } else {
      setFetched(true);
    }
  }, []);

  return [remediationStatuses, fetched];
};

const useFetchYoungWorkerNatureOfWorks = () => {
  const dispatch = useDispatch();
  const youngWorkerNatureOfWorksApi = useProtectApi("lookup/YoungWorkerNatureOfWorks");

  const [fetchedYoungWorkerNatureOfWorks, setFetchedYoungWorkerNatureOfWorks] = useState();
  const [fetched, setFetched] = useState(false);

  let youngWorkerNatureOfWorks = useSelector((state) => state.youngWorkerNatureOfWorks);

  useEffect(() => {
    const fetchYoungWorkerNatureOfWorks = () => {
      youngWorkerNatureOfWorksApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((youngWorkerNatureOfWorks) => {
            dispatch({
              type: "LOAD_YOUNG_WORKER_NATURE_OF_WORKS",
              payload: youngWorkerNatureOfWorks,
            });

            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Young Worker Nature Of Works.");
          })
      );
    };

    if (youngWorkerNatureOfWorks.length === 0) {
      !fetched && fetchYoungWorkerNatureOfWorks();
    } else {
      setFetched(true);
    }
  }, []);

  return [youngWorkerNatureOfWorks, fetched];
};

const useFetchYoungWorkerConditionOfWorks = () => {
  const dispatch = useDispatch();
  const youngWorkerConditionOfWorksApi = useProtectApi("lookup/YoungWorkerConditionOfWorks");
  const [fetched, setFetched] = useState(false);

  let youngWorkerConditionOfWorks = useSelector((state) => state.youngWorkerConditionOfWorks);

  useEffect(() => {
    const fetchYoungWorkerConditionOfWorks = () => {
      youngWorkerConditionOfWorksApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((youngWorkerConditionOfWorks) => {
            dispatch({
              type: "LOAD_YOUNG_WORKER_CONDITION_OF_WORKS",
              payload: youngWorkerConditionOfWorks,
            });

            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Young Worker Condition Of Works.");
          })
      );
    };

    if (youngWorkerConditionOfWorks.length === 0) {
      !fetched && fetchYoungWorkerConditionOfWorks();
    } else {
      setFetched(true);
    }
  }, []);

  return [youngWorkerConditionOfWorks, fetched];
};

const useFetchYoungWorkerDecisionSources = () => {
  const dispatch = useDispatch();
  const youngWorkerDecisionSourcesApi = useProtectApi("lookup/YoungWorkerDecisionSources");

  const [fetched, setFetched] = useState(false);

  let youngWorkerDecisionSources = useSelector((state) => state.youngWorkerDecisionSources);

  useEffect(() => {
    const fetchYoungWorkerDecisionSources = () => {
      youngWorkerDecisionSourcesApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((youngWorkerDecisionSources) => {
            dispatch({
              type: "LOAD_YOUNG_WORKER_DECISION_SOURCES",
              payload: youngWorkerDecisionSources,
            });

            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Young Worker Condition Of Works.");
          })
      );
    };

    if (youngWorkerDecisionSources.length === 0) {
      !fetched && fetchYoungWorkerDecisionSources();
    } else {
      setFetched(true);
    }
  }, []);

  return [youngWorkerDecisionSources, fetched];
};

const useFetchCaseDocumentTypes = () => {
  const dispatch = useDispatch();
  const caseDocumentTypesApi = useProtectApi("casedocument/types");
  const [fetched, setFetched] = useState(false);

  let caseDocumentTypes = useSelector((state) => state.caseDocumentTypes);

  useEffect(() => {
    const fetchCaseDocumentTypes = () => {
      caseDocumentTypesApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((caseDocumentTypes) => {
            dispatch({
              type: "LOAD_CASE_DOCUMENT_TYPES",
              payload: caseDocumentTypes,
            });

            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Case Document Types.");
          })
      );
    };

    if (caseDocumentTypes.length === 0) {
      !fetched && fetchCaseDocumentTypes();
    } else {
      setFetched(true);
    }
  }, []);

  return [caseDocumentTypes, fetched];
};

const useFetchReports = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const reportsApi = useProtectApi("report/");
  const [fetched, setFetched] = useState(false);

  let reports = useSelector((state) => state.reports);

  useEffect(() => {
    const fetchReports = () => {
      reportsApi.api().then((w) =>
        w
          .get()
          .forbidden((e) => {
            console.info("Access to Reports forbidden");
            toastr.error("You do not have the license required to access Reports");
            history.push("/home");
            return;
          })
          .json((json) =>
            dispatch({
              type: "LOAD_REPORTS",
              payload: json,
            })
          )
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Reports"))
      );
    };

    if (reports.length === 0) {
      !fetched && fetchReports();
    } else {
      setFetched(true);
    }
  }, []);

  return [reports, fetched];
};

const useFetchDocumentUploadSettings = () => {
  const documentSettingsApi = useProtectApi("document/settings");
  const [documentSettings, setDocumentSettings] = useState();
  const [documentSettingsFetched, setDocumentSettingsFetched] = useState(false);

  useEffect(() => {
    const fetchSettings = () => {
      documentSettingsApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((settings) => {
            setDocumentSettings(settings);
          })
          .then(() => setDocumentSettingsFetched(true))
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Document settings.");
          })
      );
    };
    !documentSettingsFetched && fetchSettings();
  }, []);

  return {
    documentSettings: documentSettings,
    documentSettingsFetched: documentSettingsFetched,
  };
};

const useFetchCurrencies = () => {
  const dispatch = useDispatch();
  const currencyApi = useProtectApi("Currency");
  const [fetched, setFetched] = useState(false);

  let currencies = useSelector((state) => state.currencies);

  useEffect(() => {
    const fetchCurrencies = () => {
      currencyApi.api().then((w) =>
        w
          .get()
          .json((json) => {
            dispatch({
              type: "LOAD_CURRENCIES",
              payload: json,
            });
            return json;
          })
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Currencies"))
      );
    };

    if (currencies.length === 0) {
      !fetched && fetchCurrencies();
    } else {
      setFetched(true);
    }
  }, []);

  return [currencies, fetched];
};

export {
  useFetchCaseCategories,
  useFetchEmploymentTypes,
  useFetchInvestigationTypes,
  useFetchNationalities,
  useFetchRemediationPartners,
  useFetchRoles,
  useFetchGenders,
  useFetchYesNoAnswers,
  useFetchSiteStatuses,
  useFetchSiteTiers,
  useFetchCountries,
  useFetchSuppliers,
  useFetchSites,
  useFetchDocuments,
  useFetchInvestigationStatuses,
  useFetchRemediationStatuses,
  useFetchYoungWorkerNatureOfWorks,
  useFetchYoungWorkerConditionOfWorks,
  useFetchYoungWorkerDecisionSources,
  useFetchSitesForCase,
  useFetchCaseDocumentTypes,
  useFetchReports,
  useFetchDocumentUploadSettings,
  useFetchLicenseTypes,
  useFetchProblemDocumentDrops,
  useFetchCurrencies,
  useFetchPaymentTypes,
};
