import { API_URL } from "../config";
import wretch from "wretch";
import { dedupe } from "wretch-middlewares";

const baseProtectApi = (token) =>
	wretch()
		.middlewares([
			dedupe({
				skip: (url, opts) => opts.skipDedupe || opts.method !== "GET",
				key: (url, opts) => opts.method + "@" + url,
			}),
		])
		.url(`${API_URL}/`)
		.auth(`Bearer ${token}`) // Authorization header
		.defaults({ headers: { Accept: "application/json" } }); // default headers

export default baseProtectApi;
