import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import useProtectApi from "../../hooks/useProtectApi";
import useFetchSupplier from "../../hooks/useFetchSupplier";
import useStyles from "./styles";
import {
  Tabs,
  Tab,
  TextField,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@material-ui/core";
import TabPanel from "../../components/Common/TabPanel";
import MUIDataTable from "mui-datatables";
import MUIDataTableAddButton from "../../components/Common/MUIDataTable.Add.Button";
import { useHistory, Route } from "react-router-dom";
import { toastr } from "../../components/Common/toastr";
import { DeleteButton } from "../../components/Common/Buttons";
import { BladeValidationDisplay } from "../../components/Common/Blade";
import SupplierAddNote from "./supplier.note.add";
import SupplierManageNote from "./supplier.note.manage";
import FormatDate from "../../helpers/date.display.format";
import useFetchCases from "../../hooks/useFetchCases";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { dateFilterChips, DateRangeFilters } from "../../components/TableFiltersDateFilters";

export const SupplierCtx = React.createContext();

const SupplierManage = () => {
  const { supplier, supplierFetched, supplierDispatcher } = useFetchSupplier();
  const [value, setValue] = React.useState(0);
  const [showAdminOptions, setShowAdminOptions] = useState(false);

  let user = useSelector((state) => state.user);

  useEffect(() => {
    user && setShowAdminOptions(user.isAdmin);
  }, [user]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return !supplierFetched ? (
    <div></div>
  ) : (
    <SupplierCtx.Provider value={{ supplier, supplierDispatcher }}>
      <div>
        <Paper style={{ padding: 0, paddingTop: 16 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="supplier tabs"
            indicatorColor="primary"
            variant="fullWidth"
            scrollButtons="auto"
          >
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Notes" {...a11yProps(1)} />
            <Tab label="Remove" {...a11yProps(2)} disabled={!showAdminOptions} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <SupplierDetails supplier={supplier} showAdminOptions={showAdminOptions} />
            <SupplierCases supplier={supplier} />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <SupplierNotes supplier={supplier} showAdminOptions={showAdminOptions} />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <RemoveSupplier supplier={supplier} />
          </TabPanel>
        </Paper>
      </div>
    </SupplierCtx.Provider>
  );
};

const SupplierDetails = ({ supplier, showAdminOptions }) => {
  const [editing, setEditing] = React.useState(false);

  const enableEditing = () => {
    setEditing(true);
  };

  const finishEditing = () => {
    setEditing(false);
  };

  return editing ? (
    <SupplierEditing finishEditing={finishEditing} />
  ) : (
    <SupplierReadOnlyDetails enableEditing={enableEditing} showAdminOptions={showAdminOptions} />
  );
};

const SupplierEditing = ({ finishEditing }) => {
  const { supplier, supplierDispatcher } = React.useContext(SupplierCtx);

  const dispatch = useDispatch();
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();

  const saveSupplierApi = useProtectApi("supplier/modify");
  const supplierForm = useRef();

  const [workingSupplier, setWorkingSupplier] = React.useState(supplier);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isBusy, setIsBusy] = React.useState(false);
  const [apiErrors, setApiErrors] = React.useState();

  const onSubmitForm = async (data, e) => {
    e.preventDefault();

    var request = {
      id: supplier.id,
      name: data.name,
      number: data.number,
    };

    const saveSupplier = () =>
      saveSupplierApi.api().then((w) =>
        w
          .put(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .res(() => {
            supplierDispatcher({
              type: "SUPPLIER_DETAILS_CHANGED",
              payload: {
                name: data.name,
                number: data.number,
              },
            });

            dispatch({
              type: "SUPPLIER_DETAILS_CHANGED",
              payload: {
                id: supplier.id,
                name: data.name,
                number: data.number,
              },
            });

            dispatch({
              type: "CASE_SUPPLIER_NAME_CHANGED",
              payload: {
                supplierId: supplier.id,
                oldName: supplier.name,
                name: data.name,
              },
            });
          })
          .then(() => toastr.success("Supplier details changed"))
          .then(() => setIsDirty(false))
          .then(() => finishEditing())
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to save Supplier information.");
          })
          .finally(() => setIsBusy(false))
      );

    setIsBusy(true);
    saveSupplier();
  };

  React.useEffect(() => {
    setIsDirty(JSON.stringify(supplier) !== JSON.stringify(workingSupplier));
  }, [supplier, workingSupplier]);

  const updateValue = (name, value) => {
    setWorkingSupplier((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={8} md={10}>
          <form onSubmit={handleSubmit(onSubmitForm)} ref={supplierForm} id="supplierForm">
            <section name="Supplier Name">
              <TextField
                label="Name *"
                name="name"
                error={!!(errors && errors.name)}
                style={{ marginBottom: 16, width: 400 }}
                autoComplete="off"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Name is required",
                  },
                  maxLength: {
                    value: 255,
                    message: "Name is too long",
                  },
                })}
                inputProps={{
                  "aria-label": "Name",
                }}
                helperText={errors && errors.name && errors.name.message}
                defaultValue={supplier && supplier.name}
                onChange={(e) => updateValue(e.target.name, e.target.value)}
              />
            </section>
            <section name="Supplier Code">
              <TextField
                label="Code *"
                name="number"
                error={!!(errors && errors.number)}
                autoComplete="off"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Code is required",
                  },
                  maxLength: {
                    value: 255,
                    message: "Code is too long",
                  },
                })}
                inputProps={{
                  "aria-label": "Description",
                }}
                style={{ marginBottom: 16, width: 400 }}
                helperText={errors && errors.number && errors.number.message}
                defaultValue={supplier && supplier.number}
                onChange={(e) => updateValue(e.target.name, e.target.value)}
              />
            </section>
          </form>

          {!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <SupplierEditingToolbar
            saveDisabled={isBusy || !isDirty}
            onCloseClick={finishEditing}
            formRef={supplierForm && supplierForm.current}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const SupplierReadOnlyDetails = ({ enableEditing, showAdminOptions }) => {
  const { supplier } = React.useContext(SupplierCtx);
  const classes = useStyles();

  return (
    <div style={{ padding: 8 }}>
      <Grid container>
        <Grid item sm={9} xs={12}>
          <Grid item sm={9} xs={12} container spacing={1}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={classes.row}>
                <div className={classes.label}>Name:</div>
                <div className={classes.value}>{supplier.name}</div>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={classes.row}>
                <div className={classes.label}>Code:</div>
                <div className={classes.value}>{supplier.number}</div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={3} xs={12}>
          {showAdminOptions && <SupplierReadOnlyToolbar onEditClick={enableEditing} />}
        </Grid>
      </Grid>
    </div>
  );
};

const SupplierEditingToolbar = ({ formRef, saveDisabled, onCloseClick }) => {
  const classes = useStyles();

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<SaveIcon />}
        disabled={saveDisabled}
        type="submit"
        form={formRef && formRef.id}
        style={{ marginBottom: 8 }}
        fullWidth={true}
      >
        Save
      </Button>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        startIcon={<CloseIcon />}
        onClick={onCloseClick}
        fullWidth={true}
      >
        Close
      </Button>
    </div>
  );
};

const SupplierReadOnlyToolbar = ({ onEditClick }) => {
  const classes = useStyles();

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<EditIcon />}
        onClick={onEditClick}
        style={{ marginBottom: 8 }}
        fullWidth={true}
      >
        Edit
      </Button>
    </div>
  );
};

const SupplierCases = ({ supplier }) => {
  const history = useHistory();
  const [fetchedCases, casesFetched] = useFetchCases();
  const [supplierCases, setSupplierCases] = useState([]);

  useEffect(() => {
    setSupplierCases(fetchedCases.filter((c) => c.supplierIds.includes(supplier.id)));
  }, [fetchedCases, casesFetched]);

  const onRowClick = (rowData, rowMeta) => {
    supplier && history.push("/case/" + rowData[0]);
  };

  const gridOptions = {
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    filterType: "multiselect",
    selectableRows: "none",
    pagination: false,
    print: false,
    download: false,
    onRowClick: onRowClick,
    elevation: 0,
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "country",
      label: "Country",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "siteFound",
      label: "Site Found",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "dateFound",
      label: "Date Found",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return FormatDate(value);
        },
        filterType: "custom",
        customFilterListOptions: dateFilterChips("Date Found"),
        filterOptions: DateRangeFilters,
      },
    },
    {
      name: "number",
      label: "CL No",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "remediationPartner",
      label: "Remediation Partner",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "caseCategory",
      label: "Category",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "investigationStatus",
      label: "Investigation Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "remediationStatus",
      label: "Remediation Status",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "ageAtAudit",
      label: "Age When Found",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "highRisk",
      label: "High Risk",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "closureApproved",
      label: "Closed",
      options: {
        filter: true,
        sort: true,
        display: false,
        filterList: ["No"],
        customFilterListOptions: {
          render: (v) => (v === "No" ? "Open" : ""),
        },
      },
    },
    {
      name: "lastUpdateDate",
      label: "Last Update",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return FormatDate(value);
          }
          return null;
        },
      },
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return FormatDate(value);
          }
          return null;
        },
        filterType: "custom",
        customFilterListOptions: dateFilterChips("End Date"),
        filterOptions: DateRangeFilters,
      },
    },
  ];

  return (
    <div style={{ marginTop: 16 }}>
      <MUIDataTable title={"Cases"} data={supplierCases} columns={gridColumns} options={gridOptions} />
    </div>
  );
};

const SupplierNotes = ({ supplier, showAdminOptions }) => {
  const history = useHistory();
  const addNote = () => {
    var url = "/supplier/manage/" + supplier.id + "/addnote";
    history.push(url);
  };

  const onRowClick = (rowData, rowMeta) => {
    supplier && history.push("/supplier/manage/" + supplier.id + "/note/" + rowData[0]);
  };

  const gridOptions = {
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    filterType: "multiselect",
    selectableRows: "none",
    pagination: false,
    print: false,
    download: false,
    elevation: 0,
    onRowClick: onRowClick,
    customToolbar: () => {
      return <MUIDataTableAddButton title="Add Note" clickHandler={addNote} />;
    },
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
        if (colIndex === 1) {
          return (new Date(a.data[colIndex]) < new Date(b.data[colIndex]) ? -1 : 1) * (order === "desc" ? 1 : -1);
        } else {
          return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === "desc" ? 1 : -1);
        }
      });
    },
  };

  const gridColumns = [
    {
      name: "noteId",
      label: "Id",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "noteDate",
      label: "Created Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return FormatDate(value);
        },
        filterType: "custom",
        customFilterListOptions: dateFilterChips("Created Date"),
        filterOptions: DateRangeFilters,
      },
    },
    {
      name: "createdBy",
      label: "Created By",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "noteText",
      label: "Note",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  return (
    <div>
      <MUIDataTable
        title={"Notes"}
        data={supplier && Array.isArray(supplier.notes) && supplier.notes}
        columns={gridColumns}
        options={gridOptions}
      />
      <Route exact path="/supplier/manage/:supplierId/addnote">
        <SupplierAddNote />
      </Route>
      <Route exact path="/supplier/manage/:supplierId/note/:noteId">
        <SupplierManageNote />
      </Route>
    </div>
  );
};

const RemoveSupplier = ({ supplier }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const deleteSupplierApi = useProtectApi("supplier/delete");
  const [isBusy, setIsBusy] = useState(false);
  const [apiErrors, setApiErrors] = useState();

  const [showRemoveConfirmDialog, setShowRemoveConfirmDialog] = useState(false);

  const closePage = () => {
    history.push("/suppliers");
  };

  const closeRemoveDialog = () => {
    setShowRemoveConfirmDialog(false);
  };

  const confirmRemoveSupplierClick = () => {
    var request = {
      Id: supplier.id,
    };

    const apiDelete = () =>
      deleteSupplierApi.api().then((w) =>
        w
          .put(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .res(() => {
            dispatch({
              type: "REMOVE_SUPPLIER",
              payload: { id: supplier.id },
            });
          })
          .then(() => closePage())
          .then(() => toastr.success("Supplier removed!"))
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to remove " + supplier.name + ".");
          })
          .finally(() => {
            closeRemoveDialog();
            setIsBusy(false);
          })
      );

    setIsBusy(true);
    apiDelete();
  };

  return (
    <div>
      <Typography>Are you sure you want to remove Supplier '{supplier.name}' from the application?</Typography>

      <Typography>
        If you do so this Supplier will no longer be available for selection for new cases in the application.
      </Typography>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}>
        <DeleteButton
          variant="outlined"
          color="primary"
          size="medium"
          onClick={() => setShowRemoveConfirmDialog(true)}
          className={classes.button}
          disabled={isBusy}
          text="Remove"
        />
      </div>
      {!!apiErrors && (
        <div style={{ marginTop: 16 }}>
          <BladeValidationDisplay validationErrors={apiErrors} />
        </div>
      )}
      {showRemoveConfirmDialog && supplier && (
        <div>
          <Dialog
            open={showRemoveConfirmDialog}
            onClose={() => showRemoveConfirmDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Remove</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to remove {supplier.name}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeRemoveDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={() => confirmRemoveSupplierClick()} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default SupplierManage;
