import React, { useState, useEffect } from "react";
import useStyles from "../../components/Common/styles";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toastr } from "../../components/Common/toastr";
import { Blade, BladeValidationDisplay, BladeTabPanel } from "../../components/Common/Blade";
import { TextField, Tab, Tabs, Grid, Typography, Button, FormControlLabel, Switch } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import useProtectApi from "../../hooks/useProtectApi";
import { useFetchCaseCategory } from "../../hooks/useFetchEntity";
import { DeleteButton } from "../../components/Common/Buttons";

const CaseCategoryManage = () => {
	const [value, setValue] = React.useState(0);
	const [localCaseCategory] = useFetchCaseCategory();

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	function a11yProps(index) {
		return {
			id: `tab-${index}`,
			"aria-controls": `tabpanel-${index}`,
		};
	}
	return !localCaseCategory ? (
		<div></div>
	) : (
		<Blade>
			<Tabs
				value={value}
				onChange={handleChange}
				aria-label="user tabs"
				variant="fullWidth"
				indicatorColor="primary">
				<Tab label="Details" {...a11yProps(0)} />
				<Tab label="Remove" {...a11yProps(1)} />
			</Tabs>
			<BladeTabPanel value={value} index={0}>
				<CaseCategoryDetails caseCategory={localCaseCategory} />
			</BladeTabPanel>
			<BladeTabPanel value={value} index={1}>
				<RemoveCaseCategory caseCategory={localCaseCategory} />
			</BladeTabPanel>
		</Blade>
	);
};

const CaseCategoryDetails = ({ caseCategory }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const renameCaseCategoryApi = useProtectApi("caseCategory/modify");
	const { register, handleSubmit, errors } = useForm();
	const history = useHistory();

	const [isSpecialCase, setIsSpecialCase] = React.useState(false);
	const [isYoungWorker, setIsYoungWorker] = React.useState(false);
	const [isBusy, setIsBusy] = useState(false);
	const [apiErrors, setApiErrors] = useState();

	useEffect(() => {
		caseCategory && setIsSpecialCase(caseCategory.isSpecialCase);
		caseCategory && setIsYoungWorker(caseCategory.isYoungWorker);
	}, [caseCategory]);

	const onSubmit = async (data) => {
		console.info(data);

		var request = {
			id: caseCategory.id,
			name: data.name,
			isSpecialCase: isSpecialCase,
			isYoungWorker: isYoungWorker,
		};

		const renameCaseCategory = () =>
			renameCaseCategoryApi.api().then((w) =>
				w
					.put(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.json((json) => {
						var modifiedCaseCategory = {
							...request,
						};

						console.info(modifiedCaseCategory);

						dispatch({
							type: "MODIFY_CASE_CATEGORY",
							payload: modifiedCaseCategory,
						});

						return modifiedCaseCategory;
					})
					.then(() => toastr.success("Case Category details changed"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to change Case Category name.");
					})
					.finally(() => setIsBusy(false))
			);

		setIsBusy(true);
		renameCaseCategory();
	};

	const closeDrawer = () => {
		history.goBack();
	};

	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<section>
					<TextField
						label="Name *"
						fullWidth
						autoFocus
						name="name"
						error={!!(errors && errors.name)}
						style={{ marginBottom: 16 }}
						autoComplete="off"
						inputRef={register({
							required: {
								value: true,
								message: "Name is required",
							},
							maxLength: {
								value: 255,
								message: "Name is too long",
							},
						})}
						inputProps={{
							"aria-label": "Name",
						}}
						helperText={errors && errors.name && errors.name.message}
						defaultValue={caseCategory.name}
					/>
				</section>

				<section name="Special Case">
					<FormControlLabel
						labelPlacement="start"
						control={
							<Switch
								color="primary"
								name="isSpecialCase"
								inputRef={register({})}
								checked={isSpecialCase}
								onChange={(e, v) => {
									setIsSpecialCase(e.target.checked);
								}}
							/>
						}
						label="Is Special Case Status"
					/>
				</section>

				<section name="Young Worker">
					<FormControlLabel
						labelPlacement="start"
						control={
							<Switch
								color="primary"
								name="isYoungWorker"
								inputRef={register({})}
								checked={isYoungWorker}
								onChange={(e, v) => {
									setIsYoungWorker(e.target.checked);
								}}
							/>
						}
						label="Is Young Worker Status"
					/>
				</section>
				{!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}

				<div className={classes.drawerBladeToolbar}>
					<Button
						variant="contained"
						color="primary"
						size="medium"
						type="submit"
						className={classes.button}
						endIcon={<SaveIcon />}
						disabled={isBusy}>
						SAVE
					</Button>
				</div>
			</form>
		</div>
	);
};

const RemoveCaseCategory = ({ caseCategory }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const removeCaseCategoryApi = useProtectApi("caseCategory/delete");
	const [isBusy, setIsBusy] = useState(false);
	const [apiErrors, setApiErrors] = useState();

	const closeDrawer = () => {
		history.goBack();
	};

	const removeCaseCategoryClick = () => {
		var request = {
			Id: caseCategory.id,
		};

		const apiDelete = () =>
			removeCaseCategoryApi.api().then((w) =>
				w
					.put(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.res(() => {
						dispatch({
							type: "REMOVE_CASE_CATEGORY",
							payload: caseCategory,
						});
					})
					.then(() => toastr.success("Case Category removed"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to remove Case Category.");
					})
			);

		setIsBusy(true);
		apiDelete();
	};

	return (
		<div>
			<Typography>
				<p>Remove Case Category '{caseCategory.name}' from the application?</p>
				<p>If you do so this Case Category will no longer be available.</p>
			</Typography>
			<div className={classes.drawerBladeToolbar}>
				<DeleteButton
					variant="outlined"
					color="primary"
					size="medium"
					onClick={() => removeCaseCategoryClick()}
					className={classes.button}
					disabled={isBusy}
					text="Remove"
				/>
			</div>

			{!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}
		</div>
	);
};

export default CaseCategoryManage;
