import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import useStyles from "../../components/Common/styles";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Blade, BladeValidationDisplay } from "../../components/Common/Blade";
import { toastr } from "../../components/Common/toastr";
import useProtectApi from "../../hooks/useProtectApi";
import { useFetchCountries, useFetchSiteTiers } from "../../hooks/useFetchEntities";

const SiteCreate = () => {
	const classes = useStyles();
	const { register, handleSubmit, errors } = useForm();
	const history = useHistory();
	const dispatch = useDispatch();
	const siteCreateApi = useProtectApi("site/create");

	const [countryNames] = useFetchCountries();
	const [tierNames] = useFetchSiteTiers();

	const [createErrors, setCreateErrors] = useState();
	const [isBusy, setIsBusy] = useState(false);

	const onSubmit = async (data) => {
		var countryId = data.country ? countryNames.find((c) => c.name === data.country).id : null;

		var tierId = data.tier ? tierNames.find((c) => c.name === data.tier).id : null;

		var siteRequest = {
			name: data.name,
			number: data.number,
			countryId: countryId,
			tierId: tierId,
		};

		const createSite = () =>
			siteCreateApi.api().then((w) =>
				w
					.post(siteRequest)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setCreateErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setCreateErrors(errDetail);
						throw new Error(errDetail);
					})
					.json((json) => {
						var newSite = {
							...siteRequest,
							id: json.id,
							countryName: data.country,
							tierName: data.tier,
						};

						dispatch({
							type: "CREATE_SITE",
							payload: newSite,
						});
					})
					.then(() => toastr.success("Site created"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to create Site.");
					})
					.finally(() => setIsBusy(false))
			);
		setIsBusy(true);
		createSite();
	};

	const closeDrawer = () => {
		history.push("/sites");
	};

	return (
		<Blade title="Create Site">
			<div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<TextField
						label="Name *"
						fullWidth
						autoFocus
						name="name"
						error={!!(errors && errors.name)}
						autoComplete="off"
						style={{ marginBottom: 16 }}
						inputRef={register({
							required: {
								value: true,
								message: "Name is required",
							},
							maxLength: {
								value: 255,
								message: "Name is too long",
							},
						})}
						inputProps={{
							"aria-label": "Description",
						}}
						helperText={errors && errors.name && errors.name.message}
					/>

					<TextField
						label="Code *"
						fullWidth
						name="number"
						error={!!(errors && errors.number)}
						autoComplete="off"
						inputRef={register({
							required: {
								value: true,
								message: "Code is required",
							},
							maxLength: {
								value: 255,
								message: "Code is too long",
							},
						})}
						inputProps={{
							"aria-label": "Description",
						}}
						helperText={errors && errors.prefix && errors.prefix.number}
						style={{ marginBottom: 16 }}
					/>

					<section>
						<Autocomplete
							id="countryAutocomplete"
							options={countryNames ? countryNames.map((r) => r.name) : []}
							getOptionLabel={(option) => option}
							renderInput={(params) => {
								return (
									<TextField
										{...params}
										name="country"
										label={"Country *"}
										fullWidth
										inputRef={register({
											required: {
												value: true,
												message: "Country is required",
											},
										})}
										error={!!(errors && errors.country)}
										helperText={errors && errors.country && errors.country.message}
										style={{ marginBottom: 16 }}
									/>
								);
							}}
						/>
					</section>

					<section>
						<Autocomplete
							id="tierAutocomplete"
							options={tierNames ? tierNames.map((r) => r.name) : []}
							getOptionLabel={(option) => option}
							renderInput={(params) => {
								return (
									<TextField
										{...params}
										name="tier"
										label={"Tier *"}
										fullWidth
										inputRef={register({
											required: {
												value: true,
												message: "Tier is required",
											},
										})}
										error={!!(errors && errors.tier)}
										helperText={errors && errors.tier && errors.tier.message}
										style={{ marginBottom: 16 }}
									/>
								);
							}}
						/>
					</section>

					{!!createErrors && <BladeValidationDisplay validationErrors={createErrors} />}

					<div className={classes.drawerBladeToolbar}>
						<Button
							variant="contained"
							color="primary"
							size="medium"
							type="submit"
							className={classes.button}
							endIcon={<SaveIcon />}
							disabled={isBusy}>
							SAVE
						</Button>
					</div>
				</form>
			</div>
		</Blade>
	);
};

export default SiteCreate;
