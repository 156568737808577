import initialState from "../initialState";
import { sortByName } from "../sortEntities";

export const caseCategories = (state = initialState.caseCategories, action) => {
	switch (action.type) {
		case "LOAD_CASE_CATEGORIES":
			return [...action.payload];

		case "LOAD_CASE_CATEGORY":
			return state.map((x) =>
				x.id !== action.payload.id
					? x
					: {
							...x,
					  }
			);

		case "MODIFY_CASE_CATEGORY":
			return state
				.map((x) =>
					x.id !== action.payload.id
						? x
						: {
								...x,
								name: action.payload.name,
								isYoungWorker: action.payload.isYoungWorker,
								isSpecialCase: action.payload.isSpecialCase,
						  }
				)
				.sort(sortByName);

		case "CREATE_CASE_CATEGORY":
			return [...state, action.payload].sort(sortByName);

		case "REMOVE_CASE_CATEGORY":
			return state.filter((caseCategory) => caseCategory.id !== action.payload.id);

		default:
			return state;
	}
};

export default caseCategories;
