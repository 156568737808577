import React from "react";
import { CaseCtx } from "../pages/cases/case.manage";
import { useDispatch } from "react-redux";
import moment from "moment";

export const useRecalculateCaseMetrics = () => {
	const { spCase } = React.useContext(CaseCtx);
	const dispatch = useDispatch();

	const isCaseOpen = () => {
		return spCase.closure && spCase.closure.closureApproved !== "Yes";
	};

	const isFoundSixWeeksAgo = () => {
		return spCase.audit && moment(spCase.audit.dateFound) < moment().add(-42, "days");
	};

	const calculateIsOpen = () => {
		return isCaseOpen();
	};

	const calculateIsOverdue = () => {
		return isCaseOpen() && moment(spCase.lastUpdatedDate) < moment().add(-42, "days");
	};

	const calculateIsMouNotSigned = () => {
		return (
			isCaseOpen() &&
			isFoundSixWeeksAgo() &&
			spCase.supplierAgreement &&
			spCase.supplierAgreement.mouSigned !== "Yes"
		);
	};

	const calculateIsInvoiceDue = () => {
		return (
			isCaseOpen() &&
			isFoundSixWeeksAgo() &&
			spCase.supplierAgreement &&
			spCase.supplierAgreement.invoicePaid !== "Yes"
		);
	};

	const calculateIsEndDateApproaching = () => {
		return isCaseOpen() && spCase.remediation && moment(spCase.remediation.endDate) >= moment().add("months", -3);
	};

	const calculateIsCriticalMetric = () => {
		var remediationStatus = spCase.detail && spCase.detail.remediationStatus ? spCase.detail.remediationStatus : "";

		return isCaseOpen() && remediationStatus.startsWith("Criti");
	};

	const calculateHasNoRemeditionPlan = () => {
		return isCaseOpen() && isFoundSixWeeksAgo() && !spCase.remediation.remediationSummary;
	};

	const calculateHasNoNeedsAssessmentMetric = () => {
		return isCaseOpen() && isFoundSixWeeksAgo() && !spCase.needsAssessment.needsAssessmentSummary;
	};

	const calculateHasOtherValue = (otherSupplier, otherSite, otherNationality) => {
		return spCase.detail.nationality === otherNationality ||
			spCase.audit.siteFound === otherSite ||
			spCase.audit.linkedTier1Site === otherSite ||
			spCase.audit.linkedTier2Site === otherSite ||
			spCase.audit.linkedTier3Site === otherSite ||
			spCase.audit.supplier1Name === otherSupplier ||
			spCase.audit.supplier2Name === otherSupplier ||
			spCase.audit.supplier3Name === otherSupplier ||
			spCase.audit.supplier4Name === otherSupplier ||
			spCase.audit.supplier5Name === otherSupplier
			? "Yes"
			: "No";
	};

	React.useEffect(() => {
		var isOpenMetric = spCase && calculateIsOpen();
		var isOverdueMetric = spCase && calculateIsOverdue();
		var isMouNotSignedMetric = spCase && calculateIsMouNotSigned();
		var isInvoiceDueMetric = spCase && calculateIsInvoiceDue();
		var isEndDateApproachingMetric = spCase && calculateIsEndDateApproaching();
		var isCriticalMetric = spCase && calculateIsCriticalMetric();
		var hasNoRemeditionPlanMetric = spCase && calculateHasNoRemeditionPlan();
		var hasNoNeedsAssessmentMetric = spCase && calculateHasNoNeedsAssessmentMetric();
		var hasOtherValue = spCase && calculateHasOtherValue("Other", "Other", "Other");
		spCase &&
			dispatch({
				type: "CASE_RECALCULATE_METRICS",
				payload: {
					id: spCase.id,
					isOpenMetric,
					isOverdueMetric,
					isMouNotSignedMetric,
					isInvoiceDueMetric,
					isEndDateApproachingMetric,
					isCriticalMetric,
					hasNoRemeditionPlanMetric,
					hasNoNeedsAssessmentMetric,
					hasOtherValue,
				},
			});
	}, [spCase]);
};
