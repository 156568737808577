import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Fab,
  Tooltip,
  Link,
  Tab,
  Tabs,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useHistory, Route, useParams } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "./styles";
import useGlobalStyles from "../../components/Common/styles";
import { CaseCtx } from "./case.manage";
import { Button, TextField } from "@material-ui/core";
import { BladeTabPanel } from "../../components/Common/Blade";
import SaveIcon from "@material-ui/icons/Save";
import GetAppIcon from "@material-ui/icons/GetApp";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import { useForm } from "react-hook-form";
import { Blade, BladeValidationDisplay } from "../../components/Common/Blade";
import { toastr } from "../../components/Common/toastr";
import useProtectApi from "../../hooks/useProtectApi";
import { DeleteButton } from "../../components/Common/Buttons";
import FormatDate from "../../helpers/date.display.format";
import { useFetchDocumentUploadSettings } from "../../hooks/useFetchEntities";
import { useDispatch } from "react-redux";
import useDownloadDocument from "../../hooks/useDownloadDocument";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { dateFilterChips, DateRangeFilters } from "../../components/TableFiltersDateFilters";

const CaseMonitoring = ({ tabIndex, beginEdit, finishEdit }) => {
  const classes = useStyles();
  const history = useHistory();
  const { spCase } = React.useContext(CaseCtx);
  const documentDownload = useDownloadDocument();

  const onCellClick = (colData, cellMeta) => {
    if (cellMeta.colIndex !== 5) {
      var monitoring = spCase.caseMonitorings[cellMeta.dataIndex];
      history.push("/case/" + spCase.id + "/monitoring/" + monitoring.monitoringId);
    }
  };

  const gridOptions = {
    elevation: 0,
    filterType: "multiselect",
    selectableRows: "none",
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    onCellClick: onCellClick,
    print: false,
    download: false,
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
  };
  const gridColumns = [
    {
      name: "monitoringId",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "caseId",
      label: "Case Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "monitoringDate",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return FormatDate(value);
        },
        filterType: "custom",
        customFilterListOptions: dateFilterChips("Date"),
        filterOptions: DateRangeFilters,
      },
    },
    {
      name: "createdBy",
      label: "Updated By",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "details",
      label: "Update",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "documents",
      label: "Documents(s)",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!Array.isArray(value)) return <div></div>;
          return (
            <div style={{ display: "flex", flexDirection: "column", cursor: "pointer" }}>
              {value.map((doc) => {
                return (
                  <Link
                    key={doc.caseMonitoringDocumentId}
                    component="span"
                    variant="body2"
                    onClick={() => {
                      documentDownload(doc.documentId, doc.fileName);
                    }}
                  >
                    {doc.fileName}
                  </Link>
                );
              })}
            </div>
          );
        },
      },
    },
  ];

  const fabStyle = {
    right: 20,
    position: "fixed",
  };

  return (
    <>
      <Tooltip title="Add" aria-label="add">
        <Fab
          color="primary"
          aria-label="add"
          style={fabStyle}
          component={Link}
          onClick={() => history.push("/case/" + spCase.id + "/add/monitoring")}
        >
          <AddIcon />
        </Fab>
      </Tooltip>

      <MUIDataTable
        title={"Case Monitoring"}
        data={spCase && Array.isArray(spCase.caseMonitorings) && spCase.caseMonitorings}
        columns={gridColumns}
        options={gridOptions}
      />

      <Route path={"/case/" + spCase.id + "/add/monitoring"}>
        <CaseMonitoringAdd />
      </Route>

      <Route path={"/case/" + spCase.id + "/monitoring/:monitoringId"}>
        <CaseMonitorManage />
      </Route>
    </>
  );
};

const CaseMonitoringAdd = () => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return (
    <Blade size="Largest">
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="user tabs"
        variant="fullWidth"
        indicatorColor="primary"
      >
        <Tab label="Create with Existing Documents" {...a11yProps(0)} />
        <Tab label="Create with New Document" {...a11yProps(1)} />
      </Tabs>
      <BladeTabPanel value={tabValue} index={0}>
        <CaseMonitoringAddWithExisting />
      </BladeTabPanel>
      <BladeTabPanel value={tabValue} index={1}>
        <CaseMonitoringAddWithNew />
      </BladeTabPanel>
    </Blade>
  );
};

const CaseMonitoringAddWithExisting = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const { spCase, caseDispatcher, caseFetched } = React.useContext(CaseCtx);

  const caseMonitoringCreateApi = useProtectApi("casemonitoring/create/attach");
  const getAvailableDocumentsApi = useProtectApi("document/country/");

  const [createErrors, setCreateErrors] = useState();
  const [isBusy, setIsBusy] = useState(false);
  const [monitoringDate, setMonitoringDate] = useState(moment());

  const [availableDocuments, setAvailableDocuments] = React.useState([]);
  const [availableDocumentsFetched, setAvailableDocumentsFetched] = React.useState(false);
  const [selectedDocumentIds, setSelectedDocumentIds] = React.useState([]);
  const [rowsSelected, setRowsSelected] = React.useState([]);

  useEffect(() => {
    const fetchAvailableDocuments = () => {
      getAvailableDocumentsApi.api().then((w) =>
        w
          .url(spCase.audit.country)
          .get()
          .json((json) => {
            setAvailableDocuments(json);
            return json;
          })
          .then(() => setAvailableDocumentsFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Documents to attach to Case"))
      );
    };

    if (!availableDocumentsFetched) {
      availableDocuments.length === 0 && caseFetched && fetchAvailableDocuments();
    }
  }, [caseFetched && availableDocuments, availableDocumentsFetched]);

  useEffect(() => {
    var ids = [];
    rowsSelected.forEach((rowIndex) => {
      var item = availableDocuments[rowIndex];
      ids.push(item.id);
    });

    setSelectedDocumentIds(ids);
  }, [rowsSelected]);

  const handleDateFoundChange = (e) => {
    setMonitoringDate(e);
  };

  const onSubmit = async (data) => {
    var moment = require("moment-timezone");
    var zoneName = moment.tz.guess();

    let _monitoringDate = moment.isMoment(monitoringDate) ? monitoringDate : moment(monitoringDate);
    let _submitDt = moment(_monitoringDate.format("YYYY/MM/DD") + " " + moment().format("HH:mm"));

    console.info(_submitDt.toDate());

    var request = {
      caseId: spCase.id,
      details: data.details,
      documentIds: selectedDocumentIds,
      monitoringDate: _submitDt.toDate(),
      timezone: zoneName,
    };

    const addMonitoring = () =>
      caseMonitoringCreateApi.api().then((w) =>
        w
          .post(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setCreateErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setCreateErrors(errDetail);
            throw new Error(errDetail);
          })
          .json((json) => {
            caseDispatcher({
              type: "CASE_MONITORING_ADDED",
              payload: json,
            });

            json.documents.forEach((d) => {
              dispatch({
                type: "DOCUMENT_ATTACHED_TO_CASE",
                payload: { id: d.documentId },
              });
            });
          })
          .then(() => {
            setSelectedDocumentIds([]);
            setAvailableDocuments([]);
            setAvailableDocumentsFetched(false);
          })
          .then(() => toastr.success("Monitoring Update added"))
          .then(() => closeDrawer())
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to add new Monitoring Update");
          })
          .finally(() => {
            setIsBusy(false);
          })
      );

    setIsBusy(true);
    addMonitoring();
  };
  const closeDrawer = () => {
    history.push("/case/" + spCase.id);
  };

  const gridOptions = {
    filterType: "multiselect",
    selectableRows: "multiple",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 30],
    print: false,
    download: false,
    filter: false,
    searchOpen: true,
    viewColumns: false,
    disableToolbarSelect: true,
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
        if (colIndex === 3) {
          return (new Date(a.data[colIndex]) < new Date(b.data[colIndex]) ? -1 : 1) * (order === "desc" ? 1 : -1);
        } else {
          return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === "desc" ? 1 : -1);
        }
      });
    },
    textLabels: {
      body: {
        noMatch: "Sorry, no Documents found",
      },
    },
    setTableProps: () => {
      return {
        padding: "checkbox",
        size: "small",
      };
    },
    rowsSelected: rowsSelected,
    onRowsSelect: (rowsSelected, allRows) => {
      setRowsSelected(allRows.map((row) => row.dataIndex));
    },
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "fileName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "createdDate",
      label: "Upload Date",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return FormatDate(value);
        },
      },
    },
    {
      name: "createdBy",
      label: "Uploaded By",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
  ];

  return !availableDocumentsFetched ? (
    <div> </div>
  ) : (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section>
            <KeyboardDatePicker
              autoOk={true}
              animateYearScrolling={true}
              name="monitoringDate"
              error={!!(errors && errors.monitoringDate)}
              format="DD/MM/YYYY"
              label="Monitoring Date"
              onChange={handleDateFoundChange}
              value={monitoringDate}
              inputRef={register({
                required: {
                  value: true,
                  message: "Monitoring Date is required",
                },
              })}
              helperText={errors && errors.monitoringDate && errors.monitoringDate.message}
              style={{ marginBottom: 16 }}
            />
          </section>

          <section name="Details">
            <TextField
              label="Monitoring Summary &amp; Details *"
              fullWidth
              name="details"
              multiline
              autoComplete="off"
              rows="6"
              error={!!(errors && errors.details)}
              style={{ marginBottom: 16 }}
              inputRef={register({
                required: {
                  value: true,
                  message: "Monitoring Details are required",
                },
              })}
              inputProps={{
                "aria-label": "Details",
              }}
              helperText={errors && errors.details && errors.details.message}
            />
          </section>

          <section name="Document selection">
            <div style={{ marginBottom: 16 }}>
              <MUIDataTable title={""} data={availableDocuments} columns={gridColumns} options={gridOptions} />
            </div>
          </section>

          {!!createErrors && <BladeValidationDisplay validationErrors={createErrors} />}

          <div className={globalClasses.drawerBladeToolbar}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              type="submit"
              className={classes.button}
              endIcon={<SaveIcon />}
              disabled={isBusy}
            >
              SAVE
            </Button>
          </div>
        </form>
      </MuiPickersUtilsProvider>
    </div>
  );
};

const CaseMonitoringAddWithNew = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { register, handleSubmit, errors, clearError } = useForm();
  const history = useHistory();

  const { spCase, caseDispatcher, caseFetched } = React.useContext(CaseCtx);

  const caseMonitoringCreateApi = useProtectApi("casemonitoring/create/upload");
  const { documentSettings, documentSettingsFetched } = useFetchDocumentUploadSettings();

  const [createErrors, setCreateErrors] = React.useState();
  const [isBusy, setIsBusy] = React.useState(false);
  const [monitoringDate, setMonitoringDate] = useState(moment());

  const [blockSubmit, setBlockSubmit] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState();
  const [selectedFileName, setSelectedFileName] = useState();
  const [selectFilePrompt, setSelectFilePrompt] = useState("Select File...");

  const closeDrawer = () => {
    history.push("/case/" + spCase.id);
  };

  const handleDateFoundChange = (e) => {
    setMonitoringDate(e);
  };

  const onSubmit = async (data) => {
    if (blockSubmit) {
      return;
    }

    var moment = require("moment-timezone");
    var zoneName = moment.tz.guess();

    //

    if (moment.isMoment(monitoringDate)) {
      console.info("do not cast. It is moment");
      console.info(monitoringDate.toISOString(true));
      console.info(monitoringDate.toJSON());
      console.info(monitoringDate.toDate());
      console.info(monitoringDate.format("MM-DD-YYYY h:mm:ss"));
    }

    //var isoMonDate = moment(monitoringDate).toISOString(true);
    //var jsonMonDate = moment(monitoringDate).toJSON();
    var monDate = moment(monitoringDate).toDate();
    var formattedMonDate = monitoringDate.format("MM-DD-YYYY h:mm:ss");
    var utc = monitoringDate.utc().toJSON();
    var json = monitoringDate.toJSON();
    var request = {
      fileName: selectedFileName,
      document: selectedDocument,
      caseId: spCase.id,
      details: data.details,
      description: data.description,
      monitoringDate: json,
      timezone: zoneName,
    };

    const createdAndAttachDoc = () =>
      caseMonitoringCreateApi.api().then((w) =>
        w
          .formData(request)
          .post()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setCreateErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setCreateErrors(errDetail);
            throw new Error(errDetail);
          })
          .json((json) => {
            caseDispatcher({
              type: "CASE_MONITORING_ADDED",
              payload: json,
            });

            return json;
          })
          .then(() => toastr.success("Monitoring Update added"))
          .then(() => closeDrawer())
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to add new Monitoring Update");
          })
          .finally(() => {
            setIsBusy(false);
          })
      );
    setIsBusy(true);
    createdAndAttachDoc();
  };

  const ValidateFileSize = (file) => {
    if (file.size > documentSettings.maxFileSize) {
      setBlockSubmit(true);
    } else {
      setBlockSubmit(false);
    }
  };

  const setFile = (e) => {
    if (e.target.files.length > 0) {
      setSelectFilePrompt("Change File...");
      setSelectedFileName(e.target.files[0].name);
      setSelectedDocument(e.target.files[0]);
      clearError("document");

      ValidateFileSize(e.target.files[0]);
    } else {
      setSelectFilePrompt("Select File...");
      setSelectedFileName("");
      setSelectedDocument(null);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section>
          <KeyboardDatePicker
            autoOk={true}
            animateYearScrolling={true}
            name="monitoringDate"
            error={!!(errors && errors.monitoringDate)}
            format="DD/MM/YYYY"
            label="Monitoring Date"
            onChange={handleDateFoundChange}
            value={monitoringDate}
            inputRef={register({
              required: {
                value: true,
                message: "Monitoring Date is required",
              },
            })}
            helperText={errors && errors.monitoringDate && errors.monitoringDate.message}
            style={{ marginBottom: 16 }}
          />
        </section>

        <section name="Details">
          <TextField
            label="Monitoring Summary &amp; Details *"
            fullWidth
            name="details"
            multiline
            autoComplete="off"
            rows="6"
            error={!!(errors && errors.details)}
            style={{ marginBottom: 16 }}
            inputRef={register({
              required: {
                value: true,
                message: "Monitoring Details are required",
              },
            })}
            inputProps={{
              "aria-label": "Details",
            }}
            helperText={errors && errors.details && errors.details.message}
          />
        </section>

        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xs={5}>
            <React.Fragment>
              <input
                color="secondary"
                type="file"
                onChange={(e) => setFile(e)}
                id="icon-button-file"
                name="document"
                style={{ display: "none" }}
                accept={documentSettingsFetched && documentSettings.allowedExtensions.toString()}
                ref={register({ required: true })}
              />
              <label htmlFor="icon-button-file">
                <Button variant="contained" component="span" className={classes.button} color="secondary">
                  {selectFilePrompt}
                </Button>
              </label>
            </React.Fragment>
          </Grid>
          <Grid item xs={7}>
            <div style={{ overflowWrap: "break-word" }}>
              <Typography>{selectedFileName}</Typography>
            </div>
          </Grid>
        </Grid>

        {errors.document && <BladeValidationDisplay validationErrors="You must select a File" marginBottom="8" />}

        {blockSubmit && (
          <BladeValidationDisplay validationErrors="The file selected is to large to upload" marginBottom="8" />
        )}

        <section name="Document Description">
          <TextField
            label="Document Description"
            fullWidth
            name="description"
            multiline
            autoComplete="off"
            rows="6"
            error={!!(errors && errors.description)}
            style={{ marginBottom: 16 }}
            inputRef={register()}
            inputProps={{
              "aria-label": "Document description",
            }}
            helperText={errors && errors.description && errors.description.message}
          />
        </section>

        {!!createErrors && <BladeValidationDisplay validationErrors={createErrors} />}

        <div className={globalClasses.drawerBladeToolbar}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            type="submit"
            className={classes.button}
            disabled={isBusy}
            endIcon={<SaveIcon />}
          >
            SAVE
          </Button>
        </div>
      </form>
    </MuiPickersUtilsProvider>
  );
};

const CaseMonitorManage = () => {
  const [tabValue, setTabValue] = React.useState(0);
  let { monitoringId } = useParams();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }
  return (
    <Blade size="Largest">
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="user tabs"
        variant="fullWidth"
        indicatorColor="primary"
      >
        <Tab label="Details" {...a11yProps(0)} />
        <Tab label="Remove Monitoring entry" {...a11yProps(1)} />
      </Tabs>

      <BladeTabPanel value={tabValue} index={0}>
        <CaseMonitoringDetails id={monitoringId} />
      </BladeTabPanel>
      <BladeTabPanel value={tabValue} index={1}>
        <RemoveCaseMonitoring id={monitoringId} />
      </BladeTabPanel>
    </Blade>
  );
};

const CaseMonitoringDetails = ({ id }) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { spCase, caseDispatcher } = React.useContext(CaseCtx);
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const modifyCaseMonitoringApi = useProtectApi("caseMonitoring/modify");

  const [monitoringDate, setMonitoringDate] = React.useState();
  const [showAddDocument, setShowAddDocument] = React.useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [apiErrors, setApiErrors] = useState();

  const localEntry = spCase.caseMonitorings.find((cm) => cm.monitoringId === id);

  const closeDrawer = () => {
    history.goBack();
  };

  const addDocumentClick = () => {
    setShowAddDocument(true);
  };

  const handleMonitoringDateChange = (e, v) => {
    setMonitoringDate(e);
  };

  useEffect(() => {
    localEntry && setMonitoringDate(localEntry.monitoringDate);
  }, [localEntry]);

  const onSubmit = async (data) => {
    var moment = require("moment-timezone");
    var zoneName = moment.tz.guess();

    let _monitoringDate = moment.isMoment(monitoringDate) ? monitoringDate : moment(monitoringDate);
    let _submitDt = moment(_monitoringDate.format("YYYY/MM/DD") + " " + moment().format("HH:mm"));

    console.info(_submitDt.toDate());

    var request = {
      monitoringId: id,
      caseId: spCase.id,
      details: data.details,
      monitoringDate: _submitDt.toDate(),
      timezone: zoneName,
    };

    const modifyMonitoringEntry = () =>
      modifyCaseMonitoringApi.api().then((w) =>
        w
          .put(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .res((res) => {
            caseDispatcher({
              type: "CASE_MONITORING_MODIFIED",
              payload: { monitoringId: id, details: data.details, monitoringDate: _submitDt.toDate() },
            });
          })
          .then(() => toastr.success("Monitoring entry details changed"))
          .then(() => closeDrawer())
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to change Monitoring entry details.");
          })
          .finally(() => setIsBusy(false))
      );

    setIsBusy(true);
    modifyMonitoringEntry();
  };

  return (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section name="Monitoring Date">
            <KeyboardDatePicker
              autoOk={true}
              name="monitoringDate"
              error={!!(errors && errors.monitoringDate)}
              animateYearScrolling={true}
              format="DD/MM/YYYY"
              label="Monitoring Date *"
              onChange={handleMonitoringDateChange}
              value={monitoringDate ? moment(monitoringDate) : null}
              inputRef={register({
                required: {
                  value: true,
                  message: "Monitoring Date is required",
                },
              })}
              helperText={errors && errors.monitoringDate && errors.monitoringDate.message}
              style={{ marginBottom: 16 }}
            />
          </section>

          <section name="Details">
            <TextField
              label="Details *"
              fullWidth
              name="details"
              multiline
              autoComplete="off"
              rows="6"
              error={!!(errors && errors.details)}
              style={{ marginBottom: 16 }}
              inputRef={register({
                required: {
                  value: true,
                  message: "Monitoring Details are required",
                },
              })}
              inputProps={{
                "aria-label": "Details",
              }}
              helperText={errors && errors.details && errors.details.message}
              defaultValue={localEntry.details}
            />
          </section>

          {!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}

          <div className={globalClasses.drawerBladeToolbar}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              type="submit"
              className={classes.button}
              endIcon={<SaveIcon />}
              disabled={isBusy}
            >
              SAVE
            </Button>
          </div>
        </form>
      </MuiPickersUtilsProvider>
      <div style={{ marginTop: 16 }}>
        <CaseMonitoringDocuments id={id} />
      </div>

      <div className={globalClasses.drawerBladeToolbar}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<NoteAddIcon />}
          onClick={addDocumentClick}
        >
          Doc
        </Button>
      </div>

      <section name="Add monitoring document">
        {showAddDocument && (
          <CaseAddMonitoringDocument
            caseMonitoringId={localEntry.monitoringId}
            closeBlade={() => setShowAddDocument(false)}
          />
        )}
      </section>
    </div>
  );
};

const CaseMonitoringDocuments = ({ id }) => {
  const { spCase } = React.useContext(CaseCtx);
  const localEntry = spCase.caseMonitorings.find((cm) => cm.monitoringId === id);
  const [showDocumentDetails, setShowDocumentDetails] = React.useState(false);

  const [selectedDocumentId, setSelectedDocumentId] = React.useState(false);

  const onRowClick = (rowData, rowMeta) => {
    setSelectedDocumentId(rowData[0]);
    setShowDocumentDetails(true);
  };

  const gridOptions = {
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    filterType: "multiselect",
    selectableRows: "none",
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15],
    print: false,
    download: false,
    filter: false,
    searchOpen: true,
    viewColumns: false,
    disableToolbarSelect: true,
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
        if (colIndex === 3) {
          return (new Date(a.data[colIndex]) < new Date(b.data[colIndex]) ? -1 : 1) * (order === "desc" ? 1 : -1);
        } else {
          return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === "desc" ? 1 : -1);
        }
      });
    },
    textLabels: {
      body: {
        noMatch: "Sorry, no Documents found",
      },
    },
    setTableProps: () => {
      return {
        padding: "checkbox",
        size: "small",
      };
    },
    onRowClick: onRowClick,
  };

  const gridColumns = [
    {
      name: "caseMonitoringDocumentId",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "fileName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "attachedDate",
      label: "Added Date",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return FormatDate(value);
        },
        filterType: "custom",
        customFilterListOptions: dateFilterChips("Added Date"),
        filterOptions: DateRangeFilters,
      },
    },
    {
      name: "attachedBy",
      label: "Added By",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "createdDate",
      label: "Created Date",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return FormatDate(value);
        },
        filterType: "custom",
        customFilterListOptions: dateFilterChips("Created Date"),
        filterOptions: DateRangeFilters,
      },
    },
    {
      name: "createdBy",
      label: "Created By",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
  ];

  return (
    <div>
      <section name="Existing documents">
        <div style={{ marginBottom: 16 }}>
          <MUIDataTable title={""} data={localEntry.documents} columns={gridColumns} options={gridOptions} />
        </div>
      </section>

      <section name="Show document details">
        {showDocumentDetails && (
          <CaseMonitoringDocument
            caseMonitoringId={localEntry.monitoringId}
            caseMonitoringDocumentId={selectedDocumentId}
            closeBlade={() => setShowDocumentDetails(false)}
          />
        )}
      </section>
    </div>
  );
};

const RemoveCaseMonitoring = ({ id }) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { spCase, caseDispatcher } = React.useContext(CaseCtx);
  const history = useHistory();
  const removeCaseMonitoringApi = useProtectApi("caseMonitoring/remove");
  const [isBusy, setIsBusy] = useState(false);
  const [apiErrors, setApiErrors] = useState();

  const closeDrawer = () => {
    history.goBack();
  };

  const removeCaseMonitoringClick = () => {
    var request = {
      caseId: spCase.id,
      MonitoringId: id,
    };

    const apiDelete = () =>
      removeCaseMonitoringApi.api().then((w) =>
        w
          .put(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .res(() => {
            caseDispatcher({
              type: "CASE_MONITORING_REMOVED",
              payload: { monitoringId: id },
            });
          })
          .then(() => toastr.success("Case Monitoring entry removed"))
          .then(() => closeDrawer())
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to remove Case Monitoring entry.");
          })
          .finally(() => setIsBusy(false))
      );

    setIsBusy(true);
    apiDelete();
  };

  return (
    <div>
      <Typography>
        <p>Remove Case Monitoring entry from this Case?</p>
      </Typography>

      <div className={globalClasses.drawerBladeToolbar}>
        <DeleteButton
          variant="outlined"
          color="primary"
          size="medium"
          onClick={() => removeCaseMonitoringClick()}
          className={classes.button}
          disabled={isBusy}
          text="Remove"
        />
      </div>
      {!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}
    </div>
  );
};

const CaseMonitoringDocument = ({ caseMonitoringId, caseMonitoringDocumentId, closeBlade }) => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return (
    <div>
      <Blade size="Larger" closeBladeOverride={closeBlade}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="user tabs"
          variant="fullWidth"
          indicatorColor="primary"
        >
          <Tab label="Document Details" {...a11yProps(0)} />
          <Tab label="Remove" {...a11yProps(1)} />
        </Tabs>
        <BladeTabPanel value={tabValue} index={0}>
          <CaseMonitoringDocumentDetails
            caseMonitoringId={caseMonitoringId}
            caseMonitoringDocumentId={caseMonitoringDocumentId}
            closeBlade={closeBlade}
          />
        </BladeTabPanel>
        <BladeTabPanel value={tabValue} index={1}>
          <RemoveCaseMonitoringDocument
            caseMonitoringId={caseMonitoringId}
            caseMonitoringDocumentId={caseMonitoringDocumentId}
            closeBlade={closeBlade}
          />
        </BladeTabPanel>
      </Blade>
    </div>
  );
};

const CaseMonitoringDocumentDetails = ({ caseMonitoringId, caseMonitoringDocumentId, closeBlade }) => {
  const { spCase, caseDispatcher, caseFetched } = React.useContext(CaseCtx);
  const documentDownload = useDownloadDocument();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const modifyCaseDocumentApi = useProtectApi("casemonitoring/document/modify/");
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  const [isBusy, setIsBusy] = useState(false);
  const [apiErrors, setApiErrors] = useState();
  const [localEntry, setLocalEntry] = useState();

  useEffect(() => {
    !localEntry &&
      caseFetched &&
      spCase.caseMonitorings.find((cm) => cm.monitoringId === caseMonitoringId) &&
      setLocalEntry(
        spCase.caseMonitorings
          .find((cm) => cm.monitoringId === caseMonitoringId)
          .documents.find((d) => d.caseMonitoringDocumentId === caseMonitoringDocumentId)
      );
  }, [caseFetched, localEntry, caseMonitoringId, caseMonitoringDocumentId]);

  const onSubmit = async (data) => {
    var request = {
      documentId: localEntry.documentId,
      caseId: spCase.id,
      description: data.description,
      caseMonitoringId: caseMonitoringId,
    };

    const modifyDocument = () =>
      modifyCaseDocumentApi.api().then((w) =>
        w
          .put(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .res(() => {
            caseDispatcher({
              type: "MONITORING_DOCUMENT_DETAILS_CHANGED",
              payload: {
                monitoringId: caseMonitoringId,
                caseMonitoringDocumentId: caseMonitoringDocumentId,
                description: data.description,
              },
            });
          })
          .then(() => toastr.success("Document details changed"))
          .then(() => closeBlade())
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to change Document details.");
          })
          .finally(() => setIsBusy(false))
      );
    setIsBusy(true);
    modifyDocument();
  };

  return !localEntry ? (
    <div></div>
  ) : (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ marginBottom: 16 }}>
          <section name="read only data">
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.row}>
                  <div className={classes.label} style={{ fontSize: "0.75rem", width: 80, minWidth: 80 }}>
                    File Name
                  </div>
                  <div>{localEntry.fileName}</div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.row}>
                  <div className={classes.label} style={{ fontSize: "0.75rem", width: 80, minWidth: 80 }}>
                    Created By
                  </div>
                  <div>{localEntry.createdBy}</div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.row}>
                  <div className={classes.label} style={{ fontSize: "0.75rem", width: 80, minWidth: 80 }}>
                    Created Date
                  </div>
                  <div>{FormatDate(localEntry.createdDate)}</div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.row}>
                  <div className={classes.label} style={{ fontSize: "0.75rem", width: 80, minWidth: 80 }}>
                    Added By
                  </div>
                  <div>{localEntry.attachedBy}</div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.row}>
                  <div className={classes.label} style={{ fontSize: "0.75rem", width: 80, minWidth: 80 }}>
                    Added Date
                  </div>
                  <div>{FormatDate(localEntry.attachedDate)}</div>
                </div>
              </Grid>
            </Grid>
            {/* <Typography>File Name: {localEntry.fileName}</Typography> */}
            {/* 
						<Typography>Created By: {localEntry.createdBy}</Typography>

						<Typography>Created Date: {FormatDate(localEntry.createdDate)}</Typography>

						<Typography>Added By: {localEntry.attachedBy}</Typography>

						<Typography>Added Date: {FormatDate(localEntry.attachedDate)}</Typography> */}
          </section>
        </div>
        <section name="Document Description">
          <TextField
            label="Description"
            fullWidth
            name="description"
            multiline
            autoComplete="off"
            rows="6"
            error={!!(errors && errors.description)}
            style={{ marginBottom: 16 }}
            inputRef={register()}
            inputProps={{
              "aria-label": "Document description",
            }}
            helperText={errors && errors.description && errors.description.message}
            defaultValue={localEntry.description}
          />
        </section>

        {!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}

        <div className={globalClasses.drawerBladeToolbar}>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            className={classes.button}
            style={{ marginRight: 16 }}
            endIcon={<GetAppIcon />}
            onClick={() => documentDownload(localEntry.documentId, localEntry.fileName)}
          >
            Download
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="medium"
            type="submit"
            className={classes.button}
            endIcon={<SaveIcon />}
            disabled={isBusy}
          >
            SAVE
          </Button>
        </div>
      </form>
    </div>
  );
};

const RemoveCaseMonitoringDocument = ({ caseMonitoringId, caseMonitoringDocumentId, closeBlade }) => {
  const { spCase, caseDispatcher, caseFetched } = React.useContext(CaseCtx);

  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const removeCaseDocumentApi = useProtectApi("casemonitoring/document/remove");
  const [isBusy, setIsBusy] = useState(false);
  const [apiErrors, setApiErrors] = useState();
  const [localEntry, setLocalEntry] = useState();
  const [showRemoveConfirmDialog, setShowRemoveConfirmDialog] = useState(false);

  useEffect(() => {
    !localEntry &&
      caseFetched &&
      spCase.caseMonitorings.find((cm) => cm.monitoringId === caseMonitoringId) &&
      setLocalEntry(
        spCase.caseMonitorings
          .find((cm) => cm.monitoringId === caseMonitoringId)
          .documents.find((d) => d.caseMonitoringDocumentId === caseMonitoringDocumentId)
      );
  }, [caseFetched, localEntry, caseMonitoringId, caseMonitoringDocumentId]);

  const closeRemoveDialog = () => {
    setShowRemoveConfirmDialog(false);
  };

  const confirmRemoveCaseDocumentClick = () => {
    var request = {
      documentId: localEntry.documentId,
      caseId: spCase.id,
      caseMonitoringId: caseMonitoringId,
    };

    const apiDelete = () =>
      removeCaseDocumentApi.api().then((w) =>
        w
          .put(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .res(() => {
            caseDispatcher({
              type: "DOCUMENT_REMOVED_FROM_CASE_MONITORING",
              payload: {
                monitoringId: caseMonitoringId,
                caseMonitoringDocumentId: caseMonitoringDocumentId,
              },
            });

            var document = {
              id: localEntry.documentId,
              status: "Available",
              fileName: localEntry.fileName,
              description: localEntry.description,
              createdDate: localEntry.createdDate,
              createdBy: localEntry.createdBy,
              country: localEntry.country,
              countryId: localEntry.countryId,
            };
            dispatch({
              type: "DOCUMENT_REMOVED_FROM_CASE",
              payload: document,
            });
          })
          .then(() => toastr.success("Document detached from Case Monitoring"))
          .then(() => closeBlade())
          .catch((error) => {
            console.log(error);
            setShowRemoveConfirmDialog(false);
            toastr.error("Unable to detach Document from Case Monitoring.");
          })
      );

    setIsBusy(true);
    apiDelete();
  };

  return !localEntry ? (
    <div></div>
  ) : (
    <div>
      <Typography>Remove document '{localEntry.fileName}' from this Monitoring entry?</Typography>

      <div className={globalClasses.drawerBladeToolbar}>
        <DeleteButton
          variant="outlined"
          color="primary"
          size="medium"
          onClick={() => setShowRemoveConfirmDialog(true)}
          className={classes.button}
          disabled={isBusy}
          text="Remove"
        />
      </div>
      {!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}
      {showRemoveConfirmDialog && localEntry && (
        <div>
          <Dialog
            open={showRemoveConfirmDialog}
            onClose={() => showRemoveConfirmDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Remove</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to remove '{localEntry.fileName}'?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeRemoveDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={() => confirmRemoveCaseDocumentClick()} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

const CaseAddMonitoringDocument = ({ caseMonitoringId, closeBlade }) => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return (
    <Blade size="Largest" closeBladeOverride={closeBlade}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="user tabs"
        variant="fullWidth"
        indicatorColor="primary"
      >
        <Tab label="Attach Existing" {...a11yProps(0)} />
        <Tab label="Add New Document" {...a11yProps(1)} />
      </Tabs>
      <BladeTabPanel value={tabValue} index={0}>
        <AddExistingDocument caseMonitoringId={caseMonitoringId} closeBlade={closeBlade} />
      </BladeTabPanel>
      <BladeTabPanel value={tabValue} index={1}>
        <AddNewDocument caseMonitoringId={caseMonitoringId} closeBlade={closeBlade} />
      </BladeTabPanel>
    </Blade>
  );
};

const AddExistingDocument = ({ caseMonitoringId, closeBlade }) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { spCase, caseDispatcher, caseFetched } = React.useContext(CaseCtx);

  const { register, handleSubmit, errors } = useForm();

  const getAvailableDocumentsApi = useProtectApi("document/country/");
  const attachDocumentsApi = useProtectApi("casemonitoring/document/attach");

  const [createErrors, setCreateErrors] = React.useState();
  const [isBusy, setIsBusy] = React.useState(false);

  const [availableDocuments, setAvailableDocuments] = React.useState([]);
  const [availableDocumentsFetched, setAvailableDocumentsFetched] = React.useState(false);
  const [selectedDocumentIds, setSelectedDocumentIds] = React.useState([]);
  const [rowsSelected, setRowsSelected] = React.useState([]);

  useEffect(() => {
    const fetchAvailableDocuments = () => {
      getAvailableDocumentsApi.api().then((w) =>
        w
          .url(spCase.audit.country)
          .get()
          .json((json) => {
            setAvailableDocuments(json);
            return json;
          })
          .then(() => setAvailableDocumentsFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Documents to attach to Case"))
      );
    };

    if (!availableDocumentsFetched) {
      availableDocuments.length === 0 && caseFetched && fetchAvailableDocuments();
    }
  }, [caseFetched && availableDocuments, availableDocumentsFetched]);

  useEffect(() => {
    var ids = [];
    rowsSelected.forEach((rowIndex) => {
      var item = availableDocuments[rowIndex];
      ids.push(item.id);
    });

    console.info(ids);

    setSelectedDocumentIds(ids);
  }, [rowsSelected]);

  const FindDocument = (documentId) => {
    return availableDocuments.find((d) => d.id === documentId);
  };

  const onSubmit = async (data) => {
    var request = {
      caseId: spCase.id,
      caseMonitoringId: caseMonitoringId,
      documentIds: selectedDocumentIds,
    };

    const attachDocs = () =>
      attachDocumentsApi.api().then((w) =>
        w
          .post(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setCreateErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setCreateErrors(errDetail);
            throw new Error(errDetail);
          })
          .json((json) => {
            json.forEach((element) => {
              caseDispatcher({
                type: "DOCUMENT_ATTACHED_TO_CASE_MONITORING",
                payload: { monitoringId: caseMonitoringId, document: element },
              });

              dispatch({
                type: "DOCUMENT_ATTACHED_TO_CASE",
                payload: { id: element.documentId },
              });
            });

            return json;
          })
          .then(() => {
            setSelectedDocumentIds([]);
            setAvailableDocuments([]);
            setAvailableDocumentsFetched(false);
          })
          .then(() => toastr.success("Document attached to Case " + spCase.number))
          .then(() => closeBlade())
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to attach Document to Case " + spCase.number);
          })
          .finally(() => {
            setIsBusy(false);
          })
      );
    setIsBusy(true);
    attachDocs();
  };

  const gridOptions = {
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    filterType: "multiselect",
    selectableRows: "multiple",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 30],
    print: false,
    download: false,
    filter: false,
    searchOpen: true,
    viewColumns: false,
    disableToolbarSelect: true,
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
        if (colIndex === 3) {
          return (new Date(a.data[colIndex]) < new Date(b.data[colIndex]) ? -1 : 1) * (order === "desc" ? 1 : -1);
        } else {
          return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === "desc" ? 1 : -1);
        }
      });
    },
    textLabels: {
      body: {
        noMatch: "Sorry, no Documents found",
      },
    },
    setTableProps: () => {
      return {
        padding: "checkbox",
        size: "small",
      };
    },
    rowsSelected: rowsSelected,
    onRowsSelect: (rowsSelected, allRows) => {
      setRowsSelected(allRows.map((row) => row.dataIndex));
    },
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "fileName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "createdDate",
      label: "Upload Date",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return FormatDate(value);
        },
        filterType: "custom",
        customFilterListOptions: dateFilterChips("Upload Date"),
        filterOptions: DateRangeFilters,
      },
    },
    {
      name: "createdBy",
      label: "Uploaded By",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <section name="Document selection">
        <div style={{ marginBottom: 16 }}>
          <MUIDataTable title={""} data={availableDocuments} columns={gridColumns} options={gridOptions} />
        </div>
      </section>

      {!!createErrors && <BladeValidationDisplay validationErrors={createErrors} />}

      <div className={globalClasses.drawerBladeToolbar}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          type="submit"
          className={classes.button}
          disabled={isBusy}
          endIcon={<SaveIcon />}
        >
          ADD
        </Button>
      </div>
    </form>
  );
};

const AddNewDocument = ({ caseMonitoringId, closeBlade }) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const { spCase, caseDispatcher } = React.useContext(CaseCtx);
  const createdAndAttachDocApi = useProtectApi("casemonitoring/document/upload");

  const { documentSettings, documentSettingsFetched } = useFetchDocumentUploadSettings();

  const { register, handleSubmit, errors, clearError } = useForm();
  const [createErrors, setCreateErrors] = React.useState();
  const [isBusy, setIsBusy] = React.useState(false);

  const [blockSubmit, setBlockSubmit] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState();
  const [selectedFileName, setSelectedFileName] = useState();
  const [selectFilePrompt, setSelectFilePrompt] = useState("Select File...");

  const onSubmit = async (data) => {
    if (blockSubmit) {
      return;
    }

    var request = {
      fileName: selectedFileName,
      document: selectedDocument,
      caseId: spCase.id,
      caseMonitoringId: caseMonitoringId,
      description: data.description,
    };
    const createdAndAttachDoc = () =>
      createdAndAttachDocApi.api().then((w) =>
        w
          .formData(request)
          .post()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setCreateErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setCreateErrors(errDetail);
            throw new Error(errDetail);
          })
          .json((json) => {
            caseDispatcher({
              type: "DOCUMENT_ATTACHED_TO_CASE_MONITORING",
              payload: { monitoringId: caseMonitoringId, document: json },
            });

            return json;
          })
          .then(() => toastr.success("Document attached to Case " + spCase.number))
          .then(() => closeBlade())
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to attach Document to Case " + spCase.number);
          })
          .finally(() => {
            setIsBusy(false);
          })
      );
    setIsBusy(true);
    createdAndAttachDoc();
  };

  const ValidateFileSize = (file) => {
    if (file.size > documentSettings.maxFileSize) {
      setBlockSubmit(true);
    } else {
      setBlockSubmit(false);
    }
  };

  const setFile = (e) => {
    if (e.target.files.length > 0) {
      setSelectFilePrompt("Change File...");
      setSelectedFileName(e.target.files[0].name);
      setSelectedDocument(e.target.files[0]);
      clearError("document");

      ValidateFileSize(e.target.files[0]);
    } else {
      setSelectFilePrompt("Select File...");
      setSelectedFileName("");
      setSelectedDocument(null);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} style={{ marginBottom: 16 }}>
        <Grid item xs={5}>
          <React.Fragment>
            <input
              color="secondary"
              type="file"
              onChange={(e) => setFile(e)}
              id="icon-button-file"
              name="document"
              style={{ display: "none" }}
              accept={documentSettingsFetched && documentSettings.allowedExtensions.toString()}
              ref={register({ required: true })}
            />
            <label htmlFor="icon-button-file">
              <Button variant="contained" component="span" className={classes.button} color="secondary">
                {selectFilePrompt}
              </Button>
            </label>
          </React.Fragment>
        </Grid>
        <Grid item xs={7}>
          <div style={{ overflowWrap: "break-word" }}>
            <Typography>{selectedFileName}</Typography>
          </div>
        </Grid>
      </Grid>

      {errors.document && <BladeValidationDisplay validationErrors="You must select a File" marginBottom="8" />}

      {blockSubmit && (
        <BladeValidationDisplay validationErrors="The file selected is to large to upload" marginBottom="8" />
      )}

      <section name="Document Description">
        <TextField
          label="Description"
          fullWidth
          name="description"
          multiline
          autoComplete="off"
          rows="6"
          error={!!(errors && errors.description)}
          style={{ marginBottom: 16 }}
          inputRef={register()}
          inputProps={{
            "aria-label": "Document description",
          }}
          helperText={errors && errors.description && errors.description.message}
        />
      </section>

      {!!createErrors && <BladeValidationDisplay validationErrors={createErrors} />}

      <div className={globalClasses.drawerBladeToolbar}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          type="submit"
          className={classes.button}
          disabled={isBusy}
          endIcon={<SaveIcon />}
        >
          SAVE
        </Button>
      </div>
    </form>
  );
};

export default CaseMonitoring;
