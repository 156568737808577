import React from "react";
import useStyles from "./styles";
import { Backdrop, CircularProgress, Fade } from "@material-ui/core";

export default function Spinner({ show = false }) {
  const classes = useStyles();
  return (
    <Fade in={show}>
      <Backdrop className={classes.backdrop} open={show}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Fade>
  );
}
