import React, { useRef } from "react";
import { InputAdornment, Grid, TextField, Typography, MenuItem } from "@material-ui/core";
import useStyles from "./styles";
import { useFetchYesNoAnswers, useFetchCurrencies } from "../../hooks/useFetchEntities";
import useProtectApi from "../../hooks/useProtectApi";
import { Autocomplete } from "@material-ui/lab";
import { toastr } from "../../components/Common/toastr";
import { useForm } from "react-hook-form";
import { CaseCtx, CaseTabReadOnlyToolbar, CaseTabEditingToolbar, CaseDocumentsDisplay } from "./case.manage";
import { useDispatch } from "react-redux";
import { CaseDocumentTypes } from "./case.document.types";
import CurrencyInput from "../../components/Common/CurrencyInput";
import DecimalInput from "../../components/Common/DecimalInput";

const CaseSupplierAgreement = ({ tabIndex, beginEdit, finishEdit, manageDocument }) => {
  const [editing, setEditing] = React.useState(false);

  const enableEditing = () => {
    setEditing(true);
    beginEdit(tabIndex);
  };

  const finishEditing = () => {
    setEditing(false);
    finishEdit(tabIndex);
  };

  return editing ? (
    <CaseSupplierAgreementEditing finishEditing={finishEditing} />
  ) : (
    <CaseSupplierAgreementReadOnly enableEditing={enableEditing} manageDocument={manageDocument} />
  );
};
const CaseSupplierAgreementEditing = ({ finishEditing, manageDocument }) => {
  const { spCase, caseDispatcher } = React.useContext(CaseCtx);

  const dispatch = useDispatch();
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [yesNoAnswers] = useFetchYesNoAnswers();
  const [currencies, currenciesFetched] = useFetchCurrencies();

  const saveSupplierAgreementApi = useProtectApi("case/supplierAgreement");
  const supplierAgreementForm = useRef();

  const [workingSupplierAgreement, setWorkingSupplierAgreement] = React.useState(spCase.supplierAgreement);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isBusy, setIsBusy] = React.useState(false);
  const [showPaidByPrincipleNotes, setShowPaidByPrincipleNotes] = React.useState(false);
  const [showPaidByOtherPartyNotes, setShowPaidByOtherPartyNotes] = React.useState(false);
  const [apiErrors, setApiErrors] = React.useState();
  const [currencySymbol, setCurrencySymbol] = React.useState("");
  const [remediationCurrencySymbol, setRemediationCurrencySymbol] = React.useState("");
  const [showConversionRate, setShowConversionRate] = React.useState(false);

  const findEntity = (name, entities) => {
    return entities.find((x) => x.name === name) || null;
  };

  const findEntityId = (name, entities) => {
    const _entity = findEntity(name, entities);
    return _entity ? _entity.id : null;
  };

  const onSubmitSupplierAgreement = async (data, e) => {
    e.preventDefault();

    var selectedCurrencyId = findEntityId(data.currency, currencies);

    var request = {
      caseId: spCase.id,
      supplierAgreement: workingSupplierAgreement,
      currencyId: selectedCurrencyId,
    };

    const saveSupplierAgreement = () =>
      saveSupplierAgreementApi.api().then((w) =>
        w
          .put(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .json((json) => {
            caseDispatcher({
              type: "CASE_MODIFY_SUPPLIER_AGREEMENT",
              payload: workingSupplierAgreement,
            });

            dispatch({
              type: "CASE_MODIFY",
              payload: {
                id: spCase.id,
              },
            });
          })
          .then(() => toastr.success("SupplierAgreement details changed"))
          .then(() => setIsDirty(false))
          .then(() => finishEditing())
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to save SupplierAgreement.");
          })
          .finally(() => setIsBusy(false))
      );

    setIsBusy(true);
    saveSupplierAgreement();
  };

  React.useEffect(() => {
    setIsDirty(JSON.stringify(spCase.supplierAgreement) !== JSON.stringify(workingSupplierAgreement));
  }, [spCase.supplierAgreement, workingSupplierAgreement]);

  React.useEffect(() => {
    setShowPaidByPrincipleNotes(workingSupplierAgreement.paidByPrinciple ? true : false);
  }, [spCase.supplierAgreement.paidByPrinciple, workingSupplierAgreement.paidByPrinciple]);

  React.useEffect(() => {
    setShowPaidByOtherPartyNotes(workingSupplierAgreement.paidByOtherParty ? true : false);
  }, [spCase.supplierAgreement.paidByOtherParty, workingSupplierAgreement.paidByOtherParty]);

  React.useEffect(() => {
    setCurrencySymbol(workingSupplierAgreement.supplierAgreementCurrencySymbol);

    currencies.length > 0 &&
      workingSupplierAgreement.supplierAgreementCurrencyName &&
      setCurrencySymbol(findEntity(workingSupplierAgreement.supplierAgreementCurrencyName, currencies).symbol);
  }, [spCase.supplierAgreement, workingSupplierAgreement.supplierAgreementCurrencyName, currencies]);

  //								{spCase.remediation.totalBudget}
  React.useEffect(() => {
    setRemediationCurrencySymbol(spCase.remediation.remediationCurrencySymbol);

    currencies.length > 0 &&
      spCase.remediation.remediationCurrencyName &&
      setRemediationCurrencySymbol(findEntity(spCase.remediation.remediationCurrencyName, currencies).symbol);
  }, [spCase.remediation, currencies]);

  React.useEffect(() => {
    currencies.length > 0 &&
      workingSupplierAgreement.supplierAgreementCurrencyName &&
      setShowConversionRate(!findEntity(workingSupplierAgreement.supplierAgreementCurrencyName, currencies).isSys);
  }, [spCase.supplierAgreement, workingSupplierAgreement.supplierAgreementCurrencyName, currencies]);

  React.useEffect(() => {
    currencySymbol && updateValue("supplierAgreementCurrencySymbol", currencySymbol);
  }, [currencySymbol]);

  const updateValue = (name, value) => {
    setWorkingSupplierAgreement((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitSupplierAgreement)} ref={supplierAgreementForm} id="supplierAgreementForm">
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} container spacing={2}>
            {/* TOP FOUR  */}
            <Grid item lg={3} md={6}>
              <section name="Mou Signed">
                <TextField
                  fullWidth
                  name="mouSigned"
                  error={!!errors && errors.mouSigned}
                  helperText={errors?.mouSigned?.message}
                  value={workingSupplierAgreement.mouSigned}
                  select
                  label="MOU Signed"
                  onChange={(e) => updateValue("mouSigned", e.target.value)}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="No MOU">No MOU</MenuItem>
                </TextField>
                {/* <Autocomplete
                  id="mouSignedAutocomplete"
                  options={["Yes", "No", "No MOU"]}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        name="mouSigned"
                        label={"MOU Signed"}
                        fullWidth
                        inputRef={register({})}
                        error={!!(errors && errors.mouSigned)}
                        helperText={errors && errors.mouSigned && errors.mouSigned.message}
                      />
                    );
                  }}
                  defaultValue={workingSupplierAgreement.mouSigned}
                  onChange={(e, v) => updateValue("mouSigned", v)}
                /> */}
              </section>
            </Grid>
            <Grid item lg={3} md={6}>
              <section name="Invoice Paid">
                <Autocomplete
                  id="invoicePaidAutocomplete"
                  options={yesNoAnswers && yesNoAnswers.map((r) => r.name)}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        name="invoicePaid"
                        label={"Invoice Paid"}
                        fullWidth
                        inputRef={register({})}
                        error={!!(errors && errors.invoicePaid)}
                        helperText={errors && errors.invoicePaid && errors.invoicePaid.message}
                      />
                    );
                  }}
                  defaultValue={workingSupplierAgreement.invoicePaid}
                  onChange={(e, v) => updateValue("invoicePaid", v)}
                />
              </section>
            </Grid>
            <Grid item lg={3} md={6}>
              <section name="Currency">
                <Autocomplete
                  id="currencyAutocomplete"
                  options={currencies ? currencies.map((r) => r.name) : []}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        name="currency"
                        label={"Currency"}
                        fullWidth
                        inputRef={register({})}
                        error={!!(errors && errors.currency)}
                        helperText={errors && errors.currency && errors.currency.message}
                      />
                    );
                  }}
                  defaultValue={workingSupplierAgreement.supplierAgreementCurrencyName}
                  onChange={(e, v) => updateValue("supplierAgreementCurrencyName", v)}
                />
              </section>
            </Grid>
            <Grid item lg={3} md={6}>
              <section name="Conversion Rate">
                {showConversionRate && (
                  <TextField
                    label="Conversion Rate"
                    value={workingSupplierAgreement.conversionRate}
                    name="conversionRate"
                    onChange={(e) => {
                      updateValue("conversionRate", e.target.value);
                    }}
                    inputRef={register()}
                    InputProps={{
                      inputComponent: DecimalInput,
                    }}
                    style={{ width: "100%" }}
                    autoComplete="off"
                    inputProps={{
                      "aria-label": "Conversion Rate",
                    }}
                    helperText={errors && errors.conversionRate && errors.conversionRate.message}
                  />
                )}
              </section>
            </Grid>

            {/* PAID BY PRIMARK */}
            <Grid item xs={12} md={6}>
              <section name="Paid by Primark">
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>Paid by Primark</legend>
                  <TextField
                    label="Amount Paid by Primark"
                    value={workingSupplierAgreement.paidByPrinciple}
                    name="paidByPrinciple"
                    onChange={(e) => {
                      updateValue("paidByPrinciple", e.target.value);
                    }}
                    inputRef={register({})}
                    InputProps={{
                      inputComponent: CurrencyInput,
                      startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                    }}
                    style={{ marginBottom: 8 }}
                    fullWidth
                    autoComplete="off"
                    inputProps={{
                      "aria-label": "Paid by Primark",
                    }}
                    helperText={errors && errors.paidByPrinciple && errors.paidByPrinciple.message}
                  />
                  {showPaidByPrincipleNotes && (
                    <>
                      <legend className={classes.label}>Notes</legend>
                      <TextField
                        fullWidth
                        name="paidByPrincipleNotes"
                        multiline
                        autoComplete="off"
                        rows="4"
                        error={!!(errors && errors.paidByPrincipleNotes)}
                        inputRef={register({})}
                        inputProps={{
                          "aria-label": "Paid by Primark Notes",
                        }}
                        helperText={errors && errors.paidByPrincipleNotes && errors.paidByPrincipleNotes.message}
                        defaultValue={workingSupplierAgreement && workingSupplierAgreement.paidByPrincipleNotes}
                        onChange={(e) => updateValue(e.target.name, e.target.value)}
                      />
                    </>
                  )}
                </fieldset>
              </section>
            </Grid>

            {/* PAID BY OTHER */}
            <Grid item xs={12} md={6}>
              <section name="Paid by Other Party Notes">
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>Paid by Other Party</legend>
                  <TextField
                    label="Amount Paid by Other Party"
                    value={workingSupplierAgreement.paidByOtherParty}
                    name="paidByOtherParty"
                    onChange={(e) => {
                      updateValue("paidByOtherParty", e.target.value);
                    }}
                    inputRef={register({})}
                    InputProps={{
                      inputComponent: CurrencyInput,
                      startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                    }}
                    style={{ marginBottom: 8 }}
                    fullWidth
                    autoComplete="off"
                    inputProps={{
                      "aria-label": "Paid by Other Party",
                    }}
                    helperText={errors && errors.paidByOtherParty && errors.paidByOtherParty.message}
                  />
                  {showPaidByOtherPartyNotes && (
                    <>
                      <legend className={classes.label}>Notes</legend>
                      <TextField
                        fullWidth
                        name="paidByOtherPartyNotes"
                        multiline
                        autoComplete="off"
                        rows="4"
                        error={!!(errors && errors.paidByOtherPartyNotes)}
                        inputRef={register({})}
                        inputProps={{
                          "aria-label": "Paid by Primark Notes",
                        }}
                        helperText={errors && errors.paidByOtherPartyNotes && errors.paidByOtherPartyNotes.message}
                        defaultValue={workingSupplierAgreement && workingSupplierAgreement.paidByOtherPartyNotes}
                        onChange={(e) => updateValue(e.target.name, e.target.value)}
                      />
                    </>
                  )}
                </fieldset>
              </section>
            </Grid>
          </Grid>

          <Grid item md={6} sm={12} xs={12} container spacing={2} direction="column">
            <Grid container item>
              <Grid item>
                <Typography className={classes.label_nosize}>Total Budget Amount:</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.value_xl} style={{ fontSize: 14 }}>
                  {remediationCurrencySymbol}
                  {spCase.remediation.totalBudget &&
                    Intl.NumberFormat("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                      spCase.remediation.totalBudget
                    )}
                </Typography>
              </Grid>
            </Grid>

            {spCase.audit.supplier1Name && (
              <Grid item>
                <section name="Amount Paid by Supplier 1">
                  <TextField
                    label={"Amount Paid by " + spCase.audit.supplier1Name}
                    value={workingSupplierAgreement.paidBySupplier1}
                    name="paidBySupplier1"
                    onChange={(e) => {
                      updateValue("paidBySupplier1", e.target.value);
                    }}
                    inputRef={register({})}
                    InputProps={{
                      inputComponent: CurrencyInput,
                      startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                    }}
                    style={{ width: "100%" }}
                    autoComplete="off"
                    inputProps={{
                      "aria-label": "Paid by Supplier 1",
                    }}
                    helperText={errors && errors.paidBySupplier1 && errors.paidBySupplier1.message}
                  />
                </section>
              </Grid>
            )}

            {spCase.audit.supplier2Name && (
              <Grid item>
                <section name="Amount Paid by Supplier 2">
                  <TextField
                    label={"Amount Paid by " + spCase.audit.supplier2Name}
                    value={workingSupplierAgreement.paidBySupplier2}
                    name="paidBySupplier2"
                    onChange={(e) => {
                      updateValue("paidBySupplier2", e.target.value);
                    }}
                    inputRef={register({})}
                    InputProps={{
                      inputComponent: CurrencyInput,
                      startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                    }}
                    style={{ width: "100%" }}
                    autoComplete="off"
                    inputProps={{
                      "aria-label": "Paid by Supplier 2",
                    }}
                    helperText={errors && errors.paidBySupplier2 && errors.paidBySupplier2.message}
                  />
                </section>
              </Grid>
            )}

            {spCase.audit.supplier3Name && (
              <Grid item>
                <section name="Amount Paid by Supplier 3">
                  <TextField
                    label={"Amount Paid by " + spCase.audit.supplier3Name}
                    value={workingSupplierAgreement.paidBySupplier3}
                    name="paidBySupplier3"
                    onChange={(e) => {
                      updateValue("paidBySupplier3", e.target.value);
                    }}
                    inputRef={register({})}
                    InputProps={{
                      inputComponent: CurrencyInput,
                      startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                    }}
                    style={{ width: "100%" }}
                    autoComplete="off"
                    inputProps={{
                      "aria-label": "Paid by Supplier 3",
                    }}
                    helperText={errors && errors.paidBySupplier3 && errors.paidBySupplier3.message}
                  />
                </section>
              </Grid>
            )}

            {spCase.audit.supplier4Name && (
              <Grid item>
                <section name="Amount Paid by Supplier 4">
                  <TextField
                    label={"Amount Paid by " + spCase.audit.supplier4Name}
                    value={workingSupplierAgreement.paidBySupplier4}
                    name="paidBySupplier4"
                    onChange={(e) => {
                      updateValue("paidBySupplier4", e.target.value);
                    }}
                    inputRef={register({})}
                    InputProps={{
                      inputComponent: CurrencyInput,
                      startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                    }}
                    style={{ width: "100%" }}
                    autoComplete="off"
                    inputProps={{
                      "aria-label": "Paid by Supplier 4",
                    }}
                    helperText={errors && errors.paidBySupplier4 && errors.paidBySupplier4.message}
                  />
                </section>
              </Grid>
            )}

            {spCase.audit.supplier5Name && (
              <Grid item>
                <section name="Amount Paid by Supplier 5">
                  <TextField
                    label={"Amount Paid by " + spCase.audit.supplier5Name}
                    value={workingSupplierAgreement.paidBySupplier5}
                    name="paidBySupplier5"
                    onChange={(e) => {
                      updateValue("paidBySupplier5", e.target.value);
                    }}
                    inputRef={register({})}
                    InputProps={{
                      inputComponent: CurrencyInput,
                      startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                    }}
                    style={{ width: "100%" }}
                    autoComplete="off"
                    inputProps={{
                      "aria-label": "Paid by Supplier 5",
                    }}
                    helperText={errors && errors.paidBySupplier5 && errors.paidBySupplier5.message}
                  />
                </section>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <section name="Summary of MOU">
              <fieldset className={classes.fieldset}>
                <legend className={classes.legend}>Summary of MOU</legend>
                <TextField
                  fullWidth
                  name="summaryOfMou"
                  multiline
                  autoComplete="off"
                  rows="10"
                  error={!!(errors && errors.remediationSummary)}
                  inputRef={register({})}
                  inputProps={{
                    "aria-label": "Summary of MOU",
                  }}
                  helperText={errors && errors.summaryOfMou && errors.summaryOfMou.message}
                  defaultValue={workingSupplierAgreement && workingSupplierAgreement.summaryOfMou}
                  onChange={(e) => updateValue(e.target.name, e.target.value)}
                />
              </fieldset>
            </section>
          </Grid>

          {!!apiErrors && (
            <Grid item xs={12} style={{ paddingRight: 16 }}>
              <div className={classes.row}>
                <div className={classes.error}>{apiErrors}</div>
              </div>
            </Grid>
          )}

          <Grid item xs={12}>
            <CaseTabEditingToolbar
              saveDisabled={isBusy || !isDirty}
              onCloseClick={finishEditing}
              formRef={supplierAgreementForm && supplierAgreementForm.current}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};
const CaseSupplierAgreementReadOnly = ({ enableEditing, manageDocument }) => {
  const classes = useStyles();
  const { spCase } = React.useContext(CaseCtx);

  const [currencies, currenciesFetched] = useFetchCurrencies();
  const [displayConvertedPayment, setDisplayConvertedPayment] = React.useState(false);
  const [systemCurrencySymbol, setSystemCurrencySymbol] = React.useState("£");

  React.useEffect(() => {
    const findCurrency = (name) => {
      return (currencies ? currencies : []).find((c) => c.name === name) || null;
    };

    // we display converted cost if the currency is NOT the system one AND the rate is not 0
    var currency = findCurrency(spCase.supplierAgreement.supplierAgreementCurrencyName);
    var rateInput = spCase.supplierAgreement.conversionRate && spCase.supplierAgreement.conversionRate > 0;
    setDisplayConvertedPayment(currency ? !currency.isSys && rateInput : false);
  }, [spCase.supplierAgreement, currencies]);

  React.useEffect(() => {
    var currency = (currencies ? currencies : []).find((c) => c.isSys) || null;
    setSystemCurrencySymbol(currency ? currency.symbol : "£");
  }, [currencies]);
  const mouOptions = ["Yes", "No", "No Mou"];
  return (
    <>
      <Grid container spacing={2} alignItems="stretch">
        {/* LEFT */}
        <Grid item md={8} xs={12} container spacing={1}>
          {/* TOP HALF  */}
          <Grid item container spacing={1}>
            {/*  COLUMN 1 */}
            <Grid item xs={12} md={3}>
              <div className={classes.row}>
                <div className={classes.label}>MOU Signed:</div>
                <div className={classes.value}>{spCase.supplierAgreement.mouSigned}</div>
              </div>
              <div className={classes.row}>
                <div className={classes.label}>Invoice Paid:</div>
                <div className={classes.value}>{spCase.supplierAgreement.invoicePaid}</div>
              </div>
            </Grid>

            {/* COLUMN 2 */}
            <Grid item xs={12} md={9}>
              <Grid container>
                <Grid item xs={12}>
                  <SupplierPaymentDisplay
                    paidBy={spCase.audit.supplier1Name}
                    paymentAmount={spCase.supplierAgreement.paidBySupplier1}
                    symbol={spCase.supplierAgreement.supplierAgreementCurrencySymbol}
                    rate={spCase.supplierAgreement.conversionRate}
                    displayConvertedPayment={displayConvertedPayment}
                    systemCurrencySymbol={systemCurrencySymbol}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SupplierPaymentDisplay
                    paidBy={spCase.audit.supplier2Name}
                    paymentAmount={spCase.supplierAgreement.paidBySupplier2}
                    symbol={spCase.supplierAgreement.supplierAgreementCurrencySymbol}
                    rate={spCase.supplierAgreement.conversionRate}
                    displayConvertedPayment={displayConvertedPayment}
                    systemCurrencySymbol={systemCurrencySymbol}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SupplierPaymentDisplay
                    paidBy={spCase.audit.supplier3Name}
                    paymentAmount={spCase.supplierAgreement.paidBySupplier3}
                    symbol={spCase.supplierAgreement.supplierAgreementCurrencySymbol}
                    rate={spCase.supplierAgreement.conversionRate}
                    displayConvertedPayment={displayConvertedPayment}
                    systemCurrencySymbol={systemCurrencySymbol}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SupplierPaymentDisplay
                    paidBy={spCase.audit.supplier4Name}
                    paymentAmount={spCase.supplierAgreement.paidBySupplier4}
                    symbol={spCase.supplierAgreement.supplierAgreementCurrencySymbol}
                    rate={spCase.supplierAgreement.conversionRate}
                    displayConvertedPayment={displayConvertedPayment}
                    systemCurrencySymbol={systemCurrencySymbol}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SupplierPaymentDisplay
                    paidBy={spCase.audit.supplier5Name}
                    paymentAmount={spCase.supplierAgreement.paidBySupplier5}
                    symbol={spCase.supplierAgreement.supplierAgreementCurrencySymbol}
                    rate={spCase.supplierAgreement.conversionRate}
                    displayConvertedPayment={displayConvertedPayment}
                    systemCurrencySymbol={systemCurrencySymbol}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SupplierPaymentDisplay
                    paidBy="Primark"
                    paymentAmount={spCase.supplierAgreement.paidByPrinciple}
                    symbol={spCase.supplierAgreement.supplierAgreementCurrencySymbol}
                    rate={spCase.supplierAgreement.conversionRate}
                    displayConvertedPayment={displayConvertedPayment}
                    systemCurrencySymbol={systemCurrencySymbol}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SupplierPaymentDisplay
                    paidBy="Other Party"
                    paymentAmount={spCase.supplierAgreement.paidByOtherParty}
                    symbol={spCase.supplierAgreement.supplierAgreementCurrencySymbol}
                    rate={spCase.supplierAgreement.conversionRate}
                    displayConvertedPayment={displayConvertedPayment}
                    systemCurrencySymbol={systemCurrencySymbol}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* FIELDSETS ALL FULL WIDTH */}
          <Grid item container spacing={2} direction="column">
            {spCase.supplierAgreement.paidByPrinciple && (
              <Grid item>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>Paid by Primark Notes:</legend>
                  <div className={classes.readOnlySummary}>
                    {spCase.supplierAgreement.paidByPrincipleNotes || <div className={classes.label}>No Notes</div>}
                  </div>
                </fieldset>
              </Grid>
            )}
            {spCase.supplierAgreement.paidByOtherParty && (
              <Grid item>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>Paid by Other Notes:</legend>
                  <div className={classes.readOnlySummary}>
                    {spCase.supplierAgreement.paidByOtherPartyNotes || <div className={classes.label}>No Notes</div>}
                  </div>
                </fieldset>
              </Grid>
            )}
            <Grid item>
              <fieldset className={classes.fieldset}>
                <legend className={classes.legend}>Summary of MOU:</legend>
                <div className={classes.readOnlySummary}>
                  {spCase.supplierAgreement.summaryOfMou || <div className={classes.label}>No Summary</div>}
                </div>
              </fieldset>
            </Grid>
          </Grid>
        </Grid>

        {/* RIGHT */}
        <Grid item xs={12} md={4}>
          <CaseDocumentsDisplay
            title="Documents"
            caseDocuments={spCase.caseDocuments}
            manageDocument={manageDocument}
            caseDocumentType={CaseDocumentTypes.supplierAgreement}
            caseId={spCase.id}
          />
        </Grid>

        {/* BUTTON BAR */}
        <Grid item xs={12}>
          <CaseTabReadOnlyToolbar
            onEditClick={enableEditing}
            documentType={CaseDocumentTypes.supplierAgreement}
            caseId={spCase.id}
          />
        </Grid>
      </Grid>
    </>
  );
};

const SupplierPaymentDisplay = ({
  paidBy,
  paymentAmount,
  symbol,
  rate,
  displayConvertedPayment,
  systemCurrencySymbol,
}) => {
  const classes = useStyles();

  return !paidBy ? null : (
    <div className={classes.row}>
      <div className={classes.label} style={{ width: "40%" }}>
        Paid by {paidBy}:
      </div>
      <div style={{ width: "60%" }}>
        <Grid container>
          <Grid item xs={4}>
            <div className={classes.value} style={{ textAlign: "right" }}>
              {paymentAmount && <span style={{ marginRight: 1 }}>{symbol}</span>}
              {paymentAmount &&
                Intl.NumberFormat("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                  paymentAmount
                )}
            </div>
          </Grid>
          {displayConvertedPayment && (
            <Grid item xs={4}>
              <div className={classes.value} style={{ textAlign: "right" }}>
                {paymentAmount && <span style={{ marginRight: 1 }}>{systemCurrencySymbol}</span>}
                {paymentAmount &&
                  Intl.NumberFormat("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                    paymentAmount * rate
                  )}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};
export default CaseSupplierAgreement;
