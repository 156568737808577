import initialState from "../initialState";

export const problemDrops = (state = initialState.problemDrops, action) => {
	switch (action.type) {
		case "LOAD_PROBLEM_DROPS":
			return [...action.payload];

		case "LOAD_PROBLEM_DROP":
			return state.map((x) =>
				x.id !== action.payload.id
					? x
					: {
							...x,
					  }
			);

		case "RESUBMIT_PROBLEM_DROP":
		case "REMOVE_PROBLEM_DROP":
			return state.filter((x) => x.id !== action.payload.id);

		default:
			return state;
	}
};

export default problemDrops;
