export const CaseFilters = Object.freeze({
	overdue: "c1",
	open: "c2",
	critical: "c3",
	closureDue: "c4",
	invDue: "c5",
	mouDue: "c6",
	needsDue: "c7",
	remDue: "c8",
});
