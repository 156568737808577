import initialState from "../initialState";

const yesNoAnswers = (state = initialState.yesNoAnswers, action) => {
	switch (action.type) {
		case "LOAD_YES_NO_ANSWERS":
			return [...action.payload];

		default:
			return state;
	}
};

const roles = (state = initialState.roles, action) => {
	switch (action.type) {
		case "LOAD_ROLES":
			return [...action.payload];

		default:
			return state;
	}
};

const genders = (state = initialState.genders, action) => {
	switch (action.type) {
		case "LOAD_GENDERS":
			return [...action.payload];

		default:
			return state;
	}
};

const youngWorkerNatureOfWorks = (state = initialState.youngWorkerNatureOfWorks, action) => {
	switch (action.type) {
		case "LOAD_YOUNG_WORKER_NATURE_OF_WORKS":
			return [...action.payload];

		default:
			return state;
	}
};

const youngWorkerConditionOfWorks = (state = initialState.youngWorkerConditionOfWorks, action) => {
	switch (action.type) {
		case "LOAD_YOUNG_WORKER_CONDITION_OF_WORKS":
			return [...action.payload];

		default:
			return state;
	}
};

const youngWorkerDecisionSources = (state = initialState.youngWorkerDecisionSources, action) => {
	switch (action.type) {
		case "LOAD_YOUNG_WORKER_DECISION_SOURCES":
			return [...action.payload];

		default:
			return state;
	}
};

const caseDocumentTypes = (state = initialState.caseDocumentTypes, action) => {
	switch (action.type) {
		case "LOAD_CASE_DOCUMENT_TYPES":
			return [...action.payload];

		default:
			return state;
	}
};

const licenseTypes = (state = initialState.licenseTypes, action) => {
	switch (action.type) {
		case "LOAD_LICENSE_TYPES":
			return [...action.payload];

		default:
			return state;
	}
};

export {
	yesNoAnswers,
	roles,
	genders,
	youngWorkerDecisionSources,
	youngWorkerNatureOfWorks,
	youngWorkerConditionOfWorks,
	caseDocumentTypes,
	licenseTypes,
};
