import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Container, Fab, Tooltip, Backdrop } from "@material-ui/core";
import { Link, useHistory, Route } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import CountryCreate from "./country.create";
import CountryManage from "./country.manage";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "../../components/Common/styles";
import { useFetchCountries } from "../../hooks/useFetchEntities";

function Countries() {
  const [countries, fetched] = useFetchCountries();
  const history = useHistory();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const onRowClick = (rowData, rowMeta) => {
    history.push("/countries/manage/" + rowData[0]);
  };

  useEffect(() => {
    setLoading(!fetched);
  }, [fetched]);

  const gridOptions = {
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    filterType: "multiselect",
    selectableRows: "none",
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    onRowClick: onRowClick,
    print: false,
    download: false,
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "code",
      label: "Code",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "caseNumberPrefix",
      label: "Case Number Prefix",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const fabStyle = {
    right: 20,
    position: "fixed",
  };

  return (
    <div style={{ marginRight: 32 }}>
      <Tooltip title="Add" aria-label="add">
        <Fab color="primary" aria-label="add" style={fabStyle} component={Link} to={"/countries/create"}>
          <AddIcon />
        </Fab>
      </Tooltip>
      {!loading && <MUIDataTable title={"Countries"} data={countries} columns={gridColumns} options={gridOptions} />}

      <Route exact path="/countries/manage/:countryId">
        <CountryManage />
      </Route>
      <Route exact path="/countries/create">
        <CountryCreate />
      </Route>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default Countries;
