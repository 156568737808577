import React from "react";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { Typography, Avatar, Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import useSignOutUser from "../../hooks/useSignOutUser";

function User({ horizontal = false }) {
	const classes = useStyles();
	const signOutUser = useSignOutUser();
	const user = useSelector((state) => state.user);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const getInitials = (user) => {
		if (!user) return "";
		if (!user.displayName) return "";

		var names = user.displayName.split(" ");
		var initials = names[0].substr(0, 1);
		var finalIndex = names.length - 1;
		if (finalIndex > 0) {
			initials += names[finalIndex].substr(0, 1);
		}
		return initials;
	};

	return !user ? (
		<Typography variant="button" gutterBottom>
			Sign In
		</Typography>
	) : horizontal ? (
		<div>
			<IconButton onClick={handleClick}>
				<Avatar>{getInitials(user)}</Avatar>
			</IconButton>

			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				style={{ marginTop: 40 }}>
				<MenuItem onClick={signOutUser.signOut}>Sign Out</MenuItem>
			</Menu>
		</div>
	) : (
		<div className={classes.userDetails}>
			<Avatar>{getInitials(user)}</Avatar>
			<Typography component="div" gutterBottom>
				{user.displayName}
			</Typography>
			<Button size="small" variant="outlined" color="secondary" onClick={signOutUser.signOut}>
				Sign Out
			</Button>
		</div>
	);
}

export default User;
