import initialState from "../initialState";
import { AuthenticationActions } from "react-aad-msal";

export const auth = (state = initialState.auth, action) => {
	switch (action.type) {
		case AuthenticationActions.Initializing:
			return {
				...state,
				initializing: true,
				initialized: false,
			};
		case AuthenticationActions.Initialized:
			return {
				...state,
				initializing: false,
				initialized: true,
			};
		case AuthenticationActions.AcquiredIdTokenSuccess:
			return {
				...state,
				idToken: action.payload,
			};
		case AuthenticationActions.AcquiredAccessTokenSuccess:
			return {
				...state,
				accessToken: action.payload,
			};
		case AuthenticationActions.AcquiredAccessTokenError:
			return {
				...state,
				accessToken: null,
			};
		case AuthenticationActions.LoginSuccess:
			return {
				...state,
				account: action.payload.account,
			};
		case AuthenticationActions.LoginError:
		case AuthenticationActions.AcquiredIdTokenError:
		case AuthenticationActions.LogoutSuccess:
			return {
				...state,
				idToken: null,
				accessToken: null,
				account: null,
			};
		case AuthenticationActions.AuthenticatedStateChanged:
			return {
				...state,
				state: action.payload,
			};
		default:
			return state;
	}
};
export default auth;
