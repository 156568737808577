import React, { useRef } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./styles";
import {
	useFetchYesNoAnswers,
	useFetchNationalities,
	useFetchEmploymentTypes,
	useFetchGenders,
	useFetchCaseCategories,
	useFetchInvestigationStatuses,
	useFetchRemediationStatuses,
	useFetchYoungWorkerNatureOfWorks,
	useFetchYoungWorkerConditionOfWorks,
	useFetchYoungWorkerDecisionSources,
} from "../../hooks/useFetchEntities";
import useProtectApi from "../../hooks/useProtectApi";
import { Autocomplete } from "@material-ui/lab";
import { toastr } from "../../components/Common/toastr";
import { useForm } from "react-hook-form";
import { CaseCtx, CaseTabReadOnlyToolbar, CaseTabEditingToolbar, CaseDocumentsDisplay } from "./case.manage";
import moment from "moment";
import { useDispatch } from "react-redux";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { CaseDocumentTypes, GetSectionCaseDocuments } from "./case.document.types";
import { motion } from "framer-motion";
import FormatDate from "../../helpers/date.display.format";

const CaseDetail = ({ tabIndex, beginEdit, finishEdit, manageDocument }) => {
	const [editing, setEditing] = React.useState(false);

	const enableEditing = () => {
		setEditing(true);
		beginEdit(tabIndex);
	};

	const finishEditing = () => {
		setEditing(false);
		finishEdit(tabIndex);
	};

	return editing ? (
		<CaseDetailEditing finishEditing={finishEditing} />
	) : (
		<CaseDetailReadOnly enableEditing={enableEditing} manageDocument={manageDocument} />
	);
};

const CaseDetailEditing = ({ finishEditing }) => {
	const { spCase, caseDispatcher } = React.useContext(CaseCtx);
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));

	const dispatch = useDispatch();
	const classes = useStyles();
	const { register, handleSubmit, errors } = useForm();

	const [yesNoAnswers] = useFetchYesNoAnswers();
	const [nationalities, nationalitiesFetched] = useFetchNationalities();
	const [employmentTypes] = useFetchEmploymentTypes();
	const [genders] = useFetchGenders();
	const [caseCategories, caseCategoriesFetched] = useFetchCaseCategories();
	const [investigationStatuses] = useFetchInvestigationStatuses();
	const [remediationStatuses] = useFetchRemediationStatuses();
	const [youngWorkerNatureOfWorks] = useFetchYoungWorkerNatureOfWorks();
	const [youngWorkerConditionOfWorks] = useFetchYoungWorkerConditionOfWorks();
	const [youngWorkerDecisionSources] = useFetchYoungWorkerDecisionSources();

	const saveDetailApi = useProtectApi("case/detail");
	const detailForm = useRef();

	const [workingDetail, setWorkingDetail] = React.useState(spCase.detail);

	const [dateOfBirth, setDateOfBirth] = React.useState(spCase.detail.dateOfBirth);
	const [isDirty, setIsDirty] = React.useState(false);
	const [isBusy, setIsBusy] = React.useState(false);
	const [apiErrors, setApiErrors] = React.useState();

	const [showSpecialCase, setShowSpecialCase] = React.useState(false);
	const [showYoungWorker, setShowYoungWorker] = React.useState(false);
	const [showOtherNationality, setShowOtherNationality] = React.useState(false);

	const findEntity = (name, entities) => {
		return entities.find((x) => x.name === name) ? entities.find((x) => x.name === name) : null;
	};

	const findEntityId = (name, entities) => {
		return entities.find((x) => x.name === name) ? entities.find((x) => x.name === name).id : null;
	};

	const onSubmitDetail = async (data, e) => {
		e.preventDefault();

		var selectedNationality = findEntityId(data.nationality, nationalities);
		var selectedEmploymentType = findEntityId(data.employmentType, employmentTypes);
		var selectedCaseCategory = findEntityId(data.caseCategory, caseCategories);
		var selectedInvestigationStatus = findEntityId(data.investigationStatus, investigationStatuses);
		var selectedRemediationStatus = findEntityId(data.remediationStatus, remediationStatuses);

		var moment = require('moment-timezone');
		var zoneName = moment.tz.guess();

		let _dateOfBirth = moment.isMoment(dateOfBirth) ? dateOfBirth : moment(dateOfBirth);
		let _submitDob =  moment(_dateOfBirth.format("YYYY/MM/DD") + " " + moment().format("HH:mm"));

		var request = {
			caseId: spCase.id,
			subjectName: data.subjectName,
			gender: data.gender,
			nationalityId: selectedNationality,
			otherNationality: data.otherNationality,
			dateOfBirth: _submitDob.toDate(),
			timezone:zoneName,
			ageAtAudit: parseInt(data.ageAtAudit),
			employmentTypeId: selectedEmploymentType,
			roleAtSite: data.roleAtSite,
			caseCategoryId: selectedCaseCategory,
			investigationStatusId: selectedInvestigationStatus,
			remediationStatusId: selectedRemediationStatus,
			highRisk: data.highRisk,
			highRiskReason: data.highRiskReason,
			specialCaseInformation: data.specialCaseInformation,
			youngWorkerJobRole: data.youngWorkerJobRole,
			youngWorkerNatureOfWork: data.natureOfWork,
			youngWorkerConditionsOfWork: data.conditionsOfWork,
			youngWorkerDecisionSource: data.decisionSource,
		};

		const saveDetail = () =>
			saveDetailApi.api().then((w) =>
				w
					.put(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.json((json) => {
						caseDispatcher({
							type: "CASE_MODIFY_DETAIL",
							payload: workingDetail,
						});

						dispatch({
							type: "CASE_MODIFY_CASE_DETAILS",
							payload: {
								id: spCase.id,
								highRisk: data.highRisk,
								caseCategory: data.caseCategory,
								ageAtAudit: data.ageAtAudit,
								remediationStatus: data.remediationStatus,
								investigationStatus: data.investigationStatus,
								nationality: data.nationality,
							},
						});
					})
					.then(() => toastr.success("Case Detail changed"))
					.then(() => setIsDirty(false))
					.then(() => finishEditing())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to save Case Detail.");
					})
					.finally(() => setIsBusy(false))
			);

		setIsBusy(true);
		saveDetail();
	};

	React.useEffect(() => {
		setIsDirty(JSON.stringify(spCase.detail) !== JSON.stringify(workingDetail));
	}, [spCase.detail, workingDetail]);

	React.useEffect(() => {
		if (nationalitiesFetched) {
			var selectedNationality = findEntity(workingDetail.nationality, nationalities);

			var isOther = selectedNationality && selectedNationality.isOther;
			setShowOtherNationality(isOther);
			!isOther && updateValue("otherNationality", null);
		}
	}, [workingDetail.nationality, nationalitiesFetched]);

	React.useEffect(() => {
		if (caseCategoriesFetched) {
			var selectedCaseCategory = findEntity(workingDetail.caseCategory, caseCategories);
			var isSpecialCase = selectedCaseCategory && selectedCaseCategory.isSpecialCase;
			var isYoungWorker = selectedCaseCategory && selectedCaseCategory.isYoungWorker;

			setShowSpecialCase(isSpecialCase);
			setShowYoungWorker(isYoungWorker);
		}
	}, [workingDetail.caseCategory, caseCategoriesFetched]);

	const updateValue = (name, value) => {
		setWorkingDetail((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleDateOfBirthChange = (e, v) => {
		setDateOfBirth(e);
		updateValue("dateOfBirth", e.toDate());
	};

	const variants = {
		hidden: { opacity: 0, x: -30 },
		visible: { opacity: 1, x: 0 },
	};

	return (
		<div style={{ padding: 24 }}>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<form onSubmit={handleSubmit(onSubmitDetail)} ref={detailForm} id="detailForm">
					<Grid container spacing={2} alignItems="stretch">
						<Grid item lg={3} md={3} sm={6} xs={12}>
							<TextField
								label="Name"
								fullWidth
								name="subjectName"
								error={!!(errors && errors.subjectName)}
								autoComplete="off"
								inputRef={register()}
								inputProps={{
									"aria-label": "Name",
								}}
								helperText={errors && errors.subjectName && errors.subjectName.message}
								value={(workingDetail && workingDetail.subjectName) || ""}
								onChange={(e) => updateValue(e.target.name, e.target.value)}
							/>
						</Grid>

						<Grid item lg={3} md={3} sm={6} xs={12}>
							<Autocomplete
								id="genderAutocomplete"
								options={genders ? genders.map((r) => r.name) : []}
								getOptionLabel={(option) => option}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											name="gender"
											label={"Gender"}
											fullWidth
											inputRef={register({})}
											error={!!(errors && errors.gender)}
											helperText={errors && errors.gender && errors.gender.message}
										/>
									);
								}}
								value={workingDetail.gender}
								onChange={(e, v) => updateValue("gender", v)}
							/>
						</Grid>

						<Grid item lg={3} md={3} sm={6} xs={12}>
							<Autocomplete
								id="nationalityAutocomplete"
								options={nationalities ? nationalities.map((r) => r.name) : []}
								getOptionLabel={(option) => option}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											name="nationality"
											label={"Nationality"}
											fullWidth
											inputRef={register({})}
											error={!!(errors && errors.nationality)}
											helperText={errors && errors.nationality && errors.nationality.message}
										/>
									);
								}}
								value={workingDetail.nationality}
								onChange={(e, v) => updateValue("nationality", v)}
							/>
						</Grid>

						{showOtherNationality && (
							<Grid item lg={3} md={3} sm={6} xs={12}>
								<TextField
									label="Other Nationality"
									fullWidth
									name="otherNationality"
									error={!!(errors && errors.otherNationality)}
									autoComplete="off"
									inputRef={register()}
									inputProps={{
										"aria-label": "Other Nationality",
										// required: workingDetail.nationality==="Other"
									}}
									helperText={errors && errors.otherNationality && errors.otherNationality.message}
									value={(workingDetail && workingDetail.otherNationality) || ""}
									onChange={(e) => updateValue(e.target.name, e.target.value)}
								/>
							</Grid>
						)}

						<Grid item lg={3} md={3} sm={6} xs={12}>
							<KeyboardDatePicker
								autoOk={true}
								name="dateOfBirth"
								error={!!(errors && errors.dateOfBirth)}
								animateYearScrolling={true}
								format="DD/MM/YYYY"
								label="Date of Birth"
								onChange={handleDateOfBirthChange}
								value={dateOfBirth ? moment(dateOfBirth) : null}
								inputRef={register({})}
								style={{ width: "100%" }}
								helperText={errors && errors.dateOfBirth && errors.dateOfBirth.message}
							/>
						</Grid>

						<Grid item lg={3} md={3} sm={6} xs={12}>
							<TextField
								label="Age at Audit"
								fullWidth
								name="ageAtAudit"
								error={!!(errors && errors.ageAtAudit)}
								autoComplete="off"
								type="number"
								min="1"
								max="18"
								inputRef={register({ max: 99, min: 1 })}
								inputProps={{
									"aria-label": "Age at Audit",
								}}
								helperText={errors && errors.ageAtAudit && errors.ageAtAudit.message}
								onChange={(e) => updateValue(e.target.name, e.target.value)}
								value={(workingDetail && workingDetail.ageAtAudit) || ""}
							/>
						</Grid>

						<Grid item lg={3} md={3} sm={6} xs={12}>
							<Autocomplete
								id="employmentTypeAutoComplete"
								options={employmentTypes ? employmentTypes.map((r) => r.name) : []}
								getOptionLabel={(option) => option}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											name="employmentType"
											label={"Employment Type"}
											fullWidth
											inputRef={register({})}
											error={!!(errors && errors.employmentType)}
											helperText={
												errors && errors.employmentType && errors.employmentType.message
											}
										/>
									);
								}}
								value={workingDetail.employmentType}
								onChange={(e, v) => updateValue("employmentType", v)}
							/>
						</Grid>

						<Grid item lg={3} md={3} sm={6} xs={12}>
							<TextField
								label="Role at Site"
								fullWidth
								name="roleAtSite"
								error={!!(errors && errors.roleAtSite)}
								autoComplete="off"
								inputRef={register()}
								inputProps={{
									"aria-label": "Role as Site",
								}}
								helperText={errors && errors.roleAtSite && errors.roleAtSite.message}
								onChange={(e) => updateValue(e.target.name, e.target.value)}
								value={workingDetail && workingDetail.roleAtSite}
							/>
						</Grid>
						<Grid item lg={3} md={3} sm={6} xs={12}>
							<Autocomplete
								id="caseCategoryAutoComplete"
								options={caseCategories ? caseCategories.map((r) => r.name) : []}
								getOptionLabel={(option) => option}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											name="caseCategory"
											label={"Case Category"}
											fullWidth
											inputRef={register({})}
											error={!!(errors && errors.caseCategory)}
											helperText={errors && errors.caseCategory && errors.caseCategory.message}
										/>
									);
								}}
								value={workingDetail.caseCategory}
								onChange={(e, v) => updateValue("caseCategory", v)}
							/>
						</Grid>
						<Grid item lg={3} md={3} sm={6} xs={12}>
							<Autocomplete
								id="investigationStatusAutoComplete"
								options={investigationStatuses ? investigationStatuses.map((r) => r.name) : []}
								getOptionLabel={(option) => option}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											name="investigationStatus"
											label={"Investigation Status"}
											fullWidth
											inputRef={register({})}
											error={!!(errors && errors.investigationStatus)}
											helperText={
												errors &&
												errors.investigationStatus &&
												errors.investigationStatus.message
											}
										/>
									);
								}}
								value={workingDetail.investigationStatus}
								onChange={(e, v) => updateValue("investigationStatus", v)}
							/>
						</Grid>
						<Grid item lg={3} md={3} sm={6} xs={12}>
							<Autocomplete
								id="remediationStatusAutoComplete"
								options={remediationStatuses ? remediationStatuses.map((r) => r.name) : []}
								getOptionLabel={(option) => option}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											name="remediationStatus"
											label={"Remediation Status"}
											fullWidth
											inputRef={register({})}
											error={!!(errors && errors.remediationStatus)}
											helperText={
												errors && errors.remediationStatus && errors.remediationStatus.message
											}
										/>
									);
								}}
								value={workingDetail.remediationStatus}
								onChange={(e, v) => updateValue("remediationStatus", v)}
							/>
						</Grid>
						<Grid item lg={3} md={3} sm={6} xs={12}>
							<Autocomplete
								id="highRiskAutoComplete"
								options={yesNoAnswers ? yesNoAnswers.map((r) => r.name) : []}
								getOptionLabel={(option) => option}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											name="highRisk"
											label={"High Risk"}
											fullWidth
											inputRef={register({})}
											error={!!(errors && errors.highRisk)}
											helperText={errors && errors.highRisk && errors.highRisk.message}
										/>
									);
								}}
								value={workingDetail.highRisk}
								onChange={(e, v) => updateValue("highRisk", v)}
							/>
						</Grid>

						{/* SPACER */}
						{(showSpecialCase || showYoungWorker) && (
							<Grid
								item
								sm={4}
								md={3}
								component={motion.div}
								variants={variants}
								initial="hidden"
								animate="visible"
								transition={{ duration: 0.2, delay: 0 }}
							/>
						)}

						<Grid item md={showYoungWorker ? 8 : 6} sm={showYoungWorker ? 8 : 6} xs={12}>
							<fieldset className={classes.fieldset}>
								<legend className={classes.legend}>High Risk Reason</legend>
								<TextField
									fullWidth
									name="highRiskReason"
									multiline
									autoComplete="off"
									rows={smDown ? "6" : "12"}
									error={!!(errors && errors.highRiskReason)}
									inputRef={register({})}
									inputProps={{
										"aria-label": "High Risk Reason",
									}}
									helperText={errors && errors.highRiskReason && errors.highRiskReason.message}
									value={(workingDetail && workingDetail.highRiskReason) || ""}
									onChange={(e) => updateValue(e.target.name, e.target.value)}
								/>
							</fieldset>
						</Grid>

						{showSpecialCase && (
							<Grid
								item
								md={6}
								sm={6}
								xs={12}
								component={motion.div}
								initial="hidden"
								animate="visible"
								variants={variants}
								transition={{ duration: 1, delay: 0.2 }}>
								<fieldset className={classes.fieldset}>
									<legend className={classes.legend}>Special Case Information</legend>
									<TextField
										// label=""
										fullWidth
										name="specialCaseInformation"
										multiline
										autoComplete="off"
										rows={smDown ? "6" : "12"}
										error={!!(errors && errors.specialCaseInformation)}
										inputRef={register({})}
										inputProps={{
											"aria-label": "Special Case Information",
										}}
										helperText={
											errors &&
											errors.specialCaseInformation &&
											errors.specialCaseInformation.message
										}
										value={(workingDetail && workingDetail.specialCaseInformation) || ""}
										onChange={(e) => updateValue(e.target.name, e.target.value)}
									/>
								</fieldset>
							</Grid>
						)}

						{showYoungWorker && (
							<Grid
								item
								lg={4}
								md={4}
								sm={4}
								xs={12}
								component={motion.div}
								initial="hidden"
								animate="visible"
								variants={variants}
								transition={{ duration: 1, delay: 0.2 }}>
								<fieldset className={classes.fieldset}>
									<legend className={classes.legend}>Young Worker</legend>
									<Grid container direction="column" spacing={1}>
										<Grid item>
											<TextField
												label="Job Role"
												fullWidth
												name="youngWorkerJobRole"
												error={!!(errors && errors.youngWorkerJobRole)}
												autoComplete="off"
												inputRef={register()}
												inputProps={{
													"aria-label": "Job Role",
												}}
												helperText={
													errors &&
													errors.youngWorkerJobRole &&
													errors.youngWorkerJobRole.message
												}
												onChange={(e) => updateValue(e.target.name, e.target.value)}
												value={(workingDetail && workingDetail.youngWorkerJobRole) || ""}
											/>
										</Grid>
										<Grid item>
											<Autocomplete
												id="natureOfWorkAutoComplete"
												options={
													youngWorkerNatureOfWorks
														? youngWorkerNatureOfWorks.map((r) => r.name)
														: []
												}
												getOptionLabel={(option) => option}
												renderInput={(params) => {
													return (
														<TextField
															{...params}
															name="natureOfWork"
															label="Nature of the Work"
															fullWidth
															inputRef={register({})}
															error={!!(errors && errors.natureOfWork)}
															helperText={
																errors &&
																errors.natureOfWork &&
																errors.natureOfWork.message
															}
														/>
													);
												}}
												value={workingDetail.natureOfWork}
												onChange={(e, v) => updateValue("natureOfWork", v)}
											/>
										</Grid>
										<Grid item>
											<Autocomplete
												id="conditionsOfWorkAutoComplete"
												options={
													youngWorkerConditionOfWorks
														? youngWorkerConditionOfWorks.map((r) => r.name)
														: []
												}
												getOptionLabel={(option) => option}
												renderInput={(params) => {
													return (
														<TextField
															{...params}
															name="conditionsOfWork"
															label="Conditions of the Work"
															fullWidth
															inputRef={register({})}
															error={!!(errors && errors.conditionsOfWork)}
															helperText={
																errors &&
																errors.conditionsOfWork &&
																errors.conditionsOfWork.message
															}
														/>
													);
												}}
												value={workingDetail.conditionsOfWork}
												onChange={(e, v) => updateValue("conditionsOfWork", v)}
											/>
										</Grid>
										<Grid item>
											<Autocomplete
												id="decisionSourceAutoComplete"
												options={
													youngWorkerDecisionSources
														? youngWorkerDecisionSources.map((r) => r.name)
														: []
												}
												getOptionLabel={(option) => option}
												renderInput={(params) => {
													return (
														<TextField
															{...params}
															name="decisionSource"
															label="Decision Source"
															fullWidth
															inputRef={register({})}
															error={!!(errors && errors.decisionSource)}
															helperText={
																errors &&
																errors.decisionSource &&
																errors.decisionSource.message
															}
														/>
													);
												}}
												value={workingDetail.decisionSource}
												onChange={(e, v) => updateValue("decisionSource", v)}
											/>
										</Grid>
									</Grid>
								</fieldset>
							</Grid>
						)}

						{!!apiErrors && (
							<Grid item lg={6} md={4} sm={12} xs={12}>
								<div className={classes.row}>
									<div className={classes.error}>{apiErrors}</div>
								</div>
							</Grid>
						)}

						<Grid item xs={12}>
							<CaseTabEditingToolbar
								saveDisabled={isBusy || !isDirty}
								onCloseClick={finishEditing}
								formRef={detailForm && detailForm.current}
							/>
						</Grid>
					</Grid>
				</form>
			</MuiPickersUtilsProvider>
		</div>
	);
};

const CaseDetailReadOnly = ({ enableEditing, manageDocument }) => {
	const classes = useStyles();

	const { spCase } = React.useContext(CaseCtx);
	const { detail } = spCase;
	const [caseCategories, caseCategoriesFetched] = useFetchCaseCategories();
	const [showSpecialCase, setShowSpecialCase] = React.useState(false);
	const [showYoungWorker, setShowYoungWorker] = React.useState(false);

	React.useEffect(() => {
		if (caseCategoriesFetched) {
			console.info(spCase.detail.caseCategory);

			var selectedCaseCategory = caseCategories.find((x) => x.name === spCase.detail.caseCategory)
				? caseCategories.find((x) => x.name === spCase.detail.caseCategory)
				: null;

			var isSpecialCase = selectedCaseCategory && selectedCaseCategory.isSpecialCase;
			var isYoungWorker = selectedCaseCategory && selectedCaseCategory.isYoungWorker;

			setShowSpecialCase(isSpecialCase);
			setShowYoungWorker(isYoungWorker);
		}
	}, [caseCategoriesFetched]);

	return (
		<>
			<Grid container spacing={2} alignItems="stretch">
				<Grid item sm={9} xs={12} container spacing={1}>
					<Grid item lg={3} md={4} sm={6} xs={6}>
						<div className={classes.row}>
							<div className={classes.label}>Name:</div>
							<div className={classes.value}>{detail.subjectName}</div>
						</div>
						<div className={classes.row}>
							<div className={classes.label}>Age at Audit:</div>
							<div className={classes.value}>{detail.ageAtAudit}</div>
						</div>
						<div className={classes.row}>
							<div className={classes.label}>Gender:</div>
							<div className={classes.value}>{detail.gender}</div>
						</div>
						<div className={classes.row}>
							<div className={classes.label}>Nationality:</div>
							<div className={classes.value}>{detail.otherNationality || detail.nationality}</div>
						</div>
						<div className={classes.row}>
							<div className={classes.label}>Date of Birth:</div>
							<div className={classes.value}>{detail.dateOfBirth && FormatDate(detail.dateOfBirth)}</div>
						</div>
					</Grid>
					<Grid item lg={3} md={4} sm={6} xs={6}>
						<div className={classes.row}>
							<div className={classes.label}>Employment Type:</div>
							<div className={classes.value}>{detail.employmentType}</div>
						</div>
						<div className={classes.row}>
							<div className={classes.label}>Role at Site:</div>
							<div className={classes.value}>{detail.roleAtSite}</div>
						</div>
					</Grid>
					<Grid item lg={3} md={4} sm={6} xs={6}>
						<div className={classes.row}>
							<div className={classes.label}>Case Category:</div>
							<div className={classes.value}>{detail.caseCategory}</div>
						</div>
						<div className={classes.row}>
							<div className={classes.label}>Investigation Status:</div>
							<div className={classes.value}>{detail.investigationStatus}</div>
						</div>
						<div className={classes.row}>
							<div className={classes.label}>Remediation Status:</div>
							<div className={classes.value}>{detail.remediationStatus}</div>
						</div>
					</Grid>
					<Grid item lg={3} md={4} sm={6} xs={6}>
						<div className={classes.row}>
							<div className={classes.label}>High Risk:</div>
							<div className={classes.value}>{detail.highRisk}</div>
						</div>
						<div className={classes.row}>
							<div className={classes.label}>High Risk Reason:</div>
							<div className={classes.value}>
								<span className={classes.readOnlySummary}>{detail.highRiskReason}</span>
							</div>
						</div>
					</Grid>

					{showSpecialCase && (
						<Grid item lg={3} md={4} sm={6} xs={12}>
							<div className={classes.row}>
								<div className={classes.label}>Special Case Information:</div>
								<div className={classes.value}>
									<span className={classes.readOnlySummary}>{detail.specialCaseInformation}</span>
								</div>
							</div>
						</Grid>
					)}

					{showYoungWorker && (
						<Grid item md={4} sm={6} xs={12}>
							<fieldset className={classes.fieldset}>
								<legend className={classes.legend}>Young Worker</legend>

								<div className={classes.row}>
									<div className={classes.label}>Job Role:</div>
									<div className={classes.value_xl}>{detail.youngWorkerJobRole}</div>
								</div>

								<div className={classes.row}>
									<div className={classes.label}>Nature of the Work:</div>
									<div className={classes.value_xl}>{detail.natureOfWork}</div>
								</div>

								<div className={classes.row}>
									<div className={classes.label}>Conditions of the Work:</div>
									<div className={classes.value_xl}>{detail.conditionsOfWork}</div>
								</div>

								<div className={classes.row}>
									<div className={classes.label}>Decision source:</div>
									<div className={classes.value_xl}>{detail.decisionSource}</div>
								</div>
							</fieldset>
						</Grid>
					)}
				</Grid>
				<Grid item sm={3} xs={12}>
					<CaseDocumentsDisplay
						title="Documents"
						caseDocuments={spCase.caseDocuments}
						manageDocument={manageDocument}
						caseDocumentType={CaseDocumentTypes.detail}
						caseId={spCase.id}
					/>
				</Grid>
				<Grid item xs={12}>
					<CaseTabReadOnlyToolbar
						onEditClick={enableEditing}
						caseId={spCase.id}
						documentType={CaseDocumentTypes.detail}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default CaseDetail;
