import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "../components/Common/toastr";
import useProtectApi from "../hooks/useProtectApi";
import { useLocation } from "react-router-dom";

const useFetchCases = () => {
	const dispatch = useDispatch();
	const casesApi = useProtectApi("case");
	const location = useLocation();

	const [fetchRequired, setFetchRequired] = useState(false);
	const [fetched, setFetched] = useState(false);

	let cases = useSelector((state) => state.cases);

	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const _filter = query.get("filter");
		_filter && setFetchRequired(true);
	}, []);

	useEffect(() => {
		const fetchCases = () => {
			casesApi.api().then((w) =>
				w
					.get()
					.json((json) =>
						dispatch({
							type: "LOAD_CASES",
							payload: json,
						})
					)
					.then(() => setFetched(true))
					.catch((error) => toastr.error("Unable to retrieve Cases"))
			);
		};

		if (fetchRequired) {
			fetchCases();
		} else {
			if (cases.length === 0) {
				!fetched && fetchCases();
			} else {
				setFetched(true);
			}
		}
	}, [fetchRequired]);

	return [cases, fetched];
};

export default useFetchCases;
