import { FormGroup, FormLabel } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import React from "react";
import FormatDate from "../helpers/date.display.format";

export const DateRangeFilters = {
  logic(date, filters) {
    if (filters[0] && filters[1]) {
      if (!date) return true;
      return !moment(date).isBetween(filters[0], filters[1], "day", "[]");
    } else if (filters[0]) {
      if (!date) return true;
      return moment(date).isBefore(filters[0], "day");
    } else if (filters[1]) {
      if (!date) return true;
      return moment(date).isAfter(filters[1], "day");
    }

    return false;
  },
  display: (filterList, onChange, index, column) => {
    return (
      <div>
        <FormLabel>{column.label}</FormLabel>
        <FormGroup row>
          <DatePicker
            clearable
            label="From"
            maxDate={filterList[index][1]}
            value={filterList[index][0] || null}
            onChange={(date) => {
              console.log(date);
              filterList[index][0] = date;
              onChange(filterList[index], index, column);
            }}
            style={{ width: "45%", marginRight: "5%" }}
          />
          <DatePicker
            label="To"
            minDate={filterList[index][0]}
            value={filterList[index][1] || null}
            onChange={(date) => {
              console.log(date);
              filterList[index][1] = date;
              onChange(filterList[index], index, column);
            }}
            style={{ width: "45%" }}
          />
        </FormGroup>
      </div>
    );
  },
};

export const dateFilterChips = (columnName) => {
  return {
    render: (v) => {
      if (v[0] && v[1]) {
        return `${columnName} ${FormatDate(v[0])}-${FormatDate(Date(v[1]))}`;
      } else if (v[0]) {
        return `${columnName} From: ${FormatDate(v[0])}`;
      } else if (v[1]) {
        return `${columnName} Until: ${FormatDate(v[1])}`;
      }
      return [];
    },
  };
};
