import React from "react";
import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Logger, LogLevel } from "msal";
import { AuthConfig } from "../config";

export const authProvider = () => {
	var internalProvider;

	const getInternalProvider = () => internalProvider;
	return {
		createSignInProvider: () => {
			internalProvider = new MsalAuthProvider(
				{
					auth: {
						authority: `https://${AuthConfig.DirectoryName}.b2clogin.com/tfp/${AuthConfig.DirectoryName}.onmicrosoft.com/B2C_1_SpdSignIn`,
						clientId: AuthConfig.ClientId,
						postLogoutRedirectUri: window.location.origin,
						redirectUri: window.location.origin,
						validateAuthority: false,
						navigateToLoginRequestUrl: false,
					},
					system: {
						logger: new Logger(
							(logLevel, message, containsPii) => {
								console.log("[MSAL]", message);
							},
							{
								level: LogLevel.Error,
								piiLoggingEnabled: false,
							}
						),
					},
					cache: {
						cacheLocation: "localStorage",
						storeAuthStateInCookie: true,
					},
				},
				{
					scopes: [AuthConfig.ApiAppId + "Use"],
				},
				{
					loginType: LoginType.Redirect,
					tokenRefreshUri: window.location.origin + "/auth.html",
				}
			);

			return internalProvider;
		},
		createResetPasswordProvider: () => {
			internalProvider = new MsalAuthProvider(
				{
					auth: {
						authority: `https://${AuthConfig.DirectoryName}.b2clogin.com/tfp/${AuthConfig.DirectoryName}.onmicrosoft.com/B2C_1_SpdPasswordReset`,
						clientId: AuthConfig.ClientId,
						postLogoutRedirectUri: window.location.origin,
						redirectUri: window.location.origin,
						validateAuthority: false,
						navigateToLoginRequestUrl: false,
					},
					system: {
						logger: new Logger(
							(logLevel, message, containsPii) => {
								console.log("[MSAL]", message);
							},
							{
								level: LogLevel.Error,
								piiLoggingEnabled: false,
							}
						),
					},
					cache: {
						cacheLocation: "localStorage",
						storeAuthStateInCookie: true,
					},
				},
				{
					scopes: [AuthConfig.ApiAppId + "Use"],
				},
				{
					loginType: LoginType.Redirect,
					tokenRefreshUri: window.location.origin + "/auth.html",
				}
			);

			return internalProvider;
		},
		provider: getInternalProvider,
	};
};

export const AuthProviderCtx = React.createContext();
