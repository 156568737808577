import initialState from "../initialState";

export const user = (state = initialState.user, action) => {
	switch (action.type) {
		case "USER_AUTHENTICATED":
			case "USER_UNLICENSED":
				case "USER_UNAUTHORIZED":
			return { ...state, ...action.payload.data };


		case "USER_UNUTHENTICATED":
			return {};
		default:
			return state;
	}
};

export default user;
