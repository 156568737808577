import React from "react";
import { Link } from "react-router-dom";
import useStyles from "./styles";

function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <div>Powered by </div>
      <img src={require("../../images/intellync_logo_new.png")} height="24" alt="Intellync" className={classes.logo} />
    </div>
  );
}

export default Footer;
