import React, { useRef } from "react";
import { Button, Paper, Grid, TextField, Typography, Hidden } from "@material-ui/core";
import useStyles from "./styles";
import {
	useFetchSiteStatuses,
	useFetchInvestigationTypes,
	useFetchSuppliers,
	useFetchSitesForCase,
	useFetchSiteTiers,
} from "../../hooks/useFetchEntities";
import useProtectApi from "../../hooks/useProtectApi";
import { Autocomplete } from "@material-ui/lab";
import { toastr } from "../../components/Common/toastr";
import { useForm } from "react-hook-form";
import { CaseCtx, CaseTabReadOnlyToolbar, CaseTabEditingToolbar, CaseDocumentsDisplay } from "./case.manage";
import { useDispatch } from "react-redux";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { CaseDocumentTypes, GetSectionCaseDocuments } from "./case.document.types";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormatDate from "../../helpers/date.display.format";
import moment from  "moment";
import IsString from "../../helpers/string.isstring";

const CaseAudit = ({ tabIndex, beginEdit, finishEdit, manageDocument }) => {
	const [editing, setEditing] = React.useState(false);

	const enableEditing = () => {
		setEditing(true);
		beginEdit(tabIndex);
	};

	const finishEditing = () => {
		setEditing(false);
		finishEdit(tabIndex);
	};

	return editing ? (
		<CaseAuditEditing finishEditing={finishEditing} />
	) : (
		<CaseAuditReadOnly enableEditing={enableEditing} manageDocument={manageDocument} />
	);
};

const CaseAuditEditing = ({ finishEditing }) => {
	const { spCase, caseDispatcher, caseFetched } = React.useContext(CaseCtx);
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const dispatch = useDispatch();
	const classes = useStyles();
	const { register, handleSubmit, errors } = useForm();
	const [siteStatuses] = useFetchSiteStatuses();
	const [investigationTypes] = useFetchInvestigationTypes();
	const [suppliers, suppliersFetched] = useFetchSuppliers();
	const [tiers] = useFetchSiteTiers();
	const [caseSites, caseSitesFetched] = useFetchSitesForCase(spCase.id);
	const saveAuditApi = useProtectApi("case/audit");
	const auditForm = useRef();

	const [workingAudit, setWorkingAudit] = React.useState(spCase.audit);
	const [isDirty, setIsDirty] = React.useState(false);
	const [isBusy, setIsBusy] = React.useState(false);
	const [apiErrors, setApiErrors] = React.useState();
	const [dateFound, setDateFound] = React.useState(spCase.audit.dateFound);

	const [showOtherSiteFound, setShowOtherSiteFound] = React.useState(false);
	const [showOtherTier1Site, setShowOtherTier1Site] = React.useState(false);
	const [showOtherTier2Site, setShowOtherTier2Site] = React.useState(false);
	const [showOtherTier3Site, setShowOtherTier3Site] = React.useState(false);

	const [showSupplierInput, setShowSupplierInput] = React.useState([1, 2, 3, 4, 5]);
	const [showSupplier1Other, setShowSupplier1Other] = React.useState(false);
	const [showSupplier2Other, setShowSupplier2Other] = React.useState(false);
	const [showSupplier3Other, setShowSupplier3Other] = React.useState(false);
	const [showSupplier4Other, setShowSupplier4Other] = React.useState(false);
	const [showSupplier5Other, setShowSupplier5Other] = React.useState(false);

	const findEntity = (name, entities) => {
		return entities.find((x) => x.name === name) || null;
	};

	const findEntityId = (name, entities) => {
		const _entity = findEntity(name, entities);
		return _entity ? _entity.id : null;
	};

	const GetSupplierNames = (s1, s2, s3, s4, s5) => {
		var names = [];
		[s1, s2, s3, s4, s5].map((e) => {
			e && names.push(e);
		});
		return names.toString();
	};

	const GetSupplierIds = (id1, id2, id3, id4, id5) => {
		var ids = [];
		[id1, id2, id3, id4, id5].map((e) => {
			e && ids.push(e);
		});
		return ids;
	};

	const onSubmitAudit = async (data, e) => {
		e.preventDefault();

		var selectedSiteStatus = findEntityId(data.siteStatus, siteStatuses);
		var selectedInvestigationType = findEntityId(data.investigationType, investigationTypes);

		var selectedSiteFound = findEntityId(data.siteFound, caseSites);
		var selectedOtherTier = findEntityId(data.siteFoundOtherTier, tiers);
		var selectedLinkedTier1Site = findEntityId(data.linkedTier1Site, caseSites);
		var selectedLinkedTier2Site = findEntityId(data.linkedTier2Site, caseSites);
		var selectedLinkedTier3Site = findEntityId(data.linkedTier3Site, caseSites);

		var selectedSupplier1Id = findEntityId(data.supplier1Name, suppliers);
		var selectedSupplier2Id = findEntityId(data.supplier2Name, suppliers);
		var selectedSupplier3Id = findEntityId(data.supplier3Name, suppliers);
		var selectedSupplier4Id = findEntityId(data.supplier4Name, suppliers);
		var selectedSupplier5Id = findEntityId(data.supplier5Name, suppliers);

		var moment = require('moment-timezone');
		var zoneName = moment.tz.guess();

		let _dateFound = moment.isMoment(dateFound) ? dateFound : moment(dateFound);
		let _submitDt =  moment(_dateFound.format("YYYY/MM/DD") + " " + moment().format("HH:mm"));

		var request = {
			caseId: spCase.id,
			dateFound: _submitDt.toDate(),
			timezone:zoneName,
			siteStatusId: selectedSiteStatus,
			investigationTypeId: selectedInvestigationType,
			auditSummary: data.auditSummary,

			siteFoundId: selectedSiteFound,
			siteFoundOther: data.siteFoundOther === undefined ? null : data.siteFoundOther,
			siteFoundOtherTierId: selectedOtherTier,

			linkedTier1SiteId: selectedLinkedTier1Site,

			linkedTier1SiteOther: data.linkedTier1SiteOther === undefined ? null : data.linkedTier1SiteOther,

			linkedTier2SiteId: selectedLinkedTier2Site,
			linkedTier2SiteOther: data.linkedTier2SiteOther === undefined ? null : data.linkedTier2SiteOther,

			linkedTier3SiteId: selectedLinkedTier3Site,
			linkedTier3SiteOther: data.linkedTier3SiteOther === undefined ? null : data.linkedTier3SiteOther,

			supplier1Id: selectedSupplier1Id,
			supplier1Other: data.supplier1Other === undefined ? null : data.supplier1Other,

			supplier2Id: selectedSupplier2Id,
			supplier2Other: data.supplier2Other === undefined ? null : data.supplier2Other,
			supplier3Id: selectedSupplier3Id,
			supplier3Other: data.supplier3Other === undefined ? null : data.supplier3Other,
			supplier4Id: selectedSupplier4Id,
			supplier4Other: data.supplier4Other === undefined ? null : data.supplier4Other,
			supplier5Id: selectedSupplier5Id,
			supplier5Other: data.supplier5Other === undefined ? null : data.supplier5Other,
		};

		const saveAudit = () =>
			saveAuditApi.api().then((w) =>
				w
					.put(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.json((json) => {
						caseDispatcher({
							type: "CASE_MODIFY_AUDIT",
							payload: workingAudit,
						});
						dispatch({
							type: "CASE_MODIFY_AUDIT",
							payload: {
								id: spCase.id,
								dateFound: moment(dateFound),
								siteFound: data.siteFound,
								siteFoundId: selectedSiteFound ? selectedSiteFound.id : null,
								linkedTier1Site: data.linkedTier1Site,
								linkedTier2Site: data.linkedTier2Site,
								linkedTier3Site: data.linkedTier3Site,
								suppliers: GetSupplierNames(
									data.supplier1Name,
									data.supplier2Name,
									data.supplier3Name,
									data.supplier4Name,
									data.supplier5Name
								),
								supplierIds: GetSupplierIds(
									selectedSupplier1Id,
									selectedSupplier2Id,
									selectedSupplier3Id,
									selectedSupplier4Id,
									selectedSupplier5Id
								),
							},
						});
					})
					.then(() => toastr.success("Audit details changed"))
					.then(() => setIsDirty(false))
					.then(() => finishEditing())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to save Audit.");
					})
					.finally(() => setIsBusy(false))
			);

		setIsBusy(true);
		saveAudit();
	};

	React.useEffect(() => {
		setIsDirty(JSON.stringify(spCase.audit) !== JSON.stringify(workingAudit));
	}, [spCase.audit, workingAudit]);

	React.useEffect(() => {
		if (caseSitesFetched) {
			var selectedSiteFound = findEntity(workingAudit.siteFound, caseSites);
			var isOther = selectedSiteFound && selectedSiteFound.isOther;
			setShowOtherSiteFound(isOther);
			!isOther && updateValue("siteFoundOther", null);
		}
	}, [workingAudit.siteFound, caseSitesFetched]);

	React.useEffect(() => {
		if (caseSitesFetched) {
			var selectedTier1Site = findEntity(workingAudit.linkedTier1Site, caseSites);
			var isOther = selectedTier1Site && selectedTier1Site.isOther;
			setShowOtherTier1Site(isOther);
			!isOther && updateValue("linkedTier1SiteOther", null);
		}
	}, [workingAudit.linkedTier1Site, caseSitesFetched]);

	React.useEffect(() => {
		if (caseSitesFetched) {
			var selectedTier3Site = findEntity(workingAudit.linkedTier3Site, caseSites);
			var isOther = selectedTier3Site && selectedTier3Site.isOther;
			setShowOtherTier3Site(isOther);
			!isOther && updateValue("linkedTier3SiteOther", null);
		}
	}, [workingAudit.linkedTier3Site, caseSitesFetched]);

	React.useEffect(() => {
		if (caseSitesFetched) {
			var selectedTier2Site = findEntity(workingAudit.linkedTier2Site, caseSites);
			var isOther = selectedTier2Site && selectedTier2Site.isOther;
			setShowOtherTier2Site(isOther);
			!isOther && updateValue("linkedTier2SiteOther", null);
		}
	}, [workingAudit.linkedTier2Site, caseSitesFetched]);

	React.useEffect(() => {
		if (suppliersFetched) {
			// determines if a supplier input should display
			var selectedSupplier1 = findEntity(workingAudit.supplier1Name, suppliers);

			//-- 2
			var selectedSupplier2 = findEntity(workingAudit.supplier2Name, suppliers);

			//-- 3
			var selectedSupplier3 = findEntity(workingAudit.supplier3Name, suppliers);

			//-- 4
			var selectedSupplier4 = findEntity(workingAudit.supplier4Name, suppliers);

			//-- 5
			var selectedSupplier5 = findEntity(workingAudit.supplier5Name, suppliers);

			// supplier 1 always displays
			// supplier 2 displays if 1 has a value
			// supplier 3 displays if 3 has a value OR
			//var selectedSuppliers = [selectedSupplier1, selectedSupplier2, selectedSupplier3, selectedSupplier4];
			var showInput1 = 1;
			var showInput2 = selectedSupplier1 || selectedSupplier2 ? 2 : null;
			var showInput3 = (selectedSupplier1 && selectedSupplier2) || selectedSupplier3 ? 3 : null;
			var showInput4 =
				(selectedSupplier1 && selectedSupplier2 && selectedSupplier3) || selectedSupplier4 ? 4 : null;

			var showInput5 =
				(selectedSupplier1 && selectedSupplier2 && selectedSupplier3 && selectedSupplier4) || selectedSupplier5
					? 5
					: null;

			setShowSupplierInput([showInput1, showInput2, showInput3, showInput4, showInput5]);
		}
	}, [
		workingAudit.supplier1Name,
		workingAudit.supplier2Name,
		workingAudit.supplier3Name,
		workingAudit.supplier4Name,
		workingAudit.supplier5Name,
		suppliersFetched,
	]);

	React.useEffect(() => {
		if (suppliersFetched) {
			// determines if other input should display
			var selectedSupplier1 = findEntity(workingAudit.supplier1Name, suppliers);
			var isOther = selectedSupplier1 && selectedSupplier1.isOther;

			setShowSupplier1Other(isOther);
			!isOther && updateValue("supplier1Other", null);

			//-- 2
			var selectedSupplier2 = findEntity(workingAudit.supplier2Name, suppliers);
			var isOther = selectedSupplier2 && selectedSupplier2.isOther;

			setShowSupplier2Other(isOther);
			!isOther && updateValue("supplier2Other", null);

			//-- 3
			var selectedSupplier3 = findEntity(workingAudit.supplier3Name, suppliers);
			var isOther = selectedSupplier3 && selectedSupplier3.isOther;

			setShowSupplier3Other(isOther);
			!isOther && updateValue("supplier3Other", null);

			//-- 4
			var selectedSupplier4 = findEntity(workingAudit.supplier4Name, suppliers);
			var isOther = selectedSupplier4 && selectedSupplier4.isOther;

			setShowSupplier4Other(isOther);
			!isOther && updateValue("supplier4Other", null);

			//-- 5
			var selectedSupplier5 = findEntity(workingAudit.supplier5Name, suppliers);
			var isOther = selectedSupplier5 && selectedSupplier5.isOther;

			setShowSupplier5Other(isOther);
			!isOther && updateValue("supplier5Other", null);
		}
	}, [
		workingAudit.supplier1Name,
		workingAudit.supplier2Name,
		workingAudit.supplier3Name,
		workingAudit.supplier4Name,
		workingAudit.supplier5Name,
		suppliersFetched,
	]);

	const updateValue = (name, value) => {
		setWorkingAudit((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleDateFoundChange = (e, v) => {
		setDateFound(e);
		updateValue("dateFound", e.toDate());
	};

	return (
		<div style={{ padding: 24 }}>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<form onSubmit={handleSubmit(onSubmitAudit)} ref={auditForm} id="auditForm">
					<Grid container spacing={2} alignItems="stretch">
						<Grid item lg={3} md={3} sm={6} xs={12}>
							<TextField
								disabled
								id="standard-disabled"
								label="Country"
								defaultValue={spCase.audit.country}
								fullWidth
							/>
						</Grid>

						<Grid item lg={3} md={3} sm={6} xs={12}>
							<KeyboardDatePicker
								autoOk={true}
								name="dateFound"
								error={!!(errors && errors.dateFound)}
								animateYearScrolling={true}
								format="DD/MM/YYYY"
								label="Date Found"
								onChange={handleDateFoundChange}
								value={dateFound ? moment(dateFound) : null}
								inputRef={register({})}
								helperText={errors && errors.dateFound && errors.dateFound.message}
								style={{ width: "100%" }}
							/>
						</Grid>

						<Grid item lg={3} md={3} sm={6} xs={12}>
							<Autocomplete
								id="siteStatusAutocomplete"
								options={siteStatuses ? siteStatuses.map((r) => r.name) : []}
								getOptionLabel={(option) => option}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											name="siteStatus"
											label={"Site Status"}
											fullWidth
											inputRef={register({})}
											error={!!(errors && errors.siteStatus)}
											helperText={errors && errors.siteStatus && errors.siteStatus.message}
											//   style={{ marginBottom: 16 }}
										/>
									);
								}}
								defaultValue={workingAudit.siteStatus}
								onChange={(e, v) => updateValue("siteStatus", v)}
							/>
						</Grid>

						<Grid item lg={3} md={3} sm={6} xs={12}>
							<Autocomplete
								id="investigationTypeAutocomplete"
								options={investigationTypes ? investigationTypes.map((r) => r.name) : []}
								getOptionLabel={(option) => option}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											name="investigationType"
											label={"Investigation Type"}
											fullWidth
											inputRef={register({})}
											error={!!(errors && errors.investigationType)}
											helperText={
												errors && errors.investigationType && errors.investigationType.message
											}
											//   style={{ marginBottom: 16 }}
										/>
									);
								}}
								defaultValue={workingAudit.investigationType}
								onChange={(e, v) => updateValue("investigationType", v)}
							/>
						</Grid>

						<Grid item lg={3} md={4} sm={6} xs={12}>
							<fieldset className={classes.fieldset}>
								<legend className={classes.legend}>Suppliers</legend>
								<Grid container direction="column" spacing={1}>
									<Grid item>
										<Autocomplete
											id="supplier1Autocomplete"
											options={suppliers ? suppliers.map((r) => r.name) : []}
											getOptionLabel={(option) => option}
											renderInput={(params) => {
												return (
													<TextField
														{...params}
														name="supplier1Name"
														label={"Supplier"}
														fullWidth
														inputRef={register({})}
														error={!!(errors && errors.supplier1Name)}
														helperText={
															errors &&
															errors.supplier1Name &&
															errors.supplier1Name.message
														}
													/>
												);
											}}
											defaultValue={workingAudit.supplier1Name}
											onChange={(e, v) => updateValue("supplier1Name", v)}
										/>
									</Grid>
									{showSupplier1Other && (
										<Grid item>
											<TextField
												label="Other Supplier 1"
												fullWidth
												name="supplier1Other"
												error={!!(errors && errors.supplier1Other)}
												autoComplete="off"
												inputRef={register()}
												inputProps={{
													"aria-label": "Other Supplier",
												}}
												helperText={
													errors && errors.supplier1Other && errors.supplier1Other.message
												}
												defaultValue={workingAudit && workingAudit.supplier1Other}
												onChange={(e) => updateValue(e.target.name, e.target.value)}
											/>
										</Grid>
									)}
									{showSupplierInput[1] && (
										<>
											<Grid item>
												<Autocomplete
													id="supplier2Autocomplete"
													options={suppliers ? suppliers.map((r) => r.name) : []}
													getOptionLabel={(option) => option}
													renderInput={(params) => {
														return (
															<TextField
																{...params}
																name="supplier2Name"
																label={"Supplier"}
																fullWidth
																inputRef={register({})}
																error={!!(errors && errors.supplier2Name)}
																helperText={
																	errors &&
																	errors.supplier2Name &&
																	errors.supplier2Name.message
																}
															/>
														);
													}}
													defaultValue={workingAudit.supplier2Name}
													onChange={(e, v) => updateValue("supplier2Name", v)}
												/>
											</Grid>

											{showSupplier2Other && (
												<Grid item>
													<TextField
														label="Other Supplier 2"
														fullWidth
														name="supplier2Other"
														error={!!(errors && errors.supplier2Other)}
														autoComplete="off"
														inputRef={register()}
														inputProps={{
															"aria-label": "Other Supplier",
														}}
														helperText={
															errors &&
															errors.supplier2Other &&
															errors.supplier2Other.message
														}
														defaultValue={workingAudit && workingAudit.supplier2Other}
														onChange={(e) => updateValue(e.target.name, e.target.value)}
													/>
												</Grid>
											)}
										</>
									)}
									{showSupplierInput[2] && (
										<>
											<Grid item>
												<Autocomplete
													id="supplier3Autocomplete"
													options={suppliers ? suppliers.map((r) => r.name) : []}
													getOptionLabel={(option) => option}
													renderInput={(params) => {
														return (
															<TextField
																{...params}
																name="supplier3Name"
																label={"Supplier"}
																fullWidth
																inputRef={register({})}
																error={!!(errors && errors.supplier3Name)}
																helperText={
																	errors &&
																	errors.supplier3Name &&
																	errors.supplier3Name.message
																}
															/>
														);
													}}
													defaultValue={workingAudit.supplier3Name}
													onChange={(e, v) => updateValue("supplier3Name", v)}
												/>
											</Grid>

											{showSupplier3Other && (
												<Grid item>
													<TextField
														label="Other Supplier 3"
														fullWidth
														name="supplier3Other"
														error={!!(errors && errors.supplier3Other)}
														autoComplete="off"
														inputRef={register()}
														inputProps={{
															"aria-label": "Other Supplier",
														}}
														helperText={
															errors &&
															errors.supplier3Other &&
															errors.supplier3Other.message
														}
														defaultValue={workingAudit && workingAudit.supplier3Other}
														onChange={(e) => updateValue(e.target.name, e.target.value)}
													/>
												</Grid>
											)}
										</>
									)}
									{showSupplierInput[3] && (
										<>
											<Grid item>
												<Autocomplete
													id="supplier4Autocomplete"
													options={suppliers ? suppliers.map((r) => r.name) : []}
													getOptionLabel={(option) => option}
													renderInput={(params) => {
														return (
															<TextField
																{...params}
																name="supplier4Name"
																label={"Supplier"}
																fullWidth
																inputRef={register({})}
																error={!!(errors && errors.supplier4Name)}
																helperText={
																	errors &&
																	errors.supplier4Name &&
																	errors.supplier4Name.message
																}
															/>
														);
													}}
													defaultValue={workingAudit.supplier4Name}
													onChange={(e, v) => updateValue("supplier4Name", v)}
												/>
											</Grid>
											{showSupplier4Other && (
												<Grid item>
													<TextField
														label="Other Supplier 4"
														fullWidth
														name="supplier4Other"
														error={!!(errors && errors.supplier4Other)}
														autoComplete="off"
														inputRef={register()}
														inputProps={{
															"aria-label": "Other Supplier",
														}}
														helperText={
															errors &&
															errors.supplier4Other &&
															errors.supplier4Other.message
														}
														defaultValue={workingAudit && workingAudit.supplier4Other}
														onChange={(e) => updateValue(e.target.name, e.target.value)}
													/>
												</Grid>
											)}
										</>
									)}

									{showSupplierInput[4] && (
										<>
											<Grid item>
												<Autocomplete
													id="supplier5Autocomplete"
													options={suppliers ? suppliers.map((r) => r.name) : []}
													getOptionLabel={(option) => option}
													renderInput={(params) => {
														return (
															<TextField
																{...params}
																name="supplier5Name"
																label={"Supplier"}
																fullWidth
																inputRef={register({})}
																error={!!(errors && errors.supplier5Name)}
																helperText={
																	errors &&
																	errors.supplier5Name &&
																	errors.supplier5Name.message
																}
															/>
														);
													}}
													defaultValue={workingAudit.supplier5Name}
													onChange={(e, v) => updateValue("supplier5Name", v)}
												/>
											</Grid>
											{showSupplier5Other && (
												<Grid item>
													<TextField
														label="Other Supplier 5"
														fullWidth
														name="supplier5Other"
														error={!!(errors && errors.supplier5Other)}
														autoComplete="off"
														inputRef={register()}
														inputProps={{
															"aria-label": "Other Supplier",
														}}
														helperText={
															errors &&
															errors.supplier5Other &&
															errors.supplier5Other.message
														}
														defaultValue={workingAudit && workingAudit.supplier5Other}
														onChange={(e) => updateValue(e.target.name, e.target.value)}
													/>
												</Grid>
											)}
										</>
									)}
								</Grid>
							</fieldset>
						</Grid>

						<Grid item lg={3} md={4} sm={6} xs={12}>
							<fieldset className={classes.fieldset}>
								<legend className={classes.legend}>Sites</legend>
								<Grid container direction="column" spacing={1}>
									<Grid item>
										<Autocomplete
											id="siteFoundAutocomplete"
											options={caseSites && caseSites.map((r) => r.name)}
											getOptionLabel={(option) => option}
											renderInput={(params) => {
												return (
													<TextField
														{...params}
														name="siteFound"
														label={"Site Found"}
														fullWidth
														inputRef={register({})}
														error={!!(errors && errors.siteFound)}
														helperText={
															errors && errors.siteFound && errors.siteFound.message
														}
													/>
												);
											}}
											defaultValue={workingAudit.siteFound}
											onChange={(e, v) => updateValue("siteFound", v)}
										/>
									</Grid>

									{showOtherSiteFound && (
										<Grid item>
											<TextField
												label="Other Site Found"
												fullWidth
												name="siteFoundOther"
												error={!!(errors && errors.siteFoundOther)}
												style={{ marginBottom: 16 }}
												autoComplete="off"
												inputRef={register()}
												inputProps={{
													"aria-label": "Other Nationality",
												}}
												helperText={
													errors && errors.siteFoundOther && errors.siteFoundOther.message
												}
												defaultValue={workingAudit && workingAudit.siteFoundOther}
												onChange={(e) => updateValue(e.target.name, e.target.value)}
											/>
											<Autocomplete
												id="siteFoundOtherTierAutocomplete"
												options={tiers && tiers.map((r) => r.name)}
												getOptionLabel={(option) => option}
												renderInput={(params) => {
													return (
														<TextField
															{...params}
															name="siteFoundOtherTier"
															label={"Other Tier"}
															fullWidth
															inputRef={register({})}
															error={!!(errors && errors.siteFoundOtherTier)}
															helperText={
																errors &&
																errors.siteFoundOtherTier &&
																errors.siteFoundOtherTier.message
															}
														/>
													);
												}}
												defaultValue={workingAudit.siteFoundOtherTier}
												onChange={(e, v) => updateValue("siteFoundOtherTier", v)}
											/>
										</Grid>
									)}

									<Grid item>
										<Autocomplete
											id="linkedTier1SiteAutocomplete"
											options={caseSites && caseSites.filter((s) => s.isTier1).map((r) => r.name)}
											getOptionLabel={(option) => option}
											renderInput={(params) => {
												return (
													<TextField
														{...params}
														name="linkedTier1Site"
														label={"Linked Tier 1 Site"}
														fullWidth
														inputRef={register({})}
														error={!!(errors && errors.linkedTier1Site)}
														helperText={
															errors &&
															errors.linkedTier1Site &&
															errors.linkedTier1Site.message
														}
													/>
												);
											}}
											defaultValue={workingAudit.linkedTier1Site}
											onChange={(e, v) => updateValue("linkedTier1Site", v)}
										/>
									</Grid>
									{showOtherTier1Site && (
										<Grid item>
											<TextField
												label="Other Tier 1 Linked Site"
												fullWidth
												name="linkedTier1SiteOther"
												error={!!(errors && errors.linkedTier1SiteOther)}
												style={{ marginBottom: 16 }}
												autoComplete="off"
												inputRef={register()}
												inputProps={{
													"aria-label": "Other Nationality",
												}}
												helperText={
													errors &&
													errors.linkedTier1SiteOther &&
													errors.linkedTier1SiteOther.message
												}
												defaultValue={workingAudit && workingAudit.linkedTier1SiteOther}
												onChange={(e) => updateValue(e.target.name, e.target.value)}
											/>
										</Grid>
									)}
									<Grid item>
										<Autocomplete
											id="linkedTier2SiteAutocomplete"
											options={caseSites && caseSites.filter((s) => s.isTier2).map((r) => r.name)}
											getOptionLabel={(option) => option}
											renderInput={(params) => {
												return (
													<TextField
														{...params}
														name="linkedTier2Site"
														label={"Linked Tier 2 Site"}
														fullWidth
														inputRef={register({})}
														error={!!(errors && errors.linkedTier2Site)}
														helperText={
															errors &&
															errors.linkedTier2Site &&
															errors.linkedTier2Site.message
														}
													/>
												);
											}}
											defaultValue={workingAudit.linkedTier2Site}
											onChange={(e, v) => updateValue("linkedTier2Site", v)}
										/>
									</Grid>
									{showOtherTier2Site && (
										<Grid item>
											<TextField
												label="Other Tier 2 Linked Site"
												fullWidth
												name="linkedTier2SiteOther"
												error={!!(errors && errors.linkedTier2SiteOther)}
												style={{ marginBottom: 16 }}
												autoComplete="off"
												inputRef={register()}
												inputProps={{
													"aria-label": "Other Linked Tier 2 site",
												}}
												helperText={
													errors &&
													errors.linkedTier2SiteOther &&
													errors.linkedTier2SiteOther.message
												}
												defaultValue={workingAudit && workingAudit.linkedTier2SiteOther}
												onChange={(e) => updateValue(e.target.name, e.target.value)}
											/>
										</Grid>
									)}
									<Grid item>
										<Autocomplete
											id="linkedTier3SiteAutocomplete"
											options={caseSites && caseSites.filter((s) => s.isTier3).map((r) => r.name)}
											getOptionLabel={(option) => option}
											renderInput={(params) => {
												return (
													<TextField
														{...params}
														name="linkedTier3Site"
														label={"Linked Tier 3 Site"}
														fullWidth
														inputRef={register({})}
														error={!!(errors && errors.linkedTier3Site)}
														helperText={
															errors &&
															errors.linkedTier3Site &&
															errors.linkedTier3Site.message
														}
													/>
												);
											}}
											defaultValue={workingAudit.linkedTier3Site}
											onChange={(e, v) => updateValue("linkedTier3Site", v)}
										/>
									</Grid>
									{showOtherTier3Site && (
										<Grid item>
											<TextField
												label="Other Tier 3 Linked Site"
												fullWidth
												name="linkedTier3SiteOther"
												error={!!(errors && errors.linkedTier3SiteOther)}
												style={{ marginBottom: 16 }}
												autoComplete="off"
												inputRef={register()}
												inputProps={{
													"aria-label": "Other Linked Tier 3 site",
												}}
												helperText={
													errors &&
													errors.linkedTier3SiteOther &&
													errors.linkedTier3SiteOther.message
												}
												defaultValue={workingAudit && workingAudit.linkedTier3SiteOther}
												onChange={(e) => updateValue(e.target.name, e.target.value)}
											/>
										</Grid>
									)}
								</Grid>
							</fieldset>
						</Grid>

						<Grid item lg={6} md={4} sm={12} xs={12}>
							<fieldset className={classes.fieldset}>
								<legend className={classes.legend}>Investigation Summary</legend>
								<TextField
									// label=""
									fullWidth
									name="auditSummary"
									multiline
									autoComplete="off"
									rows={smDown ? "6" : "12"}
									error={!!(errors && errors.auditSummary)}
									inputRef={register({})}
									inputProps={{
										"aria-label": "Investigation Summary Text",
									}}
									helperText={errors && errors.auditSummary && errors.auditSummary.message}
									defaultValue={workingAudit && workingAudit.auditSummary}
									onChange={(e) => updateValue(e.target.name, e.target.value)}
								/>
							</fieldset>
						</Grid>

						{!!apiErrors && (
							<Grid item lg={6} md={4} sm={12} xs={12}>
								<div className={classes.row}>
									<div className={classes.error}>{apiErrors}</div>
								</div>
							</Grid>
						)}
						<Grid item xs={12}>
							<CaseTabEditingToolbar
								saveDisabled={isBusy || !isDirty}
								onCloseClick={finishEditing}
								formRef={auditForm && auditForm.current}
							/>
						</Grid>
					</Grid>
				</form>
			</MuiPickersUtilsProvider>
		</div>
	);
};

const CaseAuditReadOnly = ({ enableEditing, manageDocument }) => {
	const classes = useStyles();
	const { spCase, caseDispatcher, caseFetched } = React.useContext(CaseCtx);
	const { audit } = spCase;

	return !caseFetched ? null : (
		<>
			<Grid container spacing={2} alignItems="stretch">
				<Grid item sm={9} xs={12} container spacing={1}>
					<Grid item lg={3} md={4} sm={6} xs={12}>
						<div className={classes.row}>
							<div className={classes.label}>Date Found:</div>
							<div className={classes.value}>{audit.dateFound && FormatDate(audit.dateFound)}</div>
						</div>
						<div className={classes.row}>
							<div className={classes.label}>Country:</div>
							<div className={classes.value}>{audit.country}</div>
						</div>
						<div className={classes.row}>
							<div className={classes.label}>Investigation Type:</div>
							<div className={classes.value}>{audit.investigationType}</div>
						</div>
					</Grid>
					<Grid item lg={3} md={4} sm={6} xs={12}>
						<div className={classes.row}>
							<div className={classes.label}>Site Found:</div>
							<div className={classes.value}>{audit.siteFoundOther || audit.siteFound}</div>
						</div>
						{audit.siteFoundOtherTier && (
							<div className={classes.row}>
								<div className={classes.label}>Other Tier:</div>
								<div className={classes.value}>{audit.siteFoundOtherTier}</div>
							</div>
						)}
						<div className={classes.row}>
							<div className={classes.label}>Site Status at Audit:</div>
							<div className={classes.value}>{audit.siteStatus}</div>
						</div>
					</Grid>
					<Grid item lg={3} md={4} sm={6} xs={12}>
						{/* <div className={classes.row}> */}
						<div className={classes.label}>Suppliers:</div>
						<div className={classes.value} style={{ maxWidth: "100%", marginLeft: 8 }}>
							{audit.supplier1Name && (
								<div>
									{audit.supplier1Other || audit.supplier1Name}
									{audit.supplier2Name && ","}
								</div>
							)}
							{audit.supplier2Name && (
								<div>
									{audit.supplier2Other || audit.supplier2Name}
									{audit.supplier3Name && ","}
								</div>
							)}
							{audit.supplier3Name && (
								<div>
									{audit.supplier3Other || audit.supplier3Name}
									{audit.supplier4Name && ","}
								</div>
							)}
							{audit.supplier4Name && (
								<div>
									{audit.supplier4Other || audit.supplier4Name}
									{audit.supplier5Name && ","}
								</div>
							)}
							{audit.supplier5Name && <div>{audit.supplier5Other || audit.supplier5Name}</div>}
						</div>
						{/* </div> */}
					</Grid>
					<Grid item lg={3} md={4} sm={6} xs={12}>
						{audit.linkedTier1Site && (
							<div className={classes.row}>
								<div className={classes.label}>Linked Tier 1 Site:</div>
								<div className={classes.value}>
									{audit.linkedTier1SiteOther || audit.linkedTier1Site}
								</div>
							</div>
						)}

						{audit.linkedTier2Site && (
							<div className={classes.row}>
								<div className={classes.label}>Linked Tier 2 Site:</div>
								<div className={classes.value}>
									{audit.linkedTier2SiteOther || audit.linkedTier2Site}
								</div>
							</div>
						)}

						{audit.linkedTier3Site && (
							<div className={classes.row}>
								<div className={classes.label}>Linked Tier 3 Site:</div>
								<div className={classes.value}>
									{audit.linkedTier3SiteOther || audit.linkedTier3Site}
								</div>
							</div>
						)}
					</Grid>

					<Grid item xs={12}>
						<fieldset className={classes.fieldset}>
							<legend className={classes.legend}>Investigation Summary:</legend>
							<div className={classes.readOnlySummary}>
								{audit.auditSummary || <div className={classes.label}>No Summary</div>}
							</div>
						</fieldset>
					</Grid>
				</Grid>

				<Grid item sm={3} xs={12}>
					<CaseDocumentsDisplay
						title="Documents"
						caseDocuments={spCase.caseDocuments}
						manageDocument={manageDocument}
						caseDocumentType={CaseDocumentTypes.audit}
						caseId={spCase.id}
					/>
				</Grid>

				<Grid item xs={12}>
					<CaseTabReadOnlyToolbar
						onEditClick={enableEditing}
						documentType={CaseDocumentTypes.audit}
						caseId={spCase.id}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default CaseAudit;
