import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import useStyles from "../../components/Common/styles";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Blade, BladeValidationDisplay } from "../../components/Common/Blade";
import { toastr } from "../../components/Common/toastr";
import useProtectApi from "../../hooks/useProtectApi";
import { SupplierCtx } from "./supplier.manage";

const SupplierAddNote = () => {
	const classes = useStyles();
	const { register, handleSubmit, errors } = useForm();
	const history = useHistory();
	const { supplier, supplierDispatcher } = React.useContext(SupplierCtx);

	const addSupplierNoteApi = useProtectApi("supplier/addNote");

	let { supplierId } = useParams();

	const [createErrors, setCreateErrors] = useState();
	const [isBusy, setIsBusy] = useState(false);

	const closeDrawer = () => {
		history.push("/supplier/manage/" + supplierId);
	};

	const onSubmit = async (data) => {
		var request = {
			supplierId: supplierId,
			noteText: data.noteText,
		};

		const addNote = () =>
			addSupplierNoteApi.api().then((w) =>
				w
					.put(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setCreateErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setCreateErrors(errDetail);
						throw new Error(errDetail);
					})
					.json((json) => {
						var note = {
							noteId: json.noteId,
							noteDate: json.noteDate,
							createdBy: json.createdBy,
							noteText: data.noteText,
						};
						supplierDispatcher({
							type: "SUPPLIER_ADD_NEW_NOTE",
							payload: note,
						});
					})
					.then(() => toastr.success("Note added"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to add new Note");
					})
					.finally(() => {
						setIsBusy(false);
					})
			);

		setIsBusy(true);
		addNote();
	};

	return (
		<Blade title="Add Note">
			<div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<section>
						<TextField
							label="Note *"
							fullWidth
							name="noteText"
							multiline
							autoComplete="off"
							rows="6"
							error={!!(errors && errors.noteText)}
							style={{ marginBottom: 16 }}
							inputRef={register({
								required: {
									value: true,
									message: "Note Text is required",
								},
							})}
							inputProps={{
								"aria-label": "Note Text",
							}}
							helperText={errors && errors.noteText && errors.noteText.message}
						/>
					</section>
					{!!createErrors && <BladeValidationDisplay validationErrors={createErrors} />}

					<div className={classes.drawerBladeToolbar}>
						<Button
							variant="contained"
							color="primary"
							size="medium"
							type="submit"
							className={classes.button}
							disabled={isBusy}
							endIcon={<SaveIcon />}>
							SAVE
						</Button>
					</div>
				</form>
			</div>
		</Blade>
	);
};

export default SupplierAddNote;
