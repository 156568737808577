import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core";

//https://material-ui.com/customization/palette/

const theme = createTheme({
  typography: {
    fontFamily: "Arial, sans-serif",
    fontSize: 12,
  },
  overrides: {
    MuiFab: {
      root: {
        zIndex: 1050,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    yellow: {
      main: "rgb(255,206,46)",
    },
    grey: {
      light: "rgb(190,182,175)",
      default: "rgb(56,62,66)",
      contrastText: "#fff",
    },
    primary: {
      light: "rgb(116, 196, 215)",
      main: "rgb(0, 175, 219)",
      contrastText: "#fff",
    },
    secondary: {
      main: "rgb( 60, 88, 150)",
      contrastText: "#fff",
    },
    error: {
      main: "rgb(220, 98, 94)",
    },
    warning: { main: "rgb(255,206,46)" },
    info: { main: "rgb(116,196,215)" },
    success: { main: "rgb(39, 167, 112)" },
  },
});

export default ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;
