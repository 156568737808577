import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "../components/Common/toastr";
import useProtectApi from "../hooks/useProtectApi";

const useFetchRemediationPartner = () => {
  let { remediationPartnerId } = useParams();

  const dispatch = useDispatch();
  const remediationPartnerApi = useProtectApi("remediationPartner/" + remediationPartnerId);

  const [fetchedRemediationPartner, setFetchedRemediationPartner] = useState();
  const [fetched, setFetched] = useState(false);

  let remediationPartners = useSelector((state) => state.remediationPartners);

  useEffect(() => {
    let remediationPartner = remediationPartners.find((c) => c.id === remediationPartnerId);
    const fetchRemediationPartner = () => {
      remediationPartnerApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((remediationPartner) => {
            dispatch({
              type: "LOAD_TIER",
              payload: remediationPartner,
            });

            setFetchedRemediationPartner(remediationPartner);
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve RemediationPartner details.");
          })
      );
    };

    !remediationPartner ? fetchRemediationPartner() : setFetchedRemediationPartner(remediationPartner);
  }, [remediationPartnerId]);

  return [fetchedRemediationPartner, fetched];
};

const useFetchCaseCategory = () => {
  let { caseCategoryId } = useParams();

  const dispatch = useDispatch();
  const caseCategoryApi = useProtectApi("caseCategory/" + caseCategoryId);

  const [fetchedCaseCategory, setFetchedCaseCategory] = useState();
  const [fetched, setFetched] = useState(false);

  let caseCategories = useSelector((state) => state.caseCategories);

  useEffect(() => {
    let caseCategory = caseCategories.find((c) => c.id === caseCategoryId);
    const fetchCaseCategory = () => {
      caseCategoryApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((caseCategory) => {
            dispatch({
              type: "LOAD_CASE_CATEGORY",
              payload: caseCategory,
            });

            setFetchedCaseCategory(caseCategory);
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Case Category details.");
          })
      );
    };

    !caseCategory ? fetchCaseCategory() : setFetchedCaseCategory(caseCategory);
  }, [caseCategoryId]);

  return [fetchedCaseCategory, fetched];
};

const useFetchSiteStatus = () => {
  let { siteStatusId } = useParams();

  const dispatch = useDispatch();
  const siteStatusApi = useProtectApi("siteStatus/" + siteStatusId);

  const [fetchedSiteStatus, setFetchedSiteStatus] = useState();
  const [fetched, setFetched] = useState(false);

  let siteStatuses = useSelector((state) => state.siteStatuses);

  useEffect(() => {
    let siteStatus = siteStatuses.find((c) => c.id === siteStatusId);
    const fetchSiteStatus = () => {
      siteStatusApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((siteStatus) => {
            dispatch({
              type: "LOAD_SITE_STATUS",
              payload: siteStatus,
            });

            setFetchedSiteStatus(siteStatus);
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Site Status details.");
          })
      );
    };

    !siteStatus ? fetchSiteStatus() : setFetchedSiteStatus(siteStatus);
  }, [siteStatusId]);

  return [fetchedSiteStatus, fetched];
};

const useFetchEmploymentType = () => {
  let { employmentTypeId } = useParams();

  const dispatch = useDispatch();
  const employmentTypeApi = useProtectApi("employmentType/" + employmentTypeId);

  const [fetchedEmploymentType, setFetchedEmploymentType] = useState();
  const [fetched, setFetched] = useState(false);

  let employmentTypes = useSelector((state) => state.employmentTypes);

  useEffect(() => {
    let employmentType = employmentTypes.find((c) => c.id === employmentTypeId);
    const fetchEmploymentType = () => {
      employmentTypeApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((employmentType) => {
            dispatch({
              type: "LOAD_EMPLOYMENT_TYPE",
              payload: employmentType,
            });

            setFetchedEmploymentType(employmentType);
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve EmploymentType details.");
          })
      );
    };

    !employmentType ? fetchEmploymentType() : setFetchedEmploymentType(employmentType);
  }, [employmentTypeId]);

  return [fetchedEmploymentType, fetched];
};

const useFetchInvestigationType = () => {
  let { investigationTypeId } = useParams();

  const dispatch = useDispatch();
  const investigationTypeApi = useProtectApi("investigationType/" + investigationTypeId);

  const [fetchedInvestigationType, setFetchedInvestigationType] = useState();
  const [fetched, setFetched] = useState(false);

  let investigationTypes = useSelector((state) => state.investigationTypes);

  useEffect(() => {
    let investigationType = investigationTypes.find((c) => c.id === investigationTypeId);
    const fetchInvestigationType = () => {
      investigationTypeApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((investigationType) => {
            dispatch({
              type: "LOAD_INVESTIGATION_TYPE",
              payload: investigationType,
            });

            setFetchedInvestigationType(investigationType);
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve InvestigationType details.");
          })
      );
    };

    !investigationType ? fetchInvestigationType() : setFetchedInvestigationType(investigationType);
  }, [investigationTypeId]);

  return [fetchedInvestigationType, fetched];
};

const useFetchNationality = () => {
  let { nationalityId } = useParams();

  const dispatch = useDispatch();
  const nationalityApi = useProtectApi("nationality/" + nationalityId);

  const [fetchedNationality, setFetchedNationality] = useState();
  const [fetched, setFetched] = useState(false);

  let nationalities = useSelector((state) => state.nationalities);

  useEffect(() => {
    let nationality = nationalities.find((c) => c.id === nationalityId);
    const fetchNationality = () => {
      nationalityApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((nationality) => {
            dispatch({
              type: "LOAD_NATIONALITY",
              payload: nationality,
            });

            setFetchedNationality(nationality);
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Nationality details.");
          })
      );
    };

    !nationality ? fetchNationality() : setFetchedNationality(nationality);
  }, [nationalityId]);

  return [fetchedNationality, fetched];
};

const useFetchTier = () => {
  let { tierId } = useParams();

  const dispatch = useDispatch();
  const tierApi = useProtectApi("tier/" + tierId);

  const [fetchedTier, setFetchedTier] = useState();
  const [fetched, setFetched] = useState(false);

  let tiers = useSelector((state) => state.tiers);

  useEffect(() => {
    let tier = tiers.find((c) => c.id === tierId);
    const fetchTier = () => {
      tierApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((tier) => {
            dispatch({
              type: "LOAD_TIER",
              payload: tier,
            });

            setFetchedTier(tier);
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Tier details.");
          })
      );
    };

    !tier ? fetchTier() : setFetchedTier(tier);
  }, [tierId]);

  return [fetchedTier, fetched];
};

const useFetchCountry = () => {
  let { countryId } = useParams();

  const dispatch = useDispatch();
  const countryApi = useProtectApi("country/" + countryId);

  const [fetchedCountry, setFetchedCountry] = useState();
  const [fetched, setFetched] = useState(false);

  let countries = useSelector((state) => state.countries);

  useEffect(() => {
    let country = countries.find((c) => c.id === countryId);
    const fetchCountry = () => {
      countryApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((country) => {
            dispatch({
              type: "LOAD_COUNTRY",
              payload: country,
            });

            setFetchedCountry(country);
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Country details.");
          })
      );
    };

    !country ? fetchCountry() : setFetchedCountry(country);
  }, [countryId]);

  return [fetchedCountry, fetched];
};

const useFetchDocument = () => {
  let { documentId } = useParams();

  const dispatch = useDispatch();
  const documentApi = useProtectApi("document/" + documentId);

  const [fetchedDocument, setFetchedDocument] = useState();
  const [fetched, setFetched] = useState(false);

  let documents = useSelector((state) => state.documents);

  useEffect(() => {
    let document = documents.find((c) => c.id === documentId);
    const fetchDocument = () => {
      documentApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((document) => {
            dispatch({
              type: "LOAD_DOCUMENT",
              payload: document,
            });

            setFetchedDocument(document);
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Document details.");
          })
      );
    };

    !document ? fetchDocument() : setFetchedDocument(document);
  }, [documentId]);

  return [fetchedDocument, fetched];
};

const useFetchDocumentDrop = () => {
  let { dropId } = useParams();

  const dispatch = useDispatch();
  const documentDropApi = useProtectApi("documentDrop/" + dropId);

  const [fetchedDocumentDrop, setFetchedDocumentDrop] = useState();
  const [fetched, setFetched] = useState(false);

  let documentDrops = useSelector((state) => state.problemDrops);

  useEffect(() => {
    let documentDrop = documentDrops.find((c) => c.id === dropId);
    const fetchDocumentDrop = () => {
      documentDropApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((documentDrop) => {
            dispatch({
              type: "LOAD_PROBLEM_DROP",
              payload: documentDrop,
            });

            setFetchedDocumentDrop(documentDrop);
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Document Drop details.");
          })
      );
    };

    !documentDrop ? fetchDocumentDrop() : setFetchedDocumentDrop(documentDrop);
  }, [dropId]);

  return [fetchedDocumentDrop, fetched];
};

const useFetchInvestigationStatus = () => {
  let { investigationStatusId } = useParams();

  const dispatch = useDispatch();
  const investigationStatusApi = useProtectApi("investigationStatus/" + investigationStatusId);

  const [fetchedInvestigationStatus, setFetchedInvestigationStatus] = useState();
  const [fetched, setFetched] = useState(false);

  let investigationStatuses = useSelector((state) => state.investigationStatuses);

  useEffect(() => {
    let investigationStatus = investigationStatuses.find((c) => c.id === investigationStatusId);
    const fetchInvestigationStatus = () => {
      investigationStatusApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((investigationStatus) => {
            dispatch({
              type: "LOAD_INVESTIGATION_STATUS",
              payload: investigationStatus,
            });

            setFetchedInvestigationStatus(investigationStatus);
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Investigation Status details.");
          })
      );
    };

    !investigationStatus ? fetchInvestigationStatus() : setFetchedInvestigationStatus(investigationStatus);
  }, [investigationStatusId]);

  return [fetchedInvestigationStatus, fetched];
};

const useFetchRemediationStatus = () => {
  let { remediationStatusId } = useParams();

  const dispatch = useDispatch();
  const remediationStatusApi = useProtectApi("remediationStatus/" + remediationStatusId);

  const [fetchedRemediationStatus, setFetchedRemediationStatus] = useState();
  const [fetched, setFetched] = useState(false);

  let remediationStatuses = useSelector((state) => state.remediationStatuses);

  useEffect(() => {
    let remediationStatus = remediationStatuses.find((c) => c.id === remediationStatusId);
    const fetchRemediationStatus = () => {
      remediationStatusApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((remediationStatus) => {
            dispatch({
              type: "LOAD_REMEDIATION_STATUS",
              payload: remediationStatus,
            });

            setFetchedRemediationStatus(remediationStatus);
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Remediation Status details.");
          })
      );
    };

    !remediationStatus ? fetchRemediationStatus() : setFetchedRemediationStatus(remediationStatus);
  }, [remediationStatusId]);

  return [fetchedRemediationStatus, fetched];
};
const useFetchPaymentType = () => {
  let { paymentTypeId } = useParams();

  const dispatch = useDispatch();
  const paymentTypeApi = useProtectApi("paymenttype/" + paymentTypeId);

  const [fetchedPaymentType, setFetchedPaymentType] = useState();
  const [fetched, setFetched] = useState(false);

  let paymentTypes = useSelector((state) => state.paymentTypes);

  useEffect(() => {
    let paymentType = paymentTypes.find((c) => c.id === paymentTypeId);
    const fetchRemediationStatus = () => {
      paymentTypeApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((remediationStatus) => {
            dispatch({
              type: "LOAD_PAYMENT_TYPE",
              payload: remediationStatus,
            });

            setFetchedPaymentType(remediationStatus);
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Remediation Status details.");
          })
      );
    };

    !paymentType ? fetchRemediationStatus() : setFetchedPaymentType(paymentType);
  }, [paymentTypeId]);

  return [fetchedPaymentType, fetched];
};

const useFetchCurrency = () => {
  let { currencyId } = useParams();

  const dispatch = useDispatch();
  const currencyApi = useProtectApi("currency/" + currencyId);

  const [fetchedCurrency, setFetchedCurrency] = useState();
  const [fetched, setFetched] = useState(false);

  let currencies = useSelector((state) => state.currencies);

  useEffect(() => {
    let currency = currencies.find((c) => c.id === currencyId);
    const fetchCurrency = () => {
      currencyApi.api().then((w) =>
        w
          .get()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
          })
          .json((currency) => {
            dispatch({
              type: "LOAD_CURRENCY",
              payload: currency,
            });

            setFetchedCurrency(currency);
            setFetched(true);
          })
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to retrieve Currency details.");
          })
      );
    };

    !currency ? fetchCurrency() : setFetchedCurrency(currency);
  }, [currencyId]);

  return [fetchedCurrency, fetched];
};

export {
  useFetchCaseCategory,
  useFetchEmploymentType,
  useFetchInvestigationType,
  useFetchNationality,
  useFetchRemediationPartner,
  useFetchSiteStatus,
  useFetchTier,
  useFetchCountry,
  useFetchDocument,
  useFetchInvestigationStatus,
  useFetchRemediationStatus,
  useFetchDocumentDrop,
  useFetchCurrency,
  useFetchPaymentType,
};
