import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Backdrop } from "@material-ui/core";
import { useHistory, Route } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "../../components/Common/styles";
import { useFetchProblemDocumentDrops } from "../../hooks/useFetchEntities";
import FormatDate from "../../helpers/date.display.format";
import DocumentDropManage from "./problemDocumentDrops.manage";
import { dateFilterChips, DateRangeFilters } from "../../components/TableFiltersDateFilters";

function ProblemDocumentDrops() {
  const history = useHistory();
  const classes = useStyles();

  const [problemDocumentDrops, fetched] = useFetchProblemDocumentDrops();
  const [loading, setLoading] = useState(false);

  const onCellClick = (colData, cellMeta) => {
    if (problemDocumentDrops.length > 0) {
      var drop = problemDocumentDrops[cellMeta.dataIndex];
      history.push(`/problemdrops/manage/${drop.id}`);
    }
  };

  useEffect(() => {
    setLoading(!fetched);
  }, [fetched]);

  const gridOptions = {
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    filterType: "multiselect",
    selectableRows: "none",
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    onCellClick: onCellClick,
    print: false,
    download: false,
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
        if (colIndex === 6) {
          return (new Date(a.data[colIndex]) < new Date(b.data[colIndex]) ? -1 : 1) * (order === "desc" ? 1 : -1);
        } else {
          return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === "desc" ? 1 : -1);
        }
      });
    },
    textLabels: {
      body: {
        noMatch: "Sorry, none found",
      },
    },
    storageKey: "problemDocumentDrops-table",
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "countryName",
      label: "Country",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fileName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "failureMessage",
      label: "Problem",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "uploadedDateTime",
      label: "Upload Date",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          return FormatDate(value);
        },
        filterType: "custom",
        customFilterListOptions: dateFilterChips("Upload Date"),
        filterOptions: DateRangeFilters,
      },
    },
    {
      name: "uploadedBy",
      label: "Uploaded By",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  return (
    <div style={{ marginRight: 32 }}>
      {!loading && (
        <MUIDataTable
          title={"Problems Documents"}
          data={problemDocumentDrops}
          columns={gridColumns}
          options={gridOptions}
        />
      )}

      <Route path="/problemdrops/manage/:dropId">
        <DocumentDropManage />
      </Route>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default ProblemDocumentDrops;
