const apiVersion = "v1";

const local = {
  API: {
    DATA: "https://app-intellync-protect-api-dev.azurewebsites.net/api/" + apiVersion,
  },
  Auth: {
    ClientId: "ccefa20f-f61f-491d-a609-7ee850b091df",
    ApiAppId: "https://IntellyncProtectDev.onmicrosoft.com/primarkapi/",
    DirectoryName: "IntellyncProtectDev",
  },
};

const dev = {
  API: {
    DATA: "https://app-intellync-protect-api-dev.azurewebsites.net/api/" + apiVersion,
  },
  Auth: {
    ClientId: "ccefa20f-f61f-491d-a609-7ee850b091df",
    ApiAppId: "https://IntellyncProtectDev.onmicrosoft.com/primarkapi/",
    DirectoryName: "IntellyncProtectDev",
  },
};

const uat = {
  API: {
    DATA: "https://app-intellync-protect-api-qa.azurewebsites.net/api/" + apiVersion,
  },
  Auth: {
    ClientId: "924d564e-0f78-416f-b6d4-843ae2621b2e",
    ApiAppId: "https://IntellyncProtectQA.onmicrosoft.com/primarkapi/",
    DirectoryName: "IntellyncProtectQA",
  },
};

const prod = {
  API: {
    DATA: "https://app-intellync-protect-sp-api-prod-uks.azurewebsites.net/api/" + apiVersion,
  },
  Auth: {
    ClientId: "377761a5-e9f1-474a-b6c4-20e2a10fd7ed",
    ApiAppId: "https://IntellyncProtectSpd.onmicrosoft.com/protect-spd-api/",
    DirectoryName: "IntellyncProtectSpd",
  },
};

console.log("ENVIRONMENT: ", process.env.REACT_APP_STAGE);

const config =
  process.env.REACT_APP_STAGE === "production"
    ? prod
    : process.env.REACT_APP_STAGE === "uat"
    ? uat
    : process.env.REACT_APP_STAGE === "development"
    ? dev
    : local;

const API_URL = config.API.DATA;
const AuthConfig = config.Auth;

export { API_URL, AuthConfig };
export default config;
