import React, { useRef } from "react";
import {
  Paper,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import useStyles from "./styles";
import { useFetchYesNoAnswers } from "../../hooks/useFetchEntities";
import useProtectApi from "../../hooks/useProtectApi";
import { Autocomplete } from "@material-ui/lab";
import { toastr } from "../../components/Common/toastr";
import { useForm } from "react-hook-form";
import { CaseCtx, CaseTabReadOnlyToolbar, CaseTabEditingToolbar, CaseDocumentsDisplay } from "./case.manage";
import { useDispatch } from "react-redux";
import { CaseDocumentTypes } from "./case.document.types";

const CaseClosure = ({ tabIndex, beginEdit, finishEdit, manageDocument }) => {
  const [editing, setEditing] = React.useState(false);

  const enableEditing = () => {
    setEditing(true);
    beginEdit(tabIndex);
  };

  const finishEditing = () => {
    setEditing(false);
    finishEdit(tabIndex);
  };

  return editing ? (
    <CaseClosureEditing finishEditing={finishEditing} />
  ) : (
    <CaseClosureReadOnly enableEditing={enableEditing} manageDocument={manageDocument} />
  );
};

const CaseClosureEditing = ({ finishEditing }) => {
  const { spCase, caseDispatcher } = React.useContext(CaseCtx);

  const dispatch = useDispatch();
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [yesNoAnswers] = useFetchYesNoAnswers();
  const saveClosureApi = useProtectApi("case/closure");
  const closureForm = useRef();

  const [workingClosure, setWorkingClosure] = React.useState(spCase.closure);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isBusy, setIsBusy] = React.useState(false);
  const [showConfirmClosureDialog, setShowConfirmClosureDialog] = React.useState(false);
  const [apiErrors, setApiErrors] = React.useState();

  const closeConfirmClosureDialog = () => {
    setShowConfirmClosureDialog(false);
  };

  const confirmCloseCaseClick = async () => {
    await SaveClosure();
  };

  const SaveClosure = async () => {
    var request = {
      caseId: spCase.id,
      closure: workingClosure,
    };

    const saveClosure = () =>
      saveClosureApi.api().then((w) =>
        w
          .put(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .json((json) => {
            caseDispatcher({
              type: "CASE_MODIFY_CLOSURE",
              payload: workingClosure,
            });

            workingClosure.closureApproved &&
              dispatch({
                type: "CASE_CLOSURE_APPROVED",
                payload: {
                  id: spCase.id,
                  closureApproved: workingClosure.closureApproved,
                },
              });
          })
          .then(() => toastr.success("Closure details changed"))
          .then(() => setIsDirty(false))
          .then(() => finishEditing())
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to save Closure Information.");
          })
          .finally(() => setIsBusy(false))
      );

    setIsBusy(true);
    saveClosure();
  };

  const onSubmitClosure = async (data, e) => {
    e.preventDefault();

    if (workingClosure.closureApproved === "Yes") {
      setShowConfirmClosureDialog(true);
      return;
    }

    await SaveClosure();
  };

  React.useEffect(() => {
    setIsDirty(JSON.stringify(spCase.closure) !== JSON.stringify(workingClosure));
  }, [spCase.closure, workingClosure]);

  const updateValue = (name, value) => {
    setWorkingClosure((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitClosure)} ref={closureForm} id="closureForm">
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={8} container spacing={1}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <section name="Approved">
                <Autocomplete
                  id="closureApprovedAutocomplete"
                  options={yesNoAnswers && yesNoAnswers.map((r) => r.name)}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        name="closureApproved"
                        label={"Closure Approved"}
                        fullWidth
                        inputRef={register({})}
                        error={!!(errors && errors.closureApproved)}
                        helperText={errors && errors.closureApproved && errors.closureApproved.message}
                        style={{ marginBottom: 16 }}
                      />
                    );
                  }}
                  defaultValue={workingClosure.closureApproved}
                  onChange={(e, v) => updateValue("closureApproved", v)}
                />
              </section>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <fieldset className={classes.fieldset}>
                <legend className={classes.legend}>Closure Summary</legend>
                <section name="Summary">
                  <TextField
                    label="Closure Summary"
                    fullWidth
                    name="closureSummary"
                    multiline
                    autoComplete="off"
                    rows="12"
                    error={!!(errors && errors.closureSummary)}
                    style={{ marginBottom: 16 }}
                    inputRef={register({})}
                    inputProps={{
                      "aria-label": "Closure Summary Text",
                    }}
                    helperText={errors && errors.closureSummary && errors.closureSummary.message}
                    defaultValue={workingClosure && workingClosure.closureSummary}
                    onChange={(e) => updateValue(e.target.name, e.target.value)}
                  />
                </section>
              </fieldset>
            </Grid>

            {!!apiErrors && (
              <Grid item lg={6} md={4} sm={12} xs={12}>
                <div className={classes.row}>
                  <div className={classes.error}>{apiErrors}</div>
                </div>
              </Grid>
            )}
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={12}>
            <CaseTabEditingToolbar
              saveDisabled={isBusy || !isDirty}
              onCloseClick={finishEditing}
              formRef={closureForm && closureForm.current}
            />
          </Grid>
        </Grid>
      </form>

      {showConfirmClosureDialog && (
        <Dialog
          open={showConfirmClosureDialog}
          onClose={() => setShowConfirmClosureDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirm Closure</DialogTitle>
          {/* <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to close Case {spCase.number}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeConfirmClosureDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={() => confirmCloseCaseClick()} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions> */}
          <ConfirmCloseContent
            caseNumber={spCase.number}
            closeConfirmClosureDialog={closeConfirmClosureDialog}
            confirmCloseCaseClick={confirmCloseCaseClick}
          />
        </Dialog>
      )}
    </>
  );
};

const CaseClosureReadOnly = ({ enableEditing, manageDocument }) => {
  const classes = useStyles();
  const { spCase } = React.useContext(CaseCtx);

  return (
    <>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item md={8} sm={12} xs={12} container spacing={1}>
          <Grid item xs={12}>
            {/* <div className={classes.row}>
							<div className={classes.label} style={{ minWidth: 160 }}>
								Partner confirms consent obtained:
							</div>
							<div className={classes.value}>{spCase.needsAssessment.consentObtained}</div>
						</div> */}
            <div className={classes.row}>
              <div className={classes.label} style={{ minWidth: 100 }}>
                Closure Approved:
              </div>
              <div className={classes.value}>{spCase.closure.closureApproved}</div>
            </div>
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}></Grid>

          <Grid item xs={12}>
            <fieldset className={classes.fieldset}>
              <legend className={classes.legend}>Summary of Closing Interviews:</legend>
              <div className={classes.readOnlySummary}>
                {spCase.closure.closureSummary || <div className={classes.label}>No Summary</div>}
              </div>
            </fieldset>
          </Grid>
          {/* 
					<div>
						<Typography variant="body1">Summary of Closing Interviews:</Typography>

						<Typography variant="body1" className={classes.readonlyFieldText}>
							<span className={classes.readOnlySummary}>{spCase.closure.closureSummary}</span>
						</Typography>
					</div> */}
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <CaseDocumentsDisplay
            title="Documents"
            caseDocuments={spCase.caseDocuments}
            manageDocument={manageDocument}
            caseDocumentType={CaseDocumentTypes.closure}
            caseId={spCase.id}
          />
        </Grid>

        <Grid item xs={12}>
          <CaseTabReadOnlyToolbar
            onEditClick={enableEditing}
            caseId={spCase.id}
            documentType={CaseDocumentTypes.closure}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CaseClosure;

const ConfirmCloseContent = ({ caseNumber, confirmCloseCaseClick, closeConfirmClosureDialog }) => {
  const [hasRemeidied, setHasRemeidied] = React.useState("");
  if (!hasRemeidied) {
    return (
      <>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Have you amended the Remediation Status to reflect the status at time of closure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHasRemeidied("no")} color="primary">
            No
          </Button>
          <Button onClick={() => setHasRemeidied("yes")} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </>
    );
  }

  if (hasRemeidied === "no") {
    return (
      <>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please amend the Remediation Status before proceeding.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmClosureDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </>
    );
  }

  if (hasRemeidied === "yes") {
    return (
      <>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to close Case {caseNumber}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmClosureDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={() => confirmCloseCaseClick()} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </>
    );
  }
};
