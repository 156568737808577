import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { Container, Card, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import useProtectApi from "../../hooks/useProtectApi";
import { toastr } from "../../components/Common/toastr";
import Spinner from "../../components/Common/Spinner";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { CaseFilters } from "../cases/case.filters";
import { DocumentFilters } from "../documents/document.filters";
import { ProblemDocumentDropFilters } from "../problemdrops/problemDocumentDrop.filters";

const Home = () => {
  const user = useSelector((state) => state.user);

  const [showDashboard, setShowdashboard] = React.useState(false);

  useEffect(() => {
    user && user.displayName && setShowdashboard(true);
  }, [user]);

  useEffect(() => {
    user && setShowdashboard(user.hasAppLicense && user.isAuthorized);
  }, [user]);

  return (
    <div>
      {showDashboard && <Container style={{ marginBottom: 48 }}>{showDashboard && <UserDashboard />}</Container>}
    </div>
  );
};

export default Home;

const UserDashboard = () => {
  const metricsApi = useProtectApi("metrics");

  const [loading, setLoading] = useState(true);
  const [fetched, setFetched] = useState(false);
  const [fetchedMetrics, setFetchedMetrics] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    const fetchMetrics = () => {
      setLoading(true);
      metricsApi.api().then((w) =>
        w
          .get()
          .json((json) => setFetchedMetrics(json))
          .then(() => setFetched(true))
          .catch((error) => toastr.error("Unable to retrieve Dashboard Metrics"))
          .finally(() => setLoading(false))
      );
    };

    !fetched && fetchMetrics();
  }, [fetched]);

  return !fetched ? null : (
    <>
      <div className={classes.root}>
        {fetchedMetrics.map((m, index) => (
          <MetricWidget metric={m} key={m.title} index={index} />
        ))}
      </div>

      <Spinner show={loading} />
    </>
  );
};

const MetricWidget = ({ metric, index }) => {
  const history = useHistory();
  const classes = useStyles();
  const variants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  const navigateTo = (metric) => {
    switch (metric.code) {
      case CaseFilters.critical:
      case CaseFilters.closureDue:
      case CaseFilters.invDue:
      case CaseFilters.mouDue:
      case CaseFilters.needsDue:
      case CaseFilters.open:
      case CaseFilters.overdue:
      case CaseFilters.remDue:
        history.push("/cases?filter=" + metric.code);
        break;

      case DocumentFilters.unprocessed:
        history.push("/documents");
        break;
      case ProblemDocumentDropFilters.problem:
        history.push("/problemdrops");
        break;
      default:
        history.push("/cases");
        break;
    }
  };

  return !metric ? null : (
    <Card
      elevation={1}
      raised={true}
      component={motion.a}
      onClick={() => navigateTo(metric)}
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ duration: 1, delay: 0.1 * index }}
      whileHover={{
        scale: 1.05,
        transition: { duration: 0.3 },
      }}
      whileTap={{ scale: 0.95 }}
      className={classes.metric}
    >
      <Typography className={classes.number}>{metric.value}</Typography>
      <Typography className={classes.title}>{metric.title}</Typography>
    </Card>
  );
};
