import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "start",
  },
  metric: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    width: 200,
    maxWidth: 300,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: `calc(50% - ${theme.spacing(4)}px)`,
    },
  },
  title: {
    fontSize: "1rem",
    lineHeight: "1rem",
    color: theme.palette.yellow.main,
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: 300,
    letterSpacing: "-.05rem",
    margin: 0,
  },
  number: {
    fontSize: "5rem",
    lineHeight: "5.5rem",
    fontWeight: 900,
    color: "#fff",
  },
}));
