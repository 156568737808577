import React, { useState } from "react";
import useStyles from "../../components/Common/styles";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toastr } from "../../components/Common/toastr";
import { Blade, BladeValidationDisplay, BladeTabPanel } from "../../components/Common/Blade";
import { TextField, Tab, Tabs, Grid, Typography, Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import useProtectApi from "../../hooks/useProtectApi";
import { useFetchPaymentType } from "../../hooks/useFetchEntity";
import { DeleteButton } from "../../components/Common/Buttons";

const RemediationStatusManage = () => {
  const [value, setValue] = React.useState(0);
  const [localPaymentType] = useFetchPaymentType();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }
  return !localPaymentType ? (
    <div></div>
  ) : (
    <Blade>
      <Tabs value={value} onChange={handleChange} aria-label="user tabs" variant="fullWidth" indicatorColor="primary">
        <Tab label="Details" {...a11yProps(0)} />
        <Tab label="Remove" {...a11yProps(1)} />
      </Tabs>
      <BladeTabPanel value={value} index={0}>
        <PaymentTypeDetail paymentType={localPaymentType} />
      </BladeTabPanel>
      <BladeTabPanel value={value} index={1}>
        <RemovePaymentType paymentType={localPaymentType} />
      </BladeTabPanel>
    </Blade>
  );
};

const PaymentTypeDetail = ({ paymentType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const updatePaymentType = useProtectApi(`paymenttype/${paymentType.id}`);
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  const [isBusy, setIsBusy] = useState(false);
  const [apiErrors, setApiErrors] = useState();

  const onSubmit = async (data) => {
    var request = {
      id: paymentType.id,
      name: data.name,
    };

    const update = () =>
      updatePaymentType.api().then((w) =>
        w
          .put(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .json((json) => {
            var modifiedRemediationStatus = {
              ...request,
            };

            dispatch({
              type: "RENAME_PAYMENT_TYPE",
              payload: modifiedRemediationStatus,
            });

            return modifiedRemediationStatus;
          })
          .then(() => toastr.success("Payment Type details changed"))
          .then(() => closeDrawer())
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to change Payment Type name.");
          })
          .finally(() => setIsBusy(false))
      );

    setIsBusy(true);
    update();
  };

  const closeDrawer = () => {
    history.goBack();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section>
          <TextField
            label="Name *"
            fullWidth
            autoFocus
            name="name"
            error={!!(errors && errors.name)}
            style={{ marginBottom: 16 }}
            autoComplete="off"
            inputRef={register({
              required: {
                value: true,
                message: "Name is required",
              },
              maxLength: {
                value: 255,
                message: "Name is too long",
              },
            })}
            inputProps={{
              "aria-label": "Name",
            }}
            helperText={errors && errors.name && errors.name.message}
            defaultValue={paymentType.name}
          />
        </section>

        {!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}

        <div className={classes.drawerBladeToolbar}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            type="submit"
            className={classes.button}
            endIcon={<SaveIcon />}
            disabled={isBusy}
          >
            SAVE
          </Button>
        </div>
      </form>
    </div>
  );
};

const RemovePaymentType = ({ paymentType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const removePaymentTypeApi = useProtectApi(`paymenttype/${paymentType.id}`);
  const [isBusy, setIsBusy] = useState(false);
  const [apiErrors, setApiErrors] = useState();

  const closeDrawer = () => {
    history.goBack();
  };

  const removePaymentTypeClick = () => {
    var request = {
      Id: paymentType.id,
    };

    const apiDelete = () =>
      removePaymentTypeApi.api().then((w) =>
        w
          .delete()
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .res(() => {
            dispatch({
              type: "REMOVE_PAYMENT_TYPE",
              payload: paymentType,
            });
          })
          .then(() => toastr.success("Payment Type removed"))
          .then(() => closeDrawer())
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to remove Payment Type.");
          })
      );

    setIsBusy(true);
    apiDelete();
  };

  return (
    <div>
      <Typography>
        <p>Remove Payment Type '{paymentType.name}' from the application?</p>
        <p>If you do so this Payment Type will no longer be available.</p>
      </Typography>

      <div className={classes.drawerBladeToolbar}>
        <DeleteButton
          variant="outlined"
          color="primary"
          size="medium"
          onClick={() => removePaymentTypeClick()}
          className={classes.button}
          disabled={isBusy}
          text="Remove"
        />
      </div>

      {!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}
    </div>
  );
};

export default RemediationStatusManage;
