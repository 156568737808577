import React, { useState } from "react";
import useStyles from "../../components/Common/styles";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toastr } from "../../components/Common/toastr";
import { Blade, BladeTabPanel, BladeValidationDisplay } from "../../components/Common/Blade";
import {
	TextField,
	Tab,
	Tabs,
	Grid,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Box,
	Typography,
	Button,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import useProtectApi from "../../hooks/useProtectApi";
import { useFetchCountry } from "../../hooks/useFetchEntity";
import { DeleteButton } from "../../components/Common/Buttons";

const CountryManage = () => {
	const classes = useStyles();
	const history = useHistory();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const [localCountry] = useFetchCountry();

	const closeDrawer = () => {
		history.goBack();
	};

	function a11yProps(index) {
		return {
			id: `tab-${index}`,
			"aria-controls": `tabpanel-${index}`,
		};
	}
	return !localCountry ? (
		<div></div>
	) : (
		<Blade>
			<Tabs
				value={value}
				onChange={handleChange}
				aria-label="user tabs"
				variant="fullWidth"
				indicatorColor="primary">
				<Tab label="Details" {...a11yProps(0)} />
				<Tab label="Remove" {...a11yProps(1)} />
			</Tabs>
			<BladeTabPanel value={value} index={0}>
				<CountryDetails country={localCountry} />
			</BladeTabPanel>
			<BladeTabPanel value={value} index={1}>
				<RemoveCountry country={localCountry} />
			</BladeTabPanel>
		</Blade>
	);
};

const CountryDetails = ({ country }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const countryModifyApi = useProtectApi("country/modify");
	const { register, handleSubmit, errors } = useForm();
	const history = useHistory();

	const [isBusy, setIsBusy] = useState(false);
	const [apiErrors, setApiErrors] = useState();

	const onSubmit = async (data) => {
		var request = {
			id: country.id,
			name: data.name,
			code: data.code,
		};

		const modifyCountry = () =>
			countryModifyApi.api().then((w) =>
				w
					.put(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.json((json) => {
						var modifiedCountry = {
							...request,
						};

						dispatch({
							type: "COUNTRY_MODIFIED",
							payload: modifiedCountry,
						});

						dispatch({
							type: "USER_COUNTRY_MODIFIED",
							payload: modifiedCountry,
						});

						return modifiedCountry;
					})
					.then(() => toastr.success("Country details changed"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to change Country details.");
					})
					.finally(() => setIsBusy(false))
			);

		setIsBusy(true);
		modifyCountry();
	};

	const closeDrawer = () => {
		history.goBack();
	};

	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<section>
					<TextField
						label="Name *"
						fullWidth
						autoFocus
						name="name"
						error={!!(errors && errors.name)}
						style={{ marginBottom: 16 }}
						autoComplete="off"
						inputRef={register({
							required: {
								value: true,
								message: "Name is required",
							},
							maxLength: {
								value: 255,
								message: "Name is too long",
							},
						})}
						inputProps={{
							"aria-label": "Name",
						}}
						helperText={errors && errors.name && errors.name.message}
						defaultValue={country.name}
					/>
				</section>

				<section>
					<TextField
						label="Code *"
						fullWidth
						name="code"
						error={!!(errors && errors.code)}
						autoComplete="off"
						inputRef={register({
							required: {
								value: true,
								message: "Code is required",
							},
							minLength: {
								value: 3,
								message: "Code must be 3 characters only",
							},
							maxLength: {
								value: 3,
								message: "Code must be 3 characters only",
							},
						})}
						inputProps={{
							"aria-label": "Description",
						}}
						helperText={errors && errors.code && errors.code.message}
						style={{ marginBottom: 16 }}
						defaultValue={country.code}
					/>
				</section>

				<div style={{ marginBottom: 12 }}>
					<Typography>Case Number Prefix: '{country.caseNumberPrefix}'</Typography>
				</div>

				<div style={{ marginBottom: 12 }}>
					{!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}
				</div>

				<div className={classes.drawerBladeToolbar}>
					<Button
						variant="contained"
						color="primary"
						size="medium"
						type="submit"
						className={classes.button}
						endIcon={<SaveIcon />}
						disabled={isBusy}>
						SAVE
					</Button>
				</div>
			</form>
		</div>
	);
};

const RemoveCountry = ({ country }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const removeCountryApi = useProtectApi("country/remove");
	const [isBusy, setIsBusy] = useState(false);
	const [apiErrors, setApiErrors] = useState();

	const [showRemoveConfirmDialog, setShowRemoveConfirmDialog] = useState(false);

	const closeDrawer = () => {
		history.goBack();
	};

	const closeRemoveDialog = () => {
		setShowRemoveConfirmDialog(false);
	};

	const confirmRemoveCountryClick = () => {
		var request = {
			Id: country.id,
		};

		const apiDelete = () =>
			removeCountryApi.api().then((w) =>
				w
					.put(country)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.res(() => {
						dispatch({
							type: "REMOVE_COUNTRY",
							payload: country,
						});
					})
					.then(() => toastr.success("Country removed"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						setShowRemoveConfirmDialog(false);
						toastr.error("Unable to remove Country.");
					})
			);

		setIsBusy(true);
		apiDelete();
	};

	return (
		<div>
			<Typography>
				Remove country '{country.name}' from the application? If you do so this country will no longer be
				available for selection for new cases in the application.
			</Typography>

			<div className={classes.drawerBladeToolbar}>
				<DeleteButton
					variant="outlined"
					color="primary"
					size="medium"
					onClick={() => setShowRemoveConfirmDialog(true)}
					className={classes.button}
					disabled={isBusy}
					text="Remove"
				/>
			</div>
			{!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}
			{showRemoveConfirmDialog && country && (
				<div>
					<Dialog
						open={showRemoveConfirmDialog}
						onClose={() => showRemoveConfirmDialog}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description">
						<DialogTitle id="alert-dialog-title">Remove</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								Are you sure you want to remove {country.name}?
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={closeRemoveDialog} color="primary">
								Cancel
							</Button>
							<Button onClick={() => confirmRemoveCountryClick()} color="primary" autoFocus>
								Confirm
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			)}
		</div>
	);
};

export default CountryManage;
