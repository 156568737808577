import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Container, Fab, Tooltip, Backdrop } from "@material-ui/core";
import { Link, useHistory, Route } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "../../components/Common/styles";
import RemediationPartnerCreate from "./rempart.create";
import RemediationPartnerManage from "./rempart.manage";
import { useFetchRemediationPartners } from "../../hooks/useFetchEntities";

function RemediationPartners() {
  const history = useHistory();
  const classes = useStyles();
  const [remediationPartners, fetched] = useFetchRemediationPartners();
  const [loading, setLoading] = useState(false);

  const onRowClick = (rowData, rowMeta) => {
    history.push("/remediationpartners/manage/" + rowData[0]);
  };

  useEffect(() => {
    setLoading(!fetched);
  }, [fetched]);

  const gridOptions = {
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    filterType: "multiselect",
    selectableRows: "none",
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    onRowClick: onRowClick,
    print: false,
    download: false,
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
  ];

  const fabStyle = {
    right: 20,
    position: "fixed",
  };

  return (
    <div style={{ marginRight: 32 }}>
      <Tooltip title="Add" aria-label="add">
        <Fab color="primary" aria-label="add" style={fabStyle} component={Link} to={"/remediationpartners/create"}>
          <AddIcon />
        </Fab>
      </Tooltip>
      {!loading && (
        <MUIDataTable
          title={"Remediation Partners"}
          data={remediationPartners}
          columns={gridColumns}
          options={gridOptions}
        />
      )}

      <Route exact path="/remediationpartners/manage/:remediationPartnerId">
        <RemediationPartnerManage />
      </Route>
      <Route path="/remediationpartners/create">
        <RemediationPartnerCreate />
      </Route>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default RemediationPartners;
