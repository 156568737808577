import React, { useState } from "react";
import { Button, TextField, Typography, Switch, FormControlLabel } from "@material-ui/core";
import useStyles from "../../components/Common/styles";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Blade, BladeValidationDisplay } from "../../components/Common/Blade";
import { toastr } from "../../components/Common/toastr";
import useProtectApi from "../../hooks/useProtectApi";
import { useFetchCountries } from "../../hooks/useFetchEntities";

const CountryCreate = () => {
	const classes = useStyles();
	const { register, handleSubmit, errors } = useForm();
	const history = useHistory();
	const dispatch = useDispatch();
	const countryCreateApi = useProtectApi("country/create");
	const [countries] = useFetchCountries();

	const [createErrors, setCreateErrors] = useState();
	const [isBusy, setIsBusy] = useState(false);
	const [showDuplicatePrefixWarning, setShowDuplicatePrefixWarning] = useState(false);
	const [duplicatePrefix, setDuplicatePrefix] = useState("");
	const [countryMatches, setCountryMatches] = useState([]);

	const confirmCreateCountry = async (formData) => {
		var countryRequest = {
			name: formData.name,
			code: formData.code,
			caseNumberPrefix: formData.prefix,
		};

		const createCountry = () =>
			countryCreateApi.api().then((w) =>
				w
					.post(countryRequest)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setCreateErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setCreateErrors(errDetail);
						throw new Error(errDetail);
					})
					.json((json) => {
						countryRequest.id = json.id;
						dispatch({
							type: "CREATE_COUNTRY",
							payload: countryRequest,
						});
					})
					.then(() => toastr.success("Country created"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to create Country.");
					})
					.finally(() => setIsBusy(false))
			);
		setIsBusy(true);
		createCountry();
	};

	const onSubmit = async (data) => {
		var matchingPrefixCountries = (Array.isArray(countries) && countries.length > 0 ? countries : []).filter(
			(c) => c.caseNumberPrefix === data.prefix
		);

		if (matchingPrefixCountries.length > 0) {
			if (data.confirmDuplicatePrefix) {
				await confirmCreateCountry(data);
			} else {
				setDuplicatePrefix(data.prefix);
				setCountryMatches(matchingPrefixCountries);
				setShowDuplicatePrefixWarning(true);
			}
		} else {
			await confirmCreateCountry(data);
		}
	};

	const closeDrawer = () => {
		history.push("/countries");
	};

	return (
		<Blade title="Create Country">
			<div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<TextField
						label="Name *"
						fullWidth
						autoFocus
						name="name"
						error={!!(errors && errors.name)}
						autoComplete="off"
						style={{ marginBottom: 16 }}
						inputRef={register({
							required: {
								value: true,
								message: "Name is required",
							},
							maxLength: {
								value: 255,
								message: "Name is too long",
							},
						})}
						inputProps={{
							"aria-label": "Description",
						}}
						helperText={errors && errors.name && errors.name.message}
					/>

					<TextField
						label="Code *"
						fullWidth
						name="code"
						error={!!(errors && errors.code)}
						autoComplete="off"
						inputRef={register({
							required: {
								value: true,
								message: "Code is required",
							},
							minLength: {
								value: 3,
								message: "Code must be 3 characters only",
							},
							maxLength: {
								value: 3,
								message: "Code must be 3 characters only",
							},
						})}
						inputProps={{
							"aria-label": "Description",
						}}
						helperText={errors && errors.code && errors.code.message}
						style={{ marginBottom: 16 }}
					/>

					<TextField
						label="Case Number Prefix *"
						fullWidth
						name="prefix"
						error={!!(errors && errors.prefix)}
						autoComplete="off"
						inputRef={register({
							required: {
								value: true,
								message: "Case Number Prefix is required",
							},
							maxLength: {
								value: 255,
								message: "Case Number Prefix must be 3 characters only",
							},
						})}
						inputProps={{
							"aria-label": "Description",
						}}
						helperText={errors && errors.prefix && errors.prefix.message}
						style={{ marginBottom: 16 }}
					/>

					{showDuplicatePrefixWarning && (
						<div>
							<Typography>
								The Case Number Prefix '{duplicatePrefix}' is already in use for the following
								countries.
								<ul>
									{countryMatches.map((c) => {
										return <li>{c.name}</li>;
									})}
								</ul>
							</Typography>

							<div>
								<FormControlLabel
									labelPlacement="start"
									control={
										<Switch
											color="secondary"
											name="confirmDuplicatePrefix"
											inputRef={register({})}
										/>
									}
									label={"Confirm you wish to use '" + duplicatePrefix + "'"}
								/>
							</div>
						</div>
					)}
					{!!createErrors && <BladeValidationDisplay validationErrors={createErrors} />}

					<div className={classes.drawerBladeToolbar}>
						<Button
							variant="contained"
							color="primary"
							size="medium"
							type="submit"
							className={classes.button}
							endIcon={<SaveIcon />}
							disabled={isBusy}>
							SAVE
						</Button>
					</div>
				</form>
			</div>
		</Blade>
	);
};

export default CountryCreate;
