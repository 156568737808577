import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	row: {
		display: "flex",
		alignItems: "baseline",
	},
	outline: {
		borderStyle: "solid",
		borderWidth: 0.5,
		borderColor: theme.palette.grey.light,
	},
	value: {
		fontSize: 13,
		fontWeight: "bold",
		whiteSpace: "pre-wrap",
		// color: theme.palette.secondary.main,
	},
	label: {
		fontSize: "0.8rem",
		fontWeight: "normal",
		color: theme.palette.grey.default,
		opacity: 0.7,
		marginRight: 4,
		width: 80,
		minWidth: 80,
	},
	legend: {
		fontSize: "0.8rem",
		fontWeight: "normal",
		color: theme.palette.grey.default,
	},
	toolbar: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: 16,
		paddingTop: 16,
		borderTopStyle: "solid",
		borderTopWidth: 0.5,
		borderTopColor: theme.palette.primary.main,
	},
	section: {
		borderStyle: "solid",
		borderWidth: 0.5,
		borderColor: theme.palette.grey.light,
		padding: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	red: { color: "red", fontSize: 10 },
}));
