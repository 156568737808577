import React, { useRef } from "react";
import { Button, Paper, Grid, TextField, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { useFetchYesNoAnswers, useFetchRemediationPartners } from "../../hooks/useFetchEntities";
import useProtectApi from "../../hooks/useProtectApi";
import { Autocomplete } from "@material-ui/lab";
import { toastr } from "../../components/Common/toastr";
import { useForm } from "react-hook-form";
import { CaseCtx, CaseTabReadOnlyToolbar, CaseTabEditingToolbar, CaseDocumentsDisplay } from "./case.manage";
import { useDispatch } from "react-redux";
import moment from "moment";
import { CaseDocumentTypes, GetSectionCaseDocuments } from "./case.document.types";

const CaseNeedsAssessment = ({ tabIndex, beginEdit, finishEdit, manageDocument }) => {
	const [editing, setEditing] = React.useState(false);

	const enableEditing = () => {
		setEditing(true);
		beginEdit(tabIndex);
	};

	const finishEditing = () => {
		setEditing(false);
		finishEdit(tabIndex);
	};

	return editing ? (
		<CaseNeedsAssessmentEditing finishEditing={finishEditing} />
	) : (
		<CaseNeedsAssessmentReadOnly enableEditing={enableEditing} manageDocument={manageDocument} />
	);
};
const CaseNeedsAssessmentEditing = ({ finishEditing }) => {
	const { spCase, caseDispatcher } = React.useContext(CaseCtx);

	const dispatch = useDispatch();
	const classes = useStyles();
	const { register, handleSubmit, errors } = useForm();
	const [yesNoAnswers] = useFetchYesNoAnswers();
	const [remediationPartners] = useFetchRemediationPartners();
	const saveNeedsAssessmentApi = useProtectApi("case/needsAssessment");
	const needsAssessmentForm = useRef();

	const [workingNeedsAssessment, setWorkingNeedsAssessment] = React.useState(spCase.needsAssessment);
	const [isDirty, setIsDirty] = React.useState(false);
	const [isBusy, setIsBusy] = React.useState(false);
	const [apiErrors, setApiErrors] = React.useState();

	const onSubmitNeedsAssessment = async (data, e) => {
		e.preventDefault();

		// find the remediation partner id
		var selectedRemediationPartner = remediationPartners.find((x) => x.name === data.remediationPartner);

		var remediationPartnerId = selectedRemediationPartner ? selectedRemediationPartner.id : null;

		var request = {
			caseId: spCase.id,
			needsAssessment: workingNeedsAssessment,
			remediationPartnerId: remediationPartnerId,
		};

		const saveNeedsAssessment = () =>
			saveNeedsAssessmentApi.api().then((w) =>
				w
					.put(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setApiErrors(errDetail);
						throw new Error(errDetail);
					})
					.json((json) => {
						caseDispatcher({
							type: "CASE_MODIFY_NEEDS_ASSESSMENT",
							payload: workingNeedsAssessment,
						});

						dispatch({
							type: "CASE_MODIFY_REMEDIATION_PARTNER",
							payload: {
								id: spCase.id,
								remediationPartner: data.remediationPartner,
							},
						});
					})
					.then(() => toastr.success("Needs Assessment details changed"))
					.then(() => setIsDirty(false))
					.then(() => finishEditing())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to save Needs Assessment Information.");
					})
					.finally(() => setIsBusy(false))
			);

		setIsBusy(true);
		saveNeedsAssessment();
	};

	React.useEffect(() => {
		setIsDirty(JSON.stringify(spCase.needsAssessment) !== JSON.stringify(workingNeedsAssessment));
	}, [spCase.needsAssessment, workingNeedsAssessment]);

	const updateValue = (name, value) => {
		setWorkingNeedsAssessment((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmitNeedsAssessment)} ref={needsAssessmentForm} id="needsAssessmentForm">
				<Grid container spacing={2} alignItems="stretch">
					<Grid item xs={8} container spacing={1}>
						<Grid item lg={6} md={6} sm={6} xs={12}>
							<Autocomplete
								id="remediationPartnerAutocomplete"
								options={remediationPartners ? remediationPartners.map((r) => r.name) : []}
								getOptionLabel={(option) => option}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											name="remediationPartner"
											label={"Remediation Partner"}
											fullWidth
											inputRef={register({})}
											error={!!(errors && errors.remediationPartner)}
											helperText={
												errors && errors.remediationPartner && errors.remediationPartner.message
											}
											style={{ marginBottom: 16 }}
										/>
									);
								}}
								defaultValue={workingNeedsAssessment.remediationPartner}
								onChange={(e, v) => updateValue("remediationPartner", v)}
							/>
						</Grid>

						<Grid item lg={6} md={6} sm={6} xs={12}>
							<Autocomplete
								id="consentObtainedAutocomplete"
								options={yesNoAnswers ? yesNoAnswers.map((r) => r.name) : []}
								getOptionLabel={(option) => option}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											name="consentObtained"
											label={"Partner Confirms Consent Obtained"}
											fullWidth
											inputRef={register({})}
											error={!!(errors && errors.consentObtained)}
											helperText={
												errors && errors.consentObtained && errors.consentObtained.message
											}
											style={{ marginBottom: 16 }}
										/>
									);
								}}
								defaultValue={workingNeedsAssessment.consentObtained}
								onChange={(e, v) => updateValue("consentObtained", v)}
							/>
						</Grid>

						<Grid item lg={12} md={12} sm={12} xs={12}>
							<fieldset className={classes.fieldset}>
								<legend className={classes.legend}>Needs Assessment Summary </legend>
								<TextField
									fullWidth
									name="needsAssessmentSummary"
									multiline
									autoComplete="off"
									rows="6"
									error={!!(errors && errors.highRiskReason)}
									inputRef={register({})}
									inputProps={{
										"aria-label": "High Risk Reason",
									}}
									helperText={
										errors && errors.needsAssessmentSummary && errors.needsAssessmentSummary.message
									}
									value={
										(workingNeedsAssessment && workingNeedsAssessment.needsAssessmentSummary) || ""
									}
									onChange={(e) => updateValue(e.target.name, e.target.value)}
								/>
							</fieldset>
						</Grid>

						{!!apiErrors && (
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<div className={classes.row}>
									<div className={classes.error}>{apiErrors}</div>
								</div>
							</Grid>
						)}
					</Grid>
					<Grid item xs={4}></Grid>
					<Grid item xs={12}>
						<CaseTabEditingToolbar
							saveDisabled={isBusy || !isDirty}
							onCloseClick={finishEditing}
							formRef={needsAssessmentForm && needsAssessmentForm.current}
						/>
					</Grid>
				</Grid>
			</form>
		</>
	);
};

const CaseNeedsAssessmentReadOnly = ({ enableEditing, manageDocument }) => {
	const classes = useStyles();
	const { spCase } = React.useContext(CaseCtx);

	return (
		<>
			<Grid container spacing={2} alignItems="stretch">
				<Grid item md={8} sm={12} xs={12} container spacing={1}>
					<Grid item xs={12}>
						<div className={classes.row}>
							<div className={classes.label} style={{ minWidth: 160 }}>
								Remediation Partner:
							</div>
							<div className={classes.value}>{spCase.needsAssessment.remediationPartner}</div>
						</div>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.row}>
							<div className={classes.label} style={{ minWidth: 160 }}>
								Partner confirms consent obtained:
							</div>
							<div className={classes.value}>{spCase.needsAssessment.consentObtained}</div>
						</div>
					</Grid>
					<Grid item xs={12}>
						<fieldset className={classes.fieldset}>
							<legend className={classes.legend}>Needs Assessment Summary:</legend>
							<div className={classes.readOnlySummary}>
								{spCase.needsAssessment.needsAssessmentSummary || (
									<div className={classes.label}>No Summary</div>
								)}
							</div>
						</fieldset>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={12} md={4}>
					<CaseDocumentsDisplay
						title="Documents"
						caseDocuments={spCase.caseDocuments}
						manageDocument={manageDocument}
						caseDocumentType={CaseDocumentTypes.needsAssessment}
						caseId={spCase.id}
					/>
				</Grid>
				<Grid item xs={12}>
					<CaseTabReadOnlyToolbar
						onEditClick={enableEditing}
						caseId={spCase.id}
						documentType={CaseDocumentTypes.needsAssessment}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default CaseNeedsAssessment;
