import React from "react";
import { useParams, Prompt, Route } from "react-router-dom";
import useFetchCase from "../../hooks/useFetchCase";
import useStyles from "./styles";
import TabPanel from "../../components/Common/TabPanel";
import {
	Tab,
	Tabs,
	Container,
	Divider,
	Typography,
	Card,
	Chip,
	Paper,
	List,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	ListItem,
	ListItemText,
	Badge,
	Fab,
} from "@material-ui/core";
import { Description, ExitToApp } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/Common/Spinner";
import CaseClosure from "./case.manage.closure";
import CaseRemediation from "./case.manage.remediation";
import CaseNeedsAssessment from "./case.manage.needsassessment";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import CloseIcon from "@material-ui/icons/Close";
import CaseAudit from "./case.manage.audit";
import CaseDetail from "./case.manage.detail";
import CaseSupplierAgreement from "./case.manage.supplieragreement";
import CaseMonitoring from "./case.manage.monitoring";
import CaseAddDocument from "./case.document.add";
import CaseManageDocument from "./case.document.manage";
import CaseMoreFiles from "./case.manage.morefiles";
import CaseDelete from './case.manage.delete'
import { GetSectionCaseDocuments } from "./case.document.types";
import { useRecalculateCaseMetrics } from "../../hooks/useRecalculateCaseMetrics";
import {useSelector} from 'react-redux'

export const CaseCtx = React.createContext();

const CaseManage = () => {
	const history = useHistory();
	const { spCase, caseFetched, caseDispatcher } = useFetchCase();
	const [tabValue, setTabValue] = React.useState(0);
	const [editingTabs, setEditingTabs] = React.useState([]);
	const [showLoseChangesDialog, setShowLoseChangesDialog] = React.useState(false);
	const [proposedNewTabValue, setProposedNewTabValue] = React.useState();
	const currentUser = useSelector(state => state.user)
	
	const handleTabChange = (event, newValue) => {
		if (editingTabs.length > 0) {
			setProposedNewTabValue(newValue);
			setShowLoseChangesDialog(true);
		} else {
			setTabValue(newValue);
		}
	};

	function a11yProps(tabIndex) {
		return {
			id: `tab-${tabIndex}`,
			"aria-controls": `tabpanel-${tabIndex}`,
		};
	}

	function beginEdit(tabIndex) {
		setEditingTabs((state) => [...state, tabIndex]);
	}

	function finishEdit(tabIndex) {
		setEditingTabs((state) => state.filter((i) => i !== tabIndex));
	}

	const closeLoseChangesDialog = () => {
		setShowLoseChangesDialog(false);
	};

	const confirmChangeTabClick = () => {
		console.info(tabValue);
		console.info(proposedNewTabValue);

		finishEdit(tabValue);
		setTabValue(proposedNewTabValue);
		setShowLoseChangesDialog(false);
	};

	const manageDocument = (caseId, docId) => {
		history.push("/case/" + caseId + "/document/" + docId);
	};

	return (
		<CaseCtx.Provider value={{ spCase, caseDispatcher, caseFetched }}>
			<Prompt
				when={editingTabs.length > 0}
				message={"There are unsaved Case changes. Do you wish to proceed with saving changes?"}
			/>

			{!caseFetched ? (
				<Spinner show={true} />
			) : (
				<>
					<CaseMetricsHack />
					<Paper style={{ padding: 0, paddingTop: 16 }}>
						{spCase && (
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									marginTop: -24,
									marginRight: 8,
								}}>
								<Chip icon={<Description />} label={spCase.number} color="primary" />
							</div>
						)}

						<Tabs
							value={tabValue}
							onChange={handleTabChange}
							aria-label="case tabs"
							indicatorColor="primary"
							variant="scrollable"
							scrollButtons="auto">
							<Tab label="Audit" {...a11yProps(0)} style={{ minWidth: 80 }} />
							<Tab label="Case" {...a11yProps(1)} style={{ minWidth: 80 }} />
							<Tab label="Needs Assessment" {...a11yProps(2)} style={{ minWidth: 80 }} />
							<Tab label="Remediation" {...a11yProps(3)} style={{ minWidth: 80 }} />
							<Tab label="Supplier Agreement" {...a11yProps(4)} style={{ minWidth: 80 }} />
							<Tab label="Monitoring" {...a11yProps(5)} style={{ minWidth: 80 }} />
							<Tab label="Closure" {...a11yProps(6)} style={{ minWidth: 80 }} />
							<Tab label="More Documents" {...a11yProps(7)} style={{ minWidth: 80 }} />
							{
								currentUser.isAdmin &&
							<Tab label="Delete Case" {...a11yProps(8)} style={{minWidth: 80}} />
							}
						</Tabs>

						<TabPanel value={tabValue} index={0}>
							<CaseAudit
								tabIndex={0}
								beginEdit={beginEdit}
								finishEdit={finishEdit}
								manageDocument={manageDocument}
							/>
						</TabPanel>

						<TabPanel value={tabValue} index={1}>
							<CaseDetail
								tabIndex={1}
								beginEdit={beginEdit}
								finishEdit={finishEdit}
								manageDocument={manageDocument}
							/>
						</TabPanel>

						<TabPanel value={tabValue} index={2}>
							<CaseNeedsAssessment
								tabIndex={2}
								beginEdit={beginEdit}
								finishEdit={finishEdit}
								manageDocument={manageDocument}
							/>
						</TabPanel>

						<TabPanel value={tabValue} index={3}>
							<CaseRemediation
								tabIndex={3}
								beginEdit={beginEdit}
								finishEdit={finishEdit}
								manageDocument={manageDocument}
							/>
						</TabPanel>

						<TabPanel value={tabValue} index={4}>
							<CaseSupplierAgreement
								tabIndex={4}
								beginEdit={beginEdit}
								finishEdit={finishEdit}
								manageDocument={manageDocument}
							/>
						</TabPanel>

						<TabPanel value={tabValue} index={5}>
							<CaseMonitoring spCase={spCase} />
						</TabPanel>

						<TabPanel value={tabValue} index={6}>
							<CaseClosure
								tabIndex={6}
								beginEdit={beginEdit}
								finishEdit={finishEdit}
								manageDocument={manageDocument}
							/>
						</TabPanel>

						<TabPanel value={tabValue} index={7}>
							<CaseMoreFiles spCase={spCase} manageDocument={manageDocument} />
						</TabPanel>

						<TabPanel value={tabValue} index={8}>
							<CaseDelete spCase={spCase} />
						</TabPanel>

						{showLoseChangesDialog && (
							<Dialog
								open={showLoseChangesDialog}
								onClose={() => showLoseChangesDialog}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description">
								<DialogTitle id="alert-dialog-title">Editing in Progress</DialogTitle>
								<DialogContent>
									<DialogContentText id="alert-dialog-description">
										Are you sure you want to change tab?
									</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button onClick={closeLoseChangesDialog} color="primary">
										Cancel
									</Button>
									<Button onClick={() => confirmChangeTabClick()} color="primary" autoFocus>
										Continue
									</Button>
								</DialogActions>
							</Dialog>
						)}

						<Route path="/case/:caseId/add/document/:caseDocumentType">
							<CaseAddDocument />
						</Route>

						<Route path="/case/:caseId/document/:caseDocumentId">
							<CaseManageDocument />
						</Route>
					</Paper>
				</>
			)}
		</CaseCtx.Provider>
	);
};

const CaseMetricsHack = () => {
	const _ = useRecalculateCaseMetrics();

	return <div> </div>;
};

export const CaseTabEditingToolbar = ({ formRef, saveDisabled, onCloseClick }) => {
	const classes = useStyles();
	return (
		<div className={classes.toolbar}>
			<Button
				variant="outlined"
				color="primary"
				startIcon={<CloseIcon />}
				onClick={onCloseClick}
				style={{ marginRight: 8 }}>
				Cancel
			</Button>
			<Button
				variant="contained"
				color="primary"
				startIcon={<SaveIcon />}
				disabled={saveDisabled}
				type="submit"
				form={formRef && formRef.id}>
				Save
			</Button>
		</div>
	);
};

export const CaseTabReadOnlyToolbar = ({ onEditClick, documentType, caseId }) => {
	const history = useHistory();
	const classes = useStyles();

	const cancel = () => {
		history.goBack();
	};
	return (
		<div className={classes.toolbar}>
			<Button
				variant="outlined"
				color="primary"
				endIcon={<ExitToApp />}
				onClick={cancel}
				style={{ marginRight: 8 }}>
				Cancel
			</Button>
			<Button
				variant="contained"
				color="primary"
				endIcon={<EditIcon />}
				onClick={onEditClick}
				style={{ marginRight: 8 }}>
				Edit
			</Button>
		</div>
	);
};

export const CaseDocumentsDisplay = ({ title, caseId, caseDocuments, caseDocumentType, manageDocument }) => {
	const classes = useStyles();
	const history = useHistory();

	const addDocumentClick = () => {
		history.push("/case/" + caseId + "/add/document/" + caseDocumentType);
	};

	return (
		<fieldset className={classes.fieldset} style={{ height: "100%", position: "relative" }}>
			<legend className={classes.legend}>{title}</legend>
			<List>
				{GetSectionCaseDocuments(caseDocuments, caseDocumentType).map((doc, index) => (
					<CaseDocumentDisplay
						key={index}
						fileName={doc.fileName}
						clickHandler={() => {
							manageDocument(caseId, doc.caseDocumentId);
						}}
					/>
				))}
			</List>
			<Fab size="small" color="default" onClick={addDocumentClick} className={classes.fab}>
				<NoteAddIcon />
			</Fab>
		</fieldset>
	);
};

export const CaseDocumentDisplay = ({ fileName, clickHandler }) => (
	<ListItem button onClick={clickHandler}>
		<ListItemText primary={fileName} />
	</ListItem>
);

export default CaseManage;
