import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

export default function DecimalInput(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			prefix=""
			thousandSeparator={true}
			allowNegative={false}
			decimalScale={4}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						value: values.value,
					},
				});
			}}
			isAllowed={(values) => {
				const { formattedValue, floatValue } = values;
				return formattedValue === "" || floatValue < 1000000;
			}}
		/>
	);
}

DecimalInput.propTypes = {
	inputRef: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
};
