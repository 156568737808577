import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  ListItemIcon,
} from "@material-ui/core";
import useStyles from "../../components/Common/styles";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toastr } from "../../components/Common/toastr";
import { Blade, BladeSubTitle, BladeValidationDisplay, BladeTabPanel } from "../../components/Common/Blade";
import useProtectApi from "../../hooks/useProtectApi";
import useFetchUser from "../../hooks/useFetchUser";
import EmailIcon from "@material-ui/icons/Email";
import { DeleteButton } from "../../components/Common/Buttons";
import { useFetchCountries, useFetchLicenseTypes, useFetchRoles } from "../../hooks/useFetchEntities";
import { Autocomplete } from "@material-ui/lab";

const UserManage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [localUser] = useFetchUser();

  const disableCountryAccess = localUser && localUser.isAdmin;

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return !localUser ? (
    <div></div>
  ) : (
    <Blade size="Larger">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="user tabs"
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Details" {...a11yProps(0)} />
        <Tab label="Countries" {...a11yProps(1)} disabled={disableCountryAccess} />
        <Tab label="Licences" {...a11yProps(2)} />
        <Tab label="Invite" {...a11yProps(3)} />
        <Tab label="Remove" {...a11yProps(4)} />
      </Tabs>
      <BladeTabPanel value={value} index={0}>
        <UserPersonalDetails user={localUser} />
      </BladeTabPanel>

      <BladeTabPanel value={value} index={1}>
        <ManageUserCountries user={localUser} />
      </BladeTabPanel>

      <BladeTabPanel value={value} index={2}>
        <ManageUserLicenses user={localUser} />
      </BladeTabPanel>

      <BladeTabPanel value={value} index={3}>
        <SendUserInvitation user={localUser} />
      </BladeTabPanel>

      <BladeTabPanel value={value} index={4}>
        <RemoveUser user={localUser} />
      </BladeTabPanel>
    </Blade>
  );
};

const UserPersonalDetails = ({ user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userPersonalDetailsApi = useProtectApi("user/changePersonalDetails");
  const [roleNames] = useFetchRoles();

  const [isBusy, setIsBusy] = useState(false);
  const [apiErrors, setApiErrors] = useState();

  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  const onSubmit = async (data) => {
    var roleId = data.role ? roleNames.find((c) => c.name === data.role).id : null;
    var request = {
      id: user.id,
      displayName: data.name,
      telephoneNumber: data.telephone,
      jobTitle: data.jobtitle,
      role: roleId,
    };
    const changePersonalDetails = () =>
      userPersonalDetailsApi.api().then((w) =>
        w
          .put(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .json((json) => {
            console.info(json);

            dispatch({
              type: "RELOAD_USER",
              payload: json,
            });
          })
          .then(() => toastr.success("User details changed"))
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to change user personal details.");
          })
          .finally(() => setIsBusy(false))
      );

    setIsBusy(true);
    changePersonalDetails();
  };

  const closeDrawer = () => {
    history.goBack();
  };

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <BladeSubTitle text={user.emailAddress} />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section>
          <TextField
            label="Name *"
            fullWidth
            autoFocus
            name="name"
            error={!!(errors && errors.name)}
            style={{ marginBottom: 16 }}
            autoComplete="off"
            inputRef={register({
              required: {
                value: true,
                message: "Name is required",
              },
              maxLength: {
                value: 255,
                message: "Name is too long",
              },
            })}
            inputProps={{
              "aria-label": "Name",
            }}
            helperText={errors && errors.name && errors.name.message}
            defaultValue={user.displayName}
          />
        </section>

        <section name="Role">
          <Autocomplete
            id="roleAutocomplete"
            options={roleNames && roleNames.map((r) => r.name)}
            getOptionLabel={(option) => option}
            defaultValue={user.role}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  name="role"
                  label={"Role *"}
                  fullWidth
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Role is required",
                    },
                  })}
                  error={!!(errors && errors.role)}
                  helperText={errors && errors.role && errors.role.message}
                  style={{ marginBottom: 16 }}
                />
              );
            }}
          />
        </section>

        <section>
          <TextField
            label="Job Title"
            fullWidth
            name="jobtitle"
            autoComplete="off"
            error={!!(errors && errors.jobtitle)}
            inputRef={register({
              maxLength: {
                value: 255,
                message: "Job Title must be 255 characters only",
              },
            })}
            inputProps={{
              "aria-label": "Job Title",
            }}
            helperText={errors && errors.jobtitle && errors.jobtitle.message}
            style={{ marginBottom: 16 }}
            defaultValue={user.jobTitle}
          />
        </section>

        <section>
          <TextField
            label="Telephone"
            fullWidth
            name="telephone"
            autoComplete="off"
            error={!!(errors && errors.telephone)}
            inputRef={register({
              maxLength: {
                value: 255,
                message: "Telephone must be 255 characters only",
              },
            })}
            inputProps={{
              "aria-label": "Telephone",
            }}
            helperText={errors && errors.telephone && errors.telephone.message}
            style={{ marginBottom: 16 }}
            defaultValue={user.telephoneNumber}
          />
        </section>

        {!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}

        <div className={classes.drawerBladeToolbar}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            type="submit"
            className={classes.button}
            endIcon={<SaveIcon />}
            disabled={isBusy}
          >
            SAVE
          </Button>
        </div>
      </form>
    </div>
  );
};

const ManageUserCountries = ({ user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [countries] = useFetchCountries();

  const assignCountriesApi = useProtectApi("user/assignCountries");
  const [isBusy, setIsBusy] = useState(false);
  const [apiErrors, setApiErrors] = useState();
  const [checked, setChecked] = React.useState(user.countries.map((c) => c.countryId));

  const saveCountries = () => {
    var request = {
      userId: user.id,
      countries: checked,
    };

    var userCountries = [];
    checked.map((countryId) => {
      var userCountry = {
        countryId: countryId,
        name: countries.find((c) => c.id === countryId).name,
      };
      userCountries.push(userCountry);
    });

    const apiAssign = () =>
      assignCountriesApi.api().then((w) =>
        w
          .put(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .res((res) => {
            dispatch({
              type: "MODIFY_USER_COUNTRIES",
              payload: { id: user.id, countries: userCountries },
            });
          })
          .then(() => toastr.success(user.displayName + " country access modified!"))
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to modify " + user.displayName + " Countries.");
          })
          .finally(() => setIsBusy(false))
      );

    setIsBusy(true);
    apiAssign();
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      //checked
      newChecked.push(value);
    } else {
      // unchecked
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <div>
      <List>
        {countries &&
          countries.map((c) => {
            const labelId = `checkbox-list-label-${c.name}`;

            return (
              <ListItem key={c.id} role={undefined} dense button onClick={handleToggle(c.id)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(c.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={c.name} />
              </ListItem>
            );
          })}
      </List>
      {!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}

      <div className={classes.drawerBladeToolbar}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          type="submit"
          className={classes.button}
          endIcon={<SaveIcon />}
          disabled={isBusy}
          onClick={saveCountries}
        >
          SAVE
        </Button>
      </div>
    </div>
  );
};

const ManageUserLicenses = ({ user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [licenseTypes] = useFetchLicenseTypes();

  const assignLicensesApi = useProtectApi("user/assignLicenses");
  const [isBusy, setIsBusy] = useState(false);
  const [apiErrors, setApiErrors] = useState();
  const [checked, setChecked] = React.useState(user.licenseTypes.map((l) => l));

  const saveLicenses = () => {
    var request = {
      userId: user.id,
      licenseTypes: checked,
    };

    const apiAssign = () =>
      assignLicensesApi.api().then((w) =>
        w
          .put(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .json((json) => {
            dispatch({
              type: "MODIFY_USER_LICENSES",
              payload: {
                id: user.id,
                licenseTypes: checked,
                hasApplicationLicense: json.hasApplicationLicense,
                hasReportLicense: json.hasReportLicense,
              },
            });
          })
          .then(() => toastr.success(user.displayName + " license access modified"))
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to modify " + user.displayName + " Licenses.");
          })
          .finally(() => setIsBusy(false))
      );

    setIsBusy(true);
    apiAssign();
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      //checked
      newChecked.push(value);
    } else {
      // unchecked
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <div>
      <List>
        {licenseTypes &&
          licenseTypes.map((c) => {
            const labelId = `checkbox-list-label-${c.name}`;

            return (
              <ListItem key={c.id} role={undefined} dense button onClick={handleToggle(c.id)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(c.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={c.name} />
              </ListItem>
            );
          })}
      </List>
      {!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}

      <div className={classes.drawerBladeToolbar}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          type="submit"
          className={classes.button}
          endIcon={<SaveIcon />}
          disabled={isBusy}
          onClick={saveLicenses}
        >
          SAVE
        </Button>
      </div>
    </div>
  );
};

const SendUserInvitation = ({ user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userInviteApi = useProtectApi("user/sendInvitation");
  const [isBusy, setIsBusy] = useState(false);
  const [apiErrors, setApiErrors] = useState();

  const sendUserInvitation = () => {
    var request = {
      Id: user.id,
    };

    const apiSend = () =>
      userInviteApi.api().then((w) =>
        w
          .post(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .json((json) => {
            var modifiedUser = {
              ...user,
              invitationStatus: json.invitationStatus,
              canResendInvitation: json.canResendInvitation,
            };

            dispatch({
              type: "USER_INVITATION_SENT",
              payload: modifiedUser,
            });
          })
          .then(() => toastr.success("User invite sent!"))
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to send invite to User.");
          })
          .finally(() => setIsBusy(false))
      );

    setIsBusy(true);
    apiSend();
  };
  return (
    <div>
      {user.canResendInvitation && (
        <div>
          <Typography>Send user '{user.displayName}' an invite to the application?</Typography>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={() => sendUserInvitation()}
            className={classes.button}
            endIcon={<EmailIcon />}
            disabled={isBusy}
          >
            SEND INVITE
          </Button>
        </div>
      )}
      {!user.canResendInvitation && <Typography>'{user.displayName}' has accepted our Invitation.</Typography>}
      {!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}
    </div>
  );
};

const RemoveUser = ({ user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const deleteUserApi = useProtectApi("user/delete");
  const [isBusy, setIsBusy] = useState(false);
  const [apiErrors, setApiErrors] = useState();

  const [showRemoveConfirmDialog, setShowRemoveConfirmDialog] = useState(false);

  const closeDrawer = () => {
    history.goBack();
  };

  const closeRemoveDialog = () => {
    setShowRemoveConfirmDialog(false);
  };

  const confirmRemoveUserClick = () => {
    var request = {
      Id: user.id,
    };

    const apiDelete = () =>
      deleteUserApi.api().then((w) =>
        w
          .put(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .json((json) => {
            dispatch({
              type: "REMOVE_USER",
              payload: { id: json.id },
            });
          })
          .then(() => closeDrawer())
          .then(() => toastr.success("User removed!"))
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to remove " + user.displayName + ".");
          })
          .finally(() => setIsBusy(false))
      );

    setIsBusy(true);
    apiDelete();
  };

  return (
    <div>
      <Typography>Remove user '{user.displayName}' from the application?</Typography>

      <div className={classes.drawerBladeToolbar}>
        <DeleteButton
          variant="outlined"
          color="primary"
          size="medium"
          onClick={() => setShowRemoveConfirmDialog(true)}
          className={classes.button}
          disabled={isBusy}
          text="Remove"
        />
      </div>

      {!!apiErrors && <BladeValidationDisplay validationErrors={apiErrors} />}
      {showRemoveConfirmDialog && user && (
        <div>
          <Dialog
            open={showRemoveConfirmDialog}
            onClose={() => showRemoveConfirmDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Remove</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to remove {user.displayName}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeRemoveDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={() => confirmRemoveUserClick()} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default UserManage;
