import React from "react";
import useProtectApi from "./useProtectApi";
import { toastr } from "../components/Common/toastr";

const useDownloadDocument = () => {
	const downloadDocumentApi = useProtectApi("document/download/");

	const downloadDocument = (documentId, documentName) => {
		var FileSaver = require("file-saver");

		downloadDocumentApi.api().then((w) =>
			w
				.url(documentId)
				.get()
				.notFound((err) => {
					console.log(err);
					var errDetail = JSON.parse(err.text).detail;
					toastr.error("Document requested not found");
				})
				.unauthorized((err) => {
					console.log(err);
					toastr.error("You do not have permission to view this document");
					return;
				})
				.arrayBuffer((ab) => {
					const blob = new Blob([new Uint8Array(ab, 0, ab.byteLength)]);
					FileSaver.saveAs(blob, documentName);
				})
				.catch((error) => {
					console.log(error);
					toastr.error("Unable to download Document.");
				})
		);
	};

	return downloadDocument;
};

export default useDownloadDocument;
