import initialState from "../initialState";
import moment from "moment";

export const cases = (state = initialState.cases, action) => {
	switch (action.type) {
		case "LOAD_CASES":
			return [...action.payload];

		case "CREATE_CASE":
			return [...state, action.payload];

		case "CASE_MODIFY_REMEDIATION_PARTNER":
			return state.map((c) =>
				c.id !== action.payload.id
					? c
					: {
							...c,
							...action.payload,
					  }
			);

		case "CASE_MODIFY_END_DATE":
			return state.map((c) =>
				c.id !== action.payload.id
					? c
					: {
							...c,
							...action.payload,
					  }
			);

		case "CASE_MODIFY_CASE_DETAILS":
			return state.map((c) =>
				c.id !== action.payload.id
					? c
					: {
							...c,
							...action.payload,
					  }
			);

		case "CASE_MODIFY_AUDIT":
			return state.map((c) =>
				c.id !== action.payload.id
					? c
					: {
							...c,
							...action.payload,
					  }
			);

		case "CASE_MODIFY":
			return state.map((c) =>
				c.id !== action.payload.id
					? c
					: {
							...c,
							lastUpdatedDate: moment(),
					  }
			);

		case "CASE_CLOSURE_APPROVED":
			return state.map((c) =>
				c.id !== action.payload.id
					? c
					: {
							...c,
							...action.payload,
					  }
			);

		case "CASE_SITE_FOUND_NAME_CHANGED":
			return state.map((c) =>
				c.siteFound !== action.payload.oldName
					? c
					: {
							...c,
							siteFound: action.payload.name,
					  }
			);

		case "CASE_SITE_TIER1_NAME_CHANGED":
			return state.map((c) =>
				c.linkedTier1Site !== action.payload.oldName
					? c
					: {
							...c,
							linkedTier1Site: action.payload.name,
					  }
			);

		case "CASE_SITE_TIER2_NAME_CHANGED":
			return state.map((c) =>
				c.linkedTier2Site !== action.payload.oldName
					? c
					: {
							...c,
							linkedTier2Site: action.payload.name,
					  }
			);

		case "CASE_SITE_TIER3_NAME_CHANGED":
			return state.map((c) =>
				c.linkedTier3Site !== action.payload.oldName
					? c
					: {
							...c,
							linkedTier3Site: action.payload.name,
					  }
			);

		case "CASE_SUPPLIER_NAME_CHANGED":
			const GetSuppliers = (suppliers, findSupplier, replaceSupplier) => {
				var supplierArray = suppliers.split(",");

				if (!supplierArray.find((s) => s === findSupplier)) {
					return suppliers;
				}

				var pos = supplierArray.findIndex((s) => s === findSupplier);

				supplierArray[pos] = replaceSupplier;
				return supplierArray.toString();
			};

			return state.map((c) =>
				!c.suppliers
					? c
					: {
							...c,
							suppliers: GetSuppliers(c.suppliers, action.payload.oldName, action.payload.name),
					  }
			);

		case "CASE_RECALCULATE_METRICS":
			return state.map((c) =>
				c.id !== action.payload.id
					? c
					: {
							...c,
							...action.payload,
					  }
			);
			break;

		case "CASE_DELETE": 
			return state.filter((c) => c.id !== action.payload)
			break;
			
		default:
			return state;
	}
};

export default cases;
