import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Container, Fab, Tooltip, Backdrop } from "@material-ui/core";
import { Link, useHistory, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./styles";
import SupplierManage from "./supplier.manage";
import SupplierCreate from "./supplier.create";
import { useFetchSuppliers } from "../../hooks/useFetchEntities";
import Spinner from "../../components/Common/Spinner";
import AdminRoute from "../../auth/AdminRoute";

function Suppliers() {
  const history = useHistory();
  const classes = useStyles();
  const [suppliers, fetched] = useFetchSuppliers();
  const [loading, setLoading] = useState(false);
  const [showAdminOptions, setShowAdminOptions] = useState(false);

  let user = useSelector((state) => state.user);
  const onRowClick = (rowData, rowMeta) => {
    history.push("/supplier/manage/" + rowData[0]);
  };

  useEffect(() => {
    user && setShowAdminOptions(user.isAdmin);
  }, [user]);

  useEffect(() => {
    setLoading(!fetched);
  }, [fetched]);

  const gridOptions = {
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    filterType: "multiselect",
    selectableRows: "none",
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    onRowClick: onRowClick,
    print: false,
    download: false,
    storageKey: "suppliers-table",
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "number",
      label: "Supplier Code",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const fabStyle = {
    right: 20,
    position: "fixed",
  };
  return (
    <div style={{ marginRight: 32 }}>
      {showAdminOptions && (
        <Tooltip title="Add" aria-label="add">
          <Fab color="primary" aria-label="add" className={classes.createFab} component={Link} to={"/suppliers/create"}>
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
      {!loading && (
        <MUIDataTable
          title={"Suppliers"}
          data={suppliers && suppliers.filter((s) => !s.isOther)}
          columns={gridColumns}
          options={gridOptions}
        />
      )}
      <Route path="/suppliers/create">
        <SupplierCreate />
      </Route>
      <Spinner show={loading} />
    </div>
  );
}

export default Suppliers;
