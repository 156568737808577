import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "./styles";

const MUIDataTableAddButton = ({ title, clickHandler }) => {
	const classes = useStyles();

	return (
		<React.Fragment>
			<Tooltip title={title}>
				<IconButton color="primary" className={classes.iconButton} onClick={clickHandler}>
					<AddIcon className={classes.addIcon} />
				</IconButton>
			</Tooltip>
		</React.Fragment>
	);
};

export default MUIDataTableAddButton;
