import initialState from "../initialState";

export const reports = (state = initialState.reports, action) => {
	switch (action.type) {
		case "LOAD_REPORTS":
			return [...action.payload];

		default:
			return state;
	}
};

export default reports;
