import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import useProtectApi from "../../hooks/useProtectApi";
import useFetchSite from "../../hooks/useFetchSite";
import useStyles from "./styles";
import TabPanel from "../../components/Common/TabPanel";
import {
  Tab,
  Tabs,
  Container,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  TextField,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import MUIDataTable from "mui-datatables";
import MUIDataTableAddButton from "../../components/Common/MUIDataTable.Add.Button";
import SiteAddNote from "./site.note.add";
import SiteManageNote from "./site.note.manage";
import { useHistory, Route } from "react-router-dom";
import { toastr } from "../../components/Common/toastr";
import { DeleteButton } from "../../components/Common/Buttons";
import { BladeValidationDisplay } from "../../components/Common/Blade";
import FormatDate from "../../helpers/date.display.format";
import useFetchCases from "../../hooks/useFetchCases";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import {
  dateFilterChips,
  DateRangeFilters,
} from "../../components/TableFiltersDateFilters";
import {
  useFetchCountries,
  useFetchSiteTiers,
} from "../../hooks/useFetchEntities";

export const SiteCtx = React.createContext();

const SiteManage = () => {
  const { site, siteFetched, siteDispatcher } = useFetchSite();
  const [value, setValue] = React.useState(0);
  const [showAdminOptions, setShowAdminOptions] = useState(false);

  let user = useSelector((state) => state.user);

  useEffect(() => {
    user && setShowAdminOptions(user.isAdmin);
  }, [user]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return !siteFetched ? (
    <div></div>
  ) : (
    <SiteCtx.Provider value={{ site, siteDispatcher }}>
      <Paper style={{ padding: 0, paddingTop: 16 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="site tabs"
          indicatorColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
        >
          <Tab label="Details" {...a11yProps(0)} />
          <Tab label="Notes" {...a11yProps(1)} />
          <Tab label="Remove" {...a11yProps(2)} disabled={!showAdminOptions} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <SiteDetails site={site} showAdminOptions={showAdminOptions} />
          <SiteCases site={site} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <SiteNotes site={site} showAdminOptions={showAdminOptions} />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <RemoveSite site={site} />
        </TabPanel>
      </Paper>
    </SiteCtx.Provider>
  );
};

const SiteDetails = ({ site, showAdminOptions }) => {
  const [editing, setEditing] = React.useState(false);

  const enableEditing = () => {
    setEditing(true);
  };

  const finishEditing = () => {
    setEditing(false);
  };

  return editing ? (
    <SiteEditing finishEditing={finishEditing} />
  ) : (
    <SiteReadOnlyDetails
      enableEditing={enableEditing}
      showAdminOptions={showAdminOptions}
    />
  );
};

const SiteEditing = ({ finishEditing }) => {
  const { site, siteDispatcher } = React.useContext(SiteCtx);
  const [countryNames] = useFetchCountries();
  const [tierNames] = useFetchSiteTiers();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();

  const saveSiteApi = useProtectApi("site/modify");
  const siteForm = useRef();

  const [workingSite, setWorkingSite] = React.useState(site);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isBusy, setIsBusy] = React.useState(false);
  const [apiErrors, setApiErrors] = React.useState();

  const onSubmitForm = async (data, e) => {
    e.preventDefault();
    const countryId =
      countryNames.find((c) => c.name === workingSite.countryName)?.id ?? null;
    const tierId =
      tierNames.find((c) => c.name === workingSite.tierName)?.id ?? null;

    var request = {
      id: site.id,
      name: data.name,
      number: data.number,
      countryId: countryId,
      tierId: tierId,
    };
    const saveSite = () =>
      saveSiteApi.api().then((w) =>
        w
          .put(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .res(() => {
            siteDispatcher({
              type: "SITE_DETAILS_CHANGED",
              payload: {
                name: data.name,
                number: data.number,
                countryName: workingSite.countryName,
                tierName: workingSite.tierName,
              },
            });

            dispatch({
              type: "SITE_DETAILS_CHANGED",
              payload: {
                id: site.id,
                name: data.name,
                number: data.number,
              },
            });

            dispatch({
              type: "CASE_SITE_FOUND_NAME_CHANGED",
              payload: {
                siteId: site.id,
                oldName: site.name,
                name: data.name,
              },
            });
            dispatch({
              type: "CASE_SITE_TIER1_NAME_CHANGED",
              payload: {
                siteId: site.id,
                oldName: site.name,
                name: data.name,
              },
            });

            dispatch({
              type: "CASE_SITE_TIER2_NAME_CHANGED",
              payload: {
                siteId: site.id,
                oldName: site.name,
                name: data.name,
              },
            });

            dispatch({
              type: "CASE_SITE_TIER3_NAME_CHANGED",
              payload: {
                siteId: site.id,
                oldName: site.name,
                name: data.name,
              },
            });
          })
          .then(() => toastr.success("Site details changed"))
          .then(() => setIsDirty(false))
          .then(() => finishEditing())
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to save Site information.");
          })
          .finally(() => setIsBusy(false))
      );

    setIsBusy(true);
    saveSite();
  };

  React.useEffect(() => {
    setIsDirty(JSON.stringify(site) !== JSON.stringify(workingSite));
  }, [site, workingSite]);

  const updateValue = (name, value) => {
    setWorkingSite((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={8} md={10}>
          <form
            onSubmit={handleSubmit(onSubmitForm)}
            ref={siteForm}
            id="siteForm"
          >
            <section name="Site Name">
              <TextField
                label="Name *"
                name="name"
                autoFocus
                error={!!(errors && errors.name)}
                style={{ marginBottom: 16, width: 400 }}
                autoComplete="off"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Name is required",
                  },
                  maxLength: {
                    value: 255,
                    message: "Name is too long",
                  },
                })}
                inputProps={{
                  "aria-label": "Name",
                }}
                helperText={errors && errors.name && errors.name.message}
                defaultValue={site && site.name}
                onChange={(e) => updateValue(e.target.name, e.target.value)}
              />
            </section>
            <section name="Site Code">
              <TextField
                label="Code *"
                name="number"
                error={!!(errors && errors.number)}
                autoComplete="off"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Code is required",
                  },
                  maxLength: {
                    value: 255,
                    message: "Code is too long",
                  },
                })}
                inputProps={{
                  "aria-label": "Description",
                }}
                style={{ marginBottom: 16, width: 400 }}
                helperText={errors && errors.number && errors.number.message}
                defaultValue={site && site.number}
                onChange={(e) => updateValue(e.target.name, e.target.value)}
              />
            </section>
            <section>
              <Select
                label="Country *"
                name="countryName"
                error={!!(errors && errors.country)}
                style={{ marginBottom: 16, width: 400 }}
                onChange={(e) => updateValue(e.target.name, e.target.value)}
                value={workingSite && workingSite.countryName}
                inputRef={register({
                  required: "Country is required",
                })}
              >
                {countryNames &&
                  countryNames.map((r) => {
                    return (
                      <MenuItem key={r.id} value={r.name}>
                        {r.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </section>

            <section>
              <Select
                label="Tier *"
                name="tierName"
                error={!!(errors && errors.tier)}
                style={{ marginBottom: 16, width: 400 }}
                onChange={(e) => updateValue(e.target.name, e.target.value)}
                value={workingSite && workingSite.tierName}
              >
                {tierNames &&
                  tierNames.map((t) => {
                    return (
                      <MenuItem key={t.id} value={t.name}>
                        {t.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </section>
          </form>

          {!!apiErrors && (
            <BladeValidationDisplay validationErrors={apiErrors} />
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <SiteEditingToolbar
            saveDisabled={isBusy || !isDirty}
            onCloseClick={finishEditing}
            formRef={siteForm && siteForm.current}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const SiteReadOnlyDetails = ({ enableEditing, showAdminOptions }) => {
  const { site } = React.useContext(SiteCtx);
  const classes = useStyles();

  return (
    <div style={{ padding: 8 }}>
      <Grid container>
        <Grid item sm={9} xs={12}>
          <Grid item sm={9} xs={12} container spacing={1}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={classes.row}>
                <div className={classes.label}>Name:</div>
                <div className={classes.value}>{site.name}</div>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={classes.row}>
                <div className={classes.label}>Code:</div>
                <div className={classes.value}>{site.number}</div>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={classes.row}>
                <div className={classes.label}>Country:</div>
                <div className={classes.value}>{site.countryName}</div>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={classes.row}>
                <div className={classes.label}>Tier:</div>
                <div className={classes.value}>{site.tierName}</div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={3} xs={12}>
          {showAdminOptions && (
            <SiteReadOnlyToolbar onEditClick={enableEditing} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const SiteEditingToolbar = ({ formRef, saveDisabled, onCloseClick }) => {
  const classes = useStyles();

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<SaveIcon />}
        disabled={saveDisabled}
        type="submit"
        form={formRef && formRef.id}
        style={{ marginBottom: 8 }}
        fullWidth={true}
      >
        Save
      </Button>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        startIcon={<CloseIcon />}
        onClick={onCloseClick}
        fullWidth={true}
      >
        Close
      </Button>
    </div>
  );
};

const SiteReadOnlyToolbar = ({ onEditClick }) => {
  const classes = useStyles();

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<EditIcon />}
        onClick={onEditClick}
        style={{ marginBottom: 8 }}
        fullWidth={true}
      >
        Edit
      </Button>
    </div>
  );
};

const SiteCases = ({ site }) => {
  const history = useHistory();
  const [fetchedCases, casesFetched] = useFetchCases();
  const [siteCases, setSiteCases] = useState([]);

  useEffect(() => {
    casesFetched &&
      setSiteCases(fetchedCases.filter((c) => c.siteFoundId === site.id));
  }, [fetchedCases, casesFetched]);

  const onRowClick = (rowData, rowMeta) => {
    site && history.push("/case/" + rowData[0]);
  };

  const gridOptions = {
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    filterType: "multiselect",
    selectableRows: "none",
    pagination: false,
    print: false,
    download: false,
    onRowClick: onRowClick,
    elevation: 0,
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "country",
      label: "Country",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "suppliers",
      label: "Suppliers",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "dateFound",
      label: "Date Found",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return FormatDate(value);
        },
      },
    },
    {
      name: "number",
      label: "CL No",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "remediationPartner",
      label: "Remediation Partner",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "caseCategory",
      label: "Category",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "investigationStatus",
      label: "Investigation Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "remediationStatus",
      label: "Remediation Status",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "ageAtAudit",
      label: "Age When Found",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "highRisk",
      label: "High Risk",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "closureApproved",
      label: "Closed",
      options: {
        filter: true,
        sort: true,
        display: false,
        filterList: ["No"],
        customFilterListOptions: {
          render: (v) => (v === "No" ? "Open" : ""),
        },
      },
    },
    {
      name: "lastUpdateDate",
      label: "Last Update",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return FormatDate(value);
          }
          return null;
        },
      },
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return FormatDate(value);
          }
          return null;
        },
        fillterType: "custom",
        customFilterListOptions: dateFilterChips("End Date"),
        filterOptions: DateRangeFilters,
      },
    },
  ];

  return (
    <div style={{ marginTop: 16 }}>
      <MUIDataTable
        title={"Cases"}
        data={siteCases}
        columns={gridColumns}
        options={gridOptions}
      />
    </div>
  );
};

const SiteNotes = ({ site, showAdminOptions }) => {
  const history = useHistory();
  const addNote = () => {
    var url = "/site/manage/" + site.id + "/addnote";
    history.push(url);
  };

  const onRowClick = (rowData, rowMeta) => {
    site && history.push("/site/manage/" + site.id + "/note/" + rowData[0]);
  };

  const gridOptions = {
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    filterType: "multiselect",
    selectableRows: "none",
    pagination: false,
    print: false,
    download: false,
    elevation: 0,
    onRowClick: onRowClick,
    customToolbar: () => {
      return <MUIDataTableAddButton title="Add Note" clickHandler={addNote} />;
    },
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
        if (colIndex === 1) {
          return (
            (new Date(a.data[colIndex]) < new Date(b.data[colIndex]) ? -1 : 1) *
            (order === "desc" ? 1 : -1)
          );
        } else {
          return (
            (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
            (order === "desc" ? 1 : -1)
          );
        }
      });
    },
  };

  const gridColumns = [
    {
      name: "noteId",
      label: "Id",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "noteDate",
      label: "Created Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return FormatDate(value);
        },
        filterType: "custom",
        customFilterListOptions: dateFilterChips("Created Date"),
        filterOptions: DateRangeFilters,
      },
    },
    {
      name: "createdBy",
      label: "Created By",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "noteText",
      label: "Note",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  return (
    <div>
      <MUIDataTable
        title={"Notes"}
        data={site && Array.isArray(site.notes) && site.notes}
        columns={gridColumns}
        options={gridOptions}
      />
      <Route exact path="/site/manage/:siteId/addnote">
        <SiteAddNote />
      </Route>
      <Route exact path="/site/manage/:siteId/note/:noteId">
        <SiteManageNote />
      </Route>
    </div>
  );
};

const RemoveSite = ({ site }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const deleteSiteApi = useProtectApi("site/delete");
  const [isBusy, setIsBusy] = useState(false);
  const [apiErrors, setApiErrors] = useState();

  const [showRemoveConfirmDialog, setShowRemoveConfirmDialog] = useState(false);

  const closePage = () => {
    history.push("/sites");
  };

  const closeRemoveDialog = () => {
    setShowRemoveConfirmDialog(false);
  };

  const confirmRemoveSiteClick = () => {
    var request = {
      Id: site.id,
    };

    const apiDelete = () =>
      deleteSiteApi.api().then((w) =>
        w
          .put(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setApiErrors(errDetail);
            throw new Error(errDetail);
          })
          .res(() => {
            dispatch({
              type: "REMOVE_SITE",
              payload: { id: site.id },
            });
          })
          .then(() => closePage())
          .then(() => toastr.success("Site removed!"))
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to remove " + site.name + ".");
          })
          .finally(() => {
            closeRemoveDialog();
            setIsBusy(false);
          })
      );

    setIsBusy(true);
    apiDelete();
  };

  return (
    <div>
      <Typography>
        Are you sure you want to remove Site '{site.name}' from the application?
      </Typography>

      <Typography>
        If you do so this Site will no longer be available for selection for new
        cases in the application.
      </Typography>
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}
      >
        <DeleteButton
          variant="outlined"
          color="primary"
          size="medium"
          onClick={() => setShowRemoveConfirmDialog(true)}
          className={classes.button}
          disabled={isBusy}
          text="Remove"
        />
      </div>
      {!!apiErrors && (
        <div style={{ marginTop: 16 }}>
          <BladeValidationDisplay validationErrors={apiErrors} />
        </div>
      )}
      {showRemoveConfirmDialog && site && (
        <div>
          <Dialog
            open={showRemoveConfirmDialog}
            onClose={() => showRemoveConfirmDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Remove</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to remove {site.name}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeRemoveDialog} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => confirmRemoveSiteClick()}
                color="primary"
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default SiteManage;
