import React from "react";
import { Link, useLocation } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import useStyles from "./styles";

function NavLink({ to, NavIcon, text }) {
	const classes = useStyles();
	const location = useLocation();
	return (
		<Link to={to} className={classes.navLink}>
			<ListItem button>
				<ListItemIcon>
					<NavIcon />
				</ListItemIcon>
				<ListItemText primary={text} />
			</ListItem>
		</Link>
	);
}

export default NavLink;
