import React, { useRef, useEffect, useCallback, useState } from "react";
import useStyles from "./styles";
import SearchIcon from "@material-ui/icons/Search";
import {
	InputBase,
	ListItemIcon,
	ListItemText,
	Avatar,
	Menu,
	MenuItem,
	TextField,
	InputAdornment,
} from "@material-ui/core";
import useProtectApi from "../../hooks/useProtectApi";
import { toastr } from "../Common/toastr";
import debounce from "lodash/debounce";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import LocationCity from "@material-ui/icons/LocationCity";
import Business from "@material-ui/icons/Business";

function ToolbarSearch() {
	const classes = useStyles();
	const searchApi = useProtectApi("search/");
	const user = useSelector((state) => state.user);

	const [searchTerm, setSearchTerm] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [hasAppLicense, setHasAppLicense] = useState(true);
	const [isAuthorized, setIsAuthorized] = useState(true);
	const refInput = useRef();
	const history = useHistory();

	React.useEffect(() => {
		user && setIsAuthorized(user.isAuthorized);
	}, [user]);

	React.useEffect(() => {
		user && setHasAppLicense(user.hasAppLicense);
	}, [user]);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const doSearch = (q) =>
		searchApi.api().then((w) =>
			w
				.url(q)
				.get()
				.json((json) => {
					setSearchResults(json);
					refInput.current && json.length > 0 && setAnchorEl(refInput.current);
				})
				.catch((error) => {
					console.info(error);
					toastr.error("Unable to search");
				})
				.finally(() => {})
		);

	const doDebounce = useCallback(
		debounce((val) => doSearch(val), 500),
		[]
	);

	useEffect(() => {
		searchTerm.length > 1 && doDebounce(searchTerm);
	}, [searchTerm]);

	const selectEntity = (entity) => {
		handleClose();

		var _entityUrl = "";
		switch (entity.entityType) {
			case "case":
				_entityUrl = "case";
				break;
			case "site":
				_entityUrl = "site/manage";
				break;
			case "supplier":
				_entityUrl = "supplier/manage";
				break;
			case "default":
				_entityUrl = "home";
				break;
		}

		history.push(`/${_entityUrl}/${entity.entityId}`);
		setSearchTerm("");
	};

	const fetchListItemIcon = (entity) => {
		switch (entity.entityType) {
			case "case":
				return <AssignmentInd />;
				break;

			case "site":
				return <Business />;
				break;

			case "supplier":
				return <LocationCity />;
				break;

			case "default":
				return <Avatar fontSize="small">{entity.entityType.charAt(0).toUpperCase()}</Avatar>;
				break;
		}
	};

	return !hasAppLicense || !isAuthorized ? null : (
		<div className={classes.search}>
			<TextField
				fullWidth
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				ref={refInput}
				placeholder="Search…"
				InputProps={{
					disableUnderline: true,
					classes: {
						root: classes.inputRoot,
						input: classes.inputInput,
					},
					style: {
						color: "white",
						width: 300,
					},
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>

			{/* <div className={classes.searchIcon}>
				<SearchIcon />
			</div>
			<InputBase
				placeholder="Search…"
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput,
				}}
				style={{
					color: "white",
					paddingLeft: "0.75em",
				}}
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				ref={refInput}
			/> */}

			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				elevation={4}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}>
				{searchResults.map((r, i) => (
					<MenuItem onClick={() => selectEntity(r)} key={i} className={classes.menuItem}>
						<ListItemIcon>{fetchListItemIcon(r)}</ListItemIcon>
						<ListItemText primary={r.matchedTerm} secondary={r.entityType.toUpperCase()} />
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}

export default ToolbarSearch;
