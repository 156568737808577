import initialState from "../initialState";

export const documents = (state = initialState.documents, action) => {
	switch (action.type) {
		case "LOAD_DOCUMENTS":
			return [...action.payload];

		case "LOAD_DOCUMENT":
			return [...state, action.payload];

		case "CREATE_DOCUMENT":
			return [...state, action.payload];

		case "REMOVE_DOCUMENT":
			return state.filter((doc) => doc.id !== action.payload.id);

		case "DOCUMENT_ATTACHED_TO_CASE": // removed from set
			return state.filter((doc) => doc.id !== action.payload.id);

		case "DOCUMENT_REMOVED_FROM_CASE": // added to set
			return [...state, action.payload];

		case "MODIFY_DOCUMENT_DETAILS":
			return state.map((x) =>
				x.id !== action.payload.id
					? x
					: {
							...x,
							fileName: action.payload.fileName,
							country: action.payload.country,
							description: action.payload.description,
					  }
			);
		default:
			return state;
	}
};

export default documents;
