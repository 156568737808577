export const CaseDocumentTypes = Object.freeze({
	audit: 1,
	detail: 2,
	needsAssessment: 3,
	remediation: 4,
	supplierAgreement: 5,
	monitoring: 6,
	closure: 7,
	moreDocs: 8,
});

export const GetSectionCaseDocuments = (documents, caseDocumentType) => {
	return (documents ? documents : []).filter((cd) => cd.caseDocumentTypeId === caseDocumentType);
};
