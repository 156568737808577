import initialState from "../initialState";

export const users = (state = initialState.users, action) => {
	switch (action.type) {
		case "LOAD_USERS":
			return [...action.payload];

		case "LOAD_USER":
			return state
				.map((u) =>
					u.id !== action.payload.id
						? u
						: {
								...action.payload,
						  }
				)
				.sort((a, b) => (a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1));

		case "RELOAD_USER":
			return state
				.map((u) =>
					u.id !== action.payload.id
						? u
						: {
								...action.payload,
						  }
				)
				.sort((a, b) => (a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1));

		case "USER_INVITATION_SENT":
			return state.map((u) =>
				u.id !== action.payload.id
					? u
					: {
							...u,
							invitationStatus: action.payload.invitationStatus,
							canResendInvitation: action.payload.canResendInvitation,
					  }
			);

		case "MODIFY_USER_COUNTRIES":
			return state.map((c) => (c.id !== action.payload.id ? c : { ...c, countries: action.payload.countries }));

		case "USER_PERSONAL_DETAILS_CHANGED":
			return state
				.map((u) =>
					u.id !== action.payload.id
						? u
						: {
								...u,
								displayName: action.payload.displayName,
								telephoneNumber: action.payload.telephoneNumber,
								jobTitle: action.payload.jobTitle,
						  }
				)
				.sort((a, b) => (a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1));

		case "USER_COUNTRY_MODIFIED": {
			return state.map((c) => ({
				...c,
				countries: (c.countries ? c.countries : []).map((country) => ({
					...country,
					countryName: country.countryId === action.payload.id ? action.payload.name : country.countryName,
				})),
			}));
		}

		case "CREATE_USER":
			return [...state, action.payload].sort((a, b) =>
				a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1
			);

		case "REMOVE_USER":
			return state.filter((user) => user.id !== action.payload.id);

		case "MODIFY_USER_LICENSES":
			return state.map((u) =>
				u.id !== action.payload.id
					? u
					: {
							...u,
							licenseTypes: action.payload.licenseTypes,
							hasApplicationLicense: action.payload.hasApplicationLicense,
							hasReportLicense: action.payload.hasReportLicense,
					  }
			);

		default:
			return state;
	}
};

export default users;
