import React from "react";
import baseProtectApi from "../helpers/base.protect.api.js";
import { AuthProviderCtx } from "../auth/authProvider";
import { useDispatch } from "react-redux";

const useProtectApi = (url) => {
	const dispatch = useDispatch();

	const tokenProvider = React.useContext(AuthProviderCtx);
	const api = async () => {
		var token = await tokenProvider.getAccessToken();
		return baseProtectApi(token.accessToken)
			.url(url)
			.resolve((_) =>
				_.unauthorized((err) => {
					dispatch({
						type: "USER_UNAUTHORIZED",
						payload: {
							data: {
								isAuthorized: false,
							},
						},
					})
				}).error(402, (err) => {
					dispatch({
						type: "USER_UNLICENSED",
						payload: {
							data: {
								hasAppLicense: false,
							},
						},
					});
				})
			);
	};

	return { api };
};

export default useProtectApi;
