import React from "react";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import useStyles from "./styles";

function SubNavLink({ to, NavIcon, text }) {
	const classes = useStyles();
	return (
		<Link to={to} className={classes.nestedNavLink}>
			<ListItem button>
				<ListItemIcon>
					<NavIcon style={{fontSize:16}} />
				</ListItemIcon>
				<ListItemText primary={text} />
			</ListItem>
		</Link>
	);
}

export default SubNavLink;
