import { makeStyles } from "@material-ui/styles";
import { grey, red } from "@material-ui/core/colors";

const drawerWidth = 260;
const drawerBladeWidth = 450;

var purple_100 = "rgb(65,0,153)";
var purple_75 = "rgb(120,65,179)";
var purple_50 = "rgb(166,126,204)";
var purple_25 = "rgb(211,188,230)";
var purple_10 = "rgb(236,227,244)";

var blue_100 = "rgb(0,178,227)";
var blue_75 = "rgb(28,198,234)";
var blue_50 = "rgb(149,216,241)";
var blue_25 = "rgb(204,235,247)";
var blue_10 = "rgb(234,246,251)";

var green_100 = "rgb(0,168,135)";
var green_75 = "rgb(0,192,164)";
var green_50 = "rgb(96,214,192)";
var green_25 = "rgb(182,235,233)";
var green_10 = "rgb(225,246,241)";

var orange_100 = "rgb(255,95,0)";
var orange_75 = "rgb(255,140,64)";
var orange_50 = "rgb(255,179,127)";
var orange_25 = "rgb(255,217,189)";
var orange_10 = "rgb(255,239,227)";

var yellow_100 = "rgb(255,183,27)";
var yellow_75 = "rgb(255,201,98)";
var yellow_50 = "rgb(255,218,150)";
var yellow_25 = "rgb(255,236,202)";
var yellow_10 = "rgb(255,247,233)";

var red_100 = "rgb(243,39,53)";
var red_100_contrast = "rgb(255,255,255)";
var red_75 = "rgb(254,110,99)";
var red_75_contrast = "rgb(255,255,255)";
var red_50 = "rgb(255,161,147)";
var red_25 = "rgb(255,208,199)";
var red_10 = "rgb(255,235,231)";

export const useIntellyncColors = () => {
  return {
    purple_100: purple_100,
    purple_75: purple_75,
    purple_50: purple_50,
    purple_25: purple_25,
    purple_10: purple_10,

    blue_100: blue_100,
    blue_75: blue_75,
    blue_50: blue_50,
    blue_25: blue_25,
    blue_10: blue_10,

    green_100: green_100,
    green_75: green_75,
    green_50: green_50,
    green_25: green_25,
    green_10: green_10,

    orange_100: orange_100,
    orange_75: orange_75,
    orange_50: orange_50,
    orange_25: orange_25,
    orange_10: orange_10,

    yellow_100: yellow_100,
    yellow_75: yellow_75,
    yellow_50: yellow_50,
    yellow_25: yellow_25,
    yellow_10: yellow_10,

    red_100: red_100,
    red_100_contrast: red_100_contrast,
    red_75: red_75,
    red_75_contrast: red_75_contrast,
    red_50: red_50,
    red_25: red_25,
    red_10: red_10,
  };
};

export default makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    height: "100vh",
    maxHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  drawerOpen: {
    overflowY: "hidden",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 60,
  },
  main: {
    transition: "margin-left .5s",
  },
  mainArea: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    marginBottom: 48,
    marginTop: 32,
    flexGrow: 1,
    position: "relative",
  },
  drawerBlade: {
    zIndex: `${theme.zIndex.drawer} !important`,
    width: drawerBladeWidth,
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
    },
  },
  drawerBladeLarger: {
    zIndex: `${theme.zIndex.drawer} !important`,
    width: drawerBladeWidth + drawerBladeWidth * 0.4,
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
    },
  },
  drawerBladeLargest: {
    zIndex: `${theme.zIndex.drawer} !important`,
    width: drawerBladeWidth + drawerBladeWidth * 1,
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: `${theme.zIndex.drawer + 1} !important`,
  },
  footer: {
    zIndex: `${theme.zIndex.drawer + 1} !important`,
    position: "fixed",
    bottom: 0,
    backgroundColor: grey[200],
    padding: 8,
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    textAlign: "right",
    alignItems: "baseline",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  centeredToolbar: {
    justifyContent: "space-between",
  },
  drawerBladePaper: {
    width: drawerBladeWidth,
  },
  drawerBladePaperLarger: {
    width: drawerBladeWidth + drawerBladeWidth * 0.4,
  },
  drawerBladePaperLargest: {
    width: drawerBladeWidth + drawerBladeWidth * 1,
  },
  drawerBladeToolbar: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 16,
    paddingTop: 16,
    borderTopStyle: "solid",
    borderTopWidth: 0.5,
    borderTopColor: theme.palette.primary.main,
  },
  navLink: {
    textDecoration: "none",
    color: "inherit",
  },
  nestedNavLink: {
    textDecoration: "none",
    color: "inherit",
  },
  nestedListItem: {
    paddingLeft: theme.spacing(3),
  },
  userDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: 16,
    "&:div": { padding: 16 },
  },
  logo: {
    margin: 0,
    marginLeft: 8,
  },
  iFrame: {
    position: "absolute",
    top: 0,
    left: 0,
    paddingLeft: 16,
    paddingRight: 16,
    height: "100%",
    width: "100%",
  },

  // cancelButton: {
  // 	color: grey[400],
  // 	marginRight: 8
  // },
  // deleteButton: {
  // 	color: theme.palette.getContrastText(red[700]),
  // 	backgroundColor: red[700]
  // },

  // PURPLE
  Purple_100_button: {
    color: theme.palette.getContrastText(purple_100),
    backgroundColor: purple_100,
  },
  Purple_75_button: {
    color: theme.palette.getContrastText(purple_75),
    backgroundColor: purple_75,
  },
  Purple_50_button: {
    color: theme.palette.getContrastText(purple_50),
    backgroundColor: purple_50,
  },
  Purple_25_button: {
    color: theme.palette.getContrastText(purple_25),
    backgroundColor: purple_25,
  },
  Purple_10_button: {
    color: theme.palette.getContrastText(purple_10),
    backgroundColor: purple_10,
  },

  // GREEN
  Green_100_button: {
    color: theme.palette.getContrastText(green_100),
    backgroundColor: green_100,
  },
  Green_75_button: {
    color: theme.palette.getContrastText(green_75),
    backgroundColor: green_75,
  },
  Green_50_button: {
    color: theme.palette.getContrastText(green_50),
    backgroundColor: green_50,
  },
  Green_25_button: {
    color: theme.palette.getContrastText(green_25),
    backgroundColor: green_25,
  },
  Green_10_button: {
    color: theme.palette.getContrastText(green_10),
    backgroundColor: green_10,
  },

  // BLUE
  Blue_100_button: {
    color: theme.palette.getContrastText(blue_100),
    backgroundColor: blue_100,
  },
  Blue_75_button: {
    color: theme.palette.getContrastText(blue_75),
    backgroundColor: blue_75,
  },
  Blue_50_button: {
    color: theme.palette.getContrastText(blue_50),
    backgroundColor: blue_50,
  },
  Blue_25_button: {
    color: theme.palette.getContrastText(blue_25),
    backgroundColor: blue_25,
  },
  Blue_10_button: {
    color: theme.palette.getContrastText(blue_10),
    backgroundColor: blue_10,
  },

  // ORANGE
  Orange_100_button: {
    color: theme.palette.getContrastText(orange_100),
    backgroundColor: orange_100,
  },
  Orange_75_button: {
    color: theme.palette.getContrastText(orange_75),
    backgroundColor: orange_75,
  },
  Orange_50_button: {
    color: theme.palette.getContrastText(orange_50),
    backgroundColor: orange_50,
  },
  Orange_25_button: {
    color: theme.palette.getContrastText(orange_25),
    backgroundColor: orange_25,
  },
  Orange_10_button: {
    color: theme.palette.getContrastText(orange_10),
    backgroundColor: orange_10,
  },

  // YELLOW
  Yellow_100_button: {
    color: theme.palette.getContrastText(yellow_100),
    backgroundColor: yellow_100,
  },
  Yellow_75_button: {
    color: theme.palette.getContrastText(yellow_75),
    backgroundColor: yellow_75,
  },
  Yellow_50_button: {
    color: theme.palette.getContrastText(yellow_50),
    backgroundColor: yellow_50,
  },
  Yellow_25_button: {
    color: theme.palette.getContrastText(yellow_25),
    backgroundColor: yellow_25,
  },
  Yellow_10_button: {
    color: theme.palette.getContrastText(yellow_10),
    backgroundColor: yellow_10,
  },

  // RED
  Red_100_button: {
    color: theme.palette.getContrastText(red_100),
    backgroundColor: red_100,
  },
  Red_75_button: {
    color: theme.palette.getContrastText(red_75),
    backgroundColor: red_75,
  },
  Red_50_button: {
    color: theme.palette.getContrastText(red_50),
    backgroundColor: red_50,
  },
  Red_25_button: {
    color: theme.palette.getContrastText(red_25),
    backgroundColor: red_25,
  },
  Red_10_button: {
    color: theme.palette.getContrastText(red_10),
    backgroundColor: red_10,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  readOnlySummary: {
    whiteSpace: "pre-wrap",
  },
}));
