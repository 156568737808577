import initialState from "../initialState";
import { sortByName } from "../sortEntities";

export const employmentTypes = (state = initialState.employmentTypes, action) => {
	switch (action.type) {
		case "LOAD_EMPLOYMENT_TYPES":
			return [...action.payload];

		case "LOAD_EMPLOYMENT_TYPE":
			return state.map((x) =>
				x.id !== action.payload.id
					? x
					: {
							...x,
					  }
			);

		case "RENAME_EMPLOYMENT_TYPE":
			return state
				.map((x) => (x.id !== action.payload.id ? x : { ...x, name: action.payload.name }))
				.sort(sortByName);

		case "CREATE_EMPLOYMENT_TYPE":
			return [...state, action.payload].sort(sortByName);

		case "REMOVE_EMPLOYMENT_TYPE":
			return state.filter((et) => et.id !== action.payload.id);

		default:
			return state;
	}
};

export default employmentTypes;
