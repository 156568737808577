import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Container, Fab, Tooltip, Backdrop, Tab, Tabs, Grid, Typography } from "@material-ui/core";
import { Link, useHistory, Route, useParams } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "../../components/Common/styles";
import { CaseCtx } from "./case.manage";
import { Button, TextField } from "@material-ui/core";
import { BladeTabPanel } from "../../components/Common/Blade";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Blade, BladeValidationDisplay } from "../../components/Common/Blade";
import { toastr } from "../../components/Common/toastr";
import useProtectApi from "../../hooks/useProtectApi";
import { DeleteButton } from "../../components/Common/Buttons";
import FormatDate from "../../helpers/date.display.format";
import { CaseDocumentTypes, GetSectionCaseDocuments } from "./case.document.types";
import { dateFilterChips, DateRangeFilters } from "../../components/TableFiltersDateFilters";

const CaseMoreFiles = ({ tabIndex, beginEdit, finishEdit, manageDocument }) => {
  const classes = useStyles();
  const history = useHistory();
  const { spCase } = React.useContext(CaseCtx);

  const onRowClick = (rowData, rowMeta) => {
    manageDocument(spCase.id, rowData[0]);
  };

  const gridOptions = {
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    elevation: 0,
    filterType: "multiselect",
    selectableRows: "none",
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    download: false,
    onRowClick: onRowClick,
  };

  const gridColumns = [
    {
      name: "caseDocumentId",
      label: "Record Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "attachedDate",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return FormatDate(value);
        },
        filterType: "custom",
        customFilterListOptions: dateFilterChips("Date Date"),
        filterOptions: DateRangeFilters,
      },
    },
    {
      name: "attachedBy",
      label: "Added By",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },

    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "fileName",
      label: "Document",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
  ];

  const fabStyle = {
    right: 20,
    position: "fixed",
  };

  return (
    <>
      <Tooltip title="Add" aria-label="add">
        <Fab
          color="primary"
          aria-label="add"
          style={fabStyle}
          component={Link}
          to={"/case/" + spCase.id + "/add/document/" + CaseDocumentTypes.moreDocs}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <MUIDataTable
        title={"More Documents"}
        data={
          spCase &&
          Array.isArray(spCase.caseDocuments) &&
          spCase.caseDocuments.filter((cd) => cd.caseDocumentTypeId === CaseDocumentTypes.moreDocs)
        }
        columns={gridColumns}
        options={gridOptions}
      />
    </>
  );
};

export default CaseMoreFiles;
