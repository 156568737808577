import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Container, Fab, Tooltip, Backdrop, Button } from "@material-ui/core";
import { Link, useHistory, Route } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "../../components/Common/styles";
import DocumentCreate from "./document.create";
import { useFetchDocuments } from "../../hooks/useFetchEntities";
import DocumentManage from "./document.manage";
import FormatDate from "../../helpers/date.display.format";
import useDownloadDocument from "../../hooks/useDownloadDocument";
import moment from "moment";
import { dateFilterChips, DateRangeFilters } from "../../components/TableFiltersDateFilters";

function Documents() {
  const history = useHistory();
  const classes = useStyles();
  const documentDownload = useDownloadDocument();

  const [documents, fetched] = useFetchDocuments();
  const [loading, setLoading] = useState(false);

  const onCellClick = (colData, cellMeta) => {
    if (cellMeta.colIndex !== 6 && documents.length > 0) {
      var doc = documents[cellMeta.dataIndex];
      history.push(`/documents/manage/${doc.id}`);
    }
  };

  useEffect(() => {
    setLoading(!fetched);
  }, [fetched]);

  const gridOptions = {
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    filterType: "multiselect",
    selectableRows: "none",
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    onCellClick: onCellClick,
    print: false,
    download: false,
    // customSort: (data, colIndex, order) => {
    //   return data.sort((a, b) => {
    //     if (colIndex === 3) {
    //       return (new Date(a.data[colIndex]) < new Date(b.data[colIndex]) ? -1 : 1) * (order === "desc" ? 1 : -1);
    //     } else {
    //       return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === "desc" ? 1 : -1);
    //     }
    //   });
    // },
    textLabels: {
      body: {
        noMatch: "Sorry, no Documents found",
      },
    },
    storageKey: "documents-table",
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "country",
      label: "Country",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fileName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "createdDate",
      label: "Upload Date",
      options: {
        filter: true,
        sort: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let date1 = new Date(obj1.data);
            let date2 = new Date(obj2.data);
            return (moment(date1).isBefore(date2) ? -11 : 1) * (order === "asc" ? 1 : -1);
          };
        },
        filterType: "custom",
        customFilterListOptions: dateFilterChips("Upload Date"),
        filterOptions: DateRangeFilters,
        customBodyRender: (value, tableMeta, updateValue) => {
          return FormatDate(value);
        },
      },
    },
    {
      name: "createdBy",
      label: "Uploaded By",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "",
      options: {
        filter: false,
        viewColumns: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              endIcon={<GetAppIcon />}
              onClick={() => {
                documentDownload(tableMeta.rowData[0], tableMeta.rowData[2]);
              }}
            >
              Download
            </Button>
          );
        },
      },
    },
  ];

  const fabStyle = {
    right: 20,
    position: "fixed",
  };

  return (
    <div style={{ marginRight: 32 }}>
      <Tooltip title="Add" aria-label="add">
        <Fab color="primary" aria-label="add" style={fabStyle} component={Link} to={"/documents/create"}>
          <AddIcon />
        </Fab>
      </Tooltip>
      {!loading && <MUIDataTable title={"Documents"} data={documents} columns={gridColumns} options={gridOptions} />}

      <Route path="/documents/create">
        <DocumentCreate />
      </Route>
      <Route path="/documents/manage/:documentId">
        <DocumentManage />
      </Route>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default Documents;
