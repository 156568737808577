import initialState from "../initialState";
import { sortByName } from "../sortEntities";

export const remediationPartners = (state = initialState.remediationPartners, action) => {
	switch (action.type) {
		case "LOAD_REMEDIATION_PARTNERS":
			return [...action.payload];

		case "LOAD_REMEDIATION_PARTNER":
			return state.map((rp) =>
				rp.id !== action.payload.id
					? rp
					: {
							...rp,
					  }
			);

		case "RENAME_REMEDIATION_PARTNER":
			return state
				.map((x) => (x.id !== action.payload.id ? x : { ...x, name: action.payload.name }))
				.sort(sortByName);

		case "CREATE_REMEDIATION_PARTNER":
			return [...state, action.payload].sort(sortByName);

		case "REMOVE_REMEDIATION_PARTNER":
			return state.filter((rp) => rp.id !== action.payload.id);

		default:
			return state;
	}
};

export default remediationPartners;
