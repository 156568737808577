import { Fab, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { Link, Route, useHistory, useLocation } from "react-router-dom";
import Spinner from "../../components/Common/Spinner";
import { dateFilterChips, DateRangeFilters } from "../../components/TableFiltersDateFilters";
import FormatDate from "../../helpers/date.display.format";
import useFetchCases from "../../hooks/useFetchCases";
import CaseCreate from "./case.create";
import { CaseFilters } from "./case.filters";
import useStyles from "./styles";

function Cases() {
  const [cases, fetched] = useFetchCases();
  const location = useLocation();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(!fetched);
  }, [fetched]);

  const filter = React.useMemo(() => {
    const query = new URLSearchParams(location.search);
    const _filter = query.get("filter");
    return _filter ?? "";
  }, [location.search]);
  return (
    <div style={{ marginRight: 32 }}>
      <Tooltip title="Add" aria-label="add">
        <Fab color="primary" aria-label="add" className={classes.createFab} component={Link} to={"/cases/create"}>
          <AddIcon />
        </Fab>
      </Tooltip>

      {!loading ? (
        <CasesTable
          data={cases}
          filter={filter}
          key={location.search ? "quickCases" : "casesTable"}
          quickCases={!!location.search}
        />
      ) : null}

      <Route path="/cases/create">
        <CaseCreate />
      </Route>

      <Spinner show={loading} />
    </div>
  );
}

export default Cases;

function CasesTable({ data, filter, quickCases }) {
  const history = useHistory();
  const onRowClick = (rowData, rowMeta) => {
    history.push("/case/" + rowData[0]);
  };

  const gridOptions = {
    filter: true,
    filterType: "multiselect",
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    selectableRows: "none",
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    onRowClick: onRowClick,
    print: false,
    download: false,
    storageKey: quickCases ? null : "cases-table",
  };

  const gridColumns = React.useMemo(
    () => [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "country",
        label: "Country",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "suppliers",
        label: "Suppliers",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "siteFound",
        label: "Site Found",
        options: {
          filter: true,
          sort: true,
          customFilterListOptions: {
            render: (v) => "Site: " + v,
          },
        },
      },
      {
        name: "dateFound",
        label: "Date Found",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return FormatDate(value);
          },
          filterType: "custom",
          customFilterListOptions: dateFilterChips("Date Found"),
          filterOptions: DateRangeFilters,
        },
      },
      {
        name: "number",
        label: "CL No",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "remediationPartner",
        label: "Remediation Partner",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "caseCategory",
        label: "Category",
        options: {
          filter: true,
          sort: true,
          customFilterListOptions: {
            render: (v) => "Category: " + v,
          },
        },
      },
      {
        name: "investigationStatus",
        label: "Investigation Status",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "remediationStatus",
        label: "Remediation Status",
        options: {
          display: quickCases ? false : undefined,
          filter: true,
          sort: true,
        },
      },
      {
        name: "ageAtAudit",
        label: "Age When Found",
        options: {
          filter: true,
          sort: true,
          customFilterListOptions: {
            render: (v) => "Age When Found: " + v,
          },
        },
      },
      {
        name: "highRisk",
        label: "High Risk",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "closureApproved",
        label: "Closed",
        options: {
          filter: true,
          sort: true,
          customFilterListOptions: {
            render: (v) => "Closed: " + v,
          },
        },
      },
      {
        name: "lastUpdatedDate",
        label: "Last Update",
        options: {
          display: quickCases ? false : undefined,
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              return FormatDate(value);
            }
            return null;
          },
        },
      },
      {
        name: "endDate",
        label: "End Date",
        options: {
          display: quickCases ? false : undefined,
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              return FormatDate(value);
            }
            return null;
          },

          filterType: "custom",
          customFilterListOptions: dateFilterChips("End Date"),
          filterOptions: DateRangeFilters,
        },
      },
      {
        name: "linkedTier1Site",
        label: "Tier 1 Site",
        options: {
          filter: true,
          sort: true,
          display: quickCases ? false : undefined,
          customFilterListOptions: {
            render: (v) => "Linked Tier 1 Site: " + v,
          },
        },
      },
      {
        name: "linkedTier2Site",
        label: "Tier 2 Site",
        options: {
          filter: true,
          sort: true,
          display: quickCases ? false : undefined,
          customFilterListOptions: {
            render: (v) => "Linked Tier 2 Site: " + v,
          },
        },
      },
      {
        name: "linkedTier3Site",
        label: "Tier 3 Site",
        options: {
          filter: true,
          sort: true,
          display: quickCases ? false : undefined,
          customFilterListOptions: {
            render: (v) => "Linked Tier 3 Site: " + v,
          },
        },
      },
      {
        name: "nationality",
        label: "Nationality",
        options: {
          filter: true,
          sort: true,
          display: quickCases ? false : undefined,
          customFilterListOptions: {
            render: (v) => "Nationality: " + v,
          },
        },
      },
      {
        name: "isCriticalMetric",
        label: "isCriticalMetric",
        options: {
          filter: false,
          display: false,
          viewColumns: false,
          filterList: filter === CaseFilters.critical ? [true] : null,
          customFilterListOptions: {
            render: (v) => (v === true ? "Critical" : "Non-Critical"),
          },
        },
      },
      {
        name: "isEndDateApproachingMetric",
        label: "isEndDateApproachingMetric",
        options: {
          filter: false,
          display: false,
          viewColumns: false,
          filterList: filter === CaseFilters.closureDue ? [true] : null,
          customFilterListOptions: {
            render: (v) => (v === true ? "End Date Approaching" : ""),
          },
        },
      },
      {
        name: "isInvoiceDueMetric",
        label: "isInvoiceDueMetric",
        options: {
          filter: false,
          display: false,
          viewColumns: false,
          filterList: filter === CaseFilters.invDue ? [true] : null,
          customFilterListOptions: {
            render: (v) => (v === true ? "Invoice Due" : ""),
          },
        },
      },
      {
        name: "isMouNotSignedMetric",
        label: "isMouNotSignedMetric",
        options: {
          filter: false,
          display: false,
          viewColumns: false,
          filterList: filter === CaseFilters.mouDue ? [true] : null,
          customFilterListOptions: {
            render: (v) => (v === true ? "MOU Due" : ""),
          },
        },
      },
      {
        name: "isOpenMetric",
        label: "isOpenMetric",
        options: {
          filter: false,
          display: false,
          viewColumns: false,
          filterList: filter === CaseFilters.open ? [true] : null,
          customFilterListOptions: {
            render: (v) => (v === true ? "Open" : ""),
          },
        },
      },
      {
        name: "isOverdueMetric",
        label: "isOverdueMetric",
        options: {
          filter: false,
          display: false,
          viewColumns: false,
          filterList: filter === CaseFilters.overdue ? [true] : null,
          customFilterListOptions: {
            render: (v) => (v === true ? "Overdue for Update" : ""),
          },
        },
      },
      {
        name: "hasNoNeedsAssessmentMetric",
        label: "hasNoNeedsAssessmentMetric",
        options: {
          filter: false,
          display: false,
          viewColumns: false,
          filterList: filter === CaseFilters.needsDue ? [true] : null,
          customFilterListOptions: {
            render: (v) => (v === true ? "Needs Assessment Due" : ""),
          },
        },
      },
      {
        name: "hasNoRemeditionPlanMetric",
        label: "hasNoRemeditionPlanMetric",
        options: {
          filter: false,
          display: false,
          viewColumns: false,
          filterList: filter === CaseFilters.remDue ? [true] : null,
          customFilterListOptions: {
            render: (v) => (v === true ? "Remediation Plan Due" : ""),
          },
        },
      },
      {
        name: "hasOtherValue",
        label: "Other",
        options: {
          filter: true,
          display: quickCases ? false : undefined,
          viewColumns: true,
          customFilterListOptions: {
            render: (v) => (v === "Yes" ? "Other" : ""),
          },
        },
      },
    ],
    [filter]
  );
  return <MUIDataTable title={"Cases"} data={data} columns={gridColumns} options={gridOptions} />;
}
