import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthenticationState } from "react-aad-msal";
import useProtectApi from "./useProtectApi";

const useIsAuthenticated = () => {
	const dispatch = useDispatch();
	const userApi = useProtectApi("user/signin/");
	const auth = useSelector((state) => state.auth);

	React.useEffect(() => {
		const fetchUser = (account) => {
			var id = account.idTokenClaims["extension_SpdUserId"];

			userApi.api().then((w) =>
				w
					.url(id)
					.get()
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						throw new Error(errDetail);
					})
					.json((user) => {
						dispatch({
							type: "USER_AUTHENTICATED",
							payload: {
								userId: account.id,
								data: user,
							},
						});
					})
					.catch((error) => console.log(error))
			);
		};

		const setReduxUser = (account) => {
			const user = {
				displayName: account.name,
				isAdmin: account.idTokenClaims["extension_SpdRole"] === "Admin",
			};

			dispatch({
				type: "USER_AUTHENTICATED",
				payload: {
					userId: account.accountIdentifier,
					data: user,
				},
			});

			return true;
		};

		auth.account && setReduxUser(auth.account) && fetchUser(auth.account);

		(auth.state === AuthenticationState.Unauthenticated || !auth.account) &&
			dispatch({
				type: "USER_UNAUTHENTICATED",
				payload: {},
			});
	}, [auth.state, auth.account]);

	return auth.state === AuthenticationState.Authenticated;
};

export { useIsAuthenticated };
