import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import useStyles from "../../components/Common/styles";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Blade, BladeValidationDisplay } from "../../components/Common/Blade";
import { toastr } from "../../components/Common/toastr";
import useProtectApi from "../../hooks/useProtectApi";

const RemediationStatusCreate = () => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const paymentTypeCreateApi = useProtectApi("paymenttype");

  const [createErrors, setCreateErrors] = useState();
  const [isBusy, setIsBusy] = useState(false);

  const onSubmit = async (data) => {
    var request = {
      name: data.name,
    };

    const createPaymentType = () =>
      paymentTypeCreateApi.api().then((w) =>
        w
          .post(request)
          .badRequest((err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setCreateErrors(errDetail);
            throw new Error(errDetail);
          })
          .error(409, (err) => {
            console.log(err);
            var errDetail = JSON.parse(err.text).detail;
            setCreateErrors(errDetail);
            throw new Error(errDetail);
          })
          .json((json) => {
            var newPartner = {
              ...request,
              id: json.id,
            };

            dispatch({
              type: "CREATE_PAYMENT_TYPE",
              payload: newPartner,
            });

            return newPartner;
          })
          .then(() => toastr.success("Payment Type created!"))
          .then(() => closeDrawer())
          .catch((error) => {
            console.log(error);
            toastr.error("Unable to create new Payment Type.");
          })
          .finally(() => setIsBusy(false))
      );

    setIsBusy(true);
    createPaymentType();
  };

  const closeDrawer = () => {
    history.push("/paymenttypes");
  };

  return (
    <Blade title="Create New Payment Type">
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section>
            <TextField
              label="Name *"
              fullWidth
              autoFocus
              name="name"
              error={!!(errors && errors.name)}
              style={{ marginBottom: 16 }}
              autoComplete="off"
              inputRef={register({
                required: {
                  value: true,
                  message: "Name is required",
                },
                maxLength: {
                  value: 255,
                  message: "Name is too long",
                },
              })}
              inputProps={{
                "aria-label": "Name",
              }}
              helperText={errors && errors.name && errors.name.message}
            />
          </section>

          {!!createErrors && <BladeValidationDisplay validationErrors={createErrors} />}

          <div className={classes.drawerBladeToolbar}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              type="submit"
              className={classes.button}
              endIcon={<SaveIcon />}
              disabled={isBusy}
            >
              SAVE
            </Button>
          </div>
        </form>
      </div>
    </Blade>
  );
};
export default RemediationStatusCreate;
