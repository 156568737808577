import initialState from "../initialState";
import { sortByName } from "../sortEntities";

export const siteStatuses = (state = initialState.siteStatuses, action) => {
	switch (action.type) {
		case "LOAD_SITE_STATUSES":
			return [...action.payload];

		case "LOAD_SITE_STATUS":
			return state.map((ss) =>
				ss.id !== action.payload.id
					? ss
					: {
							...ss,
					  }
			);

		case "RENAME_SITE_STATUS":
			return state
				.map((x) => (x.id !== action.payload.id ? x : { ...x, name: action.payload.name }))
				.sort(sortByName);

		case "CREATE_SITE_STATUS":
			return [...state, action.payload].sort(sortByName);

		case "REMOVE_SITE_STATUS":
			return state.filter((siteStatus) => siteStatus.id !== action.payload.id);

		default:
			return state;
	}
};

export default siteStatuses;
