import React from "react";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import useStyles from "./styles";

import ToolbarSearch from "../ToolbarSearch/";
import User from "./User";

function Banner({ toggleDrawer }) {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer}
          className={classes.menuButton}
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>
        <div style={{ flexGrow: 1 }} />
        <div>
          <ToolbarSearch />
        </div>
        <User horizontal />
      </Toolbar>
    </AppBar>
  );
}

export default Banner;
