import initialState from "../initialState";
import { sortByName } from "../sortEntities";

export const remediationStatuses = (state = initialState.remediationStatuses, action) => {
	switch (action.type) {
		case "LOAD_REMEDIATION_STATUSES":
			return [...action.payload];

		case "LOAD_REMEDIATION_STATUS":
			return state.map((ss) =>
				ss.id !== action.payload.id
					? ss
					: {
							...ss,
					  }
			);

		case "RENAME_REMEDIATION_STATUS":
			return state
				.map((x) => (x.id !== action.payload.id ? x : { ...x, name: action.payload.name }))
				.sort(sortByName);

		case "CREATE_REMEDIATION_STATUS":
			return [...state, action.payload].sort(sortByName);

		case "REMOVE_REMEDIATION_STATUS":
			return state.filter((remediationStatus) => remediationStatus.id !== action.payload.id);

		default:
			return state;
	}
};

export default remediationStatuses;
