import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Container, Paper } from "@material-ui/core";
import { Sidebar } from "./Sidebar";
import Banner from "./Banner";
import useStyles from "./styles";
import Footer from "./Footer";
import { useIsAuthenticated } from "../../hooks/useIsAuthenticated";
import Spinner from "./Spinner";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

function Main({ children }) {
	const classes = useStyles();
	const isAuthenticated = useIsAuthenticated(); // do not remove this declaration
	const [open, setOpen] = React.useState(true);
	const [hasAppLicense, setHasAppLicense] = React.useState(false);
	const [isAuthorized, setIsAuthorized] = React.useState(false);

	const user = useSelector((state) => state.user);

	const toggleDrawer = () => {
		setOpen(!open);
	};

	const variants = {
		drawerClosed: { marginLeft: 60 },
		drawerOpen: { marginLeft: 260 },
	};

	React.useEffect(() => {
		user && setHasAppLicense(user.hasAppLicense);
	}, [user]);

	React.useEffect(() => {
		user && setIsAuthorized(user.isAuthorized);
	}, [user]);

	return (
		<div>
			<CssBaseline />
			<Banner toggleDrawer={toggleDrawer} />
			<Sidebar open={open} toggleDrawer={toggleDrawer} />

			<motion.div
				className={classes.mainArea}
				initial={open ? "drawerOpen" : "drawerClosed"}
				animate={open ? "drawerOpen" : "drawerClosed"}
				variants={variants}
				transition={{ duration: 0.5, ease: "easeIn", delay: 0.1 }}>
				<div className={classes.toolbar} />
				<Container maxWidth={false} className={classes.container}>
					{hasAppLicense  && isAuthorized && children}
					{!hasAppLicense && <UnlicensedUser />}
					{!isAuthorized && <UnauthorizedUser />}
				</Container>
			</motion.div>

			<Footer />

			<Spinner show={!isAuthenticated} />
		</div>
	);
}

const UnlicensedUser = () => {
	return (
		<div>
			You do not have the appropriate license to access this application. Please contact your administrator.
		</div>
	);
};

const UnauthorizedUser = () => {
	return (
		<div>
			You do not have access to the SPD application. Possible reasons for this include:
			<ul>
				<li>You do not have an SPD account.</li>
				<li>Your SPD user identity creation has not yet been completed.</li>
				<li>Your SPD user identity has not been created correctly.</li>
				<li>Your SPD user identity has been deleted or otherwise blocked.</li>
			</ul>

			Please contact the SPD administrator for assistance.
		</div>
	);
}

export default Main;
