import React from "react";
import Drawer from "@material-ui/core/Drawer";
import NavLinks from "./NavLinks";
import useStyles from "./styles";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import User from "./User";

export const Sidebar = ({ toggleDrawer, open }) => {
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      open={open}
      onClose={() => {
        toggleDrawer();
      }}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <nav className={classes.drawer} aria-label="navigation">
        <NavLinks menuOpen={open} />

{/* 
  * Remove the below comment to reinstate the user log in and avatar
  * It has been fixed to work vertically with the menu above scrolling
 */}
        {/* {open && (
          <div
            style={{
              paddingBottom: 40,
            }}
          >
            <Divider />
            <User />
          </div>
        )} */}

      </nav>
    </Drawer>
  );
};
