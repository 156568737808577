import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	row: {
		display: "flex",
		alignItems: "baseline",
		marginBottom: 8,
	},
	totalrow: {
		display: "flex",
		alignItems: "baseline",
		marginBottom: 8,
		borderTopStyle: "solid",
		borderBottomStyle: "solid",
		borderTopColor: "lightgrey",
		borderBottomColor: "lightgrey",
		borderTopWidth: 1,
		borderBottomWidth: 1,
	},
	outline: {
		borderStyle: "solid",
		borderWidth: 0.5,
		borderColor: theme.palette.grey.light,
	},
	fieldset: {
		borderStyle: "solid",
		borderWidth: 0.5,
		borderColor: theme.palette.grey.light,
		borderRadius: 4,
		paddingBottom: theme.spacing(2),
	},
	value: {
		fontSize: 13,
		fontWeight: "bold",
		whiteSpace: "pre-wrap",
		color: theme.palette.secondary.main,
		flexGrow: 1,
		maxWidth: 80,
	},
	value_l: {
		fontSize: 13,
		fontWeight: "bold",
		whiteSpace: "pre-wrap",
		color: theme.palette.secondary.main,
		flexGrow: 1,
		maxWidth: 160,
	},
	value_xl: {
		fontSize: 13,
		fontWeight: "bold",
		whiteSpace: "pre-wrap",
		color: theme.palette.secondary.main,
		flexGrow: 1,
		maxWidth: 240,
	},
	fullWidthValue: {
		fontSize: 13,
		fontWeight: "bold",
		whiteSpace: "pre-wrap",
		color: theme.palette.secondary.main,
		flexGrow: 1,
		width: "100%",
	},
	label: {
		fontSize: "0.75rem",
		fontWeight: "normal",
		color: theme.palette.grey.default,
		opacity: 0.7,
		marginRight: 4,
		width: 80,
		minWidth: 80,
	},
	label_nosize: {
		fontSize: "0.75rem",
		fontWeight: "normal",
		color: theme.palette.grey.default,
		opacity: 0.7,
		marginRight: 4,
	},
	legend: {
		fontSize: "0.75rem",
		fontWeight: "normal",
		color: theme.palette.grey.default,
	},
	fab: {
		position: "absolute",
		right: -18,
		bottom: -12,
		color: theme.palette.primary.main,
	},
	createFab: {
		right: 10,
		top: 80,
		position: "fixed",
		zIndex: 1199,
	},
	toolbar: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: 16,
		paddingTop: 16,
		borderTopStyle: "solid",
		borderTopWidth: 0.5,
		borderTopColor: theme.palette.primary.main,
	},
	section: {
		borderStyle: "solid",
		borderWidth: 0.5,
		borderColor: theme.palette.grey.light,
		padding: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	list: {
		padding: 0,
		margin: 0,
	},
	readOnlySummary: {
		whiteSpace: "pre-wrap",
	},
	red: { color: "red", fontSize: 10 },
	error: {
		color: "rgb(243,39,53)",
		fontSize: 11,
	},
}));
