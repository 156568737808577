import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Container, Fab, Tooltip, Backdrop } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, Route } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { toastr } from "../../components/Common/toastr";
import useProtectApi from "../../hooks/useProtectApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "../../components/Common/styles";
import UserManage from "./user.manage";
import UserCreate from "./user.create";

function Users() {
  const userApi = useProtectApi("User");
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const history = useHistory();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const onRowClick = (rowData, rowMeta) => {
    history.push("/users/manage/" + rowData[0]);
  };

  useEffect(() => {
    const fetchUsers = () => {
      setLoading(true);
      userApi.api().then((w) =>
        w
          .get()
          .json((json) => {
            dispatch({
              type: "LOAD_USERS",
              payload: json,
            });
          })
          .catch((error) => toastr.error("Unable to retrieve Users"))
          .finally(() => setLoading(false))
      );
    };
    fetchUsers();
  }, []);

  const gridOptions = {
    fixedHeader: true,
    tableBodyMaxHeight: "60vh",
    filterType: "multiselect",
    selectableRows: "none",
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    onRowClick: onRowClick,
    print: false,
    download: false,
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "displayName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "jobTitle",
      label: "Job Title",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "emailAddress",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "hasApplicationLicense",
      label: "Application Licence",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "hasReportLicense",
      label: "Report Licence",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
  ];

  const fabStyle = {
    right: 20,
    position: "fixed",
  };

  return (
    <div style={{ marginRight: 32 }}>
      <Tooltip title="Add" aria-label="add">
        <Fab color="primary" aria-label="add" style={fabStyle} component={Link} to={"/users/create"}>
          <AddIcon />
        </Fab>
      </Tooltip>
      {!loading && <MUIDataTable title={"Users"} data={users} columns={gridColumns} options={gridOptions} />}

      <Route exact path="/users/manage/:userId">
        <UserManage />
      </Route>
      <Route exact path="/users/create">
        <UserCreate />
      </Route>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default Users;
