import React, { useState, useEffect } from "react";
import { Drawer, Container, Typography, Collapse, Grid, IconButton, Box } from "@material-ui/core";
import useStyles from "./styles";
import useIntellyncColors from "./styles";
import { useHistory } from "react-router-dom";
import ClearIcon from "@material-ui/icons/Clear";

const Blade = ({ title, children, size = "", closeBladeOverride }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const closeBlade = () => {
    setIsOpen(false);
    setTimeout(() => {
      closeBladeOverride ? closeBladeOverride() : history.goBack();
    }, 200);
  };

  useEffect(() => setIsOpen(true), []);

  return (
    <div>
      <Collapse>
        <Drawer
          className={classes["drawerBlade" + size]}
          variant="temporary"
          anchor="right"
          open={isOpen}
          classes={{
            paper: classes["drawerBladePaper" + size],
          }}
          onClose={closeBlade}
          ModalProps={
            {
              // keepMounted: true,
              // onBackdropClick: closeBlade,
            }
          }
        >
          <Container>
            <div className={classes.toolbar} style={{ marginBottom: 16 }} />
            <div>
              <Grid container spacing={1}>
                <Grid item xs={11}>
                  <Typography variant="h6" display="block" color="primary" gutterBottom>
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <ClearIcon
                    style={{
                      marginTop: 2,
                      cursor: "pointer",
                    }}
                    onClick={closeBlade}
                  />
                </Grid>
              </Grid>
            </div>
            {children}
            <div style={{ height: 64 }}></div>
          </Container>
        </Drawer>
      </Collapse>
    </div>
  );
};

const BladeSubTitle = ({ text }) => {
  return text ? (
    <Typography variant="button" display="block" gutterBottom>
      {text}
    </Typography>
  ) : null;
};

const BladeValidationDisplay = ({ validationErrors, marginBottom = 16 }) => {
  const colours = useIntellyncColors();

  return validationErrors ? (
    <div style={{ color: "rgb(243,39,53)", marginBottom: marginBottom }}>{validationErrors}</div>
  ) : null;
};

function BladeTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export { Blade, BladeSubTitle, BladeValidationDisplay, BladeTabPanel };
