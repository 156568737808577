import { Button, Typography, Dialog, DialogActions, DialogTitle, TextField, DialogContentText, DialogContent } from '@material-ui/core'
import { Close, Delete } from '@material-ui/icons'
import { DeleteButton } from "../../components/Common/Buttons";
import useStyles from './styles'
import React from 'react'
import useProtectApi from '../../hooks/useProtectApi'
import { toastr } from "../../components/Common/toastr";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {CaseCtx} from './case.manage'

const  CaseDelete = ({spCase}) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const handleClose = () => setDialogOpen(false)
  const classes = useStyles();

  return (
    <div>
    <Typography>
     Are you sure you want to delete case {spCase.number} from the application?
    </Typography>

    <div className={classes.toolbar}>
			<DeleteButton
				variant="contained"
        text={"DELETE"}
				color="primary"
        onClick={() => setDialogOpen(true)}
				startIcon={<Delete />}
				type="button"
			/>
			

		</div>
    <DeleteCaseDialog open={dialogOpen} handleClose={handleClose} number={spCase.number} caseId={spCase.id} />
    </div>
  )
}

export default CaseDelete

function DeleteCaseDialog({open, handleClose, caseId, number}) {
  const {spCase} = React.useContext(CaseCtx)
  const caseHasDocumentsAttached = spCase.caseDocuments?.length > 0
  const isMonitoringCases = spCase.caseMonitorings?.length > 0
  const deleteCaseApi = useProtectApi(`case/${caseId}`)
  const history = useHistory();
  const dispatch = useDispatch()
  const [apiIsBusy, setApiIsBusy] = React.useState(false)
  const [deleteField, setDeleteField] = React.useState('')

  React.useEffect(() => {
    setDeleteField("")
  }, [open])

  const handleConfirm = () => {
    setApiIsBusy(true)
   deleteCaseApi
   .api()
   .then((w) =>
				w
					.delete()
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
            throw new Error(errDetail);
					})
					.res((res) => {
            dispatch({type: 'CASE_DELETE', payload: caseId})
            history.push('/cases')
					})
					.then(() => toastr.success("Case deleted."))
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to delete case.");
					})
					.finally(() => {
            handleClose()
            setApiIsBusy(false)
          })
			);
   }
  

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="confirm-case-delete">
      <DialogTitle id="confirm-case-delete">Confirm Delete</DialogTitle>
      <DialogContent>
        {caseHasDocumentsAttached ? (
          <DialogContentText>This case has documents attached. Please remove before deleting.</DialogContentText>
        ) : isMonitoringCases ? (
          <DialogContentText>This case has monitoring entries. Please remove before deleting.</DialogContentText>
        ) : (
          <>
            <DialogContentText>
              To delete case {number} please confirm by typing "DELETE" in the text box.
            </DialogContentText>
            <TextField
              value={deleteField}
              onChange={(e) => setDeleteField(e.target.value)}
              autoFocus
              margin="dense"
              id="deleteField"
              label="Confirm Delete"
              placeholder="DELETE"
              type="text"
              fullWidth
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <DeleteButton
          text={"DELETE"}
          onClick={handleConfirm}
          color="primary"
          disabled={deleteField !== "DELETE" || apiIsBusy}
        >
          /
        </DeleteButton>
      </DialogActions>
    </Dialog>
  );
}