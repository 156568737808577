import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "../components/Common/toastr";
import useProtectApi from "../hooks/useProtectApi";

const useFetchUser = () => {
	let { userId } = useParams();

	const dispatch = useDispatch();
	const userApi = useProtectApi("user/" + userId);

	const [fetchedUser, setFetchedUser] = useState();
	const [fetched, setFetched] = useState(false);

	let users = useSelector((state) => state.users);

	useEffect(() => {
		let user = users.find((c) => c.id === userId);
		const fetchUser = () => {
			userApi.api().then((w) =>
				w
					.get()
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						throw new Error(errDetail);
					})
					.json((user) => {
						dispatch({
							type: "LOAD_USER",
							payload: user,
						});

						setFetchedUser(user);
						setFetched(true);
					})
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to retrieve User details.");
					})
			);
		};

		!user ? fetchUser() : setFetchedUser(user);
	}, [userId, users]);

	return [fetchedUser, fetched];
};

export default useFetchUser;
