import initialState from "../initialState";
import { sortByIsOther } from "../sortEntities";

export const nationalities = (state = initialState.nationalities, action) => {
	switch (action.type) {
		case "LOAD_NATIONALITIES":
			return [...action.payload];

		case "LOAD_NATIONALITY":
			return state.map((n) =>
				n.id !== action.payload.id
					? n
					: {
							...n,
					  }
			);

		case "CREATE_NATIONALITY":
			return [...state, action.payload].sort(sortByIsOther);

		case "RENAME_NATIONALITY":
			return state
				.map((x) => (x.id !== action.payload.id ? x : { ...x, name: action.payload.name }))
				.sort(sortByIsOther);

		case "REMOVE_NATIONALITY":
			return state.filter((nat) => nat.id !== action.payload.id);

		default:
			return state;
	}
};

export default nationalities;
