import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import useStyles from "../../components/Common/styles";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Blade, BladeValidationDisplay } from "../../components/Common/Blade";
import { toastr } from "../../components/Common/toastr";
import useProtectApi from "../../hooks/useProtectApi";

const TierCreate = () => {
	const classes = useStyles();
	const { register, handleSubmit, errors } = useForm();
	const history = useHistory();
	const dispatch = useDispatch();

	const tierCreateApi = useProtectApi("tier/create");

	const [createErrors, setCreateErrors] = useState();
	const [isBusy, setIsBusy] = useState(false);

	const onSubmit = async (data) => {
		var request = {
			name: data.name,
		};

		const createTier = () =>
			tierCreateApi.api().then((w) =>
				w
					.post(request)
					.badRequest((err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setCreateErrors(errDetail);
						throw new Error(errDetail);
					})
					.error(409, (err) => {
						console.log(err);
						var errDetail = JSON.parse(err.text).detail;
						setCreateErrors(errDetail);
						throw new Error(errDetail);
					})
					.json((json) => {
						var newTier = {
							...request,
							id: json.id,
						};

						dispatch({
							type: "CREATE_TIER",
							payload: newTier,
						});

						return newTier;
					})
					.then(() => toastr.success("Tier created"))
					.then(() => closeDrawer())
					.catch((error) => {
						console.log(error);
						toastr.error("Unable to create new Tier.");
					})
					.finally(() => setIsBusy(false))
			);

		setIsBusy(true);
		createTier();
	};

	const closeDrawer = () => {
		history.push("/tiers");
	};

	return (
		<Blade title="Create New Tier">
			<div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<section>
						<TextField
							label="Name *"
							fullWidth
							name="name"
							error={!!(errors && errors.name)}
							style={{ marginBottom: 16 }}
							autoComplete="off"
							inputRef={register({
								required: {
									value: true,
									message: "Name is required",
								},
								maxLength: {
									value: 255,
									message: "Name is too long",
								},
							})}
							inputProps={{
								"aria-label": "Name",
							}}
							helperText={errors && errors.name && errors.name.message}
						/>
					</section>

					{!!createErrors && <BladeValidationDisplay validationErrors={createErrors} />}

					<div className={classes.drawerBladeToolbar}>
						<Button
							variant="contained"
							color="primary"
							size="medium"
							type="submit"
							className={classes.button}
							endIcon={<SaveIcon />}
							disabled={isBusy}>
							SAVE
						</Button>
					</div>
				</form>
			</div>
		</Blade>
	);
};
export default TierCreate;
